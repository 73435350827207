///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Icon } from 'rfbp_core/components/icons/icon'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// Allowed Data Types = { string, number, boolean, date, time, datetime, option_boolean }

// TODO: On Edit also need to edit returnTableColumnsFromDatabaseFormDataFormat AND maybe some other places
export const formTypeOptions: TsInterface_UnspecifiedObject = {
  display: {
    key: 'display',
    value: rLIB('Display Text'),
    icon: (
      <Icon
        icon="quote-left"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: {},
      example_form_input: null,
    },
  },
  hyperlink: {
    key: 'hyperlink',
    value: rLIB('Hyperlink'),
    icon: (
      <Icon
        icon="link"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: {},
      example_form_input: null,
    },
  },
  short_answer: {
    key: 'short_answer',
    value: rLIB('Short Answer'),
    icon: (
      <Icon
        icon="horizontal-rule"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      // hardcode_input_type: "text",
      allowed_data_types: { string: 'full', number: 'warning' },
      example_form_input: 'string',
    },
  },
  number: {
    key: 'number',
    value: rLIB('Number'),
    icon: (
      <Icon
        icon="input-numeric"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      // hardcode_input_type: "number",
      allowed_data_types: { number: 'full' },
      example_form_input: 'number as string',
    },
  },
  paragraph: {
    key: 'paragraph',
    value: rLIB('Paragraph'),
    icon: (
      <Icon
        icon="align-justify"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      // hardcode_input_type: "text",
      allowed_data_types: { string: 'full' },
      example_form_input: 'string',
    },
  },
  multiple_choice: {
    key: 'multiple_choice',
    value: rLIB('Multiple Choice'),
    icon: (
      <Icon
        icon="circle-dot"
        type="solid"
      />
    ),
    has_options: true,
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      // hardcode_input_type: "text",
      allowed_data_types: { string: 'full', number: 'warning', boolean: 'warning' },
      example_form_input: 'string',
    },
  },
  checkboxes: {
    key: 'checkboxes',
    value: rLIB('Checkboxes'),
    icon: (
      <Icon
        icon="square-check"
        type="solid"
      />
    ),
    has_options: true,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: { option_boolean: 'full' },
      example_form_input: { optionString: '"true" | "false"' },
    },
  },
  dropdown: {
    key: 'dropdown',
    value: rLIB('Dropdown'),
    icon: (
      <Icon
        icon="circle-caret-down"
        type="solid"
      />
    ),
    has_options: true,
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      // hardcode_input_type: "text",
      allowed_data_types: { string: 'full', number: 'warning', boolean: 'warning' },
      example_form_input: 'string',
    },
  },
  date: {
    key: 'date',
    value: rLIB('Date'),
    icon: (
      <Icon
        icon="calendar-day"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: { date: 'full' },
      example_form_input: '2024-01-29',
    },
  },
  time: {
    key: 'time',
    value: rLIB('Time'),
    icon: (
      <Icon
        icon="clock"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: { time: 'full' },
      example_form_input: '23:02',
    },
  },
  datetime: {
    key: 'datetime',
    value: rLIB('Datetime'),
    icon: (
      <Icon
        icon="calendar-clock"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: { datetime: 'full' },
      example_form_input: '2023-12-22T00:29',
    },
  },
  toggle: {
    key: 'toggle',
    value: rLIB('Toggle'),
    icon: (
      <Icon
        icon="toggle-on"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: { boolean: 'full' },
      example_form_input: '"true" | "false"',
    },
  },
  image_upload: {
    key: 'image_upload',
    value: rLIB('Image Upload'),
    icon: (
      <Icon
        icon="image"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      // hardcode_input_type: null,
      allowed_data_types: { string: 'full' },
      example_form_input: 'string',
    },
  },
  feet_inch_gauge: {
    key: 'feet_inch_gauge',
    value: rLIB('Feet Inches Gauge'),
    icon: (
      <Icon
        icon="gauge"
        type="solid"
      />
    ),
    has_options: false,
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      // hardcode_input_type: "number",
      allowed_data_types: { number: 'full' },
      example_form_input: 'number as string',
    },
  },
  directory_multiple_choice: {
    key: 'directory_multiple_choice',
    value: rLIB('Directory Multiple Choice'),
    icon: (
      <Icon
        icon="cabinet-filing"
        type="solid"
      />
    ),
    analysis_procedure_data: {
      allow_hardcode_comparison: true,
      allowed_data_types: { string: 'full', number: 'warning', boolean: 'warning' },
      example_form_input: 'string',
    },
  },
  directory_field_verification: {
    key: 'directory_field_verification',
    value: rLIB('Directory Verification'),
    icon: (
      <Icon
        icon="lock-keyhole"
        type="solid"
      />
    ),
    analysis_procedure_data: {
      allow_hardcode_comparison: false,
      allowed_data_types: {},
      example_form_input: null,
    },
  },
  // photo
  // feet_inches
}

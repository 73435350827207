///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { returnFormInputsFromDatabaseDataFormat, returnTableColumnsFromDatabaseFormDataForPersistentDirectoryItems } from 'app/services/forms/form_services'
import pluralize from 'pluralize'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ActivePersistentClasses_Query,
  DatabaseRef_ClassFields_Collection,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_DirectoryItemLogs_Document,
  DatabaseRef_DirectoryNameSearch_Query,
  DatabaseRef_Directory_Collection,
  DatabaseRef_Directory_Document,
} from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsInterface_InputHooksObject,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { rJSX_HighlightedSearchString } from 'rfbp_core/components/search'
import {
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
} from 'rfbp_core/components/table'
import { TableDatabase } from 'rfbp_core/components/table/table_database'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { DirectoryItemViewDialog } from './components/dialog_directory_view'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DirectoryIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const singularizeNoun = (noun: string) => {
  // Define some common irregular plural forms
  const irregularPlurals: TsInterface_UnspecifiedObject = {
    women: 'woman',
    men: 'man',
    children: 'child',
    // Add more irregular plurals as needed
  }

  // Check if the noun is in the irregular plurals list
  if (Object.prototype.hasOwnProperty.call(irregularPlurals, noun)) {
    return irregularPlurals[noun]
  }

  // Define some common plural suffixes
  const pluralSuffixes = ['s', 'es', 'ies']

  // Check if the noun ends with a plural suffix
  for (const suffix of pluralSuffixes) {
    if (noun.endsWith(suffix)) {
      // Remove the plural suffix to get the singular form
      return noun.slice(0, -suffix.length)
    }
  }

  // If no special cases or plural suffixes are found, return the noun as is
  return noun
}

const defaultTableSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formFields, us_setFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingFormFields, us_setLoadingFormFields] = useState<boolean>(false)
  const [us_selectedClass, us_setSelectedClass] = useState<string>('')
  const [us_tableColumns_DirectoryItems, us_setTableColumns_DirectoryItems] = useState<TsInterface_TableColumns>({})
  const [us_tableSettings_DirectoryItems, us_setTableSettings_DirectoryItems] = useState<TsInterface_TableDatabaseSettings>(defaultTableSettings)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Directory', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActivePersistentClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // us_setLoadedFormFields( true )
      us_setFormFields(newData)
      us_setLoadingFormFields(false)
      ur_forceRerender()
    }
    if (us_selectedClass != null && us_selectedClass !== '') {
      us_setLoadingFormFields(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_selectedClass), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      // us_setLoadedFormFields( false )
      us_setFormFields({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedClass])

  useEffect(() => {
    us_setTableColumns_DirectoryItems(returnTableColumnsFromDatabaseFormDataForPersistentDirectoryItems(us_formFields, {}, {}))
  }, [us_formFields])

  useEffect(() => {
    const rJSX_SearchResult = (
      option: TsInterface_UnspecifiedObject,
      searchInputValue: string | null,
      inputHooks: TsInterface_InputHooksObject,
    ): JSX.Element => {
      let searchResultJSX = (
        <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
          <Typography>{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
          <Button
            color={'info'}
            onClick={() => {
              if (option.key != null && us_selectedClass != null && us_selectedClass !== '') {
                uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: (
                      <DirectoryItemViewDialog
                        classKey={us_selectedClass}
                        itemKey={option.key as string}
                      />
                    ),
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              }
            }}
            variant="contained"
            sx={{ marginBottom: '5px' }}
            startIcon={<Icon icon="magnifying-glass" />}
          >
            {rLIB('View')}
          </Button>
          <Divider />
        </Box>
      )
      return searchResultJSX
    }

    const tableSettings: TsInterface_TableDatabaseSettings = {
      rows_per_page: 100,
      show_header: true,
      size: 'small',
      sort_direction: 'asc',
      sort_property: 'name',
      use_live_data: true,
      searchable: true,
      search_settings_database: {
        search_type: 'meilisearch',
        search_client_key: uc_RootData_ClientKey,
        search_index_key: us_selectedClass,
        search_filters: ["status = 'active'"],
        search_result_renderer: rJSX_SearchResult,
      },
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    us_setTableSettings_DirectoryItems(tableSettings)
  }, [uc_RootData_ClientKey, uc_setUserInterface_CustomDialogDisplay, us_selectedClass])

  // Functions
  const tableDatabaseEndpoint_DirectoryItems = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_Directory_Collection(uc_RootData_ClientKey as string, us_selectedClass),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createItem = () => {
    let formFields = returnFormInputsFromDatabaseDataFormat(us_formFields, uc_RootData_ClientKey, {})
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formFields,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let promiseArray: TsType_UnknownPromise[] = []
                  let nameMatchSearchResults: TsInterface_UnspecifiedObject = {}
                  promiseArray.push(
                    DatabaseGetCollection(DatabaseRef_DirectoryNameSearch_Query(res_GCK.clientKey, us_selectedClass, formSubmittedData['name']))
                      .then((res_DGC) => {
                        nameMatchSearchResults = getProp(res_DGC, 'data', {})
                      })
                      .catch((rej_DGC) => {
                        console.error(rej_DGC)
                      }),
                  )
                  Promise.all(promiseArray).finally(() => {
                    if (objectToArray(nameMatchSearchResults).length === 0) {
                      // let itemKey = keyFromString(removeSpecialCharactersAndTrimString(formSubmittedData['name'])) + '_' + uuidv4()
                      let itemKey = uuidv4()
                      formSubmittedData['key'] = itemKey
                      formSubmittedData['status'] = 'active'
                      formSubmittedData['timestamp_created'] = new Date()
                      let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_Directory_Document(res_GCK.clientKey, us_selectedClass, itemKey),
                          data: formSubmittedData,
                        },
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_DirectoryItemLogs_Document(res_GCK.clientKey, us_selectedClass, itemKey, logKey),
                          data: {
                            key: logKey,
                            timestamp: new Date(),
                            text: getProp(formSubmittedData, 'name', ' Directory Item') + ' created',
                            associated_user_name: getProp(uc_RootData_GlobalUser, 'name', ''),
                          },
                        },
                      ]
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          resolve(res_DBU)
                        })
                        .catch((rej_DBU) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                          reject(rej_DBU)
                        })
                    } else {
                      let error = {
                        message: rLIB('Failed to create directory item'),
                        details: rLIB('A directory item with that name already exists'),
                        code: 'ER-D-DI-CI-01',
                      }
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                      reject({ success: false, error: error })
                    }
                  })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('New')} {singularizeNoun(getProp(getProp(us_activeClasses, us_selectedClass, {}), 'name', ''))}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_SelectedClassDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl
          className="bp_thin_select_input"
          sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px', background: themeVariables.white }}
        >
          <Select
            onChange={(event, value) => {
              us_setSelectedClass(event.target.value)
              us_setLoadingFormFields(true)
            }}
            value={us_selectedClass || ''}
          >
            {objectToArray(us_activeClasses)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_NewDataButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_selectedClass !== '') {
      buttonJSX = (
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            createItem()
          }}
          startIcon={<Icon icon="circle-plus" />}
          className="tw-mr-2"
        >
          {rLIB('Create')} {singularizeNoun(getProp(getProp(us_activeClasses, us_selectedClass, {}), 'name', ''))}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ImportDataButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (us_selectedClass !== '') {
      let importMappingOptions: TsInterface_UnspecifiedObject = {}
      for (let loopFormFieldKey in us_formFields) {
        let loopFormField = us_formFields[loopFormFieldKey]
        if (loopFormField != null && loopFormField.status === 'active') {
          importMappingOptions[loopFormFieldKey] = {
            key: loopFormFieldKey,
            required: loopFormField.required,
            label: loopFormField.name,
            automatch_properties: [loopFormField.name],
          }
        }
      }
      // JSX
      buttonJSX = (
        <BasicImportButtonAndDialog
          importAdditionalData={{}}
          importButtonColor={'info'}
          importButtonShrink={shrink}
          importButtonText={
            <>
              {rLIB('Import')} {pluralize(getProp(getProp(us_activeClasses, us_selectedClass, {}), 'name', ''))}
            </>
          }
          importDialogHeader={rLIB('Confirm Import')}
          importMappingOptions={importMappingOptions}
          importButtonDisabled={us_loadingFormFields}
          importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
            return new Promise((resolve, reject) => {
              try {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                for (let loopImportIndex in spreadsheetData) {
                  let loopImportItem = spreadsheetData[loopImportIndex]
                  let cleanImportItem: TsInterface_UnspecifiedObject = {}
                  for (let loopImportFieldKey in loopImportItem) {
                    let loopImportValue = loopImportItem[loopImportFieldKey]
                    if (loopImportValue != null && loopImportValue !== '') {
                      cleanImportItem[loopImportFieldKey] = loopImportValue.trim()
                    }
                  }
                  if (cleanImportItem['name'] != null) {
                    // Previously protected against duplicates but that functionality could have unexpected results
                    let itemKey = uuidv4()
                    // let itemKey = keyFromString(removeSpecialCharactersAndTrimString(cleanImportItem['name'])) + '_import'
                    cleanImportItem['key'] = itemKey
                    cleanImportItem['status'] = 'active'
                    cleanImportItem['timestamp_created'] = new Date()
                    let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Directory_Document(uc_RootData_ClientKey as string, us_selectedClass, itemKey),
                      data: cleanImportItem,
                    })
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_DirectoryItemLogs_Document(uc_RootData_ClientKey as string, us_selectedClass, itemKey, logKey),
                      data: {
                        key: logKey,
                        timestamp: new Date(),
                        text: getProp(cleanImportItem, 'name', ' Directory Item') + ' created',
                        associated_user_name: getProp(uc_RootData_GlobalUser, 'name', ''),
                      },
                    })
                  }
                }
                DatabaseStagedBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    resolve(res_DBU)
                  })
                  .catch((rej_DBU) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                    reject(rej_DBU)
                  })
              } catch (err) {
                let error = {
                  message: rLIB('Failed to import data'),
                  details: rLIB('An error occurred while trying to import the data'),
                  code: 'ER-D-DI-01',
                }
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                reject(error)
              }
            })
          }}
        />
      )
    }
    return buttonJSX
  }

  const rJSX_DataTable = (): JSX.Element => {
    let tabContentJSX = <></>
    if (us_selectedClass !== '') {
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{
                associated_class_key: us_selectedClass,
              }}
              tableColumns={us_tableColumns_DirectoryItems}
              tableDatabaseEndpoint={tableDatabaseEndpoint_DirectoryItems}
              tableSettings={us_tableSettings_DirectoryItems}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  // const rJSX_FixIndexButton = (): JSX.Element => {
  //   let buttonJSX = (
  //     <Button
  //       variant="contained"
  //       color="secondary"
  //       onClick={() => {
  //         console.log('FIX INDEX')
  //         console.log(uc_RootData_ClientKey)
  //         console.log(us_selectedClass)
  //         cloudFunctionManageRequest('manageSearch', {
  //           function: 'addStatusToIndexSettings',
  //           client_key: uc_RootData_ClientKey as string,
  //           search_index_key: us_selectedClass,
  //         })
  //           .then((res_CFMUR: unknown) => {
  //             console.log(res_CFMUR)
  //           })
  //           .catch((rej_CFMUR) => {
  //             console.error(rej_CFMUR)
  //           })
  //       }}
  //     >
  //       Fix Index
  //     </Button>
  //   )
  //   return buttonJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Directory')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              {rJSX_SelectedClassDropdown()}
              {rJSX_NewDataButton()}
              {rJSX_ImportDataButton(false)}
              {/* {rJSX_FixIndexButton()} */}
            </Box>
            {rJSX_DataTable()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

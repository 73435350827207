//////////////////////////////////////////
// React
import { useContext, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
// Config
import {
  ApplicationMajorPages,
  ApplicationNavPages,
  ApplicationPages,
  EmptyApplicationNavigationObject,
  generateActiveUserApplicationPermissions,
  generateApplicationNavigationObject,
  TsInterface_NavigationObject,
  TsInterface_NavPage,
} from 'rfbp_aux/data/application_structure' // OUTSIDE BOILERPLATE
import { Icon } from 'rfbp_core/components/icons'
// Context
import {
  Context_RootData_AuthenticatedUser,
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_RootData_UserPermissions,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_NavBar,
} from 'rfbp_core/services/context'
import { cloneObjectWithoutReference, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
// Typescript
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
// MUI Components
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material/'
import MuiAppBar from '@mui/material/AppBar'
import {
  returnNavBarAppBarBackgroundColorSx,
  returnNavBarAppBarTextColorSx,
  returnNavBarBackgroundColorSx,
  returnNavBarSelectedLinkColorSx,
  returnNavBarUnselectedLinkColorSx,
  rJSX_DynamicApplicationLogoSvg,
} from '../components/dynamic_app_branding'
import { themeVariables } from '../config/app_theme'

// Third Party

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  content: JSX.Element
  pageHeader: JSX.Element | string
  pageKey: string
}

interface TsInterface_NavLinkStyle {
  'py': string
  'px': number
  'color'?: string
  'fontWeight'?: number
  '&:hover, &:focus': {
    bgcolor: string
  }
  'borderLeft': string
}

interface TsInterface_ItemCategoryStyle {
  boxShadow: string
  py: number
  px: number
}

///////////////////////////////
// Variables
///////////////////////////////

// Other
const drawerWidth: number = 250
const itemCategory: TsInterface_ItemCategoryStyle = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
}

// Colors
const unselectedNavColor = returnNavBarUnselectedLinkColorSx()
const appBarBackgroundColor = returnNavBarAppBarBackgroundColorSx()
const appBarTextColor = returnNavBarAppBarTextColorSx()

///////////////////////////////
// Functions
///////////////////////////////

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const getMainWidth = (open: boolean): string => {
  let width = '100%'
  if (open) {
    width = `calc(100% - ${drawerWidth + 16}px)`
  }
  return width
}

const getMainLeftMargin = (open: boolean): string => {
  let width = `-${drawerWidth}px`
  if (open) {
    width = `-${drawerWidth + 16}px`
  }
  return width
}

// @ts-expect-error
const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  width: getMainWidth(open),
  // padding: theme.spacing( 3 ),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: getMainLeftMargin(open),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => prop !== 'open',
  // @ts-expect-error
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth + 0}px)`,
    marginLeft: `${drawerWidth + 0}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const NavOpenKeyListener = (props: any) => {
  // Props
  const openNav: any = getProp(props, 'openNav', () => {})
  const closeNav: any = getProp(props, 'closeNav', () => {})
  const navOpen: any = getProp(props, 'navOpen', null)

  // Hooks - useEffect
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.metaKey && event.keyCode === 66) {
        setTimeout(() => {
          if (navOpen === true) {
            closeNav()
          }
          if (navOpen === false) {
            openNav(true)
          }
        }, 1)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [openNav, closeNav, navOpen])

  return null
}

///////////////////////////////
// Component
///////////////////////////////

export const AuthenticatedContainer = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  const pr_pageContent: TsInterface_ComponentProps['content'] = getProp(props, 'content', <></>)
  const pr_pageHeader: TsInterface_ComponentProps['pageHeader'] = getProp(props, 'pageHeader', <></>)
  const pr_pageKey: TsInterface_ComponentProps['pageKey'] = getProp(props, 'pageKey', '')

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const { uc_RootData_AuthenticatedUser } = useContext(Context_RootData_AuthenticatedUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_RootData_UserPermissions, uc_setRootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  const { uc_UserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay } = useContext(Context_UserInterface_NavBar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((getResult) => {
        // Nothing
      })
      .catch((getReject) => {
        un_routerNavigation(ApplicationPages.UnauthenticatedLoginPage.url())
      })
  }, [un_routerNavigation, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    generateActiveUserApplicationPermissions(uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions).then((permissionResult) => {
      if (permissionResult.success) {
        uc_setRootData_UserPermissions(permissionResult.permissions)
      }
    })
    return () => {}
  }, [uc_RootData_ClientUser, uc_RootData_GlobalUser, uc_RootData_ClientPermissions, uc_setRootData_UserPermissions])

  const pageRootNavPermission: string = getProp(ApplicationPages[pr_pageKey], 'root_nav_page_permission', '')
  const pageRoleAccessPermissions: TsInterface_NavPage['page_role_access_permissions'] = getProp(
    ApplicationNavPages[pageRootNavPermission],
    'page_role_access_permissions',
    {},
  )
  let topLoadingBar: JSX.Element
  let sideBarNavObject: TsInterface_NavigationObject
  let authorizedToViewPage: boolean = false
  let clientType: string
  let userRole: string

  // Functions
  const determineClientTypeAndUserRole = (): void => {
    if (uc_RootData_ClientPermissions != null && uc_RootData_ClientPermissions.client_type != null) {
      clientType = uc_RootData_ClientPermissions.client_type
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role != null) {
      userRole = uc_RootData_ClientUser.user_role
    } else if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.user_role != null) {
      userRole = uc_RootData_GlobalUser.user_role
    }
  }

  const applyPermissionsToSideNavBarObject = (): void => {
    let initialApplicationNavigationObject = generateApplicationNavigationObject(clientType, userRole)
    let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
    for (let loopSectionKey in initialApplicationNavigationObject) {
      let loopSection = initialApplicationNavigationObject[loopSectionKey]
      for (let loopLinkKey in loopSection['links']) {
        let loopLink = loopSection['links'][loopLinkKey]
        if (uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[loopLinkKey] === true && loopLink != null) {
          if (permittedApplicationNavigationObject[loopSectionKey] == null) {
            permittedApplicationNavigationObject[loopSectionKey] = cloneObjectWithoutReference(loopSection)
            permittedApplicationNavigationObject[loopSectionKey]['links'] = {}
          }
          permittedApplicationNavigationObject[loopSectionKey]['links'][loopLinkKey] = loopLink
        }
      }
    }
    if (permittedApplicationNavigationObject == null || objectToArray(permittedApplicationNavigationObject).length === 0) {
      permittedApplicationNavigationObject = EmptyApplicationNavigationObject
    }

    let index = 0
    for (let loopSectionKey in permittedApplicationNavigationObject) {
      let loopSection = permittedApplicationNavigationObject[loopSectionKey]
      for (let loopLinkKey in loopSection['links']) {
        let loopLink = loopSection['links'][loopLinkKey]
        // @ts-ignore
        loopLink['index'] = index
        index++
      }
    }
    sideBarNavObject = permittedApplicationNavigationObject
  }

  const openNav = (): void => {
    uc_setUserInterface_NavBarDisplay(true)
  }

  const closeNav = (): void => {
    uc_setUserInterface_NavBarDisplay(false)
  }

  const checkIfNavSectionIsActive = (sectionKey: string, linkKey: string): boolean => {
    let active = false
    if (sectionKey === 'home' && linkKey === 'home' && pr_pageKey === 'HomePage') {
      active = true
    } else if (clientType != null && userRole != null && pageRoleAccessPermissions != null && pageRoleAccessPermissions[clientType + '_' + userRole] != null) {
      let compositeKey: string = clientType + '_' + userRole
      if (
        pageRoleAccessPermissions[compositeKey]['highlighted_nav_section'] === sectionKey &&
        pageRoleAccessPermissions[compositeKey]['highlighted_nav_page'] === linkKey
      ) {
        active = true
      }
    }
    return active
  }

  const returnNavLinkStyle = (selected: boolean, index: number): TsInterface_NavLinkStyle => {
    let style
    if (selected === true) {
      style = {
        'py': '2px',
        'px': 3,
        'color': returnNavBarSelectedLinkColorSx(index),
        'fontWeight': 700,
        // color: 'rgba(255, 255, 255, 0.7)',
        '&:hover, &:focus': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
        },
        'borderLeft': '6px solid ' + returnNavBarSelectedLinkColorSx(index),
      }
    } else {
      style = {
        'py': '2px',
        'px': 3,
        'color': 'rgba(255, 255, 255, 0.7)',
        'fontWeight': 700,
        '&:hover, &:focus': {
          bgcolor: 'rgba(255, 255, 255, 0.08)',
        },
        'borderLeft': '6px solid rgba(0,0,0,0)',
      }
    }
    return style
  }

  const returnNavIconStyle = (selected: boolean, index: number): TsInterface_UnspecifiedObject => {
    let navIconSX = {
      color: unselectedNavColor,
    }
    if (selected === true) {
      navIconSX['color'] = returnNavBarSelectedLinkColorSx(index)
    }
    return navIconSX
  }

  const determineLoadingBarVisibility = (): void => {
    if (uc_UserInterface_LoadingBarDisplay === true) {
      topLoadingBar = <LinearProgress color="secondary" />
    } else {
      topLoadingBar = <Box className="top_loading_bar_placeholder"></Box>
    }
  }

  const determinePageAuthorization = (): void => {
    if (pr_pageKey === 'HomePage') {
      authorizedToViewPage = true
    } else if (clientType == null || userRole == null) {
      authorizedToViewPage = true
    } else {
      if (
        uc_RootData_UserPermissions == null ||
        objectToArray(uc_RootData_UserPermissions).length === 0 ||
        uc_RootData_UserPermissions[pageRootNavPermission] === true
      ) {
        authorizedToViewPage = true
      }
    }
  }

  const getMainSectionPadding = (open: boolean): string => {
    let cssClassName = ''
    if (open === true) {
      // cssClassName = "tw-pl-2"
    }
    return cssClassName
  }

  const getHeaderTextMaxWidth = (open: boolean): string => {
    // TODO: add 40 for each nav icon visible - i.e. notifications
    let otherHeaderContentWidth = 130
    let maxWidth = `calc(100% - ${otherHeaderContentWidth}px)`
    return maxWidth
  }

  const rJSX_NavButton = (open: boolean): JSX.Element => {
    let navButtonJSX = <></>
    if (open === true) {
      navButtonJSX = (
        <IconButton
          sx={{ height: '40px', width: '40px', marginTop: '2px', ...(uc_UserInterface_NavBarDisplay && { display: 'none' }) }}
          className="tw-inline-block"
          onClick={() => {
            closeNav()
          }}
        >
          <Icon icon="chevron-left" />
        </IconButton>
      )
    } else {
      navButtonJSX = (
        <IconButton
          sx={{ height: '40px', width: '40px', marginTop: '2px', ...(!uc_UserInterface_NavBarDisplay && { display: 'none' }) }}
          className="tw-inline-block"
          onClick={() => {
            openNav()
          }}
        >
          <Icon icon="bars" />
        </IconButton>
      )
    }
    return navButtonJSX
  }

  // TODO: Move to useEffect?
  determineClientTypeAndUserRole()
  applyPermissionsToSideNavBarObject()
  determineLoadingBarVisibility()
  determinePageAuthorization()

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let authContentJSX = <Box></Box>
    if (uc_RootData_AuthenticatedUser == null || uc_RootData_AuthenticatedUser.loggedIn == null) {
      authContentJSX = (
        <Box className="tw-text-center tw-p-4">
          <CircularProgress />
        </Box>
      )
    } else if (uc_RootData_AuthenticatedUser != null && uc_RootData_AuthenticatedUser.loggedIn === false) {
      authContentJSX = (
        <Navigate
          to={ApplicationPages.UnauthenticatedLoginPage.url()}
          replace
        />
      )
    } else if (!authorizedToViewPage) {
      authContentJSX = (
        <Navigate
          to={ApplicationPages.HomePage.url()}
          replace
        />
      )
    } else {
      authContentJSX = (
        <Box
          component="div"
          className="tw-flex"
        >
          <AppBar
            position="fixed"
            // @ts-ignore
            open={uc_UserInterface_NavBarDisplay}
            sx={{ backgroundColor: appBarBackgroundColor, boxShadow: 'none' }}
            className="tw-pl-0"
          >
            {topLoadingBar}
            <Box>
              <Box className="tw-inline-block tw-float-left">{rJSX_NavButton(uc_UserInterface_NavBarDisplay)}</Box>
              <Typography
                className="tw-inline-block tw-ml-1 tw-mt-2"
                variant="h5"
                noWrap
                component="span"
                sx={{
                  color: appBarTextColor,
                  fontWeight: 700,
                  marginTop: '0px',
                  width: '100%',
                  maxWidth: getHeaderTextMaxWidth(uc_UserInterface_NavBarDisplay),
                }}
              >
                {pr_pageHeader}
              </Typography>

              {/* TODO: implement top right icons */}

              <Box className="tw-inline-block tw-float-right tw-mr-2">
                {/* <IconButton sx={{ height: "40px", width: "40px", marginTop: "4px" }} className="tw-inline-block" onClick={ () => { console.log("TEST") } }>
									<NotificationsActiveIcon/>
								</IconButton> */}
                <Link to={ApplicationMajorPages.UserSettingsPage.url()}>
                  <IconButton
                    sx={{ height: '40px', width: '40px', marginTop: '4px' }}
                    className="tw-inline-block"
                    onClick={() => {}}
                  >
                    <Icon icon="circle-user" />
                  </IconButton>
                </Link>
              </Box>
            </Box>
            {/* <Divider /> */}
          </AppBar>
          <Box className="TEMP_nav">
            <Drawer
              sx={{
                'width': drawerWidth,
                'flexShrink': 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                },
              }}
              variant="persistent"
              anchor="left"
              open={uc_UserInterface_NavBarDisplay}
            >
              <Box
                sx={{
                  background: returnNavBarBackgroundColorSx(),
                  height: 'calc(100vh - 0px)',
                  overflow: 'scroll',
                }}
              >
                <DrawerHeader sx={itemCategory}>
                  <Box style={{ width: '100%', textAlign: 'left', height: '35px' }}>
                    {rJSX_DynamicApplicationLogoSvg(
                      '35px',
                      {
                        do: themeVariables.white,
                        lc: themeVariables.white,
                        sd_l: themeVariables.white,
                        sd_s: themeVariables.white,
                        sd_d: themeVariables.white,
                      },
                      { marginLeft: '-15px' },
                    )}
                  </Box>
                </DrawerHeader>
                <List disablePadding>
                  {Object.keys(sideBarNavObject).map((sectionKey, sectionIndex) => (
                    <Box
                      component="div"
                      key={sectionKey}
                    >
                      {Object.keys(sideBarNavObject[sectionKey]['links']).map((navLinkKey, navLinkName) => (
                        <Link
                          to={sideBarNavObject[sectionKey]['links'][navLinkKey].url}
                          key={sideBarNavObject[sectionKey]['links'][navLinkKey].key}
                        >
                          <ListItem disablePadding>
                            <ListItemButton
                              sx={returnNavLinkStyle(
                                checkIfNavSectionIsActive(sectionKey, navLinkKey),
                                getProp(sideBarNavObject[sectionKey]['links'][navLinkKey], 'index', 0),
                              )}
                            >
                              <ListItemIcon
                                sx={returnNavIconStyle(
                                  checkIfNavSectionIsActive(sectionKey, navLinkKey),
                                  getProp(sideBarNavObject[sectionKey]['links'][navLinkKey], 'index', 0),
                                )}
                              >
                                <Icon
                                  size="lg"
                                  icon={sideBarNavObject[sectionKey]['links'][navLinkKey].icon}
                                />
                              </ListItemIcon>
                              <ListItemText>{sideBarNavObject[sectionKey]['links'][navLinkKey].name}</ListItemText>
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      ))}
                      <Divider
                        className="tw-my-2"
                        sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }}
                      />
                    </Box>
                  ))}
                </List>
              </Box>
            </Drawer>
          </Box>
          <Main
            className={getMainSectionPadding(uc_UserInterface_NavBarDisplay)}
            // @ts-ignore
            open={uc_UserInterface_NavBarDisplay}
          >
            <Box sx={{ height: '50px' }} />
            <Box
              sx={{
                background: themeVariables.white,
                border: '1px solid ' + themeVariables.gray_300,
                padding: '16px',
                marginLeft: '12px',
                marginRight: '12px',
                borderRadius: '10px',
                minHeight: 'calc(100vh - 60px)',
                // boxShadow: '0px 0px 4px 0px #bdbdbd',
              }}
            >
              <Box className="">{pr_pageContent}</Box>
            </Box>
            <NavOpenKeyListener
              openNav={openNav}
              closeNav={closeNav}
              navOpen={uc_UserInterface_NavBarDisplay}
            />
          </Main>
        </Box>
      )
    }
    return authContentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_CompilationTemplates_Collection } from 'rfbp_aux/services/database_endpoints/clients/data_management/compilation_templates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['CompilationsListPage']['key']

// Table
const tableSettings_CompilationTemplates: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_CompilationTemplates: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.CompilationTemplatesViewPage.url(rowData.key as string))
        }
      },
    },
    // copy: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="copy"
    //     />
    //   ),
    //   label: <>{rLIB('Copy')}</>,
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData.key != null) {
    //       let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
    //       getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
    //         .then((res_GCK) => {
    //           DatabaseGetDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, rowData.key as string))
    //             .then((res_DGD) => {
    //               DatabaseGetCollection(DatabaseRef_ReportTemplates_Components_Collection(res_GCK.clientKey, rowData.key as string))
    //                 .then((res_DGC) => {
    //                   let newReportKey = uuidv4()
    //                   let reportRoot = res_DGD.data
    //                   let reportComponents = res_DGC.data
    //                   reportRoot.key = newReportKey
    //                   reportRoot.name = reportRoot.name + '  (Copy)'
    //                   updateArray.push({
    //                     type: 'setMerge',
    //                     ref: DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, newReportKey),
    //                     data: reportRoot,
    //                   })
    //                   for (let loopComponentKey in reportComponents) {
    //                     let loopComponent = reportComponents[loopComponentKey]
    //                     loopComponent.key = loopComponentKey
    //                     updateArray.push({
    //                       type: 'setMerge',
    //                       ref: DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, newReportKey, loopComponentKey),
    //                       data: loopComponent,
    //                     })
    //                   }
    //                   DatabaseStagedBatchUpdate(updateArray)
    //                     .then((res_DSBU) => {
    //                       tableHooks.un_routerNavigation(ApplicationPages.ReportTemplatesViewPage.url(newReportKey))
    //                     })
    //                     .catch((rej_DSBU) => {
    //                       tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
    //                     })
    //                 })
    //                 .catch((rej_GDC) => {
    //                   tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GDC.error })
    //                 })
    //             })
    //             .catch((rej_GDD) => {
    //               tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GDD.error })
    //             })
    //         })
    //         .catch((rej_GCK) => {
    //           tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    //         })
    //     }
    //   },
    // },
    // delete: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="trash"
    //     />
    //   ),
    //   label: <>{rLIB('Delete')}</>,
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData.key != null) {
    //       tableHooks.uc_setUserInterface_PromptDialogDisplay({
    //         display: true,
    //         prompt: {
    //           color: 'error',
    //           confirm_text: rLIB('Delete'),
    //           default_value: '',
    //           header: rLIB('Delete Report Template'),
    //           icon: (
    //             <Icon
    //               icon="trash"
    //               type="solid"
    //             />
    //           ),
    //           input_label: rLIB('Type DELETE to confirm'),
    //           input_type: 'text',
    //           text: (
    //             <>
    //               {rLIB('Are you sure that you want to delete')} {getProp(rowData, 'name', 'this report template')}?
    //             </>
    //           ),
    //           submit_callback: (promptValue: string) => {
    //             return new Promise((resolve, reject) => {
    //               if (promptValue === 'DELETE') {
    //                 let updateObject: TsInterface_UnspecifiedObject = {
    //                   status: 'deleted',
    //                 }
    //                 getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
    //                   .then((res_GCK) => {
    //                     DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, rowData.key as string), updateObject)
    //                       .then((res_DSMD) => {
    //                         resolve(res_DSMD)
    //                       })
    //                       .catch((rej_DSMD) => {
    //                         tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
    //                         reject(rej_DSMD)
    //                       })
    //                   })
    //                   .catch((rej_GCK) => {
    //                     tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    //                     reject(rej_GCK)
    //                   })
    //                 resolve({ close_dialog: true })
    //               } else {
    //                 resolve({ close_dialog: false })
    //               }
    //             })
    //           },
    //         },
    //       })
    //     }
    //   },
    // },
  }),
  name: TableCellBasic('name', rLIB('Compilation Template Name'), 'name'),
  // file_type: TableCellBasic('file_type', rLIB("File Type"), 'file_type'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ items, setItems ] = 			useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Compilations', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const createCompilationTemplate = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Compilation Template Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = {
                name: formSubmittedData.name,
                status: 'active',
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseAddDocument(DatabaseRef_CompilationTemplates_Collection(res_GCK.clientKey), updateObject, true)
                    .then((res_AD) => {
                      formHooks.un_routerNavigation(ApplicationPages.CompilationTemplatesViewPage.url(res_AD.key))
                      resolve(res_AD)
                    })
                    .catch((rej_AD) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AD.error })
                      reject(rej_AD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Report Template')} </>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableDatabaseEndpoint_ReportTemplates = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_CompilationTemplates_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewTemplateButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createCompilationTemplate()
        }}
        startIcon={<Icon icon="circle-plus" />}
        className="tw-mb-2"
      >
        {rLIB('New Compilation Template')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_CompilationTemplatesTab = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_CompilationTemplates}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ReportTemplates}
            tableSettings={tableSettings_CompilationTemplates}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Compilations')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabHeader: rLIB('Compilation Templates'),
                  tabUrlKey: 'Templates',
                  tabButtons: [{ fullJSX: rJSX_NewTemplateButton(), minJSX: rJSX_NewTemplateButton(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_CompilationTemplatesTab()}</Box>,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.CompilationsListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Compilations', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

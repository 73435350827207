///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/clients/settings/users'
import { DatabaseRef_APILogs_Collection } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/api_logs'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { rJSX_ApiDocumentation } from './components/documentation'
import { returnApiDocumentation } from './data/endpoints'
import { tableColumns_APILogs, tableSettings_APILogs } from './tables/logs'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['APIDocumentationIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const maskString = (str: string): string => {
  // Check if the input string has at least 4 characters
  if (str.length < 4) {
    return str
  }
  // Extract the first 2 and last 2 characters
  const firstTwo = str.slice(0, 3)
  const lastTwo = str.slice(-3)
  // Create a mask with asterisks for the remaining characters
  const mask = str.slice(3, -3).replace(/[^-]/g, '*')
  // Combine the first 2, mask, and last 2 characters
  const maskedString = firstTwo + mask + lastTwo
  return maskedString
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientUser, us_setClientUser] = useState<TsInterface_UnspecifiedObject>({})
  const [us_showAPIKey, us_setShowAPIKey] = useState<boolean>(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('API Docs', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientUser(newData)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_ClientUser_Document(res_GCK.clientKey, uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser])

  // Functions
  const tableDatabaseEndpoint_APILogs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_APILogs_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const generateAPIKey = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let updateObject = {
        api_key: uuidv4(),
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, uc_RootData_GlobalUser.key as string), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_DocumentationTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-4">
        <Box className="tw-mt-2">
          {rJSX_ApiDocumentation(
            returnApiDocumentation(
              {
                uid: getProp(uc_RootData_GlobalUser, 'key', null),
                api_key: getProp(us_clientUser, 'api_key', null),
              },
              uc_RootData_ClientKey as string,
            ),
          )}
        </Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_TokensAndIdsTab = (): JSX.Element => {
    let tabJSX = <></>
    // Table JSX
    tabJSX = (
      <Card>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: '32px' }}></TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-font-black"
                  >
                    {rLIB('Fields')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-font-black"
                  >
                    {rLIB('Values')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: '32px' }}>
                  <Typography variant="body1">
                    <Icon
                      icon="user"
                      sx={{ color: themeVariables.primary_main }}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">uid</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{uc_RootData_GlobalUser.key}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: '32px' }}>
                  <Typography variant="body1">
                    <Icon
                      icon="key"
                      sx={{ color: themeVariables.warning_main }}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">api_key</Typography>
                </TableCell>
                <TableCell>{rJSX_AuthTokenTableCellContent()}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ width: '32px' }}>
                  <Typography variant="body1">
                    <Icon
                      icon="building"
                      sx={{ color: themeVariables.info_main }}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">workspace_id</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{uc_RootData_ClientKey}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
    return tabJSX
  }

  const rJSX_APILogsTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null && uc_RootData_ClientKey !== '') {
      tabJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_APILogs}
            tableDatabaseEndpoint={tableDatabaseEndpoint_APILogs}
            tableSettings={tableSettings_APILogs}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_ApiVisibilityButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_showAPIKey === true) {
      buttonJSX = (
        <Button
          size="small"
          variant="contained"
          color="info"
          className="tw-mr-2"
          onClick={() => {
            us_setShowAPIKey(false)
          }}
          disableElevation
          startIcon={
            <Icon
              icon="eyes"
              type="light"
            />
          }
        >
          {rLIB('Hide API Key')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          size="small"
          variant="outlined"
          color="info"
          className="tw-mr-2"
          onClick={() => {
            us_setShowAPIKey(true)
          }}
          disableElevation
          startIcon={
            <Icon
              icon="eyes"
              type="light"
            />
          }
        >
          {rLIB('Show API Key')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_RegenerateAPIKeyButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        size="small"
        variant="outlined"
        color="error"
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Regenerate API Key'),
              icon: (
                <Icon
                  icon="key"
                  type="solid"
                />
              ),
              submit_text: rLIB('Regenerate API Key'),
              text: (
                <>
                  {rLIB('Are you sure that you want to regenerate this API key?')} {rLIB('This will break any integrations using this API key.')}
                </>
              ),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  generateAPIKey()
                    .then((res_GAK) => {
                      resolve(res_GAK)
                    })
                    .catch((rej_GAK) => {
                      reject(rej_GAK)
                    })
                })
              },
            },
          })
        }}
        disableElevation
        startIcon={<Icon icon="key" />}
      >
        {rLIB('Regenerate API Key')}
      </Button>
    )

    return buttonJSX
  }

  const rJSX_AuthTokenTableCellContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_clientUser != null && us_clientUser.api_key != null) {
      if (us_showAPIKey === true) {
        contentJSX = (
          <Box>
            <Typography
              variant="body1"
              className="tw-mr-2 tw-inline-block"
            >
              {getProp(us_clientUser, 'api_key', '')}
            </Typography>
            {rJSX_ApiVisibilityButton()}
            {rJSX_RegenerateAPIKeyButton()}
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="body1"
              className="tw-mr-2 tw-inline-block"
            >
              {maskString(getProp(us_clientUser, 'api_key', ''))}
            </Typography>
            {rJSX_ApiVisibilityButton()}
            {rJSX_RegenerateAPIKeyButton()}
          </Box>
        )
      }
    } else {
      contentJSX = (
        <Button
          size="small"
          variant="outlined"
          color="warning"
          onClick={() => {
            generateAPIKey()
          }}
          disableElevation
          startIcon={<Icon icon="key" />}
        >
          {rLIB('Generate New API Key')}
        </Button>
      )
    }
    return contentJSX
  }

  const rJSX_Tabs = (): JSX.Element => {
    let tabsJSX = (
      <Box>
        <TabsUrl
          tabsSettings={{
            baseUrl: ApplicationPages.APIDocumentationIndexPage.url(),
            tabQueryParam: 'tab',
            overridePageTitle: true,
            basePageTitle: rLIB('API Docs', false) as string,
          }}
          tabs={[
            {
              tabUrlKey: 'Documentation',
              tabHeader: rLIB('Documentation'),
              // tabHeader: (
              //   <Stack direction="row">
              //     <Icon
              //       icon="book"
              //       className="tw-mr-2 tw-inline-block"
              //       sx={{ fontSize: '18px' }}
              //     />
              //     {rLIB('Documentation')}
              //   </Stack>
              // ),
              tabContent: rJSX_DocumentationTab(),
            },
            {
              tabUrlKey: 'Your_Keys',
              tabHeader: rLIB('Your API Keys'),
              // tabHeader: (
              //   <Stack direction="row">
              //     <Icon
              //       icon="code"
              //       className="tw-mr-2 tw-inline-block"
              //       sx={{ fontSize: '18px' }}
              //     />
              //     {rLIB('Your API Keys')}
              //   </Stack>
              // ),
              tabContent: rJSX_TokensAndIdsTab(),
            },
            {
              tabUrlKey: 'Logs',
              tabHeader: rLIB('Logs'),
              // tabHeader: (
              //   <Stack direction="row">
              //     <Icon
              //       icon="timeline"
              //       className="tw-mr-2 tw-inline-block"
              //       sx={{ fontSize: '18px' }}
              //     />
              //     {rLIB('Logs')}
              //   </Stack>
              // ),
              tabContent: rJSX_APILogsTab(),
            },
          ]}
        />
      </Box>
    )
    return tabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('API Docs')}
        pageKey={pageKey}
        content={<Box>{rJSX_Tabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  height: string
  color: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const LogoOrchestrate = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let height: TsInterface_ComponentProps['height'] = getProp(props, 'height', '30px')
  let color: TsInterface_ComponentProps['color'] = getProp(props, 'color', '#233041')

  // Hooks - useContext, useState, useReducer, other

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const Logo = (
    <Box>
      <svg
        height={height}
        viewBox="0 0 10000 1500"
      >
        <g transform="matrix(2,0,0,2,2300,-675)">
          <g transform="matrix(1.38858,0,0,1,-2091.43,-479.011)">
            <path
              d="M804.012,1087.5L804.012,1312.5C804.012,1333.2 791.911,1350 777.006,1350C762.101,1350 750,1333.2 750,1312.5L750,1087.5C750,1066.8 762.101,1050 777.006,1050C791.911,1050 804.012,1066.8 804.012,1087.5Z"
              fill={'rgb(7,197,199)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1,-1641.43,-554.011)">
            <path
              d="M804.012,1087.5L804.012,1312.5C804.012,1333.2 791.911,1350 777.006,1350C762.101,1350 750,1333.2 750,1312.5L750,1087.5C750,1066.8 762.101,1050 777.006,1050C791.911,1050 804.012,1066.8 804.012,1087.5Z"
              fill={'rgb(148,118,223)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1,-1416.43,-404.011)">
            <path
              d="M804.012,1087.5L804.012,1312.5C804.012,1333.2 791.911,1350 777.006,1350C762.101,1350 750,1333.2 750,1312.5L750,1087.5C750,1066.8 762.101,1050 777.006,1050C791.911,1050 804.012,1066.8 804.012,1087.5Z"
              fill={'rgb(186,78,219)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1,-966.435,-479.011)">
            <path
              d="M804.012,1087.5L804.012,1312.5C804.012,1333.2 791.911,1350 777.006,1350C762.101,1350 750,1333.2 750,1312.5L750,1087.5C750,1066.8 762.101,1050 777.006,1050C791.911,1050 804.012,1066.8 804.012,1087.5Z"
              fill={'rgb(254,86,140)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1.5,-1978.93,-1079.01)">
            <path
              d="M804.012,1075L804.012,1325C804.012,1338.8 791.911,1350 777.006,1350C762.101,1350 750,1338.8 750,1325L750,1075C750,1061.2 762.101,1050 777.006,1050C791.911,1050 804.012,1061.2 804.012,1075Z"
              fill={'rgb(6,160,214)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1.5,-1753.94,-1154.01)">
            <path
              d="M804.012,1075L804.012,1325C804.012,1338.8 791.911,1350 777.006,1350C762.101,1350 750,1338.8 750,1325L750,1075C750,1061.2 762.101,1050 777.006,1050C791.911,1050 804.012,1061.2 804.012,1075Z"
              fill={'rgb(127,133,217)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1.5,-1528.93,-1079.01)">
            <path
              d="M804.012,1075L804.012,1325C804.012,1338.8 791.911,1350 777.006,1350C762.101,1350 750,1338.8 750,1325L750,1075C750,1061.2 762.101,1050 777.006,1050C791.911,1050 804.012,1061.2 804.012,1075Z"
              fill={'rgb(160,102,223)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1.5,-1303.93,-1004.01)">
            <path
              d="M804.012,1075L804.012,1325C804.012,1338.8 791.911,1350 777.006,1350C762.101,1350 750,1338.8 750,1325L750,1075C750,1061.2 762.101,1050 777.006,1050C791.911,1050 804.012,1061.2 804.012,1075Z"
              fill={'rgb(223,45,213)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,1.5,-1078.93,-1079.01)">
            <path
              d="M804.012,1075L804.012,1325C804.012,1338.8 791.911,1350 777.006,1350C762.101,1350 750,1338.8 750,1325L750,1075C750,1061.2 762.101,1050 777.006,1050C791.911,1050 804.012,1061.2 804.012,1075Z"
              fill={'rgb(253,73,164)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,2,-1866.44,-1679.01)">
            <path
              d="M804.012,1068.75L804.012,1331.25C804.012,1341.6 791.911,1350 777.006,1350C762.101,1350 750,1341.6 750,1331.25L750,1068.75C750,1058.4 762.101,1050 777.006,1050C791.911,1050 804.012,1058.4 804.012,1068.75Z"
              fill={'rgb(47,145,219)'}
            />
          </g>
          <g transform="matrix(1.38858,0,0,2,-1191.43,-1679.01)">
            <path
              d="M804.012,1068.75L804.012,1331.25C804.012,1341.6 791.911,1350 777.006,1350C762.101,1350 750,1341.6 750,1331.25L750,1068.75C750,1058.4 762.101,1050 777.006,1050C791.911,1050 804.012,1058.4 804.012,1068.75Z"
              fill={'rgb(242,56,194)'}
            />
          </g>
        </g>
        <g transform="matrix(1.48307,0,0,1.48356,2669.2,-559.87)">
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M796.949,1388.47C790.754,1388.47 785.049,1386.88 779.832,1383.7C774.616,1380.52 770.418,1376.33 767.239,1371.11C764.06,1365.9 762.471,1360.19 762.471,1354L762.471,1281.16C762.471,1274.96 764.06,1269.26 767.239,1264.04C770.418,1258.83 774.616,1254.63 779.832,1251.46C785.049,1248.28 790.754,1246.69 796.949,1246.69L871.774,1246.69C878.131,1246.69 883.918,1248.28 889.135,1251.46C894.351,1254.63 898.508,1258.83 901.606,1264.04C904.703,1269.26 906.252,1274.96 906.252,1281.16L906.252,1354C906.252,1360.19 904.703,1365.9 901.606,1371.11C898.508,1376.33 894.351,1380.52 889.135,1383.7C883.918,1386.88 878.131,1388.47 871.774,1388.47L796.949,1388.47ZM798.66,1353.76L870.062,1353.76C870.388,1353.76 870.714,1353.59 871.04,1353.27C871.366,1352.94 871.529,1352.62 871.529,1352.29L871.529,1282.87C871.529,1282.54 871.366,1282.21 871.04,1281.89C870.714,1281.56 870.388,1281.4 870.062,1281.4L798.66,1281.4C798.334,1281.4 798.008,1281.56 797.682,1281.89C797.356,1282.21 797.193,1282.54 797.193,1282.87L797.193,1352.29C797.193,1352.62 797.356,1352.94 797.682,1353.27C798.008,1353.59 798.334,1353.76 798.66,1353.76Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M926.058,1388.47L926.058,1281.16C926.058,1274.96 927.647,1269.26 930.826,1264.04C934.005,1258.83 938.244,1254.63 943.542,1251.46C948.84,1248.28 954.586,1246.69 960.781,1246.69L1039.27,1246.69L1039.27,1281.4L962.248,1281.4C961.922,1281.4 961.596,1281.56 961.27,1281.89C960.944,1282.21 960.781,1282.54 960.781,1282.87L960.781,1388.47L926.058,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1088.42,1388.47C1082.23,1388.47 1076.52,1386.88 1071.31,1383.7C1066.09,1380.52 1061.89,1376.33 1058.71,1371.11C1055.53,1365.9 1053.94,1360.19 1053.94,1354L1053.94,1281.16C1053.94,1274.96 1055.53,1269.26 1058.71,1264.04C1061.89,1258.83 1066.09,1254.63 1071.31,1251.46C1076.52,1248.28 1082.23,1246.69 1088.42,1246.69L1197.48,1246.69L1197.48,1281.4L1090.13,1281.4C1089.81,1281.4 1089.48,1281.56 1089.16,1281.89C1088.83,1282.21 1088.67,1282.54 1088.67,1282.87L1088.67,1352.29C1088.67,1352.62 1088.83,1352.94 1089.16,1353.27C1089.48,1353.59 1089.81,1353.76 1090.13,1353.76L1197.73,1353.76L1197.73,1388.47L1088.42,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1218.02,1388.47L1218.02,1200.24L1252.74,1200.24L1252.74,1246.69L1327.32,1246.69C1333.52,1246.69 1339.22,1248.28 1344.44,1251.46C1349.66,1254.63 1353.86,1258.83 1357.03,1264.04C1360.21,1269.26 1361.8,1274.96 1361.8,1281.16L1361.8,1388.47L1327.08,1388.47L1327.08,1282.87C1327.08,1282.54 1326.96,1282.21 1326.71,1281.89C1326.47,1281.56 1326.1,1281.4 1325.61,1281.4L1254.21,1281.4C1253.88,1281.4 1253.56,1281.56 1253.23,1281.89C1252.91,1282.21 1252.74,1282.54 1252.74,1282.87L1252.74,1388.47L1218.02,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1414.86,1388.47C1408.67,1388.47 1402.96,1386.88 1397.75,1383.7C1392.53,1380.52 1388.33,1376.33 1385.15,1371.11C1381.98,1365.9 1380.39,1360.19 1380.39,1354L1380.39,1281.16C1380.39,1274.96 1381.98,1269.26 1385.15,1264.04C1388.33,1258.83 1392.53,1254.63 1397.75,1251.46C1402.96,1248.28 1408.67,1246.69 1414.86,1246.69L1489.69,1246.69C1496.05,1246.69 1501.83,1248.28 1507.05,1251.46C1512.27,1254.63 1516.42,1258.83 1519.52,1264.04C1522.62,1269.26 1524.17,1274.96 1524.17,1281.16L1524.17,1335.18L1415.11,1335.18L1415.11,1352.29C1415.11,1352.62 1415.27,1352.94 1415.6,1353.27C1415.92,1353.59 1416.25,1353.76 1416.58,1353.76L1524.17,1353.76L1524.17,1388.47L1414.86,1388.47ZM1415.11,1304.13L1489.44,1304.13L1489.44,1282.87C1489.44,1282.54 1489.28,1282.21 1488.95,1281.89C1488.63,1281.56 1488.3,1281.4 1487.98,1281.4L1416.58,1281.4C1416.25,1281.4 1415.92,1281.56 1415.6,1281.89C1415.27,1282.21 1415.11,1282.54 1415.11,1282.87L1415.11,1304.13Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1581.14,1388.47C1574.95,1388.47 1569.24,1386.88 1564.02,1383.7C1558.81,1380.52 1554.61,1376.33 1551.43,1371.11C1548.25,1365.9 1546.66,1360.19 1546.66,1354L1546.66,1347.64L1581.38,1347.64L1581.38,1352.29C1581.38,1352.62 1581.55,1352.94 1581.87,1353.27C1582.2,1353.59 1582.53,1353.76 1582.85,1353.76L1654.25,1353.76C1654.58,1353.76 1654.91,1353.59 1655.23,1353.27C1655.56,1352.94 1655.72,1352.62 1655.72,1352.29L1655.72,1336.4C1655.72,1336.07 1655.56,1335.79 1655.23,1335.54C1654.91,1335.3 1654.58,1335.18 1654.25,1335.18L1581.14,1335.18C1574.95,1335.18 1569.24,1333.59 1564.02,1330.41C1558.81,1327.23 1554.61,1323.04 1551.43,1317.82C1548.25,1312.61 1546.66,1306.82 1546.66,1300.47L1546.66,1281.16C1546.66,1274.96 1548.25,1269.26 1551.43,1264.04C1554.61,1258.83 1558.81,1254.63 1564.02,1251.46C1569.24,1248.28 1574.95,1246.69 1581.14,1246.69L1655.97,1246.69C1662.32,1246.69 1668.11,1248.28 1673.33,1251.46C1678.54,1254.63 1682.74,1258.83 1685.92,1264.04C1689.1,1269.26 1690.69,1274.96 1690.69,1281.16L1690.69,1287.51L1655.72,1287.51L1655.72,1282.87C1655.72,1282.54 1655.56,1282.21 1655.23,1281.89C1654.91,1281.56 1654.58,1281.4 1654.25,1281.4L1582.85,1281.4C1582.53,1281.4 1582.2,1281.56 1581.87,1281.89C1581.55,1282.21 1581.38,1282.54 1581.38,1282.87L1581.38,1298.76C1581.38,1299.08 1581.55,1299.37 1581.87,1299.61C1582.2,1299.86 1582.53,1299.98 1582.85,1299.98L1655.97,1299.98C1662.32,1299.98 1668.11,1301.57 1673.33,1304.74C1678.54,1307.92 1682.74,1312.12 1685.92,1317.33C1689.1,1322.55 1690.69,1328.33 1690.69,1334.69L1690.69,1354C1690.69,1360.19 1689.1,1365.9 1685.92,1371.11C1682.74,1376.33 1678.54,1380.52 1673.33,1383.7C1668.11,1386.88 1662.32,1388.47 1655.97,1388.47L1581.14,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1750.35,1388.47C1744,1388.47 1738.21,1386.88 1732.99,1383.7C1727.77,1380.52 1723.58,1376.33 1720.4,1371.11C1717.22,1365.9 1715.63,1360.19 1715.63,1354L1715.63,1203.91L1750.35,1203.91L1750.35,1246.69L1804.39,1246.69L1804.39,1281.4L1750.35,1281.4L1750.35,1352.29C1750.35,1352.62 1750.52,1352.94 1750.84,1353.27C1751.17,1353.59 1751.49,1353.76 1751.82,1353.76L1804.39,1353.76L1804.39,1388.47L1750.35,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1826.4,1388.47L1826.4,1281.16C1826.4,1274.96 1827.99,1269.26 1831.17,1264.04C1834.35,1258.83 1838.59,1254.63 1843.88,1251.46C1849.18,1248.28 1854.93,1246.69 1861.12,1246.69L1939.62,1246.69L1939.62,1281.4L1862.59,1281.4C1862.26,1281.4 1861.94,1281.56 1861.61,1281.89C1861.29,1282.21 1861.12,1282.54 1861.12,1282.87L1861.12,1388.47L1826.4,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M1993.17,1388.47C1986.97,1388.47 1981.23,1386.88 1975.93,1383.7C1970.63,1380.52 1966.43,1376.33 1963.33,1371.11C1960.24,1365.9 1958.69,1360.19 1958.69,1354L1958.69,1299.98L2067.75,1299.98L2067.75,1282.87C2067.75,1282.54 2067.58,1282.21 2067.26,1281.89C2066.93,1281.56 2066.61,1281.4 2066.28,1281.4L1958.69,1281.4L1958.69,1246.69L2067.99,1246.69C2074.35,1246.69 2080.14,1248.28 2085.35,1251.46C2090.57,1254.63 2094.72,1258.83 2097.82,1264.04C2100.92,1269.26 2102.47,1274.96 2102.47,1281.16L2102.47,1388.47L1993.17,1388.47ZM1994.88,1353.76L2067.75,1353.76L2067.75,1331.02L1993.41,1331.02L1993.41,1352.29C1993.41,1352.62 1993.57,1352.94 1993.9,1353.27C1994.23,1353.59 1994.55,1353.76 1994.88,1353.76Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M2163.36,1388.47C2157,1388.47 2151.21,1386.88 2145.99,1383.7C2140.78,1380.52 2136.58,1376.33 2133.4,1371.11C2130.22,1365.9 2128.63,1360.19 2128.63,1354L2128.63,1203.91L2163.36,1203.91L2163.36,1246.69L2217.39,1246.69L2217.39,1281.4L2163.36,1281.4L2163.36,1352.29C2163.36,1352.62 2163.52,1352.94 2163.84,1353.27C2164.17,1353.59 2164.5,1353.76 2164.82,1353.76L2217.39,1353.76L2217.39,1388.47L2163.36,1388.47Z"
              fill={color}
            />
          </g>
          <g transform="matrix(2.86249,0,0,2.86249,-1983.12,-2877.9)">
            <path
              d="M2271.43,1388.47C2265.24,1388.47 2259.53,1386.88 2254.32,1383.7C2249.1,1380.52 2244.9,1376.33 2241.72,1371.11C2238.55,1365.9 2236.96,1360.19 2236.96,1354L2236.96,1281.16C2236.96,1274.96 2238.55,1269.26 2241.72,1264.04C2244.9,1258.83 2249.1,1254.63 2254.32,1251.46C2259.53,1248.28 2265.24,1246.69 2271.43,1246.69L2346.26,1246.69C2352.62,1246.69 2358.41,1248.28 2363.62,1251.46C2368.84,1254.63 2372.99,1258.83 2376.09,1264.04C2379.19,1269.26 2380.74,1274.96 2380.74,1281.16L2380.74,1335.18L2271.68,1335.18L2271.68,1352.29C2271.68,1352.62 2271.84,1352.94 2272.17,1353.27C2272.49,1353.59 2272.82,1353.76 2273.15,1353.76L2380.74,1353.76L2380.74,1388.47L2271.43,1388.47ZM2271.68,1304.13L2346.01,1304.13L2346.01,1282.87C2346.01,1282.54 2345.85,1282.21 2345.53,1281.89C2345.2,1281.56 2344.87,1281.4 2344.55,1281.4L2273.15,1281.4C2272.82,1281.4 2272.49,1281.56 2272.17,1281.89C2271.84,1282.21 2271.68,1282.54 2271.68,1282.87L2271.68,1304.13Z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </Box>
  )

  return Logo
}

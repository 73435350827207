///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Format option for each cell
			Test Download from data bucket with date range

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { downloadCustomReport } from 'app/services/reports/report_helper_functions'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ActiveClasses_Query,
  DatabaseRef_ActivePersistentClasses_Query,
  DatabaseRef_ClassFields_Collection,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveDataBuckets_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_ReportTemplates_Components_Collection,
  DatabaseRef_ReportTemplates_Components_Document,
  DatabaseRef_ReportTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import { DatabaseRef_DataForSpecificDataBucket_DateRange_Query } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
// import {
// 	Json
// } from 'rfbp_core/components/code_display'
import { Box, Button, Card, Checkbox, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material/'
import { DatabaseRef_ActiveCalculatedFields_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/calculated_fields'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { CustomPdfEditor } from 'rfbp_core/components/pdf/editor'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, excelColumnIndexToLetter, getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_GenericPromiseReject, TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { removeSpecialCharactersAndTrimString } from '../architecture_classes/classes_list'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ReportTemplatesViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

const formInputs_RepeatedDataCellType: TsInterface_FormInputs = {
  cell_type: {
    key: 'cell_type',
    label: rLIB('Cell Type'),
    // input_type: "text_basic",
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [
      {
        key: 'mapped_data_field',
        value: (
          <>
            <Icon
              icon="square-code"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Data Field')}
          </>
        ),
      },
      {
        key: 'mapped_calculated_field',
        disabled: false,
        value: (
          <>
            <Icon
              icon="square-divide"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Calculated Field')}
          </>
        ),
      },
      {
        key: 'mapped_metadata_field',
        // disabled: true,
        value: (
          <>
            <Icon
              icon="square-poll-vertical"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Metadata Field')}
          </>
        ),
      },
      {
        key: 'mapped_association_field',
        // disabled: true,
        value: (
          <>
            <Icon
              icon="square-share-nodes"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Association Field')}
          </>
        ),
      },
      {
        key: 'hardcode',
        value: (
          <>
            <Icon
              icon="square-quote"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.warning_main, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Hardcoded Value')}
          </>
        ),
      },
      {
        key: 'excel_formula',
        value: (
          <>
            <Icon
              icon="square-divide"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.success_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Excel Formula')}
          </>
        ),
      },
      // { key: "internal_formula", value: s_CUSTOM_FORMULA },
    ],
  },
}

const formInputs_PdfVariableMappingType: TsInterface_FormInputs = {
  mapping_type: {
    key: 'mapping_type',
    label: rLIB('Variable Mapping Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [
      {
        key: 'mapped_data_field',
        value: (
          <>
            <Icon
              icon="square-code"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Data Field')}
          </>
        ),
      },
      {
        key: 'mapped_calculated_field',
        disabled: false,
        value: (
          <>
            <Icon
              icon="square-divide"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Calculated Field')}
          </>
        ),
      },
      {
        key: 'mapped_metadata_field',
        // disabled: true,
        value: (
          <>
            <Icon
              icon="square-poll-vertical"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Metadata Field')}
          </>
        ),
      },
      {
        key: 'mapped_association_field',
        // disabled: true,
        value: (
          <>
            <Icon
              icon="square-share-nodes"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.info_light, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Association Field')}
          </>
        ),
      },
      {
        key: 'hardcode',
        value: (
          <>
            <Icon
              icon="square-quote"
              className="tw-mr-2 tw-cursor-pointer"
              sx={{ color: themeVariables.warning_main, fontSize: '22px', marginRight: '6px' }}
            />
            {rLIB('Hardcoded Value')}
          </>
        ),
      },
    ],
  },
}

const formInputs_RepeatedDataCellContent_HardcodeValue: TsInterface_FormInputs = {
  hardcode_value: {
    key: 'hardcode_value',
    label: rLIB('Hardcoded Value'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const formInputs_RepeatedDataCellContent_ExcelFormula: TsInterface_FormInputs = {
  excel_value: {
    key: 'excel_value',
    label: rLIB('Excel Formula'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  TEMP_legend: {
    data_type: 'string',
    key: 'TEMP_legend',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box
          className=""
          sx={{ opacity: '0.3' }}
        >
          Use "#R" as placeholder for a dynamic row number that the function occurs on (i.e. = A#R + B#R would be A1+B1 for row 1, A2+B2 for row 2, etc)
        </Box>
      )
      return inputJSX
    },
  },
}

const metadataFieldOptions: TsInterface_UnspecifiedObject = {
  confirmation_number: {
    key: 'confirmation_number',
    value: 'Confirmation Number',
  },
  associated_data_bucket_name: {
    key: 'associated_data_bucket_name',
    value: 'Data Bucket Name',
  },
  timestamp_primary_query: {
    key: 'timestamp_primary_query',
    value: 'Primary Timestamp',
  },
  timestamp_submitted: {
    key: 'timestamp_submitted',
    value: 'Submission Timestamp',
  },
  review_status: {},
}

const valueFormatOptions: TsInterface_UnspecifiedObject = {
  default: { key: 'default', value: rLIB('Default') },
  currency: { key: 'currency', value: rLIB('Currency') },
  percentage: { key: 'percentage', value: rLIB('Percentage') },
  // date_mmddyyyy: { key: 'date_mmddyyyy', value: rLIB('Date (mm/dd/yyyy)') },
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activePersistentClasses, us_setActivePersistentClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_classFieldsData, us_setClassFieldsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeCalculatedFields, us_setActiveCalculatedFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reportTemplateComponents, us_setReportTemplateComponents] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reportTemplateRoot, us_setReportTemplateRoot] = useState<TsInterface_UnspecifiedObject>({})
  const [us_testDownloadFormData, us_setTestDownloadFormData] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_loadedClientsList, us_setLoadedClientsList] = useState<boolean>(false)
  const [us_copyingReportTemplate, us_setCopyingReportTemplate] = useState<boolean>(false)
  const [us_pdfViewMode, us_setPdfViewMode] = useState<'edit' | 'view'>('edit')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  let un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Report Template', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setReportTemplateRoot(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setReportTemplateComponents(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ReportTemplates_Components_Collection(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setActiveClasses])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveCalculatedFields(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveCalculatedFields_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setActiveCalculatedFields])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Load Class Field Data
        let promiseArray: TsType_UnknownPromise[] = []
        let classFieldData: TsInterface_UnspecifiedObject = {}
        for (let loopClassKey in getProp(us_reportTemplateRoot, 'associated_class_keys', {})) {
          let loopClassBoolean = us_reportTemplateRoot['associated_class_keys'][loopClassKey]
          if (loopClassBoolean === true) {
            promiseArray.push(
              DatabaseGetCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, loopClassKey))
                .then((res_DGC) => {
                  classFieldData[loopClassKey] = res_DGC.data
                })
                .catch((rej_DGC) => {
                  console.error(rej_DGC)
                }),
            )
          }
        }
        Promise.all(promiseArray).finally(() => {
          us_setClassFieldsData(classFieldData)
          ur_forceRerender()
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_activeClasses, us_reportTemplateRoot])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Load Test Download Data
        DatabaseGetCollection(DatabaseRef_ActivePersistentClasses_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            us_setActivePersistentClasses(res_DGC.data)
            ur_forceRerender()
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // useEffect(() => {
  //   if (us_loadedClientsList === false) {
  //     if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.key != null) {
  //       cloudFunctionManageRequest('manageUser', {
  //         function: 'getAvailableClientsList',
  //         user_key: uc_RootData_GlobalUser.key,
  //       })
  //         .then((res_CFMUR: unknown) => {
  //           us_setAllClients(getProp(res_CFMUR, 'clientToggleOptions', {}))
  //           us_setLoadedClientsList(true)
  //         })
  //         .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
  //           console.error(rej_CFMUR)
  //         })
  //     }
  //   }
  // }, [uc_RootData_GlobalUser, us_loadedClientsList])

  // Functions
  const updateReportTemplateRootData = (newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, itemKey), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const updateReportTemplateTabData = (tabData: TsInterface_UnspecifiedObject, newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const editTemplateName = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_reportTemplateRoot,
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: <>{rLIB('Report Template Name')}</>,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Document(res_GCK.clientKey, itemKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Report Template')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editTabName = (tabData: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: tabData,
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: <>{rLIB('Tab Name')}</>,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Tab Name')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const addExcelTab = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: <>{rLIB('Tab Name')}</>,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let newTabKey = 'tab_' + new Date().getTime()
              let updateObject: TsInterface_UnspecifiedObject = {
                component_type: 'excel_tab',
                key: newTabKey,
                name: formSubmittedData.name,
                order: new Date().getTime(),
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, newTabKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Report Template')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editColumnName = (tabData: TsInterface_UnspecifiedObject, column: TsInterface_UnspecifiedObject, index: number): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: column,
          formInputs: {
            header: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'header',
              label: <>{rLIB('Column Name')}</>,
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (tabData != null && index != null && tabData['repeated_data'] != null && tabData['repeated_data'][index] != null) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      repeated_data: tabData['repeated_data'],
                    }
                    updateObject['repeated_data'][index]['header'] = formSubmittedData.header
                    DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  } else {
                    let error = {
                      message: rLIB('Failed to edit header'),
                      details: rLIB('Missing Column Data'),
                      code: 'ER-D-RTV-EDN-01',
                    }
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                    reject({ success: false, error: error })
                  }
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Tab Name')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editColumnDataType = (tabData: TsInterface_UnspecifiedObject, column: TsInterface_UnspecifiedObject, index: number): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: column,
          formInputs: {
            column_data_type: {
              data_type: 'string',
              input_type: 'multiple_choice_radio',
              key: 'column_data_type',
              label: rLIB('Column Data Type'),
              options: [
                { key: 'string', value: 'string' },
                { key: 'number', value: 'number' },
              ],
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (tabData != null && index != null && tabData['repeated_data'] != null && tabData['repeated_data'][index] != null) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      repeated_data: tabData['repeated_data'],
                    }
                    updateObject['repeated_data'][index]['column_data_type'] = formSubmittedData.column_data_type
                    DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  } else {
                    let error = {
                      message: rLIB('Failed to edit header'),
                      details: rLIB('Missing Column Data'),
                      code: 'ER-D-RTV-EDN-01',
                    }
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                    reject({ success: false, error: error })
                  }
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Tab Name')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteColumn = (tabData: TsInterface_UnspecifiedObject, index: number): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        icon: <Icon icon="trash" />,
        header: rLIB('Delete Column'),
        text: rLIB('Are you sure that you want to delete this column?'),
        submit_text: rLIB('Delete'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                if (tabData != null && index != null && tabData['repeated_data'] != null && tabData['repeated_data'][index] != null) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    repeated_data: tabData['repeated_data'],
                  }
                  updateObject['repeated_data'].splice(index, 1)
                  DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                } else {
                  let error = {
                    message: rLIB('Failed to edit header'),
                    details: rLIB('Missing Column Data'),
                    code: 'ER-D-RTV-DC-01',
                  }
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                  reject({ success: false, error: error })
                }
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const swapColumns = (tabData: TsInterface_UnspecifiedObject, index: number): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (tabData != null && index != null && tabData['repeated_data'] != null && tabData['repeated_data'][index] != null) {
          let updateObject: TsInterface_UnspecifiedObject = {
            repeated_data: tabData['repeated_data'],
          }
          if (index < 0 || index >= updateObject['repeated_data'].length - 1) {
            // Nothing
          } else {
            let temp = updateObject['repeated_data'][index]
            updateObject['repeated_data'][index] = updateObject['repeated_data'][index + 1]
            updateObject['repeated_data'][index + 1] = temp
          }
          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        } else {
          let error = {
            message: rLIB('Failed to edit header'),
            details: rLIB('Missing Column Data'),
            code: 'ER-D-RTV-IC-01',
          }
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        }
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const insertColumn = (tabData: TsInterface_UnspecifiedObject, index: number): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (tabData != null && index != null && tabData['repeated_data'] != null && tabData['repeated_data'][index] != null) {
          let updateObject: TsInterface_UnspecifiedObject = {
            repeated_data: tabData['repeated_data'],
          }
          updateObject['repeated_data'].splice(index + 1, 0, { header: null, content: {} }) // mapped_data_field, hardcode, excel_formula
          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        } else {
          let error = {
            message: rLIB('Failed to edit header'),
            details: rLIB('Missing Column Data'),
            code: 'ER-D-RTV-IC-01',
          }
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        }
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const openCellContentEditor = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnIndex: number,
    formInputs: TsInterface_FormInputs,
    formSettings: TsInterface_FormSettings,
    fieldToUpdateKey: string,
  ): void => {
    let formData: TsInterface_UnspecifiedObject = {}
    if (
      tabData != null &&
      columnIndex != null &&
      tabData['repeated_data'] != null &&
      tabData['repeated_data'][columnIndex] != null &&
      tabData['repeated_data'][columnIndex]['content'] != null &&
      tabData['repeated_data'][columnIndex]['content'][classKey] != null
    ) {
      formData = tabData['repeated_data'][columnIndex]['content'][classKey]
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: formData,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  if (tabData != null && columnIndex != null && tabData['repeated_data'] != null && tabData['repeated_data'][columnIndex] != null) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      repeated_data: tabData['repeated_data'],
                    }
                    if (updateObject['repeated_data'][columnIndex]['content'] == null) {
                      updateObject['repeated_data'][columnIndex]['content'] = {}
                    }
                    if (updateObject['repeated_data'][columnIndex]['content'][classKey] == null) {
                      updateObject['repeated_data'][columnIndex]['content'][classKey] = {}
                    }
                    updateObject['repeated_data'][columnIndex]['content'][classKey][fieldToUpdateKey] = formSubmittedData[fieldToUpdateKey]
                    DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  } else {
                    let error = {
                      message: rLIB('Failed to edit header'),
                      details: rLIB('Missing Column Data'),
                      code: 'ER-D-RTV-EDN-01',
                    }
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                    reject({ success: false, error: error })
                  }
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Edit Cell Type'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const testReportDownload = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey))
          .then((res_DGC) => {
            let dataBucketOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopDataBucketKey in res_DGC.data) {
              let loopDataBucket = res_DGC.data[loopDataBucketKey]
              dataBucketOptions.push({
                key: loopDataBucketKey,
                value: loopDataBucket.name,
              })
            }
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: us_testDownloadFormData,
                  formInputs: {
                    associated_data_bucket_key: {
                      data_type: 'string',
                      input_type: 'multiple_choice_select',
                      key: 'associated_data_bucket_key',
                      label: rLIB('Data Bucket'),
                      required: true,
                      options: dataBucketOptions,
                    },
                    start_date: {
                      data_type: 'number',
                      input_type: 'timestamp_datetime',
                      key: 'start_date',
                      label: rLIB('Start Date'),
                      required: true,
                    },
                    end_date: {
                      data_type: 'number',
                      input_type: 'timestamp_datetime',
                      key: 'end_date',
                      label: rLIB('End Date'),
                      required: true,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      us_setTestDownloadFormData(formSubmittedData)
                      DatabaseGetCollection(
                        DatabaseRef_DataForSpecificDataBucket_DateRange_Query(
                          res_GCK.clientKey,
                          formSubmittedData.associated_data_bucket_key,
                          new Date(formSubmittedData.start_date),
                          new Date(formSubmittedData.end_date),
                        ),
                      )
                        .then((res_DSMD) => {
                          // TODO: Different file types = PDF, Excel, etc

                          downloadCustomReport(res_GCK.clientKey, itemKey, res_DSMD.data, 'Test Report Download Placeholder Name', 'download', null)
                            .then((res_DR) => {
                              resolve(res_DR)
                            })
                            .catch((rej_DR) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DR.error })
                              reject(rej_DR)
                            })
                        })
                        .catch((rej_DSMD) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{rLIB('Edit Report Template')}</>,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="pen-to-square"
                    />
                  ),
                },
              },
            })
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const addPdfVariable = (tabData: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Variable Name'),
              required: true,
            },
            example_value: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'example_value',
              label: rLIB('Example Value'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let newVariableKey = keyFromString(removeSpecialCharactersAndTrimString(formSubmittedData.name)) + '_' + new Date().getTime()
              let updateObject = {
                pdf_variables: {
                  [newVariableKey]: {
                    key: newVariableKey,
                    name: formSubmittedData.name,
                    example_value: formSubmittedData.example_value,
                    associated_class_mappings: {},
                  },
                },
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                    .then((res_DSMD) => {
                      // console.log( res_DSMD )
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Report Template')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const copyReportTemplateToOtherWorkspace = (): void => {
    us_setCopyingReportTemplate(true)
    cloudFunctionManageRequest('manageUser', {
      function: 'getAvailableClientsList',
      user_key: uc_RootData_GlobalUser.key,
    })
      .then((res_CFMUR: unknown) => {
        let clientOptions: TsInterface_UnspecifiedObject = {}
        for (let loopWorkspaceKey in getProp(res_CFMUR, 'clientToggleOptions', {})) {
          let loopWorkspace = getProp(res_CFMUR, 'clientToggleOptions', {})[loopWorkspaceKey]
          if (loopWorkspace != null && loopWorkspace.name != null && loopWorkspace.key != null) {
            clientOptions[loopWorkspaceKey] = {
              key: loopWorkspace.key,
              value: loopWorkspace.name,
            }
          }
        }
        us_setCopyingReportTemplate(false)
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {},
              formInputs: {
                client_key: {
                  data_type: 'string',
                  input_type: 'multiple_choice_radio',
                  key: 'client_key',
                  label: rLIB('Workspace to copy to'),
                  required: true,
                  options: objectToArray(clientOptions),
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  // Copy Report Template to specified workspace
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_ReportTemplates_Document(formSubmittedData.client_key, itemKey),
                      data: us_reportTemplateRoot,
                    },
                  ]
                  for (let loopComponentKey in us_reportTemplateComponents) {
                    let loopComponent = us_reportTemplateComponents[loopComponentKey]
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_ReportTemplates_Components_Document(formSubmittedData.client_key, itemKey, loopComponentKey),
                      data: loopComponent,
                    })
                  }
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: <>{rLIB('Copy Report Template to Other Workspace')}</>,
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      })
      .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
        console.error(rej_CFMUR)
        us_setCopyingReportTemplate(false)
      })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={() => {
          un_routerNavigation(ApplicationPages.ReportsListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2 tw-mb-2"
      >
        {rLIB('Back to all Report Templates')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewTabButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        disableElevation
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          addExcelTab()
        }}
        className="tw-mb-2"
      >
        {rLIB('Add Tab')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_EditViewToggleButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_pdfViewMode === 'edit') {
      buttonJSX = (
        <Button
          color="info"
          variant="contained"
          disableElevation
          startIcon={<Icon icon="pen-to-square" />}
          onClick={() => {
            us_setPdfViewMode('view')
          }}
          className="tw-mb-2"
        >
          {rLIB('Edit Mode')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          color="info"
          variant="contained"
          disableElevation
          startIcon={<Icon icon="magnifying-glass" />}
          onClick={() => {
            us_setPdfViewMode('edit')
          }}
          className="tw-mb-2"
        >
          {rLIB('View Mode')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_CopyButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.super === true) {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          disabled={us_copyingReportTemplate}
          startIcon={
            us_copyingReportTemplate ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="copy" />
            )
          }
          onClick={() => {
            copyReportTemplateToOtherWorkspace()
          }}
          className="tw-mb-2 tw-ml-2"
        >
          {rLIB('Copy To Other Workspace')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_reportTemplateRoot != null && us_reportTemplateRoot.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Report Template')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_reportTemplateRoot.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Report Template') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_ReportTemplateSettings = (): JSX.Element => {
    let settingsJSX = (
      <Box>
        <Card className="tw-p-2">
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Report Template Name')}
            </Typography>
            <Box className="tw-ml-6">
              <Typography variant="h6">
                <Box className="tw-mr-1 tw-inline-block tw-opacity-40">{getProp(us_reportTemplateRoot, 'name', '')}</Box>
                <Icon
                  icon="pen-to-square"
                  sx={{
                    '&:hover': {
                      color: themeVariables.success_main,
                    },
                  }}
                  tooltip={rLIB('Edit')}
                  tooltipPlacement="right"
                  className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
                  onClick={() => {
                    editTemplateName()
                  }}
                />
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('File Type')}
            </Typography>
            <Box className="tw-ml-6">
              <Typography variant="h6">
                <Box className="tw-mr-1 tw-inline-block tw-opacity-40">{getProp(us_reportTemplateRoot, 'file_type', '')}</Box>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Report Template Visibility')}
            </Typography>
            <Box className="tw-ml-4">
              <Switch
                color="info"
                checked={getProp(us_reportTemplateRoot, 'visible', false) === true ? true : false}
                disabled={false}
                onChange={(event, value) => {
                  if (value === true) {
                    updateReportTemplateRootData({ visible: true })
                  } else if (value === false) {
                    updateReportTemplateRootData({ visible: false })
                  }
                }}
              />
              {getProp(us_reportTemplateRoot, 'visible', false) === true ? rLIB('Report Template Visible') : rLIB('Report Template Not Visible')}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Forms for this Report')}
            </Typography>
            <Box>
              {objectToArray(us_activeClasses)
                .sort(dynamicSort('name', null))
                .map((formField: TsInterface_UnspecifiedObject, index: number) => (
                  <Box key={index}>
                    <Checkbox
                      checked={getProp(getProp(us_reportTemplateRoot, 'associated_class_keys', {}), formField.key, false)}
                      onChange={(event, value) => {
                        if (value != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            associated_class_keys: {
                              [formField.key]: value,
                            },
                          }
                          updateReportTemplateRootData(updateObject)
                        }
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {formField.name}
                  </Box>
                ))}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Manage')}
            </Typography>
            <Box className="tw-ml-6">
              <Button
                className="tw-mr-2"
                color="error"
                variant="contained"
                disableElevation
                onClick={() => {
                  // deleteTab( tabData )
                }}
                disabled={true}
                startIcon={<Icon icon="trash" />}
              >
                {rLIB('Delete report template')}
              </Button>
              <Button
                className="tw-mr-2"
                color="success"
                variant="contained"
                disableElevation
                onClick={() => {
                  testReportDownload()
                }}
                startIcon={<Icon icon="download" />}
              >
                {rLIB('Download test data')}
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    )
    return settingsJSX
  }

  // Excel
  const rJSX_ExcelTab_RepeatedDataSection_ClassCell_HardcodedValue = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['hardcode_value'] != null
    ) {
      contentJSX = columnData['content'][classKey]['hardcode_value']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellContent_HardcodeValue, {}, 'hardcode_value')
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection_ClassCell_ExcelFormula = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['excel_value'] != null
    ) {
      contentJSX = columnData['content'][classKey]['excel_value']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellContent_ExcelFormula, {}, 'excel_value')
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection_ClassCell_MappedValue = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['mapped_data_field_name'] != null &&
      columnData['content'][classKey]['mapped_data_field_key'] != null
    ) {
      contentJSX = columnData['content'][classKey]['mapped_data_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (
            tabData != null &&
            columnIndex != null &&
            tabData['repeated_data'] != null &&
            tabData['repeated_data'][columnIndex] != null &&
            tabData['repeated_data'][columnIndex]['content'] != null &&
            tabData['repeated_data'][columnIndex]['content'][classKey] != null
          ) {
            formData = tabData['repeated_data'][columnIndex]['content'][classKey]
          }
          let mappedFieldOptions: TsInterface_UnspecifiedObject[] = []
          if (us_classFieldsData != null && us_classFieldsData[classKey] != null) {
            for (let loopFieldKey in us_classFieldsData[classKey]) {
              let loopField = us_classFieldsData[classKey][loopFieldKey]
              if (loopField.status === 'active') {
                if (loopField.form_type === 'image_upload') {
                  mappedFieldOptions.push({
                    key: loopField.key + '_url',
                    value: loopField.name,
                  })
                } else if (loopField.form_type === 'directory_field_verification') {
                  // Multiple Options for Directory Field Verification
                  mappedFieldOptions.push({
                    key: loopField.key,
                    value: (loopField.name + ' - ' + rLIB('Verification Code', false)) as string,
                  })
                  mappedFieldOptions.push({
                    key: loopField.key + '_verified',
                    value: (loopField.name + ' - ' + rLIB('Verified', false)) as string,
                  })
                  mappedFieldOptions.push({
                    key: loopField.key + '_directory_item_name',
                    value: (loopField.name + ' - ' + rLIB('Matching Directory Item', false)) as string,
                  })
                } else if (loopField.form_type === 'checkboxes') {
                  for (let loopOptionKey in loopField.options) {
                    let loopOption = loopField.options[loopOptionKey]
                    mappedFieldOptions.push({
                      key: loopField.key + '.' + loopOptionKey,
                      value: loopField.name + ' - ' + loopOption.value,
                    })
                  }
                } else {
                  mappedFieldOptions.push({
                    key: loopField.key,
                    value: loopField.name,
                  })
                }
              }
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_data_field_key: {
                    key: 'mapped_data_field_key',
                    label: rLIB('Mapped Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: mappedFieldOptions,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        if (tabData != null && columnIndex != null && tabData['repeated_data'] != null && tabData['repeated_data'][columnIndex] != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            repeated_data: tabData['repeated_data'],
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'] == null) {
                            updateObject['repeated_data'][columnIndex]['content'] = {}
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'][classKey] == null) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey] = {}
                          }
                          updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_data_field_key'] = formSubmittedData['mapped_data_field_key']
                          let mappedFieldKeySubstring = null
                          let mappedFieldOptionSubstring = null
                          if (
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].includes('.')
                          ) {
                            mappedFieldKeySubstring = formSubmittedData['mapped_data_field_key'].split('.')[0]
                            mappedFieldOptionSubstring = formSubmittedData['mapped_data_field_key'].split('.')[1]
                          }
                          if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key']] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key']]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_data_field_name'] =
                              us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key']]['name']
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            mappedFieldKeySubstring != null &&
                            us_classFieldsData[classKey][mappedFieldKeySubstring] != null &&
                            us_classFieldsData[classKey][mappedFieldKeySubstring]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_data_field_name'] =
                              us_classFieldsData[classKey][mappedFieldKeySubstring]['name'] + ' - ' + mappedFieldOptionSubstring
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].endsWith('_url') &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_url$/, '')] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_url$/, '')]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_data_field_name'] = (us_classFieldsData[classKey][
                              formSubmittedData['mapped_data_field_key'].replace(/_url$/, '')
                            ]['name'] +
                              ' - ' +
                              rLIB('url', false)) as string
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].endsWith('_verified') &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_verified$/, '')] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_verified$/, '')]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_data_field_name'] = (us_classFieldsData[classKey][
                              formSubmittedData['mapped_data_field_key'].replace(/_verified$/, '')
                            ]['name'] +
                              ' - ' +
                              rLIB('verified', false)) as string
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].endsWith('_directory_item_name') &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_directory_item_name$/, '')] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_directory_item_name$/, '')]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_data_field_name'] = (us_classFieldsData[classKey][
                              formSubmittedData['mapped_data_field_key'].replace(/_directory_item_name$/, '')
                            ]['name'] +
                              ' - ' +
                              rLIB('Matching Directory Item', false)) as string
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Mapped Value'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection_ClassCell_CalculatedField = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['mapped_calculated_field_name'] != null &&
      columnData['content'][classKey]['mapped_calculated_field_key'] != null
    ) {
      contentJSX = columnData['content'][classKey]['mapped_calculated_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (
            tabData != null &&
            columnIndex != null &&
            tabData['repeated_data'] != null &&
            tabData['repeated_data'][columnIndex] != null &&
            tabData['repeated_data'][columnIndex]['content'] != null &&
            tabData['repeated_data'][columnIndex]['content'][classKey] != null
          ) {
            formData = tabData['repeated_data'][columnIndex]['content'][classKey]
          }
          let calculatedFieldOptions: TsInterface_UnspecifiedObject[] = []
          if (us_activeCalculatedFields != null) {
            // TODO: Correct this to be the correct data

            for (let loopFieldKey in us_activeCalculatedFields) {
              let loopField = us_activeCalculatedFields[loopFieldKey]
              calculatedFieldOptions.push({
                key: loopField.key,
                value: loopField.name,
              })
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_calculated_field_key: {
                    key: 'mapped_calculated_field_key',
                    label: rLIB('Calculated Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: calculatedFieldOptions.sort(dynamicSort('value', 'asc')),
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        if (tabData != null && columnIndex != null && tabData['repeated_data'] != null && tabData['repeated_data'][columnIndex] != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            repeated_data: tabData['repeated_data'],
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'] == null) {
                            updateObject['repeated_data'][columnIndex]['content'] = {}
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'][classKey] == null) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey] = {}
                          }
                          updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_calculated_field_key'] =
                            formSubmittedData['mapped_calculated_field_key']

                          if (
                            us_activeCalculatedFields != null &&
                            us_activeCalculatedFields != null &&
                            us_activeCalculatedFields[formSubmittedData['mapped_calculated_field_key']] != null &&
                            us_activeCalculatedFields[formSubmittedData['mapped_calculated_field_key']]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_calculated_field_name'] =
                              us_activeCalculatedFields[formSubmittedData['mapped_calculated_field_key']]['name']
                          }

                          console.log(updateObject)

                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit field'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Calculated Field'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection_ClassCell_AssociationField = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['mapped_association_field_name'] != null &&
      columnData['content'][classKey]['mapped_association_field_key'] != null
    ) {
      contentJSX = columnData['content'][classKey]['mapped_association_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (
            tabData != null &&
            columnIndex != null &&
            tabData['repeated_data'] != null &&
            tabData['repeated_data'][columnIndex] != null &&
            tabData['repeated_data'][columnIndex]['content'] != null &&
            tabData['repeated_data'][columnIndex]['content'][classKey] != null
          ) {
            formData = tabData['repeated_data'][columnIndex]['content'][classKey]
          }
          let associationFieldOptions: TsInterface_UnspecifiedObject[] = []
          if (us_activePersistentClasses != null) {
            for (let loopFieldKey in us_activePersistentClasses) {
              let loopField = us_activePersistentClasses[loopFieldKey]
              associationFieldOptions.push({
                key: loopField.key,
                value: loopField.name,
              })
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_association_field_key: {
                    key: 'mapped_association_field_key',
                    label: rLIB('Association Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: associationFieldOptions,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        if (tabData != null && columnIndex != null && tabData['repeated_data'] != null && tabData['repeated_data'][columnIndex] != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            repeated_data: tabData['repeated_data'],
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'] == null) {
                            updateObject['repeated_data'][columnIndex]['content'] = {}
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'][classKey] == null) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey] = {}
                          }
                          updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_association_field_key'] =
                            formSubmittedData['mapped_association_field_key']
                          if (
                            us_activePersistentClasses != null &&
                            us_activePersistentClasses[formSubmittedData['mapped_association_field_key']] != null &&
                            us_activePersistentClasses[formSubmittedData['mapped_association_field_key']]['name'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_association_field_name'] =
                              us_activePersistentClasses[formSubmittedData['mapped_association_field_key']]['name']
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Associated Field'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection_ClassCell_MetadataField = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['mapped_metadata_field_name'] != null &&
      columnData['content'][classKey]['mapped_metadata_field_key'] != null
    ) {
      contentJSX = columnData['content'][classKey]['mapped_metadata_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (
            tabData != null &&
            columnIndex != null &&
            tabData['repeated_data'] != null &&
            tabData['repeated_data'][columnIndex] != null &&
            tabData['repeated_data'][columnIndex]['content'] != null &&
            tabData['repeated_data'][columnIndex]['content'][classKey] != null
          ) {
            formData = tabData['repeated_data'][columnIndex]['content'][classKey]
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_metadata_field_key: {
                    key: 'mapped_metadata_field_key',
                    label: rLIB('Metadata Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: objectToArray(metadataFieldOptions),
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        if (tabData != null && columnIndex != null && tabData['repeated_data'] != null && tabData['repeated_data'][columnIndex] != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            repeated_data: tabData['repeated_data'],
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'] == null) {
                            updateObject['repeated_data'][columnIndex]['content'] = {}
                          }
                          if (updateObject['repeated_data'][columnIndex]['content'][classKey] == null) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey] = {}
                          }
                          updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_metadata_field_key'] =
                            formSubmittedData['mapped_metadata_field_key']
                          if (
                            metadataFieldOptions != null &&
                            metadataFieldOptions[formSubmittedData['mapped_metadata_field_key']] != null &&
                            metadataFieldOptions[formSubmittedData['mapped_metadata_field_key']]['value'] != null
                          ) {
                            updateObject['repeated_data'][columnIndex]['content'][classKey]['mapped_metadata_field_name'] =
                              metadataFieldOptions[formSubmittedData['mapped_metadata_field_key']]['value']
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Cell Type'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection_ClassCell = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    columnData: TsInterface_UnspecifiedObject,
    columnIndex: number,
  ): JSX.Element => {
    let cellJSX = <></>
    if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['cell_type'] === 'mapped_data_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-code"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Mapped Field')}
            tooltipPlacement="top"
            onClick={() => {
              openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
            }}
          />
          {rJSX_ExcelTab_RepeatedDataSection_ClassCell_MappedValue(classKey, tabData, columnData, columnIndex)}
        </Box>
      )
    } else if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['cell_type'] === 'mapped_association_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-share-nodes"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Association Field')}
            tooltipPlacement="top"
            onClick={() => {
              openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
            }}
          />
          {rJSX_ExcelTab_RepeatedDataSection_ClassCell_AssociationField(classKey, tabData, columnData, columnIndex)}
        </Box>
      )
    } else if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['cell_type'] === 'mapped_metadata_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-poll-vertical"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Association Field')}
            tooltipPlacement="top"
            onClick={() => {
              openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
            }}
          />
          {rJSX_ExcelTab_RepeatedDataSection_ClassCell_MetadataField(classKey, tabData, columnData, columnIndex)}
        </Box>
      )
    } else if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['cell_type'] === 'hardcode'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-quote"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.warning_main, fontSize: '22px' }}
            tooltip={rLIB('Hardcoded Value')}
            tooltipPlacement="top"
            onClick={() => {
              openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
            }}
          />
          {rJSX_ExcelTab_RepeatedDataSection_ClassCell_HardcodedValue(classKey, tabData, columnData, columnIndex)}
        </Box>
      )
    } else if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['cell_type'] === 'excel_formula'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-divide"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.success_light, fontSize: '22px' }}
            tooltip={rLIB('Excel Formula')}
            tooltipPlacement="top"
            onClick={() => {
              openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
            }}
          />
          {rJSX_ExcelTab_RepeatedDataSection_ClassCell_ExcelFormula(classKey, tabData, columnData, columnIndex)}
        </Box>
      )
    } else if (
      columnData != null &&
      columnData['content'] != null &&
      columnData['content'][classKey] != null &&
      columnData['content'][classKey]['cell_type'] === 'mapped_calculated_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-divide"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Calculated Field')}
            tooltipPlacement="top"
            onClick={() => {
              openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
            }}
          />
          {rJSX_ExcelTab_RepeatedDataSection_ClassCell_CalculatedField(classKey, tabData, columnData, columnIndex)}
        </Box>
      )

      // TODO

      // } else if(
      // columnData != null &&
      // columnData["content"] != null &&
      // columnData["content"][ classKey ] != null &&
      // columnData["content"][ classKey ]["cell_type"] === "internal_formula"
      // ){
      // cellJSX =
      // <Box>
      // 	<Icon
      // 		icon="square-bolt"
      // 		className="tw-mr-2 tw-cursor-pointer"
      // 		sx={{ color: themeVariables.error_light, fontSize: "22px" }}
      // 		tooltip={ s_CUSTOM_FORMULA }
      // 		tooltipPlacement='top'
      // 		onClick={ () => {
      // 			openCellContentEditor( classKey, tabData, columnIndex, formInputs_RepeatedDataCellType,	{ submit_button_hide: true }, "cell_type" )
      // 		}}
      // 	/>
      // </Box>
    } else {
      cellJSX = (
        <Box
          className="tw-italic tw-cursor-pointer"
          sx={{ color: themeVariables.secondary_main }}
          onClick={() => {
            openCellContentEditor(classKey, tabData, columnIndex, formInputs_RepeatedDataCellType, { submit_button_hide: true }, 'cell_type')
          }}
        >
          <Icon
            icon="square-question"
            className="tw-mr-2 tw-align-top"
            sx={{ color: themeVariables.secondary_main, fontSize: '22px' }}
            tooltipPlacement="top"
          />
          {rLIB('Select Cell Type')}
        </Box>
      )
    }
    return cellJSX
  }

  const rJSX_ExcelTab_RepeatedDataSection = (tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let repeatedDataSectionJSX = <></>
    if (tabData.include_repeated_data_section === true) {
      let activeClasses: TsInterface_UnspecifiedObject = {}
      for (let loopClassKey in getProp(us_reportTemplateRoot, 'associated_class_keys', {})) {
        let loopClassBoolean = us_reportTemplateRoot['associated_class_keys'][loopClassKey]
        if (loopClassBoolean === true && us_activeClasses != null && us_activeClasses[loopClassKey] != null && us_activeClasses[loopClassKey]['name'] != null) {
          activeClasses[loopClassKey] = {
            name: us_activeClasses[loopClassKey]['name'],
            key: loopClassKey,
          }
        }
      }
      repeatedDataSectionJSX = (
        <Card className="tw-p-2 tw-mt-4">
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Repeated Data')}
            </Typography>
          </Box>
          <TableContainer className="bp_show_horizontal_scroll">
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ minWidth: '150px', background: themeVariables.gray_200 }}></TableCell>
                  {objectToArray(getProp(tabData, 'repeated_data', {}))
                    .sort(dynamicSort('order', null))
                    .map((column: TsInterface_UnspecifiedObject, index: number) => (
                      <TableCell
                        key={index}
                        sx={{ minWidth: '200px', backgroundColor: index % 2 === 1 ? themeVariables.gray_200 : themeVariables.gray_100 }}
                      >
                        <Stack
                          direction="row"
                          spacing={0}
                          className="tw-justify-between"
                        >
                          <Box className="tw-align-bottom">
                            <Typography
                              variant="h6"
                              sx={{ color: themeVariables.success_light, verticalAlign: 'bottom' }}
                              className="tw-font-black tw-inline-block"
                            >
                              {excelColumnIndexToLetter(index)}
                            </Typography>
                            <Icon
                              icon="trash"
                              className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2 tw-mb-2"
                              sx={{
                                'verticalAlign': 'bottom',
                                '&:hover': {
                                  color: themeVariables.error_main,
                                },
                              }}
                              tooltip={rLIB('Delete Column')}
                              tooltipPlacement="top"
                              onClick={() => {
                                deleteColumn(tabData, index)
                              }}
                            />
                          </Box>
                          <Box>
                            <Box>
                              <Icon
                                icon="right-left"
                                className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
                                sx={{
                                  'verticalAlign': 'bottom',
                                  '&:hover': {
                                    color: themeVariables.info_main,
                                  },
                                }}
                                tooltip={rLIB('Swap Columns')}
                                tooltipPlacement="top"
                                onClick={() => {
                                  swapColumns(tabData, index)
                                }}
                              />
                            </Box>
                            <Box>
                              <Icon
                                icon="circle-plus"
                                className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
                                sx={{
                                  'verticalAlign': 'bottom',
                                  '&:hover': {
                                    color: themeVariables.success_main,
                                  },
                                }}
                                tooltip={rLIB('Insert Column')}
                                tooltipPlacement="top"
                                onClick={() => {
                                  insertColumn(tabData, index)
                                }}
                              />
                            </Box>
                          </Box>
                        </Stack>
                      </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                  <TableCell sx={{ minWidth: '150px', fontWeight: 'bold', background: themeVariables.gray_200 }}>{rLIB('Header')}</TableCell>
                  {objectToArray(getProp(tabData, 'repeated_data', {}))
                    .sort(dynamicSort('order', null))
                    .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
                      <TableCell
                        key={columnIndex}
                        sx={{ minWidth: '200px', backgroundColor: columnIndex % 2 === 1 ? themeVariables.gray_200 : themeVariables.gray_100 }}
                      >
                        {column.header != null ? column.header : <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Header')}</Box>}
                        <Icon
                          icon="pen-to-square"
                          className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
                          sx={{
                            'verticalAlign': 'bottom',
                            '&:hover': {
                              color: themeVariables.success_main,
                            },
                          }}
                          tooltip={rLIB('Edit')}
                          tooltipPlacement="top"
                          onClick={() => {
                            editColumnName(tabData, column, columnIndex)
                          }}
                        />
                      </TableCell>
                    ))}
                </TableRow>
                {objectToArray(activeClasses)
                  .sort(dynamicSort('name', 'asc'))
                  .map((classObject: TsInterface_UnspecifiedObject, classIndex: number) => (
                    <TableRow key={classIndex}>
                      <TableCell sx={{ minWidth: '150px', fontWeight: 'bold', background: themeVariables.gray_200 }}>{classObject.name}</TableCell>
                      {objectToArray(getProp(tabData, 'repeated_data', {}))
                        .sort(dynamicSort('order', null))
                        .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
                          <TableCell
                            key={columnIndex}
                            sx={{ minWidth: '200px', backgroundColor: columnIndex % 2 === 1 ? themeVariables.gray_200 : themeVariables.gray_100 }}
                          >
                            {rJSX_ExcelTab_RepeatedDataSection_ClassCell(classObject.key, tabData, column, columnIndex)}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell sx={{ minWidth: '150px', fontWeight: 'bold', background: themeVariables.gray_200 }}>{rLIB('Data Type')}</TableCell>
                  {objectToArray(getProp(tabData, 'repeated_data', {}))
                    .sort(dynamicSort('order', null))
                    .map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
                      <TableCell
                        key={columnIndex}
                        sx={{ minWidth: '200px', backgroundColor: columnIndex % 2 === 1 ? themeVariables.gray_200 : themeVariables.gray_100 }}
                      >
                        {column.column_data_type != null ? (
                          column.column_data_type
                        ) : (
                          <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Default')}</Box>
                        )}
                        <Icon
                          icon="pen-to-square"
                          className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
                          sx={{
                            'verticalAlign': 'bottom',
                            '&:hover': {
                              color: themeVariables.success_main,
                            },
                          }}
                          tooltip={rLIB('Edit')}
                          tooltipPlacement="top"
                          onClick={() => {
                            editColumnDataType(tabData, column, columnIndex)
                          }}
                        />
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tw-mt-2 tw-text-right">
            <Button
              color="success"
              variant="contained"
              disableElevation
              startIcon={<Icon icon="circle-plus" />}
              onClick={() => {
                let updateObject: TsInterface_UnspecifiedObject = {
                  repeated_data: getProp(tabData, 'repeated_data', []),
                }
                updateObject.repeated_data.push({
                  header: null,
                  content: {},
                })
                updateReportTemplateTabData(tabData, updateObject)
              }}
            >
              {rLIB('Add Column')}
            </Button>
          </Box>
        </Card>
      )
    }
    return repeatedDataSectionJSX
  }

  const rJSX_ExcelTab = (tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card className="tw-p-2">
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Tab Name')}
            </Typography>
            <Box className="tw-ml-6">
              <Typography variant="h6">
                <Box className="tw-mr-1 tw-inline-block tw-opacity-40">{tabData.name}</Box>
                <Icon
                  icon="pen-to-square"
                  sx={{
                    '&:hover': {
                      color: themeVariables.success_main,
                    },
                  }}
                  tooltip={rLIB('Edit')}
                  tooltipPlacement="right"
                  className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
                  onClick={() => {
                    editTabName(tabData)
                  }}
                />
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Sections to Include')}
            </Typography>
            <Box className="tw-ml-4">
              <Switch
                color="info"
                checked={tabData.include_repeated_data_section === true ? true : false}
                disabled={false}
                onChange={(event, value) => {
                  if (value === true) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      include_repeated_data_section: true,
                    }
                    if (tabData.repeated_data == null) {
                      updateObject.repeated_data = []
                    }
                    updateReportTemplateTabData(tabData, updateObject)
                  } else if (value === false) {
                    updateReportTemplateTabData(tabData, { include_repeated_data_section: false })
                  }
                }}
              />
              {tabData.include_repeated_data_section === true ? rLIB('Include Repeated Data on This Tab') : rLIB('Do Not Include Repeated Data on This Tab')}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Manage')}
            </Typography>
            <Box className="tw-ml-6">
              <Button
                color="error"
                variant="contained"
                disableElevation
                onClick={() => {
                  // deleteTab( tabData )
                }}
                disabled={true}
                startIcon={<Icon icon="trash" />}
              >
                {rLIB('Delete Tab')}
              </Button>
            </Box>
          </Box>
        </Card>
        <Box>{rJSX_ExcelTab_RepeatedDataSection(tabData)}</Box>
      </Box>
    )
    return tabJSX
  }

  // PDF Templates
  const openPdfVariableTypeMappingEditor = (tabData: TsInterface_UnspecifiedObject, variableObject: TsInterface_UnspecifiedObject, classKey: string): void => {
    let formData: TsInterface_UnspecifiedObject = {}
    if (variableObject != null && variableObject['associated_class_mappings'] != null && variableObject['associated_class_mappings'][classKey] != null) {
      formData = variableObject['associated_class_mappings'][classKey]
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: formData,
          formInputs: formInputs_PdfVariableMappingType,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_hide: true,
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = {
                pdf_variables: {
                  [variableObject.key]: {
                    associated_class_mappings: {
                      [classKey]: {
                        mapping_type: formSubmittedData.mapping_type,
                      },
                    },
                  },
                },
              }
              console.log(updateObject)
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                    .then((res_DSMD) => {
                      // console.log( res_DSMD )
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Edit Cell Type'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const rJSX_PdfVariableMapping_HardcodedValue = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    variableObject: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['hardcode_value'] != null
    ) {
      contentJSX = variableObject['associated_class_mappings'][classKey]['hardcode_value']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          let formData: TsInterface_UnspecifiedObject = {}
          if (variableObject != null && variableObject['associated_class_mappings'] != null && variableObject['associated_class_mappings'][classKey] != null) {
            formData = variableObject['associated_class_mappings'][classKey]
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  hardcode_value: {
                    key: 'hardcode_value',
                    label: rLIB('Hardcoded Value'),
                    input_type: 'text_basic',
                    required: true,
                    data_type: 'string',
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        if (variableObject != null && variableObject['associated_class_mappings'] != null) {
                          updateObject = {
                            pdf_variables: {
                              [variableObject.key]: {
                                associated_class_mappings: {
                                  [classKey]: {
                                    hardcode_value: formSubmittedData['hardcode_value'],
                                  },
                                },
                              },
                            },
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Mapped Value'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_PdfVariableMapping_MappedValue = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    variableObject: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_data_field_name'] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_data_field_key'] != null
    ) {
      contentJSX = variableObject['associated_class_mappings'][classKey]['mapped_data_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (variableObject != null && variableObject['associated_class_mappings'] != null && variableObject['associated_class_mappings'][classKey] != null) {
            formData = variableObject['associated_class_mappings'][classKey]
          }
          let mappedFieldOptions: TsInterface_UnspecifiedObject[] = []
          if (us_classFieldsData != null && us_classFieldsData[classKey] != null) {
            for (let loopFieldKey in us_classFieldsData[classKey]) {
              let loopField = us_classFieldsData[classKey][loopFieldKey]
              if (loopField.status === 'active') {
                if (loopField.form_type === 'image_upload') {
                  mappedFieldOptions.push({
                    key: loopField.key + '_url',
                    value: loopField.name,
                  })
                } else if (loopField.form_type === 'directory_field_verification') {
                  // Multiple Options for Directory Field Verification
                  mappedFieldOptions.push({
                    key: loopField.key,
                    value: (loopField.name + ' - ' + rLIB('Verification Code', false)) as string,
                  })
                  mappedFieldOptions.push({
                    key: loopField.key + '_verified',
                    value: (loopField.name + ' - ' + rLIB('Verified', false)) as string,
                  })
                  mappedFieldOptions.push({
                    key: loopField.key + '_directory_item_name',
                    value: (loopField.name + ' - ' + rLIB('Matching Directory Item', false)) as string,
                  })
                } else if (loopField.form_type === 'checkboxes') {
                  for (let loopOptionKey in loopField.options) {
                    let loopOption = loopField.options[loopOptionKey]
                    mappedFieldOptions.push({
                      key: loopField.key + '.' + loopOptionKey,
                      value: loopField.name + ' - ' + loopOption.value,
                    })
                  }
                } else {
                  mappedFieldOptions.push({
                    key: loopField.key,
                    value: loopField.name,
                  })
                }
              }
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_data_field_key: {
                    key: 'mapped_data_field_key',
                    label: rLIB('Mapped Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: mappedFieldOptions,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        if (variableObject != null && variableObject['associated_class_mappings'] != null) {
                          updateObject = {
                            pdf_variables: {
                              [variableObject.key]: {
                                associated_class_mappings: {
                                  [classKey]: {
                                    mapped_data_field_key: formSubmittedData['mapped_data_field_key'],
                                  },
                                },
                              },
                            },
                          }
                          let mappedFieldKeySubstring = null
                          let mappedFieldOptionSubstring = null
                          if (
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].includes('.')
                          ) {
                            mappedFieldKeySubstring = formSubmittedData['mapped_data_field_key'].split('.')[0]
                            mappedFieldOptionSubstring = formSubmittedData['mapped_data_field_key'].split('.')[1]
                          }
                          if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key']] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key']]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_data_field_name'] =
                              us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key']]['name']
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            mappedFieldKeySubstring != null &&
                            us_classFieldsData[classKey][mappedFieldKeySubstring] != null &&
                            us_classFieldsData[classKey][mappedFieldKeySubstring]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_data_field_name'] =
                              us_classFieldsData[classKey][mappedFieldKeySubstring]['name'] + ' - ' + mappedFieldOptionSubstring
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].endsWith('_url') &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_url$/, '')] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_url$/, '')]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_data_field_name'] =
                              (us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_url$/, '')]['name'] +
                                ' - ' +
                                rLIB('url', false)) as string
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].endsWith('_verified') &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_verified$/, '')] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_verified$/, '')]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_data_field_name'] =
                              (us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_verified$/, '')]['name'] +
                                ' - ' +
                                rLIB('verified', false)) as string
                          } else if (
                            us_classFieldsData != null &&
                            us_classFieldsData[classKey] != null &&
                            formSubmittedData != null &&
                            formSubmittedData['mapped_data_field_key'] != null &&
                            formSubmittedData['mapped_data_field_key'].endsWith('_directory_item_name') &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_directory_item_name$/, '')] != null &&
                            us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_directory_item_name$/, '')]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_data_field_name'] =
                              (us_classFieldsData[classKey][formSubmittedData['mapped_data_field_key'].replace(/_directory_item_name$/, '')]['name'] +
                                ' - ' +
                                rLIB('Matching Directory Item', false)) as string
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Mapped Value'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_PdfVariableMapping_CalculatedField = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    variableObject: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_calculated_field_name'] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_calculated_field_key'] != null
    ) {
      contentJSX = variableObject['associated_class_mappings'][classKey]['mapped_calculated_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (variableObject != null && variableObject['associated_class_mappings'] != null && variableObject['associated_class_mappings'][classKey] != null) {
            formData = variableObject['associated_class_mappings'][classKey]
          }
          let calculatedFieldOptions: TsInterface_UnspecifiedObject[] = []
          if (us_activeCalculatedFields != null) {
            // TODO: Correct this to be the correct data

            for (let loopFieldKey in us_activeCalculatedFields) {
              let loopField = us_activeCalculatedFields[loopFieldKey]
              calculatedFieldOptions.push({
                key: loopField.key,
                value: loopField.name,
              })
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_calculated_field_key: {
                    key: 'mapped_calculated_field_key',
                    label: rLIB('Calculated Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: calculatedFieldOptions.sort(dynamicSort('value', 'asc')),
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        if (variableObject != null && variableObject['associated_class_mappings'] != null) {
                          updateObject = {
                            pdf_variables: {
                              [variableObject.key]: {
                                associated_class_mappings: {
                                  [classKey]: {
                                    mapped_calculated_field_key: formSubmittedData['mapped_calculated_field_key'],
                                  },
                                },
                              },
                            },
                          }
                          if (
                            us_activeCalculatedFields != null &&
                            us_activeCalculatedFields != null &&
                            us_activeCalculatedFields[formSubmittedData['mapped_calculated_field_key']] != null &&
                            us_activeCalculatedFields[formSubmittedData['mapped_calculated_field_key']]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_calculated_field_name'] =
                              us_activeCalculatedFields[formSubmittedData['mapped_calculated_field_key']]['name']
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit field'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Calculated Field'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_PdfVariableMapping_AssociationField = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    variableObject: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_association_field_name'] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_association_field_key'] != null
    ) {
      contentJSX = variableObject['associated_class_mappings'][classKey]['mapped_association_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (variableObject != null && variableObject['associated_class_mappings'] != null && variableObject['associated_class_mappings'][classKey] != null) {
            formData = variableObject['associated_class_mappings'][classKey]
          }
          let associationFieldOptions: TsInterface_UnspecifiedObject[] = []
          if (us_activePersistentClasses != null) {
            for (let loopFieldKey in us_activePersistentClasses) {
              let loopField = us_activePersistentClasses[loopFieldKey]
              associationFieldOptions.push({
                key: loopField.key,
                value: loopField.name,
              })
            }
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_association_field_key: {
                    key: 'mapped_association_field_key',
                    label: rLIB('Association Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: associationFieldOptions,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        if (variableObject != null && variableObject['associated_class_mappings'] != null) {
                          updateObject = {
                            pdf_variables: {
                              [variableObject.key]: {
                                associated_class_mappings: {
                                  [classKey]: {
                                    mapped_association_field_key: formSubmittedData['mapped_association_field_key'],
                                  },
                                },
                              },
                            },
                          }
                          if (
                            us_activePersistentClasses != null &&
                            us_activePersistentClasses[formSubmittedData['mapped_association_field_key']] != null &&
                            us_activePersistentClasses[formSubmittedData['mapped_association_field_key']]['name'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_association_field_name'] =
                              us_activePersistentClasses[formSubmittedData['mapped_association_field_key']]['name']
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Associated Field'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_PdfVariableMapping_MetadataField = (
    classKey: string,
    tabData: TsInterface_UnspecifiedObject,
    variableObject: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let cellContentJSX = <></>
    let contentJSX = <></>
    let editIconJSX = <></>
    if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_metadata_field_name'] != null &&
      variableObject['associated_class_mappings'][classKey]['mapped_metadata_field_key'] != null
    ) {
      contentJSX = variableObject['associated_class_mappings'][classKey]['mapped_metadata_field_name']
    } else {
      contentJSX = (
        <Box
          sx={{ color: themeVariables.error_light }}
          className="tw-italic tw-inline-block"
        >
          {rLIB('No Value')}
        </Box>
      )
    }
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100 tw-ml-2"
        sx={{
          'verticalAlign': 'bottom',
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        tooltip={rLIB('Edit')}
        tooltipPlacement="top"
        onClick={() => {
          // // Too annoyingly different than other edits since it has dynamic form input options and 2 fields to update
          let formData: TsInterface_UnspecifiedObject = {}
          if (variableObject != null && variableObject['associated_class_mappings'] != null && variableObject['associated_class_mappings'][classKey] != null) {
            formData = variableObject['associated_class_mappings'][classKey]
          }
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: {
                  mapped_metadata_field_key: {
                    key: 'mapped_metadata_field_key',
                    label: rLIB('Metadata Field'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    submit_on_change: true,
                    options: objectToArray(metadataFieldOptions),
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: { submit_button_hide: true },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {}
                        if (variableObject != null && variableObject['associated_class_mappings'] != null) {
                          updateObject = {
                            pdf_variables: {
                              [variableObject.key]: {
                                associated_class_mappings: {
                                  [classKey]: {
                                    mapped_metadata_field_key: formSubmittedData['mapped_metadata_field_key'],
                                  },
                                },
                              },
                            },
                          }
                          if (
                            metadataFieldOptions != null &&
                            metadataFieldOptions[formSubmittedData['mapped_metadata_field_key']] != null &&
                            metadataFieldOptions[formSubmittedData['mapped_metadata_field_key']]['value'] != null
                          ) {
                            updateObject['pdf_variables'][variableObject.key]['associated_class_mappings'][classKey]['mapped_metadata_field_name'] =
                              metadataFieldOptions[formSubmittedData['mapped_metadata_field_key']]['value']
                          }
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        } else {
                          let error = {
                            message: rLIB('Failed to edit header'),
                            details: rLIB('Missing Column Data'),
                            code: 'ER-D-RTV-EDN-01',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit Cell Type'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    cellContentJSX = (
      <Box className="tw-inline-block tw-align-top">
        {contentJSX}
        {editIconJSX}
      </Box>
    )
    return cellContentJSX
  }

  const rJSX_PdfVariableMappingCellContents = (
    tabData: TsInterface_UnspecifiedObject,
    variableObject: TsInterface_UnspecifiedObject,
    classKey: string,
  ): JSX.Element => {
    let cellJSX = <></>
    if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapping_type'] === 'mapped_data_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-code"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Mapped Field')}
            tooltipPlacement="top"
            onClick={() => {
              openPdfVariableTypeMappingEditor(tabData, variableObject, classKey)
            }}
          />
          {rJSX_PdfVariableMapping_MappedValue(classKey, tabData, variableObject)}
        </Box>
      )
    } else if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapping_type'] === 'mapped_association_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-share-nodes"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Association Field')}
            tooltipPlacement="top"
            onClick={() => {
              openPdfVariableTypeMappingEditor(tabData, variableObject, classKey)
            }}
          />
          {rJSX_PdfVariableMapping_AssociationField(classKey, tabData, variableObject)}
        </Box>
      )
    } else if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapping_type'] === 'mapped_metadata_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-poll-vertical"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Association Field')}
            tooltipPlacement="top"
            onClick={() => {
              openPdfVariableTypeMappingEditor(tabData, variableObject, classKey)
            }}
          />
          {rJSX_PdfVariableMapping_MetadataField(classKey, tabData, variableObject)}
        </Box>
      )
    } else if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapping_type'] === 'hardcode'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-quote"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.warning_main, fontSize: '22px' }}
            tooltip={rLIB('Hardcoded Value')}
            tooltipPlacement="top"
            onClick={() => {
              openPdfVariableTypeMappingEditor(tabData, variableObject, classKey)
            }}
          />
          {rJSX_PdfVariableMapping_HardcodedValue(classKey, tabData, variableObject)}
        </Box>
      )
    } else if (
      variableObject != null &&
      variableObject['associated_class_mappings'] != null &&
      variableObject['associated_class_mappings'][classKey] != null &&
      variableObject['associated_class_mappings'][classKey]['mapping_type'] === 'mapped_calculated_field'
    ) {
      cellJSX = (
        <Box>
          <Icon
            icon="square-divide"
            className="tw-mr-2 tw-cursor-pointer"
            sx={{ color: themeVariables.info_light, fontSize: '22px' }}
            tooltip={rLIB('Calculated Field')}
            tooltipPlacement="top"
            onClick={() => {
              openPdfVariableTypeMappingEditor(tabData, variableObject, classKey)
            }}
          />
          {rJSX_PdfVariableMapping_CalculatedField(classKey, tabData, variableObject)}
        </Box>
      )
    } else {
      cellJSX = (
        <Box
          className="tw-italic tw-cursor-pointer"
          sx={{ color: themeVariables.secondary_main }}
          onClick={() => {
            openPdfVariableTypeMappingEditor(tabData, variableObject, classKey)
          }}
        >
          <Icon
            icon="square-question"
            className="tw-mr-2 tw-align-top"
            sx={{ color: themeVariables.secondary_main, fontSize: '22px' }}
            tooltipPlacement="top"
          />
          {rLIB('Select Cell Type')}
        </Box>
      )
    }
    return cellJSX
  }

  const rJSX_PdfVariableNameCellContents = (variableObject: TsInterface_UnspecifiedObject, tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let cellJSX = <></>
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: variableObject,
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: rLIB('Variable Name'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let updateObject = {
                      pdf_variables: {
                        [variableObject.key]: {
                          name: formSubmittedData.name,
                        },
                      },
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                          .then((res_DSMD) => {
                            // console.log( res_DSMD )
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Edit Report Template')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    let deleteIconJSX = (
      <Icon
        icon="trash"
        className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main"
        tooltip={rLIB('Delete')}
        tooltipPlacement="right"
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash" />,
              header: rLIB('Delete Variable'),
              text: rLIB('Are you sure that you want to delete this variable?'),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        pdf_variables: {
                          [variableObject.key]: {
                            status: 'deleted',
                          },
                        },
                      }
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }}
      />
    )
    if (variableObject != null && variableObject['name'] != null) {
      cellJSX = (
        <Box>
          {variableObject['name']}
          {editIconJSX}
          {deleteIconJSX}
        </Box>
      )
    } else {
      cellJSX = (
        <Box className="tw-opacity-30 tw-italic">
          {editIconJSX}
          {deleteIconJSX}
        </Box>
      )
    }
    return cellJSX
  }

  const rJSX_PdfVariableExampleValueCellContents = (variableObject: TsInterface_UnspecifiedObject, tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let cellJSX = <></>
    let editIconJSX = <></>
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: variableObject,
                formInputs: {
                  example_value: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'example_value',
                    label: rLIB('Example Value'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let updateObject = {
                      pdf_variables: {
                        [variableObject.key]: {
                          example_value: formSubmittedData.example_value,
                        },
                      },
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                          .then((res_DSMD) => {
                            // console.log( res_DSMD )
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Edit Report Template')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    if (variableObject != null && variableObject['example_value'] != null) {
      cellJSX = (
        <Box>
          {variableObject['example_value']}
          {editIconJSX}
        </Box>
      )
    } else {
      cellJSX = (
        <Box className="tw-opacity-30 tw-italic">
          {rLIB('No Example Value')}
          {editIconJSX}
        </Box>
      )
    }
    return cellJSX
  }

  const rJSX_PdfVariableValueFormat = (variableObject: TsInterface_UnspecifiedObject, tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let cellJSX = <></>
    let editIconJSX = <></>
    editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: variableObject,
                formInputs: {
                  value_format: {
                    data_type: 'string',
                    input_type: 'multiple_choice_radio',
                    key: 'value_format',
                    label: rLIB('Value Format'),
                    required: true,
                    options: objectToArray(valueFormatOptions),
                    submit_on_change: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let updateObject = {
                      pdf_variables: {
                        [variableObject.key]: {
                          value_format: formSubmittedData.value_format,
                        },
                      },
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_ReportTemplates_Components_Document(res_GCK.clientKey, itemKey, tabData.key), updateObject)
                          .then((res_DSMD) => {
                            // console.log( res_DSMD )
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Edit Variable Value Format')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    if (variableObject != null && variableObject['value_format'] != null) {
      if (valueFormatOptions[variableObject['value_format']] != null) {
        cellJSX = (
          <Box>
            {valueFormatOptions[variableObject['value_format']]['value']}
            {editIconJSX}
          </Box>
        )
      } else {
        cellJSX = (
          <Box>
            {variableObject['value_format']}
            {editIconJSX}
          </Box>
        )
      }
    } else {
      cellJSX = (
        <Box className="tw-opacity-30 tw-italic">
          {rLIB('Default')}
          {editIconJSX}
        </Box>
      )
    }
    return cellJSX
  }

  const rJSX_VariableMappingTable = (pdfVariables: TsInterface_UnspecifiedObject, tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let tableJSX = <></>
    // Get List of Active Classes
    let activeClasses: TsInterface_UnspecifiedObject = {}
    for (let loopClassKey in getProp(us_reportTemplateRoot, 'associated_class_keys', {})) {
      let loopClassBoolean = us_reportTemplateRoot['associated_class_keys'][loopClassKey]
      if (loopClassBoolean === true && us_activeClasses != null && us_activeClasses[loopClassKey] != null && us_activeClasses[loopClassKey]['name'] != null) {
        activeClasses[loopClassKey] = {
          name: us_activeClasses[loopClassKey]['name'],
          key: loopClassKey,
        }
      }
    }
    let filteredPdfVariables: TsInterface_UnspecifiedObject = {}
    for (let loopPdfVariableKey in pdfVariables) {
      let loopPdfVariable = pdfVariables[loopPdfVariableKey]
      if (loopPdfVariable['status'] != 'deleted') {
        filteredPdfVariables[loopPdfVariableKey] = loopPdfVariable
      }
    }
    // Generate JSX
    tableJSX = (
      <Box>
        <Card>
          <TableContainer className="bp_show_horizontal_scroll">
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ minWidth: '150px', background: themeVariables.gray_200 }}></TableCell>
                  <TableCell sx={{ minWidth: '150px', background: themeVariables.warning_main, fontWeight: 'bold' }}>{rLIB('EXAMPLE VALUE')}</TableCell>
                  <TableCell sx={{ minWidth: '100px', fontWeight: 'bold' }}>{rLIB('Value Format')}</TableCell>
                  {objectToArray(activeClasses)
                    .sort(dynamicSort('name', 'asc'))
                    .map((classObject: TsInterface_UnspecifiedObject, classIndex: number) => (
                      <TableCell
                        key={classIndex}
                        sx={{
                          minWidth: '200px',
                          backgroundColor: classIndex % 2 === 1 ? themeVariables.gray_200 : themeVariables.gray_100,
                          fontWeight: 'bold',
                        }}
                      >
                        {classObject.name}
                      </TableCell>
                    ))}
                </TableRow>
                {objectToArray(filteredPdfVariables)
                  .sort(dynamicSort('name', null))
                  .map((variableObject: TsInterface_UnspecifiedObject, variableIndex: number) => (
                    <TableRow key={variableIndex}>
                      <TableCell sx={{ minWidth: '150px', background: themeVariables.gray_200, fontWeight: 'bold' }}>
                        {rJSX_PdfVariableNameCellContents(variableObject, tabData)}
                      </TableCell>
                      <TableCell sx={{ minWidth: '150px', background: themeVariables.warning_main }}>
                        {rJSX_PdfVariableExampleValueCellContents(variableObject, tabData)}
                      </TableCell>
                      <TableCell sx={{ minWidth: '100px' }}>{rJSX_PdfVariableValueFormat(variableObject, tabData)}</TableCell>
                      {objectToArray(activeClasses)
                        .sort(dynamicSort('name', 'asc'))
                        .map((classObject: TsInterface_UnspecifiedObject, classIndex: number) => (
                          <TableCell
                            key={classIndex}
                            sx={{ minWidth: '200px', backgroundColor: classIndex % 2 === 1 ? themeVariables.gray_200 : themeVariables.gray_100 }}
                          >
                            {rJSX_PdfVariableMappingCellContents(tabData, variableObject, classObject.key)}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_PDFTab = (tabData: TsInterface_UnspecifiedObject): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null && itemKey != null && tabData != null) {
      tabJSX = (
        <Box>
          <Card className="">
            <CustomPdfEditor
              pdfRoot={us_reportTemplateRoot}
              pdfTemplate={getProp(tabData, 'pdf_template', {})}
              pdfFullTemplate={tabData}
              pdfPossibleVariables={us_activeClasses}
              pdfData={getProp(tabData, 'pdf_data', {})}
              mode={us_pdfViewMode}
              databaseDocumentRef={DatabaseRef_ReportTemplates_Components_Document(uc_RootData_ClientKey, itemKey, tabData.key)}
            />
          </Card>
          <Card className="tw-mt-2 tw-p-2">
            <Typography
              className="tw-font-bold"
              variant="h6"
            >
              {rLIB('Variable Mapping')}
            </Typography>

            {rJSX_VariableMappingTable(tabData.pdf_variables, tabData)}
            <Button
              className="tw-mt-2"
              color="success"
              variant="contained"
              onClick={() => {
                addPdfVariable(tabData)
              }}
              startIcon={<Icon icon="circle-plus" />}
            >
              {rLIB('Add Variable')}
            </Button>
          </Card>
        </Box>
      )
    }
    return tabJSX
  }

  // Page
  const rJSX_Tabs = (): JSX.Element => {
    let tabsArray: TsInterface_TabContentUrlArray = [
      {
        tabUrlKey: 'Settings',
        tabHeader: rLIB('Report Settings'),
        tabOnChange: () => {},
        tabContent: rJSX_ReportTemplateSettings(),
        tabButtons: [{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 }],
      },
    ]
    if (us_reportTemplateRoot != null && us_reportTemplateRoot.file_type === 'excel' && us_reportTemplateComponents != null) {
      let sortedTabsArray = objectToArray(us_reportTemplateComponents).sort(dynamicSort('order', null))
      for (let loopTabIndex in sortedTabsArray) {
        let loopTab = sortedTabsArray[loopTabIndex]
        let loopTabJSX = {
          tabUrlKey: 'tab_' + loopTabIndex,
          tabHeader: loopTab.name,
          tabOnChange: () => {},
          tabContent: rJSX_ExcelTab(loopTab),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_NewTabButton(), minJSX: rJSX_NewTabButton(), sizeCutoff: 0 },
          ],
        }
        tabsArray.push(loopTabJSX)
      }
    } else if (us_reportTemplateRoot != null && us_reportTemplateRoot.file_type === 'pdf' && us_reportTemplateComponents != null) {
      let sortedTabsArray = objectToArray(us_reportTemplateComponents).sort(dynamicSort('order', null))
      // TODO: If support for multiple pages is added, this will need to be updated
      for (let loopTabIndex in sortedTabsArray) {
        let loopTab = sortedTabsArray[loopTabIndex]
        let loopTabJSX = {
          tabUrlKey: 'page_' + loopTabIndex,
          // tabHeader: loopTab.name,
          tabHeader: rLIB('Page Editor'),
          tabOnChange: () => {},
          tabContent: rJSX_PDFTab(loopTab),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_EditViewToggleButton(), minJSX: rJSX_EditViewToggleButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_CopyButton(), minJSX: rJSX_CopyButton(), sizeCutoff: 0 },
          ],
        }
        tabsArray.push(loopTabJSX)
      }
    }
    let tabsJSX = (
      <TabsUrl
        tabs={tabsArray}
        tabsSettings={{
          baseUrl: ApplicationPages.ReportTemplatesViewPage.url(itemKey),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: rLIB('Report Template', false) as string,
        }}
      />
    )
    return tabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={<Box>{rJSX_Tabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip } from '@mui/material/'
import { useContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveDataBuckets_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { DatabaseRef_AutomaticReconciliations_Collection } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/automatic_reconciliations'
import {
  DatabaseRef_Reconciliations_Collection,
  DatabaseRef_Reconciliation_Document,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/reconciliations'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ReconciliationListPage']['key']

// Table
const tableSettings_ActiveImpromptuReconciliations: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableSettings_ActiveAutomaticReconciliations: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_updated',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_ActiveImpromptuReconciliations: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.ReconciliationImpromptuViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Reconciliation'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this reconciliation?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_Reconciliation_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Reconciliation Name'), 'name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  filter_start_date: TableCellBasic('filter_start_date', rLIB('Start Date'), 'filter_start_date'),
  filter_end_date: TableCellBasic('filter_end_date', rLIB('End Date'), 'filter_end_date'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Date Created'), 'timestamp_created', ' D MMM YYYY - h:mm a', true),
}

const tableColumns_ActiveAutomaticReconciliations: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.ReconciliationAutoViewPage.url(rowData.key as string))
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Reconciliation Name'), 'name'),
  // schedule_status: TableCellBasic('schedule_status', rLIB('Status'), 'schedule_status'),
  schedule_status: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Status')
      },
      header_sort_by: 'schedule_status',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.schedule_status === 'in_queue') {
          cellJSX = (
            <Chip
              color="success"
              variant="filled"
              label={
                <Box>
                  <Icon
                    icon="play"
                    className="tw-mr-2"
                  />
                  {rLIB('In Queue')}
                </Box>
              }
            />
          )
        } else {
          cellJSX = (
            <Chip
              color="warning"
              variant="filled"
              label={
                <Box>
                  <Icon
                    icon="pause"
                    className="tw-mr-2"
                  />
                  {rLIB('Not in Queue')}
                </Box>
              }
            />
          )
        }
        return cellJSX
      },
    },
  },
  // associated_data_bucket_name: TableCellBasic( "associated_data_bucket_name", rLIB("Data Bucket"), "associated_data_bucket_name" ),
  // timestamp_updated: TableCellTimestamp( "timestamp_updated", s_DATE_LAST_RAN, "timestamp_updated", " D MMM YYYY - h:mm a", true ),
}

export const autoReconciliationLibraryOptions: TsInterface_UnspecifiedObject = {
  crude_oil_metered: {
    key: 'crude_oil_metered',
    value: 'Rolling 7 Day Crude Oil Comparison (with meters)',
    fields_to_map: {
      // unique_identifier: { type: "unqiue_identifier", key: "unique_identifier", value: rLIB("Unique Identifier") },
      unique_identifier: { type: 'class_field', key: 'unique_identifier', value: rLIB('Unique Identifier') },
      volume: { type: 'class_field', key: 'volume', value: rLIB('Volume') },
      start_meter: { type: 'class_field', key: 'start_meter', value: rLIB('Start Meter') },
      stop_meter: { type: 'class_field', key: 'stop_meter', value: rLIB('Stop Meter') },
      // associated_reconciliation_timestamp: { key: "associated_reconciliation_timestamp", value: s_TIMESTAMP },
    },
    group_options: {
      station_log: { key: 'station_log', value: rLIB('Station Log') },
      carrier_ticket: { key: 'carrier_ticket', value: rLIB('Carrier Ticket') },
    },
  },
  crude_oil_volume: {
    key: 'crude_oil_volume',
    value: 'Rolling 7 Day Crude Oil Comparison (just volume)',
    fields_to_map: {
      // unique_identifier: { type: "unqiue_identifier", key: "unique_identifier", value: rLIB("Unique Identifier") },
      unique_identifier: { type: 'class_field', key: 'unique_identifier', value: rLIB('Unique Identifier') },
      volume: { type: 'class_field', key: 'volume', value: rLIB('Volume') },
    },
    group_options: {
      station_log: { key: 'station_log', value: rLIB('Station Log') },
      carrier_ticket: { key: 'carrier_ticket', value: rLIB('Carrier Ticket') },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Reconciliations', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    ur_forceRerender()
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_ActiveImpromptuReconciliations = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      // { prop: "type", comparator: "==", value: "impromptu" }
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_Reconciliations_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_ActiveAutomaticReconciliations = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      // { prop: "status", comparator: "==", value: "active" },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_AutomaticReconciliations_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createImpromptuAnalysis = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        let dataBucketOptions: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey)).then((res_DGC) => {
            for (let loopQRCodeKey in res_DGC.data) {
              let loopQRCode = res_DGC.data[loopQRCodeKey]
              dataBucketOptions[loopQRCodeKey] = {
                key: loopQRCodeKey,
                value: loopQRCode.name,
              }
            }
          }),
        )
        Promise.all(promiseArray).finally(() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Reconciliation Name')}</>,
                    required: true,
                  },
                  associated_data_bucket_key: {
                    data_type: 'string',
                    input_type: 'multiple_choice_select',
                    key: 'associated_data_bucket_key',
                    label: rLIB('Data Bucket Data Se'),
                    options: objectToArray(dataBucketOptions),
                    required: true,
                  },
                  filter_start_date: {
                    data_type: 'number',
                    input_type: 'timestamp_datetime',
                    key: 'filter_start_date',
                    label: <>{rLIB('Data Filter Start Date')}</>,
                    required: true,
                  },
                  filter_end_date: {
                    data_type: 'number',
                    input_type: 'timestamp_datetime',
                    key: 'filter_end_date',
                    label: <>{rLIB('Data Filter End Date')}</>,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          associated_data_bucket_key: formSubmittedData.associated_data_bucket_key,
                          associated_data_bucket_name: getProp(dataBucketOptions[formSubmittedData.associated_data_bucket_key], 'value', null),
                          filter_end_date: formSubmittedData.filter_end_date,
                          filter_start_date: formSubmittedData.filter_start_date,
                          name: formSubmittedData.name,
                          type: 'impromptu',
                          status: 'active',
                          timestamp_created: new Date(),
                          timestamp_updated: new Date(),
                        }
                        DatabaseAddDocument(DatabaseRef_Reconciliations_Collection(res_GCK.clientKey), updateObject, true)
                          .then((res_DAD) => {
                            un_routerNavigation(ApplicationPages.ReconciliationImpromptuViewPage.url(res_DAD.key))
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                            reject(rej_DAD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Reconciliation')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const createAutomaticAnalysis = (): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        let dataBucketOptions: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey)).then((res_DGC) => {
            for (let loopQRCodeKey in res_DGC.data) {
              let loopQRCode = res_DGC.data[loopQRCodeKey]
              dataBucketOptions[loopQRCodeKey] = {
                key: loopQRCodeKey,
                value: loopQRCode.name,
              }
            }
          }),
        )
        Promise.all(promiseArray).finally(() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Reconciliation Name')}</>,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          name: formSubmittedData.name,
                          status: 'active',
                          timestamp_created: new Date(),
                        }
                        DatabaseAddDocument(DatabaseRef_AutomaticReconciliations_Collection(res_GCK.clientKey), updateObject, true)
                          .then((res_DAD) => {
                            un_routerNavigation(ApplicationPages.ReconciliationAutoViewPage.url(res_DAD.key))
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                            reject(rej_DAD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Reconciliation')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const rJSX_NewImproptuAnalysisButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createImpromptuAnalysis()
        }}
        startIcon={<Icon icon="circle-plus" />}
        className="tw-mb-2"
      >
        {rLIB('New Impromptu Reconciliation')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewAutomaticAnalysisButton = () => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          createAutomaticAnalysis()
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Reconciliation')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AnalysesTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_ActiveImpromptuReconciliations}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ActiveImpromptuReconciliations}
            tableSettings={tableSettings_ActiveImpromptuReconciliations}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_AutomaticReconciliationsTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_ActiveAutomaticReconciliations}
            tableDatabaseEndpoint={tableDatabaseEndpoint_ActiveAutomaticReconciliations}
            tableSettings={tableSettings_ActiveAutomaticReconciliations}
          />
        </Card>
      )
    }
    return tableJSX
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Reconciliations') as JSX.Element}
        pageKey={pageKey}
        content={
          <Box>
            <Box className="tw-mb-2">{rJSX_NewAutomaticAnalysisButton()}</Box>
            {rJSX_AutomaticReconciliationsTable()}
            {/* <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Reconciliations',
                  tabHeader: rLIB('Reconciliations'),
                  tabOnChange: () => {},
                  tabContent: rJSX_AutomaticReconciliationsTable(),
                  tabButtons: [{ fullJSX: rJSX_NewAutomaticAnalysisButton(), minJSX: rJSX_NewAutomaticAnalysisButton(), sizeCutoff: 0 }],
                },
                // {
                // tabUrlKey: 'Impromptu_Reconciliations',
                // tabHeader: rLIB('Impromptu Reconciliations'),
                // tabOnChange: () => {},
                // tabContent: rJSX_AnalysesTable(),
                // tabButtons: [{ fullJSX: rJSX_NewImproptuAnalysisButton(), minJSX: rJSX_NewImproptuAnalysisButton(), sizeCutoff: 0 }],
                // },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.ReconciliationListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Reconciliations', false) as string,
              }}
            /> */}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

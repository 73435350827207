///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TaskTemplates_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'task_templates', 'main')
}

export const DatabaseRef_TaskTemplates_Document = (clientKey: string, formKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'task_templates', 'main', formKey)
}

export const DatabaseRef_ActiveTaskTemplates_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'task_templates', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Page used to manage access to application pages on a client by client basis

		TODO:
			[ ] Typescript - 1 isntance of any
			[ ] Typescript - 5 isntances of TsInterface_UnspecifiedObject
			[ ] Bug - Warning: A component is changing an uncontrolled input to be controlled (on checklist)

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, generateAvailableClientLevelPermissions } from 'rfbp_aux/data/application_structure'
import { cloudFunctionManageRequest } from 'rfbp_aux/services/cloud_functions'
import {
  DatabaseRef_ClientPermissions_Document,
  DatabaseRef_RootClient_Collection,
  DatabaseRef_RootClient_Document,
} from 'rfbp_aux/services/database_endpoints/database_endpoints'
import { DatabaseRef_ClientUsers_Query } from 'rfbp_aux/services/database_endpoints/users'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableCellBasic, TsInterface_TableColumns, TsInterface_TableDatabaseSettings } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_GenericPromiseReject, TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey = ApplicationPages['SuperClientPermissionsManagementPage']['key']

// Tables
const tableSettings_ClientUsers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_ClientUsers: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Name'), 'name'),
  key: TableCellBasic('key', rLIB('User Key'), 'key'),
  email: TableCellBasic('email', rLIB('Email'), 'email'),
  phone: TableCellBasic('phone', rLIB('Phone'), 'phone'),
  user_role: TableCellBasic('user_role', rLIB('User Role'), 'user_role'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientLevelPermissions, us_setClientLevelPermissions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUsers, us_setClientUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formData_ClientType, us_setFormData_ClientType] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClientKey, us_setSelectedClientKey] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Get Client Level Permissions
    us_setClientLevelPermissions(generateAvailableClientLevelPermissions())
    return () => {}
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setFormData_ClientType(newData)
      ur_forceRerender()
    }
    if (us_selectedClientKey != null && us_selectedClientKey !== '') {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, us_selectedClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientUsers(newData)
      ur_forceRerender()
    }
    if (us_selectedClientKey != null && us_selectedClientKey !== '') {
      unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClientUsers_Query(us_selectedClientKey), updateLiveData)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, us_selectedClientKey])

  const selectClientKey = (event: SelectChangeEvent<any>): void => {
    us_setSelectedClientKey(event.target.value)
    ur_forceRerender()
  }

  const togglePermission = (event: React.ChangeEvent<HTMLInputElement>, permissionKey: string, permissionValue: string | boolean): void => {
    let permissionUpdateObject: TsInterface_UnspecifiedObject = {}
    permissionUpdateObject[permissionKey] = permissionValue
    DatabaseSetMergeDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey), permissionUpdateObject)
      .then((res_DSMD) => {
        DatabaseGetDocument(DatabaseRef_ClientPermissions_Document(us_selectedClientKey)).then((res_DGD) => {
          us_setFormData_ClientType(res_DGD.data)
        })
      })
      .catch((rej_DSMD) => {
        console.error(rej_DSMD)
      })
  }

  const createNewClient = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            client_key: {
              key: 'client_key',
              label: rLIB('Client Key'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            name: {
              key: 'name',
              label: rLIB('Client Name'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              DatabaseGetDocument(DatabaseRef_RootClient_Document(formSubmittedData.client_key))
                .then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null && res_DGD.data.key != null) {
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: rLIB('Failed to create client'),
                        details: rLIB('Client key already used'),
                        code: 'ER-D-CPM-CNC-01',
                      },
                    })
                  } else {
                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_RootClient_Document(formSubmittedData.client_key),
                        data: {
                          key: formSubmittedData.client_key,
                          name: formSubmittedData.name,
                        },
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_ClientPermissions_Document(formSubmittedData.client_key),
                        data: {
                          client_type: 'Main',
                        },
                      },
                    ]
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        setTimeout(() => {
                          us_setSelectedClientKey(formSubmittedData.client_key)
                        }, 100)
                        resolve(res_DBU)
                      })
                      .catch((rej_DBU) => {
                        reject(rej_DBU)
                      })
                  }
                })
                .catch((rej_DGD) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_RootClient_Document(formSubmittedData.client_key),
                      data: {
                        key: formSubmittedData.client_key,
                        name: formSubmittedData.name,
                      },
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_ClientPermissions_Document(formSubmittedData.client_key),
                      data: {
                        client_type: 'Main',
                      },
                    },
                  ]
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      setTimeout(() => {
                        us_setSelectedClientKey(formSubmittedData.client_key)
                      }, 100)
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Create New Client'),
          formDialogIcon: <Icon icon="pen-to-square" />,
        },
      },
    })
  }

  const createNewUser = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              key: 'name',
              label: rLIB('User Name'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            email: {
              key: 'email',
              label: rLIB('Email'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            phone: {
              key: 'phone',
              label: rLIB('Phone'),
              input_type: 'text_basic',
              required: true,
              data_type: 'string',
            },
            password: {
              key: 'password',
              label: rLIB('Password'),
              input_type: 'text_password',
              required: true,
              data_type: 'string',
            },
            confirm_password: {
              key: 'confirm_password',
              label: rLIB('Confirm Password'),
              input_type: 'text_password',
              required: true,
              data_type: 'string',
            },
            user_role: {
              key: 'user_role',
              label: rLIB('User Role'),
              input_type: 'multiple_choice_radio',
              required: true,
              data_type: 'string',
              options: [
                { key: 'admin', value: 'Admin' },
                // { value: "user", label: "User" },
              ],
            },
            // access_level: {
            // key: 'access_level',
            // label: rLIB("User Role"),
            // input_type: 'multiple_choice_radio',
            // required: true,
            // data_type: 'string',
            // options: [
            //   { key: 'owner', value: 'Owner' },
            //   { key: 'admin', value: 'Admin' },
            //   // { value: "user", label: "User" },
            // ],
            // },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              if (formSubmittedData.password === formSubmittedData.confirm_password) {
                cloudFunctionManageRequest('manageUser', {
                  function: 'createUserAccount',
                  // accessLevel: formSubmittedData.access_level,
                  clientKey: us_selectedClientKey,
                  email: formSubmittedData.email,
                  name: formSubmittedData.name,
                  password: formSubmittedData.password,
                  phone: formSubmittedData.phone,
                  userRole: formSubmittedData.user_role,
                })
                  .then((res_CFMUR: unknown) => {
                    resolve({ success: true, error: {} })
                  })
                  .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMUR.error })
                    reject(rej_CFMUR)
                  })
              } else {
                let error = {
                  message: rLIB('Failed to create user'),
                  details: rLIB("Passwords don't match"),
                  code: 'ER-D-CPM-CNU-01',
                }
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                reject({ success: false, error: error })
              }
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Create New Client'),
          formDialogIcon: <Icon icon="pen-to-square" />,
        },
      },
    })
  }

  // JSX Generation
  const rJSX_ClientToggleDropdown = (): JSX.Element => {
    let clientToggleDropdownJSX = (
      <FormControl
        className="bp_thin_select_input tw-mr-2 tw-mb-2"
        sx={{ minWidth: '200px', background: themeVariables.background_paper }}
      >
        {/* <InputLabel id="select_client_dropdown">{ s_SELECT_CLIENT }</InputLabel> */}
        <Select
          // labelId="select_client_dropdown"
          value={us_selectedClientKey}
          // label={ s_SELECT_CLIENT }
          onChange={selectClientKey}
        >
          {/* <MenuItem value={ "" } className="tw-opacity-30 tw-italic">{ s_SELECT_CLIENT }</MenuItem> */}
          {objectToArray(us_clientsList).map((client, index) => (
            <MenuItem
              value={client.key}
              key={index}
            >
              {client.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return clientToggleDropdownJSX
  }

  const rJSX_NewClientButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mb-2"
        color="success"
        onClick={() => {
          createNewClient()
        }}
        variant="contained"
      >
        <Icon
          icon="circle-plus"
          className="tw-pr-1"
        />
        {rLIB('Create New Client')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewUserButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mb-2"
        color="success"
        onClick={() => {
          createNewUser()
        }}
        variant="contained"
        disabled={us_selectedClientKey == null || us_selectedClientKey === ''}
      >
        <Icon
          icon="circle-plus"
          className="tw-pr-1"
        />
        {rLIB('Create New User')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_PermissionChecklist = (): JSX.Element => {
    let clientPermissionsJSX: JSX.Element = <Box></Box>
    if (us_selectedClientKey != null && us_selectedClientKey !== '') {
      if (
        us_formData_ClientType != null &&
        us_formData_ClientType.client_type != null &&
        us_clientLevelPermissions != null &&
        us_clientLevelPermissions[us_formData_ClientType.client_type] != null
      ) {
        clientPermissionsJSX = (
          <Box className="tw-text-left tw-p-4">
            {objectToArray(us_clientLevelPermissions[us_formData_ClientType.client_type]).map((section, index) => (
              <Box key={index}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                >
                  {section.sectionName}
                </Typography>
                <FormGroup>
                  {objectToArray(section.permissions).map((permission, index2) => (
                    <Box key={index2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={us_formData_ClientType[permission.key] || false}
                            onChange={(event, value) => {
                              togglePermission(event, permission.key, value)
                            }}
                          />
                        }
                        key={permission.key}
                        label={permission.pageName}
                      />
                    </Box>
                  ))}
                </FormGroup>
              </Box>
            ))}
          </Box>
        )
      } else {
        clientPermissionsJSX = (
          <Box className="tw-text-center tw-p-4">
            <Typography
              variant="h5"
              noWrap
              component="div"
            >
              {rLIB('The selected client is missing a valid client type')}
            </Typography>
          </Box>
        )
      }
    } else {
      clientPermissionsJSX = (
        <Box className="tw-text-center tw-p-4">
          <Typography
            variant="h5"
            noWrap
            component="div"
          >
            {rLIB('Select a client to edit permissions')}
          </Typography>
        </Box>
      )
    }
    return clientPermissionsJSX
  }

  // const rJSX_ClientTypeForm = (): JSX.Element => {
  // let clientTypeJSX: JSX.Element = <Box></Box>
  // // Client Type Selection
  // if ( us_selectedClientKey != null && us_selectedClientKey !== "" ){
  // 	clientTypeJSX =
  // 	<Card className="tw-text-left tw-p-4" variant="outlined">
  // 		<Form
  // 			formAdditionalData={ formAdditionalData_ClientType }
  // 			formData={ us_formData_ClientType }
  // 			formInputs={ formInputs_ClientType }
  // 			formOnChange={ formOnChange_ClientType }
  // 			formSettings={ formSettings_ClientType }
  // 			formSubmission={ formSubmission_ClientType }
  // 		/>
  // 	</Card>
  // }
  // return clientTypeJSX
  // }

  const rJSX_PermissionsTab = (): JSX.Element => {
    let permissionsTabJSX = (
      <Box>
        <Card variant="outlined">{rJSX_PermissionChecklist()}</Card>
      </Box>
    )
    return permissionsTabJSX
  }

  const rJSX_UsersTab = (): JSX.Element => {
    let tabJSX = (
      <Card>
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_ClientUsers}
          tableData={objectToArray(us_clientUsers)}
          tableSettings={tableSettings_ClientUsers}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    // Page JSX
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Client Permissions')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Permissions',
                  tabHeader: rLIB('Permissions'),
                  tabOnChange: () => {},
                  tabContent: rJSX_PermissionsTab(),
                  tabButtons: [
                    { fullJSX: rJSX_ClientToggleDropdown(), minJSX: rJSX_ClientToggleDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewClientButton(), minJSX: rJSX_NewClientButton(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Users',
                  tabHeader: rLIB('Users'),
                  tabOnChange: () => {},
                  tabContent: rJSX_UsersTab(),
                  tabButtons: [
                    { fullJSX: rJSX_ClientToggleDropdown(), minJSX: rJSX_ClientToggleDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewUserButton(), minJSX: rJSX_NewUserButton(), sizeCutoff: 0 },
                  ],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SuperClientPermissionsManagementPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Client Permissions', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

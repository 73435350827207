//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { TextField } from '@mui/material/'
import { LocalizationProvider, MobileDatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromiseFunctionSingleDateParam } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  datePickerDate: Date
  datePickerDateOnChange: TsType_UnknownPromiseFunctionSingleDateParam
  datePickerDisabled?: boolean
  datePickerText?: string | JSX.Element
  datePickerSettings?: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

// CSS
let inputCSS: string = `
	.bps_date_picker_input .MuiInputAdornment-positionEnd {
		display: none;
	}
	.bps_date_picker_input {
		padding-right: 0px;
	}
`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const DatePicker = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_inputDate: TsInterface_ComponentProps['datePickerDate'] = getProp(props, 'datePickerDate', new Date().getTime())
  let pr_inputDateText: TsInterface_ComponentProps['datePickerText'] = getProp(props, 'datePickerText', rLIB('Select a Date'))
  let pr_inputDisabled: TsInterface_ComponentProps['datePickerDisabled'] = getProp(props, 'datePickerDisabled', false)
  let pr_inputSettings: TsInterface_ComponentProps['datePickerSettings'] = getProp(props, 'datePickerSettings', {})
  let pr_onInputDateChange: TsInterface_ComponentProps['datePickerDateOnChange'] = getProp(props, 'datePickerDateOnChange', (newDate: Date) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_calendarDate, us_setCalendarDate] = useState<Date>(pr_inputDate)
  const [us_disableInput, us_setDisableInput] = useState<boolean>(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  let inputCssClass = 'bps_date_picker_input'
  if (pr_inputSettings != null && pr_inputSettings.thin_input === true) {
    inputCssClass = 'bps_date_picker_input bp_thin_text_input'
  }

  // Functions
  const updateDatePickerDate = (newDateValue: Date): void => {
    us_setCalendarDate(newDateValue)
    us_setDisableInput(true)
    pr_onInputDateChange(newDateValue).finally(() => {
      us_setDisableInput(false)
      ur_forceRerender()
    })
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      // <LocalizationProvider dateAdapter={ AdapterDateFns as any }>
      <React.Fragment>
        <LocalizationProvider dateAdapter={AdapterDayjs as any}>
          <MuiDatePicker
            className={inputCssClass}
            disabled={us_disableInput || pr_inputDisabled}
            label={pr_inputDateText}
            value={us_calendarDate}
            onChange={(newDateValue) => {
              if (newDateValue != null) {
                updateDatePickerDate(newDateValue)
              }
            }}
            closeOnSelect={true}
            // autoOk={true}
            renderInput={(params) => (
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={{ background: themeVariables.background_paper, width: '110px', minWidth: '110px', paddingRight: '0px' }}
                {...params}
              />
            )}
            InputProps={{
              endAdornment: null,
            }}
          />
        </LocalizationProvider>
        <style>{inputCSS}</style>
      </React.Fragment>
    )

    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

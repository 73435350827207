//======================================//
// MUI
import { createTheme } from '@mui/material/styles'

///////////////////////////////
// Variables
///////////////////////////////

// Google Maps
const googleMapsLightTheme: any[] = []
// const googleMapsDarkTheme = [
// { "featureType": "all",								"elementType": "geometry",				"stylers": [ { "color": "#242f3e" } ] },
// { "featureType": "all",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#746855" } ] },
// { "featureType": "all"								"elementType": "labels.text.stroke",	"stylers": [ { "color": "#242f3e" } ] },
// { "featureType": "administrative.locality",			"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// { "featureType": "poi",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// { "featureType": "poi.park",							"elementType": "geometry",				"stylers": [ { "color": "#263c3f" } ] },
// { "featureType": "poi.park",							"elementType": "labels.text.fill",		"stylers": [ { "color": "#6b9a76" } ] },
// { "featureType": "road",								"elementType": "geometry",				"stylers": [ { "color": "#38414e" } ] },
// { "featureType": "road",								"elementType": "geometry.stroke",		"stylers": [ { "color": "#212a37" } ] },
// { "featureType": "road",								"elementType": "labels.text.fill",		"stylers": [ { "color": "#9ca5b3" } ] },
// { "featureType": "road.highway",						"elementType": "geometry",				"stylers": [ { "color": "#746855" } ] },
// { "featureType": "road.highway",						"elementType": "geometry.stroke",		"stylers": [ { "color": "#1f2835" } ] },
// { "featureType": "road.highway",						"elementType": "labels.text.fill",		"stylers": [ { "color": "#f3d19c" } ] },
// { "featureType": "transit",							"elementType": "geometry",				"stylers": [ { "color": "#2f3948" } ] },
// { "featureType": "transit.station",					"elementType": "labels.text.fill",		"stylers": [ { "color": "#d59563" } ] },
// { "featureType": "water",							"elementType": "geometry",				"stylers": [ { "color": "#17263c" } ] },
// { "featureType": "water",							"elementType": "labels.text.fill",		"stylers": [ { "color": "#515c6d" } ] },
// { "featureType": "water",							"elementType": "labels.text.stroke",	"stylers": [ { "color": "#17263c" } ]}
// ]

//=========================//
//   USED BY BOILERPLATE   //
//=========================//

// Default Theme

const palettes = {
  primary: {
    '50': '#e7f2fe',
    '100': '#b6d8fb',
    '200': '#86bdf9',
    '300': '#55a3f6',
    '400': '#2589f4',
    '500': '#0b6fda',
    '600': '#0957aa',
    '700': '#063e79',
    '800': '#042549',
    '900': '#010c18',
  },
  blue: {
    '50': '#e9f3fb',
    '100': '#d3e8f7',
    '200': '#a8d1ef',
    '300': '#7cbae8',
    '400': '#51a3e0',
    '500': '#258cd9',
    '600': '#1e70ad',
    '700': '#165482',
    '800': '#0f3856',
    '900': '#071c2b',
  },
  green: {
    '50': '#e9fbfb',
    '100': '#c7f1f2',
    '200': '#a6e6e9',
    '300': '#84dbdf',
    '400': '#60d1d3',
    '500': '#3cc6c8',
    '600': '#30a0a1',
    '700': '#24787a',
    '800': '#1a5052',
    '900': '#0f282a',
  },
  yellow: {
    '50': '#fef6e7',
    '100': '#fbe3b6',
    '200': '#f9d086',
    '300': '#f6be55',
    '400': '#f4ab25',
    '500': '#da920b',
    '600': '#aa7109',
    '700': '#795106',
    '800': '#493104',
    '900': '#181001',
  },
  red: {
    '50': '#fee5ec',
    '100': '#feccd9',
    '200': '#fd9ab3',
    '300': '#fd678d',
    '400': '#fc3568',
    '500': '#fc0242',
    '600': '#c90235',
    '700': '#970127',
    '800': '#64011a',
    '900': '#32000d',
  },
  purple: {
    '50': '#f2e7fe',
    '100': '#d8b6fb',
    '200': '#bd86f9',
    '300': '#a355f6',
    '400': '#8925f4',
    '500': '#6f0bda',
    '600': '#5709aa',
    '700': '#3e0679',
    '800': '#250449',
    '900': '#0c0118',
  },
}

const themeVariables = {
  // Background
  mode: 'light',
  background_paper: '#fff',
  // background_default: 		"#F4F6F7",
  background_default: '#f1f1f1',
  background_highlight: '#eeeeee',
  contrast_text: '#000000',
  map_styles: googleMapsLightTheme,
  // Nav
  navGradientTopColor: 'rgb(212, 27, 41)',
  navGradientBottomColor: 'rgb(198, 1, 28)',
  // mode: "dark",
  // background_paper: 			"#121212",
  // background_default: 			"#090909",
  // background_highlight: 		"#121212",
  // contrast_text: 				"#ffffff",
  // map_styles: 					googleMapsDarkTheme,
  // Common
  black: '#000',
  white: '#fff',
  // Primary
  primary_dark: palettes['primary']['900'],
  primary_main: palettes['primary']['800'],
  primary_light: palettes['primary']['700'],
  primary_wash: palettes['primary']['100'],
  // Secondary
  secondary_dark: palettes['purple']['600'],
  secondary_main: palettes['purple']['500'],
  secondary_light: palettes['purple']['400'],
  secondary_wash: palettes['purple']['100'],
  // Info - Blue
  info_dark: palettes['blue']['600'],
  info_main: palettes['blue']['500'],
  info_light: palettes['blue']['400'],
  info_wash: palettes['blue']['100'],
  // Success - Green
  success_dark: palettes['green']['700'],
  success_main: palettes['green']['600'],
  success_light: palettes['green']['500'],
  success_wash: palettes['green']['100'],
  // Warning - Yellow
  warning_dark: palettes['yellow']['500'],
  warning_main: palettes['yellow']['400'],
  warning_light: palettes['yellow']['300'],
  warning_wash: palettes['yellow']['100'],
  // Error - Red
  error_dark: palettes['red']['600'],
  error_main: palettes['red']['500'],
  error_light: palettes['red']['400'],
  error_wash: palettes['red']['100'],
  // Gray
  gray_50: '#fafafa',
  gray_100: '#f5f5f5',
  gray_200: '#eeeeee',
  gray_300: '#e0e0e0',
  gray_400: '#bdbdbd',
  gray_500: '#9e9e9e',
  gray_600: '#757575',
  gray_700: '#616161',
  gray_800: '#424242',
  gray_900: '#212121',
  // Custom
  background_json: '#121212',
}

const themeOptions: any = {
  palette: {
    mode: themeVariables.mode,
    primary: {
      main: themeVariables.primary_main,
      light: themeVariables.primary_light,
      dark: themeVariables.primary_dark,
      contrastText: themeVariables.white,
    },
    secondary: {
      main: themeVariables.secondary_main,
      light: themeVariables.secondary_light,
      dark: themeVariables.secondary_dark,
      contrastText: themeVariables.white,
    },
    error: {
      main: themeVariables.error_main,
      light: themeVariables.error_light,
      dark: themeVariables.error_dark,
      contrastText: themeVariables.white,
    },
    warning: {
      main: themeVariables.warning_main,
      light: themeVariables.warning_light,
      dark: themeVariables.warning_dark,
      contrastText: themeVariables.white,
    },
    info: {
      main: themeVariables.info_main,
      light: themeVariables.info_light,
      dark: themeVariables.info_dark,
      contrastText: themeVariables.white,
    },
    success: {
      main: themeVariables.success_main,
      light: themeVariables.success_light,
      dark: themeVariables.success_dark,
      contrastText: themeVariables.white,
    },
    grey: {
      gray_50: themeVariables.gray_50,
      gray_100: themeVariables.gray_100,
      gray_200: themeVariables.gray_200,
      gray_300: themeVariables.gray_300,
      gray_400: themeVariables.gray_400,
      gray_500: themeVariables.gray_500,
      gray_600: themeVariables.gray_600,
      gray_700: themeVariables.gray_700,
      gray_800: themeVariables.gray_800,
      gray_900: themeVariables.gray_900,
      gray_A100: themeVariables.gray_100,
      gray_A200: themeVariables.gray_200,
      gray_A400: themeVariables.gray_400,
      gray_A700: themeVariables.gray_700,
    },
    background: {
      paper: themeVariables.background_paper,
      default: themeVariables.background_default,
    },
  },
  typography: {
    // fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const DefaultAppTheme = createTheme(themeOptions)
export { themeVariables }

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:
			1 @ts-expect-error - really annoying casting problem on permission overrides

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material/'
import { createUser, updateUser } from 'app/models/users'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages, ClientTypes, generateAvailableUserLevelPermissions, TsType_ClientTypes, TsType_UserRoles } from 'rfbp_aux/data/application_structure'
import { TsInterface_FormInputs, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_ClientPermissions } from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const formOnChange_User: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  // Nothing
}

export const formSubmission_UserCreate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    createUser(
      formInputs_UserNew,
      formSubmittedData,
      formHooks.uc_RootData_ClientPermissions,
      formHooks.uc_RootData_ClientKey,
      formHooks.uc_setRootData_ClientKey,
      formHooks.uc_RootData_ClientUser,
    )
      .then((res_CU) => {
        formHooks.un_routerNavigation(ApplicationPages.AdminDatabaseUserListPage.url())
        resolve(res_CU)
      })
      .catch((rej_CU) => {
        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CU.error })
        reject(rej_CU)
      })
  })
}

export const formSubmission_UserUpdate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    updateUser(
      formAdditionalData.key as string,
      formInputs_UserEdit,
      formSubmittedData,
      formHooks.uc_RootData_ClientPermissions,
      formHooks.uc_RootData_ClientKey,
      formHooks.uc_setRootData_ClientKey,
      formHooks.uc_RootData_ClientUser,
    )
      .then((res_UU) => {
        formHooks.un_routerNavigation(ApplicationPages.AdminDatabaseUserListPage.url())
        resolve(res_UU)
      })
      .catch((rej_UU) => {
        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UU.error })
        reject(rej_UU)
      })
  })
}

export const formSettings_User: TsInterface_FormSettings = {
  highlight_missing: true,
  submit_button_alignment: 'right',
  submit_button_hide: false,
  submit_button_icon: <Icon icon="floppy-disk" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{rLIB('Save')}</>,
}

export const formInputs_UserNew: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('User Name'),
    required: true,
  },
  phone: {
    data_type: 'string',
    input_type: 'phone_number_usa',
    key: 'phone',
    label: rLIB('Phone'),
    required: true,
  },
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: rLIB('Email'),
    required: true,
  },
  password: {
    data_type: 'string',
    input_type: 'text_password',
    key: 'password',
    label: rLIB('Password'),
    required: true,
  },
  user_role: {
    data_type: 'string',
    key: 'user_role',
    input_type: 'custom_form_input_jsx',
    label: rLIB('User Type'),
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Function to update permission override checkboxes
      const updatePermissionOverrides = (event: React.ChangeEvent, value: TsType_UserRoles) => {
        let userPermissionsList = generateAvailableUserLevelPermissions(
          value,
          formAdditionalData['client_type'] as TsType_ClientTypes,
          formAdditionalData['root_client_permissions'] as unknown as TsInterface_RootData_ClientPermissions,
        )
        formData['permission_overrides'] = {}
        for (let sectionKey in userPermissionsList) {
          let section = userPermissionsList[sectionKey]
          if (section != null && section['permissions'] != null) {
            for (let permissionKey in section['permissions']) {
              let permission = section['permissions'][permissionKey]
              if (permission.access === 'always_yes' || permission.access === 'default_yes') {
                formData['permission_overrides'][permissionKey] = true
              } else {
                formData['permission_overrides'][permissionKey] = false
              }
            }
          }
        }
        // formInputChange(event, value, "user_role", "custom_form_input_jsx", formInput, {} )
        formInputChange('user_role', value, true)
        // formInputChange(event, formData["permission_overrides"], "permission_overrides", "custom_form_input_jsx", formInput, {} )
        formInputChange('permission_overrides', formData['permission_overrides'], true)
      }
      // Generate user role options for custom form input
      let clientUserRoleOptions: TsInterface_UnspecifiedObject = {}
      if (
        formAdditionalData != null &&
        formAdditionalData.client_type != null &&
        ClientTypes != null &&
        ClientTypes[formAdditionalData.client_type as TsType_ClientTypes] != null &&
        ClientTypes[formAdditionalData.client_type as TsType_ClientTypes]['user_roles'] != null
      ) {
        for (let userRoleKey in ClientTypes[formAdditionalData.client_type as TsType_ClientTypes]['user_roles']) {
          clientUserRoleOptions[userRoleKey] = ClientTypes[formAdditionalData.client_type as TsType_ClientTypes]['user_roles'][userRoleKey]
        }
      }
      let inputJSX = (
        <div className="tw-mt-4">
          <FormControl component="fieldset">
            <FormLabel component="legend">{rLIB('User Type')}</FormLabel>
            <RadioGroup
              row
              name="radio-buttons-group"
              onChange={(event: React.ChangeEvent, value: string) => {
                updatePermissionOverrides(event, value as TsType_UserRoles)
              }}
              value={formData[formInput['key']] || null}
            >
              {objectToArray(clientUserRoleOptions).map((userRole) => (
                <FormControlLabel
                  key={userRole.key}
                  value={userRole.key}
                  labelPlacement="bottom"
                  control={
                    <Radio
                      color="info"
                      checkedIcon={userRole.icon}
                      icon={userRole.icon}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 48 } }}
                    ></Radio>
                  }
                  label={userRole.name}
                ></FormControlLabel>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      )
      return inputJSX
    },
  },
  permission_overrides: {
    data_type: 'string',
    key: 'permission_overrides',
    input_type: 'custom_form_input_jsx',
    label: rLIB('Permissions'),
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Function to toggle permission overrides
      const togglePermissionCheckbox = (event: React.ChangeEvent, permissionKey: string, value: boolean) => {
        if (formData == null) {
          formData = { permission_overrides: {} }
        }
        if (formData['permission_overrides'] == null) {
          formData['permission_overrides'] = {}
        }
        // @ts-expect-error
        formData['permission_overrides'][permissionKey] = value
        // formInputChange(event, formData["permission_overrides"], "permission_overrides", "custom_form_input_jsx", formInput, {} )
        formInputChange('permission_overrides', formData['permission_overrides'], true)
      }
      // Function to color checkboxes
      const returnCheckboxColor = (defaultOverrideBool: boolean, actualOverrideBool: boolean) => {
        let sx
        if (defaultOverrideBool === actualOverrideBool) {
          sx = { 'color': themeVariables.success_main, '&.Mui-checked': { color: themeVariables.success_main } }
        } else {
          sx = { 'color': themeVariables.error_main, '&.Mui-checked': { color: themeVariables.error_main } }
        }
        return sx
      }
      // Function to return checkbox
      const rJSX_Checkbox = (individualPermission: TsInterface_UnspecifiedObject) => {
        let checkboxJSX
        if (individualPermission.access === 'always_yes') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, true)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  disabled
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'default_yes') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, true)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  sx={returnCheckboxColor(true, getProp(formData['permission_overrides'], individualPermission.permissionKey, true))}
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'default_no') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, false)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  sx={returnCheckboxColor(false, getProp(formData['permission_overrides'], individualPermission.permissionKey, false))}
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'always_no') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, false)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  disabled
                />
              }
              label={individualPermission.pageName}
            />
          )
        }
        return checkboxJSX
      }
      // TODO: checkboxes to override permissions
      let inputJSX
      if (
        formData != null &&
        formData['user_role'] != null &&
        formAdditionalData != null &&
        formAdditionalData['client_type'] != null &&
        formAdditionalData['root_client_permissions'] != null
      ) {
        let userPermissionsList = generateAvailableUserLevelPermissions(
          formData['user_role'] as TsType_UserRoles,
          formAdditionalData['client_type'] as TsType_ClientTypes,
          formAdditionalData['root_client_permissions'] as unknown as TsInterface_RootData_ClientPermissions,
        )
        inputJSX = (
          <div className="tw-mt-6">
            <FormLabel component="legend">{rLIB('User Permissions')}</FormLabel>
            <Box
              component="div"
              className="tw-mt-1"
              sx={{ marginLeft: '24px' }}
            >
              {objectToArray(userPermissionsList).map((permissionSection) => (
                <span key={permissionSection.sectionKey}>
                  <Typography
                    variant="subtitle1"
                    display="block"
                    color="primary"
                  >
                    {permissionSection.sectionName}
                  </Typography>
                  {objectToArray(permissionSection.permissions).map((permission) => (
                    <span key={permission.permissionKey}>{rJSX_Checkbox(permission)}</span>
                  ))}
                </span>
              ))}
            </Box>
          </div>
        )
      } else {
        inputJSX = <div></div>
      }
      return inputJSX
    },
  },
}

export const formInputs_UserEdit: TsInterface_FormInputs = {
  name: formInputs_UserNew['name'],
  phone: formInputs_UserNew['phone'],
  user_role: formInputs_UserNew['user_role'],
  permission_overrides: formInputs_UserNew['permission_overrides'],
}

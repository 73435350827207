//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Provides easy to use

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsType_TableSortBy,
} from 'rfbp_core/components/table'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const TableCellBasic = (propKey: string, propName: string | JSX.Element, sortBy: TsType_TableSortBy): TsInterface_TableColumn => {
  let basicCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return <>{rowData[propKey]}</>
      },
    },
  }
  return basicCell
}

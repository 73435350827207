///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material/'
import { DataItemViewDialog } from 'app/pages/data_transactional/components/dialog_data_item_dialog'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskLogs_Query, DatabaseRef_Tasks_Document } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/tasks'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_UserInterface_CustomDialog,
  Context_UserInterface_CustomDialog2,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnFormattedDate,
  returnTimezoneAbbreviation,
  underscoresToSpacesAndCapitalize,
} from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { returnTaskStatusColors, rJSX_TaskStatusIcon } from '../tasks_index'
import { taskTemplateStepTypes } from '../task_template_view'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_TaskView {
  clientKey: string
  taskKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const TaskViewDialog: React.FC<TsInterface_TaskView> = ({ clientKey, taskKey }): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_task, us_setTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskLogs, us_setTaskLogs] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_CustomDialogDisplay2 } = useContext(Context_UserInterface_CustomDialog2)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTask(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Tasks_Document(clientKey, taskKey), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, clientKey, taskKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskLogs(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskLogs_Query(clientKey, taskKey, 100), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, clientKey, taskKey])

  // Functions
  const returnSortedChecklistItems = (): TsInterface_UnspecifiedObject[] => {
    let sortedChecklistItems: TsInterface_UnspecifiedObject[] = []
    if (us_task != null && us_task['task_steps'] != null) {
      for (let stepKey in us_task['task_steps']) {
        let step = us_task['task_steps'][stepKey]
        if (step != null && step['status'] === 'active' && (step['step_type'] === 'navigation' || step['step_type'] === 'form')) {
          let stepObject: TsInterface_UnspecifiedObject = { ...step }
          // if (us_allTaskMetadata['additional_task_step_data'] != null && us_allTaskMetadata['additional_task_step_data'][stepKey] != null) {
          //   stepObject['additional_task_step_data'] = us_allTaskMetadata['additional_task_step_data'][stepKey]
          // }
          // if (us_userTaskData != null && us_userTaskData['steps'] != null && us_userTaskData['steps'][stepKey] != null) {
          //   stepObject['user_task_data'] = us_userTaskData['steps'][stepKey]
          // }
          sortedChecklistItems.push(stepObject)
        }
      }
    }
    return sortedChecklistItems.sort(dynamicSort('order', 'asc'))
  }

  // JSX Generation
  const rJSX_TaskDetailsLineItem = (label: string | JSX.Element, value: string | JSX.Element): JSX.Element => {
    return (
      <Box>
        <Typography variant="h6">
          <Box
            component={'span'}
            sx={{ fontWeight: 'bold', marginRight: '8px', color: returnTaskStatusColors(getProp(us_task, 'status', '')) }}
          >
            {label}:
          </Box>
          <Box
            component={'span'}
            sx={{}}
          >
            {value}
          </Box>
        </Typography>
      </Box>
    )
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = (
      <Card sx={{ padding: '16px' }}>
        {rJSX_TaskDetailsLineItem(rLIB('Task Number'), getProp(us_task, 'id_number', rJSX_MissingText()))}
        {rJSX_TaskDetailsLineItem(rLIB('Assigned To'), getProp(us_task, 'associated_assigned_user_name', rJSX_MissingText()))}
        {rJSX_TaskDetailsLineItem(
          rLIB('Task Status'),
          us_task.status != null ? <>{underscoresToSpacesAndCapitalize(getProp(us_task, 'status', ''))}</> : rJSX_MissingText(),
        )}
        {rJSX_TaskDetailsLineItem(rLIB('Form Type'), getProp(us_task, 'associated_class_name', rJSX_MissingText()))}
        {rJSX_TaskDetailsLineItem(rLIB('Data Bucket'), getProp(us_task, 'associated_data_bucket_name', rJSX_MissingText()))}
        <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} />
        {rJSX_TaskDetailsLineItem(
          rLIB('Task Created'),
          us_task.timestamp_created != null ? <>{returnFormattedDate(getProp(us_task, 'timestamp_created', ''), 'D MMM YYYY h:mm a')}</> : rJSX_MissingText(),
        )}
        {rJSX_TaskDetailsLineItem(
          rLIB('Task Completed'),
          us_task.timestamp_completed != null ? (
            <>{returnFormattedDate(getProp(us_task, 'timestamp_completed', ''), 'D MMM YYYY h:mm a')}</>
          ) : (
            rJSX_MissingText()
          ),
        )}
        {/* <Divider sx={{ marginTop: '8px', marginBottom: '8px' }} /> */}
      </Card>
    )
    return tabJSX
  }

  const rJSX_ChecklistItem = (step: TsInterface_UnspecifiedObject, index: number, disableAll: boolean): JSX.Element => {
    let borderColor: string = themeVariables.white
    let contentJSX = (
      <Card sx={{ marginBottom: '16px', padding: '4px' }}>
        <Box
          sx={{
            border: '2px solid ' + borderColor + ' !important',
            borderRadius: '10px',
          }}
        >
          <Box className="tw-px-2 tw-pt-2">
            <Stack
              direction="row"
              spacing={2}
              // sx={{ justifyContent: 'space-between' }}
            >
              <Stack
                direction="row"
                spacing={1}
              >
                <Icon
                  className="tw-mr-2"
                  icon={getProp(taskTemplateStepTypes, getProp(step, 'step_type', 'form'), {}).icon}
                  sx={{ color: getProp(taskTemplateStepTypes, getProp(step, 'step_type', 'form'), {}).icon_color, fontSize: '24px' }}
                />
                {/* <Box>
                  <Icon
                    icon="square"
                    type="light"
                    sx={{ color: themeVariables.gray_400, fontSize: '28px' }}
                  />
                </Box> */}
                <Typography
                  sx={{ fontWeight: 500 }}
                  variant="h6"
                >
                  {rLIB('Step')} {index}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Divider className="tw-mt-2" />
          <Box className="tw-p-2">
            <Typography>{step.name}</Typography>
          </Box>
        </Box>
      </Card>
    )
    return contentJSX
  }

  const rJSX_TaskSteps = (): JSX.Element => {
    let tabJSX = (
      <Card sx={{ padding: '16px' }}>
        <Box>
          {returnSortedChecklistItems().map((step, index) => (
            <Box key={index}>{rJSX_ChecklistItem(step, index + 1, false)}</Box>
          ))}
        </Box>
      </Card>
    )

    return tabJSX
  }

  /*


timestamp_completed
timestamp_created

task_procedures
  */

  const rJSX_LogsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {objectToArray(us_taskLogs)
                .sort(dynamicSort('timestamp', 'desc'))
                .map((log: TsInterface_UnspecifiedObject, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ display: 'inline-block' }}
                      >
                        <Box
                          component={'span'}
                          sx={{ fontWeight: 'bold', marginRight: '8px' }}
                        >
                          {returnFormattedDate(log.timestamp, 'YYYY-MM-DD HH:mm')} {returnTimezoneAbbreviation(log.timestamp)}
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        sx={{ display: 'inline-block' }}
                      >
                        <Box
                          component={'span'}
                          sx={{ marginRight: '8px', opacity: '0.4' }}
                        >
                          {log.text}
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        variant="outlined"
                        color="info"
                        label={
                          <Box>
                            <Icon
                              icon="user"
                              sx={{ marginRight: '8px' }}
                            />
                            {log.associated_user_name}
                          </Box>
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ opacity: '0.2', textAlign: 'right', fontStyle: 'italic' }}>{us_task.key}</Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_MissingText = (): JSX.Element => {
    return (
      <Box
        component="span"
        sx={{ fontStyle: 'italic', opacity: '0.4' }}
      >
        {rLIB('Missing')}
      </Box>
    )
  }

  const rJSX_AssignmentHistoryTab = (): JSX.Element => {
    let tabJSX = (
      <Card>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Name')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Email')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Status')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Confirmation Number')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Assigned')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Completed')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Task Link')}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{rLIB('Data Item')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objectToArray(getProp(us_task, 'associated_assigned_users_history', {}))
                .sort(dynamicSort('timestamp', 'desc'))
                .map((user: TsInterface_UnspecifiedObject, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body1">{getProp(user, 'name', rJSX_MissingText())}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{getProp(user, 'email', rJSX_MissingText())}</Typography>
                    </TableCell>
                    <TableCell>
                      <Stack
                        direction="row"
                        spacing={1}
                      >
                        <Box sx={{ paddingRight: '4px', display: 'inline-block' }}>{rJSX_TaskStatusIcon(getProp(user, 'status', ''))}</Box>
                        <Typography variant="body1">{underscoresToSpacesAndCapitalize(getProp(user, 'status', ''))}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">{getProp(user, 'confirmation_number', rJSX_MissingText())}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {user.timestamp_assigned != null ? returnFormattedDate(user.timestamp_assigned, 'YYYY-MM-DD h:mm a') : rJSX_MissingText()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">
                        {user.timestamp_completed != null ? returnFormattedDate(user.timestamp_completed, 'YYYY-MM-DD h:mm a') : rJSX_MissingText()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        startIcon={<Icon icon="link" />}
                        sx={{
                          'color': themeVariables.white,
                          'backgroundColor': returnTaskStatusColors(getProp(user, 'status', '')),
                          '&:hover': {
                            backgroundColor: returnTaskStatusColors(getProp(user, 'status', '')),
                          },
                        }}
                        onClick={() => {
                          let url = window.location.origin + ApplicationPages.UnauthenticatedTaskChecklistPage.url(clientKey, taskKey, user.key as string)
                          window.open(url, '_blank')
                        }}
                      >
                        {rLIB('Task')}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {user.associated_data_item_keys != null && user.associated_data_item_keys.length > 0 ? (
                        user.associated_data_item_keys.map((itemKey: string, index: number) => (
                          <Button
                            key={index}
                            variant="contained"
                            color="inherit"
                            size="small"
                            startIcon={<Icon icon="magnifying-glass" />}
                            sx={{
                              'color': themeVariables.white,
                              'backgroundColor': returnTaskStatusColors(getProp(user, 'status', '')),
                              '&:hover': {
                                backgroundColor: returnTaskStatusColors(getProp(user, 'status', '')),
                              },
                            }}
                            onClick={() => {
                              uc_setUserInterface_CustomDialogDisplay({
                                display: true,
                                dialog: {
                                  dialog_jsx: <DataItemViewDialog itemKey={itemKey} />,
                                  settings: {
                                    max_width: 'lg',
                                  },
                                },
                              })
                            }}
                          >
                            {rLIB('Data Item')}
                          </Button>
                        ))
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
    return tabJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xxl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay2(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: returnTaskStatusColors(getProp(us_task, 'status', '')) }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="ballot-check" />
              </IconButton>
              <Typography
                variant={'h6'}
                sx={{ flexGrow: 1, color: themeVariables.white }}
              >
                {rLIB('Task')} {getProp(us_task, 'id_number', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box sx={{ padding: '8px' }}>
              <TabsBasic
                tabs={[
                  {
                    tabHeader: rLIB('Details'),
                    tabContent: rJSX_DetailsTab(),
                  },
                  // {
                  //   tabHeader: rLIB('Json'),
                  //   tabContent: (
                  //     <Json
                  //       data={us_task}
                  //       alphabetized={true}
                  //     />
                  //   ),
                  // },
                  {
                    tabHeader: rLIB('Task Steps'),
                    tabContent: rJSX_TaskSteps(),
                  },
                  {
                    tabHeader: rLIB('Assignment History'),
                    tabContent: rJSX_AssignmentHistoryTab(),
                  },
                  {
                    tabHeader: rLIB('Logs'),
                    tabContent: rJSX_LogsTab(),
                  },
                ]}
                tabsSettings={{}}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

/* eslint-disable react/prop-types */
///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ApiDocumentationEndpointComponent {
  endpoint: TsInterface_ApiDocumentationEndpoint
}

export interface TsInterface_ApiDocumentation {
  base_data: {
    base_url: string
    version: string
  }
  endpoints: {
    [key: string]: TsInterface_ApiDocumentationEndpoint
  }
}

interface TsInterface_ApiDocumentationEndpointExampleResponse {
  key: string
  response: TsInterface_UnspecifiedObject
}

interface TsInterface_ApiDocumentationEndpointParameter {
  key: string
  required: boolean
  description: string
  data_type: string
  example_value: string | number
  allowed_values?: string[]
}

interface TsInterface_ApiDocumentationEndpoint {
  description: string
  short_description: string
  alert_banner?: string
  method: string
  url: string
  example_request?: TsInterface_UnspecifiedObject
  example_responses: {
    [key: string]: TsInterface_ApiDocumentationEndpointExampleResponse
  }
  parameters: {
    [key: string]: TsInterface_ApiDocumentationEndpointParameter
  }
}

export interface TsInterface_ApiDocumentationUserInfo {
  uid: string | null
  api_key: string | null
}

///////////////////////////////
// Variables
///////////////////////////////

const baseURL = 'https://us-central1-data-ore-app.cloudfunctions.net/api'

///////////////////////////////
// Component
///////////////////////////////

const ApiEndpointDetails: React.FC<TsInterface_ApiDocumentationEndpointComponent> = ({ endpoint }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_visibleExampleResponse, us_setActiveExampleResponse] = useState<string | null>(null)

  // Functions
  const rSX_ResponseBorder = (responseCode: string | null): string => {
    let border = '3px solid ' + themeVariables.gray_300
    if (responseCode != null) {
      switch (responseCode.charAt(0)) {
        case '1':
          border = '2px solid ' + themeVariables.info_main
          break
        case '2':
          border = '2px solid ' + themeVariables.success_main
          break
        case '3':
          border = '2px solid ' + themeVariables.warning_main
          break
        case '4':
          border = '2px solid ' + themeVariables.error_main
          break
        case '5':
          border = '2px solid ' + themeVariables.error_main
          break
        default:
          border = '2px solid ' + themeVariables.gray_300
      }
    }
    return border
  }

  const returnMethodColor = (): string => {
    let methodColor = themeVariables.gray_400
    switch (endpoint.method) {
      case 'GET':
        methodColor = themeVariables.success_main
        break
      case 'POST':
        methodColor = themeVariables.info_main
        break
      case 'PUT':
        methodColor = themeVariables.warning_main
        break
      case 'DELETE':
        methodColor = themeVariables.error_main
        break
      case 'PATCH':
        methodColor = themeVariables.secondary_main
        break
      default:
    }
    return methodColor
  }

  // JSX
  const rJSX_ParameterRequired = (required: boolean): JSX.Element => {
    let requiredJSX: JSX.Element = <></>
    if (required === true) {
      requiredJSX = (
        <Icon
          icon="asterisk"
          className="tw-ml-1"
          tooltip={rLIB('Required')}
          tooltipPlacement="right"
          sx={{ color: themeVariables.error_main }}
        />
      )
    }
    return requiredJSX
  }

  const rJSX_RequestParameters = (): JSX.Element => {
    let parametersJSX: JSX.Element = <></>
    let headerCellSX = { fontFamily: 'monospace', fontSize: '14px', fontWeight: 'bold', color: returnMethodColor() }
    parametersJSX = (
      <Card sx={{ border: '2px solid ' + themeVariables.gray_700 }}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={headerCellSX}>{rLIB('Parameter')}</TableCell>
                <TableCell sx={headerCellSX}>{rLIB('Data Type')}</TableCell>
                <TableCell sx={headerCellSX}>{rLIB('Description')}</TableCell>
                <TableCell sx={headerCellSX}>{rLIB('Example Value')}</TableCell>
                <TableCell sx={headerCellSX}>{rLIB('Allowed Values')}</TableCell>
              </TableRow>
              {objectToArray(getProp(endpoint, 'parameters', {})).map((parameter: TsInterface_ApiDocumentationEndpointParameter, index: number) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: 'bold', fontFamily: 'monospace', fontSize: '14px' }}>
                    <Stack
                      direction="row"
                      spacing={0}
                    >
                      {parameter.key}
                      {rJSX_ParameterRequired(parameter.required)}
                    </Stack>
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: 'monospace', fontSize: '14px' }}
                    className="tw-opacity-60"
                  >
                    {parameter.data_type}
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: 'monospace', fontSize: '14px' }}
                    className="tw-opacity-60"
                  >
                    {parameter.description}
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: 'monospace', fontSize: '14px' }}
                    className="tw-opacity-60"
                  >
                    {parameter.example_value}
                  </TableCell>
                  <TableCell
                    sx={{ fontFamily: 'monospace', fontSize: '14px' }}
                    className="tw-opacity-60"
                  >
                    {parameter.allowed_values != null ? parameter.allowed_values.join(', ') : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
    return parametersJSX
  }

  const rJSX_ExampleRequest = (): JSX.Element => {
    let exampleRequestJSX: JSX.Element = <></>
    exampleRequestJSX = (
      <Box>
        <Stack
          direction="row"
          spacing={0}
        >
          <Box className="tw-inline-block tw-mr-1">{rJSX_EndpointMethodButton()}</Box>
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '16px', marginTop: '2px', fontFamily: 'monospace', fontWeight: 'normal', opacity: '0.5' }}
          >
            {baseURL}
          </Box>
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '16px', marginTop: '2px', fontFamily: 'monospace', fontWeight: 'bold' }}
          >
            {endpoint.url}
          </Box>
        </Stack>

        <Box
          sx={{ border: '2px solid ' + themeVariables.white }}
          className="tw-rounded-md tw-mt-2"
        >
          <Json data={getProp(endpoint, 'example_request', {})} />
        </Box>
      </Box>
    )
    return exampleRequestJSX
  }

  const rJSX_ExampleResponseButtons = (
    response: TsInterface_ApiDocumentationEndpointExampleResponse,
    index: number,
    activeResponseCode: string,
  ): JSX.Element => {
    let responseButtonsJSX: JSX.Element = <></>
    let buttonColor: TsType_MuiComponentColors = 'success'
    let buttonVariant: 'outlined' | 'contained' = 'outlined'
    // Change color based on first character in response key
    let responseKeyFirstChar = response.key.charAt(0)
    switch (responseKeyFirstChar) {
      case '1':
        buttonColor = 'info'
        break
      case '2':
        buttonColor = 'success'
        break
      case '3':
        buttonColor = 'warning'
        break
      case '4':
        buttonColor = 'error'
        break
      case '5':
        buttonColor = 'error'
        break
      default:
        buttonColor = 'info'
    }
    if (response.key === activeResponseCode) {
      buttonVariant = 'contained'
    }
    responseButtonsJSX = (
      <Box>
        <Button
          color={buttonColor}
          variant={buttonVariant}
          key={index}
          sx={{ fontFamily: 'monospace', fontWeight: 'bold' }}
          className="tw-mr-2"
          onClick={() => {
            us_setActiveExampleResponse(response.key)
          }}
        >
          {response.key}
        </Button>
      </Box>
    )
    return responseButtonsJSX
  }

  const rJSX_ExampleResponses = (): JSX.Element => {
    let activeResponseCode = '200'
    if (us_visibleExampleResponse != null) {
      activeResponseCode = us_visibleExampleResponse
    } else {
      if (
        objectToArray(getProp(endpoint, 'example_responses', {})).length > 0 &&
        objectToArray(getProp(endpoint, 'example_responses', {}))[0] != null &&
        objectToArray(getProp(endpoint, 'example_responses', {}))[0].key != null
      ) {
        activeResponseCode = objectToArray(getProp(endpoint, 'example_responses', {}))[0].key
      }
    }
    let responseJSX: JSX.Element = (
      <Box>
        <Box>
          {objectToArray(getProp(endpoint, 'example_responses', {})).map((response: TsInterface_ApiDocumentationEndpointExampleResponse, index: number) => (
            <Box
              key={index}
              className="tw-inline-block"
            >
              {rJSX_ExampleResponseButtons(response, index, activeResponseCode)}
            </Box>
          ))}
        </Box>
        <Box
          className="tw-mt-2"
          sx={{ borderRadius: '22px', border: rSX_ResponseBorder(getProp(endpoint, `example_responses.${activeResponseCode}.key`, null)) }}
        >
          <Json data={getProp(endpoint, `example_responses.${activeResponseCode}.response`, {})} />
        </Box>
      </Box>
    )
    return responseJSX
  }

  const rJSX_EndpointMethodButton = (): JSX.Element => {
    let methodButtonJSX = <></>
    // Method
    methodButtonJSX = (
      <Box
        className="tw-inline-block tw-mr-3 tw-py-0.5 tw-px-4 tw-rounded-sm tw-text-center"
        sx={{ background: returnMethodColor(), color: themeVariables.white, fontSize: '16px', fontFamily: 'monospace', fontWeight: 'bold', width: '70px' }}
      >
        {endpoint.method}
      </Box>
    )
    return methodButtonJSX
  }

  const rJSX_WarningBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (endpoint.alert_banner != null) {
      bannerJSX = (
        <Box
          className="tw-mb-2 tw-mt-2"
          sx={{ background: themeVariables.warning_dark, color: themeVariables.white, padding: '10px', borderRadius: '5px' }}
        >
          <Icon
            icon="exclamation-triangle"
            className="tw-mr-2"
          />
          <Typography
            variant="body1"
            className="tw-inline-block tw-opacity-90"
          >
            {endpoint.alert_banner}
          </Typography>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_ApiDocumentationEndpoint = (): JSX.Element => {
    let endpointJSX = (
      <Box>
        <Accordion className="tw-mb-2">
          <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
            {rJSX_EndpointMethodButton()}
            <Box
              className="tw-inline-block"
              sx={{ fontSize: '16px', marginTop: '2px', fontFamily: 'monospace', fontWeight: 'bold' }}
            >
              {endpoint.url}
            </Box>
            <Box className="tw-ml-3 tw-inline-block">
              <Typography
                sx={{ marginTop: '3px' }}
                variant="body1"
                className="tw-opacity-30"
              >
                {endpoint.short_description}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingTop: '0px' }}>
            <Divider />
            {rJSX_WarningBanner()}
            <Box className="tw-mt-2">
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {rLIB('Description')}
              </Typography>
              <Box className="tw-pl-4">
                <Typography
                  variant="body1"
                  className="tw-opacity-50"
                >
                  {endpoint.description}
                </Typography>
              </Box>
            </Box>
            <Box className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-font-bold tw-mb-2"
              >
                {rLIB('Request Parameters')}
              </Typography>
              <Box className="tw-pl-4">{rJSX_RequestParameters()}</Box>
            </Box>
            <Box className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-font-bold tw-mb-2"
              >
                {rLIB('Example Request')}
              </Typography>
              <Box className="tw-pl-4">{rJSX_ExampleRequest()}</Box>
            </Box>

            <Box className="tw-mt-4">
              <Typography
                variant="h6"
                className="tw-font-bold tw-mb-2"
              >
                {rLIB('Responses')}
              </Typography>
              <Box className="tw-pl-4">{rJSX_ExampleResponses()}</Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
    return endpointJSX
  }

  return <>{rJSX_ApiDocumentationEndpoint()}</>
}

///////////////////////////////
// Exports
///////////////////////////////

export const rJSX_ApiDocumentation = (apiDocumentation: TsInterface_ApiDocumentation): JSX.Element => {
  let documentationJSX: JSX.Element = <></>
  documentationJSX = (
    <Box>
      {objectToArray(getProp(apiDocumentation, 'endpoints', {})).map((endpoint: TsInterface_ApiDocumentationEndpoint, index: number) => (
        <Box key={index}>
          <ApiEndpointDetails endpoint={endpoint} />
        </Box>
      ))}
    </Box>
  )
  return documentationJSX
}

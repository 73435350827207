///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { activateAccount, deactivateAccount } from 'app/models/users'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellPhone,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const viewUser = (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks): void => {
  if (rowData.key != null) {
    tableHooks.un_routerNavigation(ApplicationPages.AdminDatabaseUserViewPage.url(rowData.key as string))
  }
}

const editUser = (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks): void => {
  if (rowData.key != null) {
    tableHooks.un_routerNavigation(ApplicationPages.AdminDatabaseUserEditPage.url(rowData.key as string))
  }
}

const disableUserAccount = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
): void => {
  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'error',
      icon: <Icon icon="lock" />,
      header: rLIB('Disable Account'),
      text: rLIB('Are you sure that you want to lock this user account?'),
      submit_text: rLIB('Lock Account'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              if (res_GCK.clientKey != null && rowData.key != null) {
                let userName = getProp(tableHooks.uc_RootData_ClientUser, 'name', null)
                let userKey = getProp(tableHooks.uc_RootData_ClientUser, 'key', null)
                deactivateAccount(res_GCK.clientKey, rowData.key as string, userName, userKey)
                  .then((res_DA) => {
                    // TODO: Toast?
                    resolve(res_DA)
                  })
                  .catch((rej_DA) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DA.error })
                    reject(rej_DA)
                  })
              }
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject(rej_GCK)
            })
        })
      },
    },
  })
}

const enableUserAccount = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
): void => {
  tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'warning',
      icon: <Icon icon="lock-open" />,
      header: rLIB('Enable Account'),
      text: rLIB('Are you sure that you want to unlock this user account?'),
      submit_text: rLIB('Unlock account'),
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((red_GCK) => {
              if (red_GCK.clientKey != null && rowData.key != null) {
                let userName = getProp(tableHooks.uc_RootData_ClientUser, 'name', null)
                let userKey = getProp(tableHooks.uc_RootData_ClientUser, 'key', null)
                activateAccount(red_GCK.clientKey, rowData.key as string, userName, userKey)
                  .then((rej_AA) => {
                    // TODO: Toast?
                    resolve(rej_AA)
                  })
                  .catch((rej_AA) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AA.error })
                    reject(rej_AA)
                  })
              }
            })
            .catch((res_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: res_GCK.error })
              reject(res_GCK)
            })
        })
      },
    },
  })
}

// JSX generation

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_AllUsers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 20,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: rLIB('No Users found by search') as JSX.Element,
    search_property_options: [
      { key: 'name', value: rLIB('User Name') },
      { key: 'email', value: rLIB('Email') },
      { key: 'phone', value: rLIB('Phone') },
    ],
  },
}

export const tableSettings_User: TsInterface_TableDatabaseSettings = {
  rows_per_page: 20,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const nameColumn = TableCellBasic('name', rLIB('User Name'), 'name')
const emailColumn = TableCellBasic('email', rLIB('Email'), 'email')
const phoneColumn = TableCellPhone('phone', rLIB('Phone'), 'phone')
const manageColumn = TableCellManage({
  view: {
    icon: <Icon icon="magnifying-glass" />,
    label: <>{rLIB('View')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      viewUser(rowData, tableAdditionalData, tableHooks)
    },
  },
  edit: {
    icon: <Icon icon="pen-to-square" />,
    label: <>{rLIB('Edit')}</>,
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '!=',
      value: 'deleted',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      editUser(rowData, tableAdditionalData, tableHooks)
    },
  },
  delete: {
    icon: <Icon icon="lock" />,
    label: <>{rLIB('Disable Account')}</>,
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '!=',
      value: 'deleted',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      disableUserAccount(rowData, tableAdditionalData, tableHooks)
    },
  },
  undelete: {
    icon: <Icon icon="lock-open" />,
    label: <>{rLIB('Enable Account')}</>,
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '==',
      value: 'deleted',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      enableUserAccount(rowData, tableAdditionalData, tableHooks)
    },
  },
})
const keyColumn = TableCellBasic('key', rLIB('Key'), 'key')

export const tableColumns_User: TsInterface_TableColumns = {
  manage: manageColumn,
  name: nameColumn,
  email: emailColumn,
  phone: phoneColumn,
}

export const tableColumns_UserReadOnly: TsInterface_TableColumns = {
  name: nameColumn,
  key: keyColumn,
  email: emailColumn,
  phone: phoneColumn,
}

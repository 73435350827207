///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import { MuiChipsInput } from 'mui-chips-input'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ActiveClassCustomForms_Query,
  DatabaseRef_ActiveClasses_Query,
  DatabaseRef_ClassFields_Collection,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_TaskTemplates_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/task_templates'
import { DatabaseRef_ActiveReportTemplates_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { isValidEmail } from '../export_scheduled_emails/components/recurring_email_form_dialog'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TaskTemplateViewPage']['key']

export const taskTemplateStepTypes: TsInterface_UnspecifiedObject = {
  form: {
    key: 'form',
    value: rLIB('Form Data Entry'),
    icon: 'ballot-check',
    icon_color: themeVariables.info_main,
  },
  navigation: {
    key: 'navigation',
    value: rLIB('Navigation to Location'),
    icon: 'map-location-dot',
    icon_color: themeVariables.error_main,
  },
  email: {
    key: 'email',
    value: rLIB('Automated Email'),
    icon: 'paper-plane',
    icon_color: themeVariables.secondary_light,
  },
  metadata: {
    key: 'metadata',
    value: rLIB('Metadata'),
    icon: 'database',
    icon_color: themeVariables.warning_main,
  },
}

const formInputs_Email: TsInterface_FormInputs = {
  to_array: {
    data_type: 'string',
    key: 'to_array',
    label: rLIB('To'),
    input_type: 'custom_form_input_jsx',
    required: true,
    renderCustomFormInput: (
      formInput: TsInterface_FormInput,
      formInputs: TsInterface_FormInputs,
      formData: TsInterface_FormData,
      formInputChange: TsType_InputChangeCallback,
      formSettings: TsInterface_FormSettings,
      formAdditionalData: TsInterface_FormAdditionalData,
      formHooks: TsInterface_FormHooksObject,
    ) => {
      let inputJSX = (
        <Box
          className=""
          sx={{ marginBottom: '16px' }}
        >
          <MuiChipsInput
            label={rLIB('Email Recipients')}
            placeholder={rLIB('Add Emails', false) as string}
            fullWidth={true}
            value={getProp(formData, formInput.key, [])}
            onChange={(chipsArray: string[]) => {
              formInputChange(formInput.key, chipsArray, true)
            }}
            disabled={false}
            disableEdition={true}
            disableDeleteOnBackspace={true}
            onKeyDown={(event: any) => {
              // Nothing
            }}
            addOnBlur={true}
            renderChip={(Component, key, props) => {
              // eslint-disable-next-line
              if (isValidEmail(props.label) === true) {
                return (
                  <Component
                    {...props}
                    key={key}
                  />
                )
              } else {
                return (
                  <Component
                    {...props}
                    key={key}
                    color="error"
                  />
                )
              }
            }}
          />
        </Box>
      )
      return inputJSX
    },
  },
  subject: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'subject',
    label: rLIB('Subject'),
    required: true,
  },
  body: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'body',
    label: rLIB('Body'),
    required: true,
  },
  attachment: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'attachment',
    label: rLIB('Attachment'),
    required: true,
    options: [],
  },
  attachment_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'attachment_name',
    label: rLIB('Attachment Name'),
    required: true,
  },
  associated_class_mapped_name_field_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_class_mapped_name_field_key',
    label: rLIB('Location Name'),
    required: true,
    options: [],
  },
  associated_class_mapped_latitude_field_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_class_mapped_latitude_field_key',
    label: rLIB('Latitude'),
    required: true,
    options: [],
  },
  associated_class_mapped_longitude_field_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_class_mapped_longitude_field_key',
    label: rLIB('Longitude'),
    required: true,
    options: [],
  },
}

const dataItemCreationOptions: TsInterface_UnspecifiedObject = {
  single: {
    key: 'single',
    name: rLIB('Single Data Item'),
  },
  multiple_determinate: {
    key: 'multiple_determinate',
    name: rLIB('Multiple Items - Determinate'),
  },
  multiple_indeterminate: {
    key: 'multiple_indeterminate',
    name: rLIB('Multiple Items - Indeterminate'),
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_taskTemplate, us_setTaskTemplate] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClassCustomForms, us_setSelectedClassCustomForms] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeReportTemplates, us_setActiveReportTemplates] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  let un_routerNavigation = useNavigate()
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Task Template') as string
  }, [])

  useEffect(() => {
    if (us_taskTemplate != null && us_taskTemplate.name != null) {
      document.title = (rLIB('Task Template', false) as string) + ' - ' + us_taskTemplate.name
    } else {
      document.title = rLIB('Task Template', false) as string
    }
  }, [us_taskTemplate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskTemplate(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedClassCustomForms(newData)
    }
    if (us_taskTemplate != null && us_taskTemplate.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveClassCustomForms_Query(res_GCK.clientKey, us_taskTemplate.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_taskTemplate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeTransactionalClasses: TsInterface_UnspecifiedObject = {}
      for (let loopClassKey in newData) {
        let loopClass = newData[loopClassKey]
        if (loopClass != null && loopClass.class_type === 'transactional_data') {
          activeTransactionalClasses[loopClassKey] = loopClass
        }
      }
      us_setActiveClasses(activeTransactionalClasses)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveReportTemplates(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const setMergeTaskTemplateRootData = (newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, itemKey), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const moveTaskTemplateStepUp = (step: TsInterface_UnspecifiedObject): void => {
    // Find the index of the step
    let stepIndex = 0
    let orderedSteps = objectToArray(getProp(us_taskTemplate, 'steps', {})).sort(dynamicSort('order', 'asc'))
    for (let loopStepIndex in orderedSteps) {
      let loopStep = orderedSteps[loopStepIndex]
      if (step.key === loopStep.key) {
        stepIndex = parseInt(loopStepIndex)
      }
    }
    // If the step is not the first step - update the order of the step above it
    if (stepIndex > 0) {
      let aboveStep = orderedSteps[stepIndex - 1]
      // Update the step order
      let updateObject = {
        steps: {
          [aboveStep.key]: { order: getProp(us_taskTemplate, 'steps', {})[step.key].order },
          [step.key]: { order: getProp(us_taskTemplate, 'steps', {})[aboveStep.key].order },
        },
      }
      setMergeTaskTemplateRootData(updateObject)
    }
  }

  const moveTaskTemplateStepDown = (step: TsInterface_UnspecifiedObject): void => {
    // Find the index of the step
    let stepIndex = 0
    let orderedSteps = objectToArray(getProp(us_taskTemplate, 'steps', {})).sort(dynamicSort('order', 'asc'))
    for (let loopStepIndex in orderedSteps) {
      let loopStep = orderedSteps[loopStepIndex]
      if (step.key === loopStep.key) {
        stepIndex = parseInt(loopStepIndex)
      }
    }
    // If the step is not the last step - update the order of the step below it
    if (stepIndex < orderedSteps.length - 1) {
      let belowStep = orderedSteps[stepIndex + 1]
      // Update the step order
      let updateObject = {
        steps: {
          [belowStep.key]: { order: getProp(us_taskTemplate, 'steps', {})[step.key].order },
          [step.key]: { order: getProp(us_taskTemplate, 'steps', {})[belowStep.key].order },
        },
      }
      setMergeTaskTemplateRootData(updateObject)
    }
  }

  const deleteTaskTemplateStep = (step: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Step'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this step?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                // let updateObject = {
                //   steps: {
                //     [step.key]: {
                //       status: 'deleted',
                //     },
                //   },
                // }
                let updateObject = {
                  steps: getProp(us_taskTemplate, 'steps', {}),
                }
                delete updateObject.steps[step.key]
                DatabaseUpdateDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, itemKey), updateObject)
                  .then((res_DUD) => {
                    resolve(res_DUD)
                  })
                  .catch((rej_DUD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    reject(rej_DUD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  // JSX Generation
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_taskTemplate != null && us_taskTemplate.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Task Template')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_taskTemplate.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Task Template') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={() => {
          un_routerNavigation(ApplicationPages.TasksIndexPage.url() + '?tab=templates')
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2 tw-mb-2"
      >
        {rLIB('Back to all Tasks')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AddStepButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Step Name')}</>,
                    required: true,
                  },
                  step_type: {
                    key: 'step_type',
                    label: rLIB('Step Type'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    options: objectToArray(taskTemplateStepTypes),
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let newStepKey = uuidv4()
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          steps: {
                            [newStepKey]: {
                              name: formSubmittedData.name,
                              step_type: formSubmittedData.step_type,
                              key: newStepKey,
                              status: 'active',
                              order: new Date().getTime(),
                            },
                          },
                        }
                        DatabaseSetMergeDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, itemKey), updateObject)
                          .then((res_DSMD) => {
                            resolve({ close_dialog: true })
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD.error)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK.error)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Task Template Step')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="circle-plus"
                  />
                ),
              },
            },
          })
        }}
        disableElevation
        startIcon={<Icon icon="circle-plus" />}
        className="tw-mr-2 tw-mb-2"
        disabled={us_taskTemplate.locked === true}
      >
        {rLIB('Add Step')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DataTypeDropdown = (): JSX.Element => {
    let filteredActiveClasses: TsInterface_UnspecifiedObject = {}
    for (let loopClassKey in us_activeClasses) {
      if (us_activeClasses[loopClassKey].class_type === 'transactional_data') {
        filteredActiveClasses[loopClassKey] = us_activeClasses[loopClassKey]
      }
    }
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            //
            // let updateObject = { data_type: event.target.value }
            let updateObject: any = { associated_class_key: event.target.value }
            if (us_activeClasses != null && us_activeClasses[event.target.value] != null && us_activeClasses[event.target.value].name != null) {
              updateObject['associated_class_name'] = us_activeClasses[event.target.value].name
            }
            setMergeTaskTemplateRootData(updateObject)
          }}
          value={getProp(us_taskTemplate, 'associated_class_key', '')}
          disabled={us_taskTemplate.locked === true} // TODO: Lock as soon as it's used for the first time???
        >
          {objectToArray(filteredActiveClasses)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DataItemCreationDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            //
            // let updateObject = { data_type: event.target.value }
            let updateObject: any = { data_item_creation_method: event.target.value }
            setMergeTaskTemplateRootData(updateObject)
          }}
          value={getProp(us_taskTemplate, 'data_item_creation_method', '')}
          disabled={us_taskTemplate.locked === true} // TODO: Lock as soon as it's used for the first time???
        >
          {objectToArray(dataItemCreationOptions)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DeterminateCountSelection = (): JSX.Element => {
    let countInputJSX = <></>
    if (getProp(us_taskTemplate, 'data_item_creation_method', null) === 'multiple_determinate') {
      countInputJSX = (
        <Box>
          <FormControl
            className="bp_thin_text_input tw-mt-2"
            sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
          >
            {/* <InputLabel>{rLIB('Default Number of Items')}</InputLabel> */}
            <TextField
              label={rLIB('Default Number of Items')}
              type="number"
              value={getProp(us_taskTemplate, 'data_item_multiple_determinate_count', 1)}
              onChange={(event) => {
                let updateObject: any = { data_item_multiple_determinate_count: parseInt(event.target.value) }
                setMergeTaskTemplateRootData(updateObject)
              }}
            />
          </FormControl>
        </Box>
      )
    }
    return countInputJSX
  }

  const rJSX_MultipleItemStep = (): JSX.Element => {
    let stepDropdownJSX = <></>
    let formStepOptions: TsInterface_UnspecifiedObject = {}
    if (getProp(us_taskTemplate, 'steps', {}) != null) {
      for (let loopStepKey in getProp(us_taskTemplate, 'steps', {})) {
        let loopStep = getProp(us_taskTemplate, 'steps', {})[loopStepKey]
        if (getProp(loopStep, 'step_type', null) === 'form') {
          formStepOptions[loopStepKey] = loopStep
        }
      }
    }
    if (
      getProp(us_taskTemplate, 'data_item_creation_method', null) === 'multiple_determinate' ||
      getProp(us_taskTemplate, 'data_item_creation_method', null) === 'multiple_indeterminate'
    ) {
      stepDropdownJSX = (
        <Box>
          <FormControl
            className="bp_thin_select_input tw-mt-2"
            sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
          >
            <InputLabel>{rLIB('Step to Repeat')}</InputLabel>
            <Select
              label={rLIB('Step to Repeat')}
              onChange={(event, value) => {
                let updateObject: any = { data_item_multiple_repeated_step: event.target.value }
                setMergeTaskTemplateRootData(updateObject)
              }}
              value={getProp(us_taskTemplate, 'data_item_multiple_repeated_step', '')}
              disabled={us_taskTemplate.locked === true} // TODO: Lock as soon as it's used for the first time???
            >
              {objectToArray(formStepOptions)
                .sort(dynamicSort('order', 'asc'))
                .map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                  >
                    {option['name']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )
    }
    return stepDropdownJSX
  }

  const rJSX_StepOrderDropdown = (): JSX.Element => {
    let dataItemCreationOptions: TsInterface_UnspecifiedObject = {
      sequential: {
        key: 'sequential',
        name: rLIB('Sequential'),
      },
      any_order: {
        key: 'any_order',
        name: rLIB('Any Order'),
      },
    }
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            //
            // let updateObject = { data_type: event.target.value }
            let updateObject: any = { step_order_of_completion: event.target.value }
            setMergeTaskTemplateRootData(updateObject)
          }}
          value={getProp(us_taskTemplate, 'step_order_of_completion', '')}
          disabled={us_taskTemplate.locked === true} // TODO: Lock as soon as it's used for the first time???
        >
          {objectToArray(dataItemCreationOptions)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DetailsTabContent = (): JSX.Element => {
    return (
      <Card className="tw-p-4">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Form for Data Bucket')}
        </Typography>
        <Box className="tw-ml-4">{rJSX_DataTypeDropdown()}</Box>

        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Item Creation')}
        </Typography>
        <Box className="tw-ml-4">
          {rJSX_DataItemCreationDropdown()}
          {rJSX_MultipleItemStep()}
          {rJSX_DeterminateCountSelection()}
        </Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Step Completion Order')}
        </Typography>
        <Box className="tw-ml-4">{rJSX_StepOrderDropdown()}</Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Security')}
        </Typography>
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_taskTemplate.require_app === true ? true : false}
            disabled={true}
            onChange={(event, value) => {
              if (value === true) {
                setMergeTaskTemplateRootData({ require_app: true })
              } else if (value === false) {
                setMergeTaskTemplateRootData({ require_app: false })
              }
            }}
          />
          {us_taskTemplate.require_app === true ? rLIB('Users must complete task in the app') : rLIB('User can complete task with the app or from the browser')}
        </Box>
      </Card>
    )
  }

  const rJSX_CustomFormDropdown = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let customForms: TsInterface_UnspecifiedObject = {}
    for (let loopFormKey in us_selectedClassCustomForms) {
      customForms[loopFormKey] = us_selectedClassCustomForms[loopFormKey]
    }
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            let updateObject: any = {
              steps: {
                [step.key]: {
                  associated_class_custom_form_key: event.target.value,
                },
              },
            }
            if (
              us_selectedClassCustomForms != null &&
              us_selectedClassCustomForms[event.target.value] != null &&
              us_selectedClassCustomForms[event.target.value].name != null
            ) {
              updateObject['steps'][step.key]['associated_class_custom_form_name'] = us_selectedClassCustomForms[event.target.value].name
            }
            setMergeTaskTemplateRootData(updateObject)
          }}
          value={getProp(us_taskTemplate['steps'][step.key], 'associated_class_custom_form_key', '')}
          disabled={us_taskTemplate.locked === true} // TODO: Lock as soon as it's used for the first time???
        >
          {objectToArray(customForms)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_FormStepContent = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let contentJSX = (
      <Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB('Form')}
        </Typography>
        {rJSX_CustomFormDropdown(step)}
      </Box>
    )
    return contentJSX
  }

  const rJSX_LocationClassDropdown = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let filteredActiveClasses: TsInterface_UnspecifiedObject = {}
    for (let loopClassKey in us_activeClasses) {
      if (us_activeClasses[loopClassKey].class_type === 'persistent_data') {
        filteredActiveClasses[loopClassKey] = us_activeClasses[loopClassKey]
      }
    }
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            let updateObject: any = {
              steps: {
                [step.key]: {
                  associated_class_key: event.target.value,
                },
              },
            }
            if (us_activeClasses != null && us_activeClasses[event.target.value] != null && us_activeClasses[event.target.value].name != null) {
              updateObject['steps'][step.key]['associated_class_name'] = us_activeClasses[event.target.value].name
            }
            setMergeTaskTemplateRootData(updateObject)
          }}
          value={getProp(us_taskTemplate['steps'][step.key], 'associated_class_key', '')}
          disabled={us_taskTemplate.locked === true} // TODO: Lock as soon as it's used for the first time???
        >
          {objectToArray(filteredActiveClasses)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_NavigationStepContent = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let contentJSX = (
      <Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB('Location')}
        </Typography>
        {rJSX_LocationClassDropdown(step)}
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'associated_class_mapped_name_field_key')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Location Name')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'associated_class_mapped_name_field_key', rLIB('Location Name'))}
        </Box>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'associated_class_mapped_latitude_field_key')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Latitude')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'associated_class_mapped_latitude_field_key', rLIB('Latitude'))}
        </Box>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'associated_class_mapped_longitude_field_key')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Longitude')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'associated_class_mapped_longitude_field_key', rLIB('Longitude'))}
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_MetadataStepContent = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let contentJSX = (
      <Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB('Directory Association')}
        </Typography>
        {rJSX_LocationClassDropdown(step)}
      </Box>
    )
    return contentJSX
  }

  const rJSX_TriggeredEventsFormLineItem = (step: TsInterface_UnspecifiedObject, lineItemKey: string, lineItemName: string | JSX.Element): JSX.Element => {
    // TODO: Form Inputs
    let formInputs: TsInterface_FormInputs = {}
    if (formInputs_Email != null && formInputs_Email[lineItemKey] != null) {
      formInputs[lineItemKey] = formInputs_Email[lineItemKey]
    }
    let templateOptions: TsInterface_UnspecifiedObject[] = []
    if (
      lineItemKey === 'attachment' &&
      us_activeReportTemplates != null &&
      formInputs != null &&
      formInputs['attachment'] != null &&
      formInputs['attachment'].options != null
    ) {
      for (let loopTemplateKey in us_activeReportTemplates) {
        let loopTemplate = us_activeReportTemplates[loopTemplateKey]
        if (loopTemplate != null && loopTemplate.file_type === 'pdf') {
          templateOptions.push({
            key: loopTemplate.key,
            value: loopTemplate.name,
          })
        }
      }
      formInputs['attachment'].options = templateOptions
    }

    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-inline-block tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let promiseArray: Promise<any>[] = []
              let classFields: TsInterface_UnspecifiedObject = {}
              if (
                step != null &&
                step.associated_class_key != null &&
                (lineItemKey === 'associated_class_mapped_name_field_key' ||
                  lineItemKey === 'associated_class_mapped_latitude_field_key' ||
                  lineItemKey === 'associated_class_mapped_longitude_field_key')
              ) {
                promiseArray.push(
                  DatabaseGetCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, getProp(step, 'associated_class_key', '')))
                    .then((res_DGC) => {
                      classFields = res_DGC.data

                      formInputs[lineItemKey]['options'] = objectToArray(classFields).map((field: TsInterface_UnspecifiedObject) => {
                        return {
                          key: field.key,
                          value: field.name,
                        }
                      })
                    })
                    .catch((rej_DGC) => {
                      console.error(rej_DGC)
                    }),
                )
              }
              Promise.all(promiseArray).finally(() => {
                uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: step,
                      formInputs: formInputs,
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          let updateObject: any = { steps: { [step.key]: { [lineItemKey]: formSubmittedData[lineItemKey] } } }
                          if (
                            step != null &&
                            step.associated_class_key != null &&
                            (lineItemKey === 'associated_class_mapped_name_field_key' ||
                              lineItemKey === 'associated_class_mapped_latitude_field_key' ||
                              lineItemKey === 'associated_class_mapped_longitude_field_key')
                          ) {
                            let lineItemNameKey = lineItemKey.replace('_key', '_name')
                            if (
                              formSubmittedData[lineItemKey] != null &&
                              formSubmittedData[lineItemKey] !== '' &&
                              classFields != null &&
                              classFields[formSubmittedData[lineItemKey]] != null &&
                              classFields[formSubmittedData[lineItemKey]].name != null
                            ) {
                              updateObject['steps'][step.key][lineItemNameKey] = classFields[formSubmittedData[lineItemKey]].name
                            }
                          }
                          setMergeTaskTemplateRootData(updateObject)
                            .then(() => {
                              resolve({ success: true })
                            })
                            .catch(() => {
                              reject({ success: false })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: (
                        <>
                          {rLIB('Edit')} {lineItemName}
                        </>
                      ),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              })
            })
            .catch((rej_GCK) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }}
      />
    )
    let linItemJSX = <></>
    if (step != null && step[lineItemKey] != null) {
      if (lineItemKey === 'to_array') {
        linItemJSX = <Box className="tw-inline-block">{step[lineItemKey].join(', ')}</Box>
      } else if (lineItemKey === 'attachment') {
        if (us_activeReportTemplates != null && step[lineItemKey] != null && us_activeReportTemplates[step[lineItemKey]] != null) {
          linItemJSX = <Box className="tw-inline-block">{us_activeReportTemplates[step[lineItemKey]].name}</Box>
        } else {
          linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
        }
      } else if (
        lineItemKey === 'associated_class_mapped_name_field_key' ||
        lineItemKey === 'associated_class_mapped_latitude_field_key' ||
        lineItemKey === 'associated_class_mapped_longitude_field_key'
      ) {
        let lineItemNameKey = lineItemKey.replace('_key', '_name')
        if (step[lineItemNameKey] != null) {
          linItemJSX = <Box className="tw-inline-block">{step[lineItemNameKey]}</Box>
        } else {
          linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
        }
      } else {
        linItemJSX = <Box className="tw-inline-block">{step[lineItemKey]}</Box>
      }
    } else {
      linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
    }
    return (
      <Box className="tw-inline-block">
        {linItemJSX}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_MissingTriggerFieldIcon = (step: TsInterface_UnspecifiedObject, fieldKey: string): JSX.Element => {
    let iconJSX = <></>
    if (step == null || step[fieldKey] == null) {
      iconJSX = (
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
          sx={{ color: themeVariables.error_main }}
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="circle-check"
          className="tw-mr-2"
          sx={{ color: themeVariables.success_main }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_EmailStepContent = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let contentJSX = (
      <Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB('Email')}
        </Typography>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'to_array')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Email Recipients')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'to_array', rLIB('Email Recipients'))}
        </Box>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'subject')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Subject')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'subject', rLIB('Subject'))}
        </Box>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'body')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Body')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'body', rLIB('Body'))}
        </Box>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'attachment')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Attachment')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'attachment', rLIB('Attachment'))}
        </Box>
        <Box>
          {rJSX_MissingTriggerFieldIcon(step, 'attachment_name')}
          <Typography
            variant="body1"
            className="tw-font-bold tw-mr-2 tw-inline-block"
          >
            {rLIB('Attachment Name')}:
          </Typography>
          {rJSX_TriggeredEventsFormLineItem(step, 'attachment_name', rLIB('Attachment Name'))}
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_StepContent = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let stepContentJSX = <></>
    if (getProp(step, 'step_type', 'form') === 'form') {
      stepContentJSX = rJSX_FormStepContent(step)
    } else if (getProp(step, 'step_type', 'form') === 'navigation') {
      stepContentJSX = rJSX_NavigationStepContent(step)
    } else if (getProp(step, 'step_type', 'form') === 'email') {
      stepContentJSX = rJSX_EmailStepContent(step)
    } else if (getProp(step, 'step_type', 'form') === 'metadata') {
      stepContentJSX = rJSX_MetadataStepContent(step)
    }
    return stepContentJSX
  }

  const rJSX_RepeatIcon = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let repeatIconJSX = <></>
    if (
      step.key === getProp(us_taskTemplate, 'data_item_multiple_repeated_step', null) &&
      (getProp(us_taskTemplate, 'data_item_creation_method', null) === 'multiple_determinate' ||
        getProp(us_taskTemplate, 'data_item_creation_method', null) === 'multiple_indeterminate')
    ) {
      repeatIconJSX = (
        <Icon
          icon="repeat"
          className="tw-ml-2"
          sx={{ color: themeVariables.warning_main }}
        />
      )
    }
    return repeatIconJSX
  }

  const rJSX_TaskStep = (step: TsInterface_UnspecifiedObject) => {
    let taskStep = (
      <Accordion className="tw-mb-2">
        <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
          <Stack
            direction="row"
            spacing={2}
            className="tw-justify-between tw-w-full"
          >
            <Typography variant="h6">
              <Icon
                className="tw-mr-2"
                icon={getProp(taskTemplateStepTypes, getProp(step, 'step_type', 'form'), {}).icon}
                sx={{ color: getProp(taskTemplateStepTypes, getProp(step, 'step_type', 'form'), {}).icon_color }}
              />
              {step.name}
              {step.type}
              {rJSX_RepeatIcon(step)}
            </Typography>
            <Box className="tw-text-right">
              <Tooltip title={rLIB('Move Step Up')}>
                <Button
                  size="small"
                  variant="outlined"
                  color="info"
                  className="tw-mr-2 bp_icon_only_button"
                  startIcon={<Icon icon="chevron-up" />}
                  onClick={(event) => {
                    event.stopPropagation()
                    moveTaskTemplateStepUp(step)
                  }}
                >
                  {/* {rLIB('Move Step Up')} */}
                </Button>
              </Tooltip>
              <Tooltip title={rLIB('Move Step Down')}>
                <Button
                  size="small"
                  variant="outlined"
                  color="info"
                  className="tw-mr-2 bp_icon_only_button"
                  startIcon={<Icon icon="chevron-down" />}
                  onClick={(event) => {
                    event.stopPropagation()
                    moveTaskTemplateStepDown(step)
                  }}
                >
                  {/* {rLIB('Move Step Down')} */}
                </Button>
              </Tooltip>
              <Tooltip title={rLIB('Delete Step')}>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  className="tw-mr-2 bp_icon_only_button"
                  startIcon={<Icon icon="trash" />}
                  onClick={(event) => {
                    event.stopPropagation()
                    deleteTaskTemplateStep(step)
                  }}
                >
                  {/* {rLIB('Delete Step')} */}
                </Button>
              </Tooltip>
            </Box>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: '0px' }}>
          <Divider className="tw-mb-2" />

          <Box>{rJSX_StepContent(step)}</Box>
        </AccordionDetails>
      </Accordion>
    )
    return taskStep
  }

  const rJSX_StepSequenceTabContent = (steps: TsInterface_UnspecifiedObject): JSX.Element => {
    return (
      <Card className="tw-p-4">
        <Box>
          {objectToArray(steps)
            .sort(dynamicSort('order', null))
            .map((step: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_TaskStep(step)}</Box>
            ))}
        </Box>
      </Card>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'settings',
                  tabHeader: rLIB('Template Settings'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DetailsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'sequence',
                  tabHeader: rLIB('Task Sequence'),
                  tabOnChange: () => {},
                  tabContent: rJSX_StepSequenceTabContent(us_taskTemplate.steps),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_AddStepButton(false), minJSX: rJSX_AddStepButton(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'json',
                  tabHeader: rLIB('Json'),
                  tabOnChange: () => {},
                  tabContent: (
                    <Json
                      data={us_taskTemplate}
                      alphabetized={true}
                    />
                  ),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.TaskTemplateViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Tasks', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/* eslint-disable no-case-declarations */
///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { getProp } from '../helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

// Div
interface TsInterface_EmailTemplateElement_div {
  contents?: TsInterface_EmailTemplateObject
  element_type: 'div'
  style?: TsInterface_UnspecifiedObject
}

interface TsInterface_EmailTemplateElement_div_conditional {
  condition_data_object_key: string
  condition_data_object_prop_key: string
  condition_operator: '<' | '<=' | '!=' | '==' | '>' | '>='
  condition_value: string | number | boolean | null
  contents?: TsInterface_EmailTemplateObject
  element_type: 'div_conditional'
  style?: TsInterface_UnspecifiedObject
}

// Text
interface TsInterface_EmailTemplateElement_h1_static {
  element_type: 'h1'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_h1_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'h1'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_h2_static {
  element_type: 'h2'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_h2_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'h2'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_h3_static {
  element_type: 'h3'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_h3_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'h3'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_h4_static {
  element_type: 'h4'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_h4_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'h4'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_h5_static {
  element_type: 'h5'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_h5_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'h5'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_h6_static {
  element_type: 'h6'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_h6_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'h6'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_p_static {
  element_type: 'p'
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_p_dynamic {
  data_object_key: string
  data_object_prop_key: string
  element_type: 'p'
  style?: TsInterface_UnspecifiedObject
  text_type: 'dynamic'
}

interface TsInterface_EmailTemplateElement_a_static {
  element_type: 'a'
  href_type: 'static'
  href_url: string
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_a_dynamic_url {
  element_type: 'a'
  href_type: 'dynamic'
  href_url_data_object_key: string
  href_url_data_object_prop_key: string
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'static'
}

interface TsInterface_EmailTemplateElement_a_img_static {
  element_type: 'a'
  href_type: 'static'
  href_url: string
  img_alt: string
  img_height: string
  img_src: string
  img_style?: TsInterface_UnspecifiedObject
  img_width: string
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'img'
}

interface TsInterface_EmailTemplateElement_a_img_dynamic_url {
  element_type: 'a'
  href_type: 'dynamic'
  href_url_data_object_key: string
  href_url_data_object_prop_key: string
  img_alt: string
  img_height: string
  img_src: string
  img_style?: TsInterface_UnspecifiedObject
  img_width: string
  style?: TsInterface_UnspecifiedObject
  text: string
  text_type: 'img'
}

// Table
interface TsInterface_EmailTemplateElement_table_looped {
  element_type: 'table'
  looped_data_object_key: string
  table_columns: { [$columnKey: string]: TsInterface_EmailTemplateElement_table_looped_column }
  table_style?: TsInterface_UnspecifiedObject
  table_type: 'looped_data'
  tr_header_style?: TsInterface_UnspecifiedObject
  tr_row_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_EmailTemplateElement_table_looped_column {
  column_header: string
  data_object_prop_key: string
  td_style?: TsInterface_UnspecifiedObject
  th_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_EmailTemplateElement_table_static {
  element_type: 'table'
  looped_data_object_key: string
  table_rows: TsInterface_EmailTemplateElement_table_static_row[]
  table_style?: TsInterface_UnspecifiedObject
  table_type: 'static_data'
  tr_header_style?: TsInterface_UnspecifiedObject
  tr_row_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_EmailTemplateElement_table_static_row {
  table_cells: TsInterface_EmailTemplateElement_table_static_cell[]
}

interface TsInterface_EmailTemplateElement_table_static_cell {
  td_text: string
  td_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_EmailTemplateElement_image_static_src {
  alt: string
  element_type: 'img'
  height: string
  src: string
  src_type: 'static'
  style?: TsInterface_UnspecifiedObject
  width: string
}

interface TsInterface_EmailTemplateElement_image_dynamic_src {
  alt: string
  element_type: 'img'
  height: string
  src_type: 'dynamic'
  src_url_data_object_key: string
  src_url_data_object_prop_key: string
  style?: TsInterface_UnspecifiedObject
  width: string
}

export interface TsInterface_EmailTemplateObject {
  [$elementKey: string]:
    | TsInterface_EmailTemplateElement_a_dynamic_url
    | TsInterface_EmailTemplateElement_a_img_dynamic_url
    | TsInterface_EmailTemplateElement_a_img_static
    | TsInterface_EmailTemplateElement_a_static
    | TsInterface_EmailTemplateElement_div
    | TsInterface_EmailTemplateElement_div_conditional
    | TsInterface_EmailTemplateElement_h1_dynamic
    | TsInterface_EmailTemplateElement_h1_static
    | TsInterface_EmailTemplateElement_h2_dynamic
    | TsInterface_EmailTemplateElement_h2_static
    | TsInterface_EmailTemplateElement_h3_dynamic
    | TsInterface_EmailTemplateElement_h3_static
    | TsInterface_EmailTemplateElement_h4_dynamic
    | TsInterface_EmailTemplateElement_h4_static
    | TsInterface_EmailTemplateElement_h5_dynamic
    | TsInterface_EmailTemplateElement_h5_static
    | TsInterface_EmailTemplateElement_h6_dynamic
    | TsInterface_EmailTemplateElement_h6_static
    | TsInterface_EmailTemplateElement_image_dynamic_src
    | TsInterface_EmailTemplateElement_image_static_src
    | TsInterface_EmailTemplateElement_p_dynamic
    | TsInterface_EmailTemplateElement_p_static
    | TsInterface_EmailTemplateElement_table_looped
    | TsInterface_EmailTemplateElement_table_static
}

// TODO: Conditional Display of divs based on data

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const generateStyleString = (styleObject: TsInterface_UnspecifiedObject): string => {
  let styleString = ''
  for (let loopStyleKey in styleObject) {
    styleString += `${loopStyleKey}: ${styleObject[loopStyleKey]};`
  }
  return styleString
}

///////////////////////////////
// Exports
///////////////////////////////

export const exampleHtmlEmailDataObject: TsInterface_UnspecifiedObject = {
  links: {
    google_test: 'https://www.google.com/?test=1',
    image_test: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
  },
  rootObject: {
    title: 'THIS IS FROM AN OBJECT',
    description: 'This is a description from an object',
    error_count: 2,
    warning_count: 0,
  },
  objectsToLoopThrough: {
    object1: {
      number: 1,
      title: 'Title 1',
      description: 'Description 1',
    },
    object2: {
      number: 2,
      title: 'Title 2',
      description: 'Description 2',
    },
    object3: {
      number: 3,
      title: 'Title 3',
      description: 'Description 3',
    },
  },
}

export const exampleHtmlEmailTemplateObject: TsInterface_EmailTemplateObject = {
  DIV1: {
    element_type: 'div',
    style: {
      'background': '#F1F1F1',
      'font-size': '20px',
      'width': '100%',
      'min-height': '100px',
      'padding': '16px',
      'text-align': 'left',
    },
    contents: {
      CARD_DIV: {
        element_type: 'div',
        style: {
          'background': '#FFFFFF',
          'color': 'rgba(0, 0, 0, 0.87)',
          'border-radius': '5px',
          'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
          'margin-bottom': '16px',
        },
        contents: {
          HEADER_DIV: {
            element_type: 'div',
            style: {
              padding: '8px',
            },
            contents: {
              TITLE1: {
                element_type: 'h1',
                text_type: 'static',
                text: 'Static Table Header',
                style: {
                  'font-size': '24px',
                  'font-weight': 'bold',
                  'color': 'rgba(0, 0, 0, 0.87)',
                },
              },
            },
          },
          DIVIDER_DIV: {
            element_type: 'div',
            style: {
              'background-color': 'rgb(200, 200, 200, 0.5)',
              'width': '100%',
              'height': '1px',
              'margin-bottom': '4px',
            },
          },
          TABLE_DIV: {
            element_type: 'table',
            table_type: 'looped_data',
            looped_data_object_key: 'objectsToLoopThrough',
            table_style: {
              'width': '100%',
              'color': '#000',
              'font-size': '15px',
            },
            table_columns: {
              NUMBER_COL: {
                data_object_prop_key: 'number',
                column_header: 'Number',
                th_style: {
                  padding: '2px',
                },
                td_style: {
                  background: '#EA2027',
                  color: '#FFFFFF',
                  padding: '2px',
                },
              },
              TITLE_COL: {
                data_object_prop_key: 'title',
                column_header: 'Title',
                th_style: {
                  padding: '2px',
                },
                td_style: {
                  padding: '2px',
                },
              },
              DESCRIPTION_COL: {
                data_object_prop_key: 'description',
                column_header: 'Description',
                th_style: {
                  padding: '2px',
                },
                td_style: {
                  padding: '2px',
                },
              },
            },
          },
        },
      },

      CHIP1_ERROR_DIV: {
        element_type: 'div_conditional',
        condition_data_object_key: 'rootObject',
        condition_data_object_prop_key: 'error_count',
        condition_operator: '>',
        condition_value: 0,
        style: {
          'background': '#EA2027',
          'font-size': '20px',
          'padding': '4px',
          'border-radius': '20px',
          'text-align': 'left',
          'width': 'auto',
          'display': 'inline-block',
          'padding-left': '8px',
          'padding-right': '8px',
          'margin-right': '8px',
        },
        contents: {
          ERROR_NUMBER: {
            element_type: 'h1',
            text_type: 'dynamic',
            data_object_key: 'rootObject',
            data_object_prop_key: 'error_count',
            style: {
              'display': 'inline-block',
              'margin-right': '4px',
              'font-weight': 'bold',
            },
          },
          ERROR_TEXT: {
            element_type: 'h1',
            text_type: 'static',
            text: 'Errors',
            style: {
              'display': 'inline-block',
              'font-weight': 'bold',
            },
          },
        },
      },
      CHIP1_NO_ERROR_DIV: {
        element_type: 'div_conditional',
        condition_data_object_key: 'rootObject',
        condition_data_object_prop_key: 'error_count',
        condition_operator: '==',
        condition_value: 0,
        style: {
          'background': '#009432',
          'font-size': '20px',
          'padding': '4px',
          'border-radius': '20px',
          'text-align': 'left',
          'width': 'auto',
          'display': 'inline-block',
          'padding-left': '8px',
          'padding-right': '8px',
          'margin-right': '8px',
        },
        contents: {
          ERROR_TEXT: {
            element_type: 'h1',
            text_type: 'static',
            text: 'No Errors',
            style: {
              'display': 'inline-block',
              'font-weight': 'bold',
            },
          },
        },
      },
      CHIP2_WARNING_DIV: {
        element_type: 'div_conditional',
        condition_data_object_key: 'rootObject',
        condition_data_object_prop_key: 'warning_count',
        condition_operator: '>',
        condition_value: 0,
        style: {
          'background': '#F79F1F',
          'font-size': '20px',
          'padding': '4px',
          'border-radius': '20px',
          'text-align': 'left',
          'width': 'auto',
          'display': 'inline-block',
          'padding-left': '8px',
          'padding-right': '8px',
          'margin-right': '8px',
        },
        contents: {
          WARNING_NUMBER: {
            element_type: 'h1',
            text_type: 'dynamic',
            data_object_key: 'rootObject',
            data_object_prop_key: 'warning_count',
            style: {
              'display': 'inline-block',
              'margin-right': '4px',
              'font-weight': 'bold',
            },
          },
          ERROR_TEXT: {
            element_type: 'h1',
            text_type: 'static',
            text: 'Warnings',
            style: {
              'display': 'inline-block',
              'font-weight': 'bold',
            },
          },
        },
      },
      CHIP2_NO_WARNING_DIV: {
        element_type: 'div_conditional',
        condition_data_object_key: 'rootObject',
        condition_data_object_prop_key: 'warning_count',
        condition_operator: '==',
        condition_value: 0,
        style: {
          'background': '#009432',
          'font-size': '20px',
          'padding': '4px',
          'border-radius': '20px',
          'text-align': 'left',
          'width': 'auto',
          'display': 'inline-block',
          'padding-left': '8px',
          'padding-right': '8px',
          'margin-right': '8px',
        },
        contents: {
          ERROR_TEXT: {
            element_type: 'h1',
            text_type: 'static',
            text: 'No Warnings',
            style: {
              'display': 'inline-block',
              'font-weight': 'bold',
            },
          },
        },
      },
      LINK_DIV1: {
        element_type: 'div',
        contents: {
          LINK: {
            element_type: 'a',
            text: 'click here',
            text_type: 'static',
            href_type: 'dynamic',
            href_url_data_object_key: 'links',
            href_url_data_object_prop_key: 'google_test',
            style: {
              'color': 'blue',
              'text-decoration': 'underline',
              'cursor': 'pointer',
            },
          },
        },
      },
      LINK_DIV2: {
        element_type: 'div',
        contents: {
          LINK: {
            element_type: 'a',
            text: 'click here',
            text_type: 'img',
            href_type: 'dynamic',
            href_url_data_object_key: 'links',
            href_url_data_object_prop_key: 'google_test',
            img_src: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
            img_alt: 'logo.png',
            img_width: '00px',
            img_height: 'auto',
            style: {
              'color': 'blue',
              'text-decoration': 'underline',
              'cursor': 'pointer',
            },
          },
        },
      },
      LOGO_DIV: {
        element_type: 'div',
        contents: {
          LOGO_IMG: {
            element_type: 'img',
            src_type: 'static',
            src: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
            alt: 'logo.png',
            width: '100px',
            height: 'auto',
          },
          LOGO_IMG2: {
            element_type: 'img',
            src_type: 'dynamic',
            src_url_data_object_key: 'links',
            src_url_data_object_prop_key: 'image_test',
            alt: 'logo.png',
            width: '300px',
            height: 'auto',
          },
        },
        style: {
          'text-align': 'center',
        },
      },
    },
  },
}

export const generateHtmlForEmailFromTemplateObject = (templateObject: TsInterface_EmailTemplateObject, dataObjects: TsInterface_UnspecifiedObject): string => {
  let emailString = ''
  for (let loopItemKey in templateObject) {
    let loopElementObject = getProp(templateObject, loopItemKey, {})
    // TODO: Add more cases
    // a, img, ul, ol, li etc.

    // Switch through the element types
    switch (loopElementObject.element_type) {
      case 'div':
        emailString += `<div style='${generateStyleString(loopElementObject.style)}'>`
        if (getProp(loopElementObject, 'contents', null) != null) {
          emailString += generateHtmlForEmailFromTemplateObject(loopElementObject.contents, dataObjects)
        }
        emailString += '</div>'
        break
      case 'div_conditional':
        let displayConditionalDiv = false
        let conditionValue = getProp(loopElementObject, 'condition_value', null)
        if (
          loopElementObject.condition_operator != null &&
          loopElementObject.condition_data_object_key != null &&
          loopElementObject.condition_data_object_prop_key != null &&
          getProp(dataObjects, loopElementObject.condition_data_object_key, null) != null
        ) {
          let dataObjectPropValue = getProp(dataObjects[loopElementObject.condition_data_object_key], loopElementObject.condition_data_object_prop_key, null)
          switch (loopElementObject.condition_operator) {
            case '<':
              if (dataObjectPropValue < conditionValue) {
                displayConditionalDiv = true
              }
              break
            case '<=':
              if (dataObjectPropValue <= conditionValue) {
                displayConditionalDiv = true
              }
              break
            case '==':
              if (dataObjectPropValue == conditionValue) {
                displayConditionalDiv = true
              }
              break
            case '!=':
              if (dataObjectPropValue != conditionValue) {
                displayConditionalDiv = true
              }
              break
            case '>':
              if (dataObjectPropValue > conditionValue) {
                displayConditionalDiv = true
              }
              break
            case '>=':
              if (dataObjectPropValue >= conditionValue) {
                displayConditionalDiv = true
              }
              break
          }
        }
        if (displayConditionalDiv === true) {
          emailString += `<div style='${generateStyleString(loopElementObject.style)}'>`
          if (getProp(loopElementObject, 'contents', null) != null) {
            emailString += generateHtmlForEmailFromTemplateObject(loopElementObject.contents, dataObjects)
          }
          emailString += '</div>'
        }
        break
      case 'h1':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<h1 style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</h1>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<h1 style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</h1>`
            break
        }
        break
      case 'h2':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<h2 style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</h2>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<h2 style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</h2>`
            break
        }
        break
      case 'h3':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<h3 style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</h3>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<h3 style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</h3>`
            break
        }
        break
      case 'h4':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<h4 style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</h4>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<h4 style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</h4>`
            break
        }
        break
      case 'h5':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<h5 style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</h5>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<h5 style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</h5>`
            break
        }
        break
      case 'h6':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<h6 style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</h6>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<h6 style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</h6>`
            break
        }
        break
      case 'p':
        switch (loopElementObject.text_type) {
          case 'static':
            emailString += `<p style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</p>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.data_object_prop_key, '')
            emailString += `<p style='${generateStyleString(loopElementObject.style)}'>${dataObjectProp}</p>`
            break
        }
        break
      case 'a':
        switch (loopElementObject.text_type) {
          case 'static':
            switch (loopElementObject.href_type) {
              case 'static':
                emailString += `<a target='_blank' href='${loopElementObject.href_url}' style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</a>`
                break
              case 'dynamic':
                let dataObject = getProp(dataObjects, loopElementObject.href_url_data_object_key, {})
                let dataObjectProp = getProp(dataObject, loopElementObject.href_url_data_object_prop_key, '')
                emailString += `<a target='_blank' href='${dataObjectProp}' style='${generateStyleString(loopElementObject.style)}'>${loopElementObject.text}</a>`
            }
            break
          case 'img':
            switch (loopElementObject.href_type) {
              case 'static':
                emailString += `<a target='_blank' href='${loopElementObject.href_url}' style='${generateStyleString(loopElementObject.style)}'><img src='${loopElementObject.img_src}' alt='${loopElementObject.omg_alt}' height='${loopElementObject.img_height}' width='${loopElementObject.img_width}' style='${generateStyleString(loopElementObject.img_style)}'/></a>`
                break
              case 'dynamic':
                let dataObject = getProp(dataObjects, loopElementObject.href_url_data_object_key, {})
                let dataObjectProp = getProp(dataObject, loopElementObject.href_url_data_object_prop_key, '')
                emailString += `<a target='_blank' href='${dataObjectProp}' style='${generateStyleString(loopElementObject.style)}'><img src='${loopElementObject.img_src}' alt='${loopElementObject.omg_alt}' height='${loopElementObject.img_height}' width='${loopElementObject.img_width}' style='${generateStyleString(loopElementObject.img_style)}'/></a>`
            }
            break
        }
        break
      case 'table':
        switch (loopElementObject.table_type) {
          case 'looped_data':
            let loopedDataObject = getProp(dataObjects, loopElementObject.looped_data_object_key, {})
            emailString += `<table style='${generateStyleString(loopElementObject.table_style)}'>`
            emailString += `<tr style='${generateStyleString(loopElementObject.tr_header_style)}'>`
            for (let loopColumnKey in loopElementObject.table_columns) {
              let loopColumnObject = getProp(loopElementObject.table_columns, loopColumnKey, {})
              emailString += `<th style='${generateStyleString(loopColumnObject.th_style)}'>${loopColumnObject.column_header}</th>`
            }
            emailString += '</tr>'
            for (let loopRowKey in loopedDataObject) {
              let loopRowObject = getProp(loopedDataObject, loopRowKey, {})
              emailString += `<tr style='${generateStyleString(loopElementObject.tr_row_style)}'>`
              for (let loopColumnKey in loopElementObject.table_columns) {
                let loopColumnObject = getProp(loopElementObject.table_columns, loopColumnKey, {})
                let loopColumnData = getProp(loopRowObject, loopColumnObject.data_object_prop_key, '')
                emailString += `<td style='${generateStyleString(loopColumnObject.td_style)}'>${loopColumnData}</td>`
              }
              emailString += '</tr>'
            }
            emailString += '</table>'
            break
          case 'static_data':
            emailString += `<table style='${generateStyleString(loopElementObject.table_style)}'>`
            for (let loopRowIndex in loopElementObject.table_rows) {
              let loopRow = getProp(loopElementObject.table_rows, loopRowIndex, {})
              emailString += `<tr>`
              for (let loopCellIndex in loopRow) {
                let loopCell = getProp(loopRow, loopCellIndex, {})
                emailString += `<td style='${generateStyleString(loopCell.td_style)}'>${loopCell.td_text}</td>`
              }
              emailString += '</tr>'
            }
            emailString += '</table>'
            break
        }
        break
      case 'img':
        switch (loopElementObject.src_type) {
          case 'static':
            emailString += `<img src='${loopElementObject.src}' alt='${loopElementObject.alt}' height='${loopElementObject.height}' width='${loopElementObject.width}' style='${generateStyleString(loopElementObject.style)}'/>`
            break
          case 'dynamic':
            let dataObject = getProp(dataObjects, loopElementObject.src_url_data_object_key, {})
            let dataObjectProp = getProp(dataObject, loopElementObject.src_url_data_object_prop_key, '')
            emailString += `<img src='${dataObjectProp}' alt='${loopElementObject.alt}' height='${loopElementObject.height}' width='${loopElementObject.width}' style='${generateStyleString(loopElementObject.style)}'/>`
            break
        }
        break
    }
  }

  return emailString
}

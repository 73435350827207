///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Client
export const DatabaseRef_DataBuckets_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main')
}

export const DatabaseRef_DataBuckets_Document = (clientKey: string, qrCodeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', qrCodeKey)
}

export const DatabaseRef_ActiveDataBuckets_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_DataBucketsTiedToReconciliation_Query = (clientKey: string, reconciliationKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_reconciliation_keys.' + reconciliationKey, comparator: '==', value: true }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_ActiveDataBucketsWithSpecificClass_Query = (clientKey: string, classKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'associated_class_key', comparator: '==', value: classKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_DataBucket_ImportSessions_Collection = (clientKey: string, dataBucketKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', dataBucketKey, 'import_sessions')
}

export const DatabaseRef_DataBucket_ImportSession_Document = (
  clientKey: string,
  dataBucketKey: string,
  sessionKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', dataBucketKey, 'import_sessions', sessionKey)
}

export const DatabaseRef_DataBucket_CalculatedFields_Collection = (clientKey: string, dataBucketKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', dataBucketKey, 'calculated_fields')
}

export const DatabaseRef_DataBucket_CalculatedField_Document = (
  clientKey: string,
  dataBucketKey: string,
  calculatedFieldKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', dataBucketKey, 'calculated_fields', calculatedFieldKey)
}

export const DatabaseRef_DataBucketsActiveCalculations_Query = (clientKey: string, bucketKey: string): Query<unknown> => {
  let orderByArray: TsInterface_OrderByArray = []
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject: TsInterface_QueryCursorsObject = {}
  let limit: number | null = null
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', bucketKey, 'calculated_fields'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_DataBucketCalculationsDateRanges_Collection = (
  clientKey: string,
  bucketKey: string,
  calculationKey: string,
): CollectionReference<DocumentData> => {
  return collection(
    getFirestore(),
    'clients',
    clientKey,
    'architecture',
    'data_buckets',
    'main',
    bucketKey,
    'calculated_fields',
    calculationKey,
    'date_range_cutoffs',
  )
}

export const DatabaseRef_DataBuckets_InboundEmailMapping_Document = (clientKey: string, qrCodeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'data_buckets', 'main', qrCodeKey, 'inbound_emails', 'field_mapping')
}

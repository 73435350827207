///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

const applicationName = 'Orchestrate'
const stateJurisdiction = 'Utah'
const companyName = 'Fleet Logistics Pro, LLC'

///////////////////////////////
// Exports
///////////////////////////////

export const rJSX_LegalDocument = (legalDocContent: TsInterface_UnspecifiedObject[]): JSX.Element => {
  let legalDocJSX: JSX.Element = (
    <Box>
      {legalDocContent.map((section: TsInterface_UnspecifiedObject, index: number) => (
        <Box
          className="tw-mb-4"
          key={index}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold' }}
          >
            {section.subheader}
          </Typography>
          {section.paragraphs.map((paragraph: string, index: number) => (
            <Typography
              key={index}
              variant="body1"
              className="tw-ml-6 tw-mb-2"
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
      ))}
    </Box>
  )
  return legalDocJSX
}

export const returnPrivacyPolicyText = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 11 May 2024
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2024-05-11': [
      {
        subheader: `Privacy Policy`,
        paragraphs: [
          `This Privacy Policy ("Policy") explains how ${companyName} ("we" or "us") collects, uses, and discloses your information when you use ${applicationName} ("the Service"). By accessing or using the Service, you agree to the terms of this Policy.`,
        ],
      },
      {
        subheader: `1. Information We Collect`,
        paragraphs: [
          `(a) Personal Information: We may collect personal information that you provide to us, such as your name, email address, and other contact information.`,
          `(b) Usage Data: We may automatically collect information about how you access and use the Service, including your IP address, browser type, device identifiers, and other usage data.`,
        ],
      },
      {
        subheader: `2. Use of Information`,
        paragraphs: [
          `(a) We may use the information we collect to provide and maintain the Service, communicate with you, and improve the quality of our service.`,
          `(b) We may also use your information to send you promotional communications or other marketing materials, but you may opt out of receiving these communications at any time.`,
        ],
      },
      {
        subheader: `3. Sharing of Information`,
        paragraphs: [
          `(a) We may share your information with third-party service providers who help us operate the Service or assist us in providing certain features.`,
          `(b) We may also share your information in response to a legal request or to protect our rights, property, or safety, or the rights, property, or safety of others.`,
        ],
      },
      {
        subheader: `4. Cookies and Similar Technologies`,
        paragraphs: [
          `We may use cookies and similar tracking technologies to collect information about your use of the Service and to provide certain features.`,
        ],
      },
      {
        subheader: `5. Data Retention`,
        paragraphs: [
          `We will retain your information for as long as necessary to fulfill the purposes outlined in this Policy unless a longer retention period is required or permitted by law.`,
        ],
      },
      {
        subheader: `6. Security`,
        paragraphs: [
          `We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure.`,
        ],
      },
      {
        subheader: `7. Children's Privacy`,
        paragraphs: [
          `The Service is not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete that information as quickly as possible.`,
        ],
      },
      {
        subheader: `8. Changes to this Policy`,
        paragraphs: [
          `We reserve the right to modify or replace this Policy at any time. If we make material changes to this Policy, we will notify you by email or by posting a notice on the Service.`,
        ],
      },
      {
        subheader: `9. Contact Us`,
        paragraphs: [`If you have any questions about this Policy, please contact our support team or your account representative.`],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnTermsOfServiceText = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 11 May 2024
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2024-05-11': [
      {
        subheader: `Terms of Service`,
        paragraphs: [
          `These Terms of Service ("Terms") govern your access to and use of ${applicationName} ("the Service"), provided by ${companyName} ("we" or "us"). Please read these Terms carefully before using the Service.`,
        ],
      },
      {
        subheader: `1. Acceptance of Terms`,
        paragraphs: [
          `By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to all of these Terms, you may not access or use the Service.`,
        ],
      },
      {
        subheader: `2. Use of the Service`,
        paragraphs: [
          `(a) You must be at least 18 years old to use the Service.`,
          `(b) You agree to use the Service only for lawful purposes and in compliance with all applicable laws and regulations.`,
          `(c) You are solely responsible for any content you submit or transmit through the Service.`,
        ],
      },
      {
        subheader: `3. User Accounts`,
        paragraphs: [
          `(a) You may be required to create an account to access certain features of the Service. You agree to provide accurate and complete information when creating your account.`,
          `(b) You are responsible for maintaining the security of your account and password. You agree to notify us immediately of any unauthorized use of your account.`,
        ],
      },
      {
        subheader: `4. Intellectual Property`,
        paragraphs: [
          `(a) All content and materials available through the Service are protected by copyright and other intellectual property laws.`,
          `(b) You may not modify, adapt, reproduce, distribute, or create derivative works based on any content or materials available through the Service without our prior written consent.`,
        ],
      },
      {
        subheader: `5. Third-Party Links`,
        paragraphs: [
          `The Service may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the content or practices of any third-party websites or services.`,
        ],
      },
      {
        subheader: `6. Termination`,
        paragraphs: [
          `We may terminate or suspend access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.`,
        ],
      },
      {
        subheader: `7. Disclaimer of Warranties`,
        paragraphs: [
          `THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.`,
        ],
      },
      {
        subheader: `8. Limitation of Liability`,
        paragraphs: [
          `IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE.`,
        ],
      },
      {
        subheader: `9. Governing Law`,
        paragraphs: [
          `These Terms shall be governed by and construed in accordance with the laws of the state of ${stateJurisdiction}, without regard to its conflict of law provisions.`,
        ],
      },
      {
        subheader: `10. Changes to Terms`,
        paragraphs: [
          `We reserve the right to modify or replace these Terms at any time. If we make material changes to these Terms, we will notify you by email or by posting a notice on the Service.`,
        ],
      },
      {
        subheader: `11. Contact Us`,
        paragraphs: [`If you have any questions about these Terms, please contact our support team or your account representative.`],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnCurrentLegalVersionNumbers = () => {
  let currentVersionNumbers = {
    privacyPolicy: '2024-05-11',
    termsOfService: '2024-05-11',
  }
  return currentVersionNumbers
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Checkbox, Divider, FormControl, MenuItem, Select, Switch, TextField, Typography } from '@mui/material/'
import { analysisProcedureOptions } from 'app/models/analysis/analysis_functions'
import { formTypeOptions } from 'app/models/forms/form_types'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClassFields_Collection, DatabaseRef_Class_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_AnalysisTemplates_ActiveAnalysisProcedures_Query,
  DatabaseRef_AnalysisTemplates_AnalysisProcedures_Collection,
  DatabaseRef_AnalysisTemplates_AnalysisProcedures_Document,
  DatabaseRef_AnalysisTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/data_management/analysis_templates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseAddDocument, DatabaseGetLiveCollection, DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataAnalysisTemplateViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_analysisTemplate, us_setAnalysisTemplate] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisTemplateAnalysisProcedures, us_setAnalysisTemplateAnalysisProcedures] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedForm, us_setAssociatedForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedFormFields, us_setAssociatedFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedAnalysisProcedure, us_setSelectedAnalysisProcedure] = useState<string>('')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Data Analysis Template', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAnalysisTemplate(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_AnalysisTemplates_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedForm(newData)
      ur_forceRerender()
    }
    if (us_analysisTemplate != null && us_analysisTemplate.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Class_Document(res_GCK.clientKey, us_analysisTemplate.associated_class_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_analysisTemplate, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeFormFields: TsInterface_UnspecifiedObject = {}
      for (let loopFieldKey in newData) {
        let loopField = newData[loopFieldKey]
        if (loopField.status === 'active') {
          activeFormFields[loopFieldKey] = loopField
        }
      }
      us_setAssociatedFormFields(activeFormFields)
      ur_forceRerender()
    }
    if (us_analysisTemplate != null && us_analysisTemplate.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_analysisTemplate.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_analysisTemplate, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAnalysisTemplateAnalysisProcedures(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_AnalysisTemplates_ActiveAnalysisProcedures_Query(res_GCK.clientKey, itemKey),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_analysisTemplate != null && us_analysisTemplate.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Data Analysis Template')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_analysisTemplate.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Data Analysis Template') as JSX.Element
    }
    return pageHeader
  }

  const createAnalysisProcedure = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Analysis Procedure Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    name: formSubmittedData.name,
                    timestamp_created: new Date(),
                    status: 'active',
                    active: true,
                  }
                  DatabaseAddDocument(DatabaseRef_AnalysisTemplates_AnalysisProcedures_Collection(res_GCK.clientKey, itemKey), updateObject, true)
                    .then((res_DAD) => {
                      us_setSelectedAnalysisProcedure(res_DAD.key)
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Analysis Procedure')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editAnalysisProcedureName = (analysisProcedure: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: analysisProcedure,
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Analysis Procedure Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = {
                    name: formSubmittedData.name,
                    timestamp_created: new Date(),
                    status: 'active',
                  }
                  DatabaseSetMergeDocument(
                    DatabaseRef_AnalysisTemplates_AnalysisProcedures_Document(res_GCK.clientKey, itemKey, analysisProcedure.key),
                    updateObject,
                  )
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Analysis Procedure')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const updateAnalysisProcedureData = (analysisProcedure: TsInterface_UnspecifiedObject, newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_AnalysisTemplates_AnalysisProcedures_Document(res_GCK.clientKey, itemKey, analysisProcedure.key), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  // let testAllData = {}
  // let testRowData = {
  // 	TEST1: "2",
  // 	TEST2: "3",
  // 	TEST3: "1"
  // }
  // let testAnalysisProcedure = {
  // 	"condition": "not_between_two_numbers_exclusive",
  // 	"field1": "TEST1",
  // 	// "field1_option": null,
  // 	"field2": "TEST2",
  // 	// "field2_hardcode": null,
  // 	// "field2_option": null,
  // 	"field3": "TEST3",
  // 	// "field3_hardcode": null,
  // }

  // if(
  // 	testAnalysisProcedure != null &&
  // 	testAnalysisProcedure["condition"] != null &&
  // 	analysisProcedureOptions != null &&
  // 	analysisProcedureOptions[ testAnalysisProcedure["condition"] ] != null &&
  // 	analysisProcedureOptions[ testAnalysisProcedure["condition"] ]["evaluation"] != null
  // ){
  // 	console.log( "<><><><><>" )
  // 	console.log( analysisProcedureOptions[ testAnalysisProcedure["condition"] ]["evaluation"]( testAllData, testRowData, testAnalysisProcedure ) )
  // } else {
  // 	console.error("No condition or evaluation")
  // }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          un_routerNavigation(ApplicationPages.DataAnalysisListPage.url())
        }}
        startIcon={<Icon icon="angle-left" />}
      >
        {rLIB('Back to all Analyses')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewAnalysisProcedure = (): JSX.Element => {
    let buttonJSX = (
      <Button
        size="small"
        variant="contained"
        color="success"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          createAnalysisProcedure()
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('Add Analysis Procedure')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AnalysisProcedure_Field1 = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let sx: TsInterface_UnspecifiedObject = {}
    if (getProp(analysisProcedure, 'field1', null) == null || getProp(analysisProcedure, 'field1', '') === '') {
      sx = {
        minWidth: '130px',
        marginRight: '8px',
        marginBottom: '8px',
        backgroundColor: themeVariables.warning_main,
      }
    } else {
      sx = {
        minWidth: '130px',
        marginRight: '8px',
        marginBottom: '8px',
      }
    }
    let inputJSX = <></>
    inputJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={sx}
      >
        <Select
          onChange={(event, value) => {
            updateAnalysisProcedureData(analysisProcedure, {
              field1: event.target.value,
              field1_option: null,
              condition: null,
              field2: null,
              field2_option: null,
              field2_hardcode: null,
              field3: null,
              field3_hardcode: null,
            })
          }}
          value={analysisProcedure.field1 || ''}
          disabled={objectToArray(us_associatedFormFields).length === 0}
        >
          {objectToArray(us_associatedFormFields)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Field1Option = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let inputJSX = <></>
    if (
      analysisProcedure != null &&
      analysisProcedure.field1 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field1] != null &&
      us_associatedFormFields[analysisProcedure.field1]['form_type'] != null &&
      us_associatedFormFields[analysisProcedure.field1]['options'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types']['option_boolean'] ===
        'full'
    ) {
      let sx: TsInterface_UnspecifiedObject = {}
      if (getProp(analysisProcedure, 'field1_option', null) == null || getProp(analysisProcedure, 'field1_option', '') === '') {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: themeVariables.warning_main,
        }
      } else {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
        }
      }
      // JSX
      inputJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={sx}
        >
          <Select
            onChange={(event, value) => {
              updateAnalysisProcedureData(analysisProcedure, {
                // field1: null,
                field1_option: event.target.value,
                // condition: null,
                // field2: null,
                // field2_option: null,
                // field2_hardcode: null,
                // field3: null,
                // field3_hardcode: null,
              })
            }}
            value={analysisProcedure.field1_option || ''}
            disabled={objectToArray(us_associatedFormFields[analysisProcedure.field1]['options']).length === 0}
          >
            {objectToArray(us_associatedFormFields[analysisProcedure.field1]['options'])
              .sort(dynamicSort('order', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['value']}
                >
                  {option['value']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Condition = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let allowedDataTypes: TsInterface_UnspecifiedObject = {}
    if (
      analysisProcedure != null &&
      analysisProcedure.field1 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field1] != null &&
      us_associatedFormFields[analysisProcedure.field1]['form_type'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types'] != null
    ) {
      allowedDataTypes = formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types']
    }
    let availableAnalysisProcedureOptions: TsInterface_UnspecifiedObject = {}
    for (let loopAnalysisProcedureOptionKey in analysisProcedureOptions) {
      let loopAnalysisProcedureOption = analysisProcedureOptions[loopAnalysisProcedureOptionKey]
      let matchingDataTypes = false
      for (let loopAllowedDataTypeKey in loopAnalysisProcedureOption.allowed_data_types) {
        if (allowedDataTypes[loopAllowedDataTypeKey] === 'full') {
          matchingDataTypes = true
        }
      }
      if (matchingDataTypes === true) {
        availableAnalysisProcedureOptions[loopAnalysisProcedureOptionKey] = loopAnalysisProcedureOption
      }
    }
    let sx: TsInterface_UnspecifiedObject = {}
    if (getProp(analysisProcedure, 'condition', null) == null || getProp(analysisProcedure, 'condition', '') === '') {
      sx = {
        minWidth: '130px',
        marginRight: '8px',
        marginBottom: '8px',
        backgroundColor: themeVariables.warning_main,
      }
    } else {
      sx = {
        minWidth: '130px',
        marginRight: '8px',
        marginBottom: '8px',
      }
    }
    let inputJSX = <></>
    inputJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={sx}
      >
        <Select
          onChange={(event, value) => {
            updateAnalysisProcedureData(analysisProcedure, {
              // field1: null,
              // field1_option: null,
              condition: event.target.value,
              field2: null,
              field2_option: null,
              field2_hardcode: null,
              field3: null,
              field3_hardcode: null,
            })
          }}
          value={analysisProcedure.condition || ''}
          disabled={objectToArray(availableAnalysisProcedureOptions).length === 0}
        >
          {objectToArray(availableAnalysisProcedureOptions).map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
            >
              {option['name']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Field2 = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let allowedDataTypes: TsInterface_UnspecifiedObject = {}
    if (
      analysisProcedure != null &&
      analysisProcedure.field1 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field1] != null &&
      us_associatedFormFields[analysisProcedure.field1]['form_type'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types'] != null
    ) {
      allowedDataTypes = formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types']
    }
    // Determine which form fields are compatible with the selected field 1
    let matchingFormFields: TsInterface_UnspecifiedObject = {}
    for (let loopFornFieldKey in us_associatedFormFields) {
      let loopFormField = us_associatedFormFields[loopFornFieldKey]
      let matchingDataTypes = false
      if (
        loopFormField != null &&
        loopFormField['form_type'] != null &&
        formTypeOptions != null &&
        formTypeOptions[loopFormField['form_type']] != null &&
        formTypeOptions[loopFormField['form_type']]['analysis_procedure_data'] != null &&
        formTypeOptions[loopFormField['form_type']]['analysis_procedure_data']['allowed_data_types'] != null
      ) {
        let loopAllowedDataTypes = formTypeOptions[loopFormField['form_type']]['analysis_procedure_data']['allowed_data_types']
        if (allowedDataTypes['string'] === 'full' && loopAllowedDataTypes['string'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['number'] === 'full' && loopAllowedDataTypes['number'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['boolean'] === 'full' && (loopAllowedDataTypes['boolean'] === 'full' || loopAllowedDataTypes['option_boolean'] === 'full')) {
          matchingDataTypes = true
        }
        if (allowedDataTypes['date'] === 'full' && loopAllowedDataTypes['date'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['time'] === 'full' && loopAllowedDataTypes['time'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['datetime'] === 'full' && loopAllowedDataTypes['datetime'] === 'full') {
          matchingDataTypes = true
        }
        if (
          allowedDataTypes['option_boolean'] === 'full' &&
          (loopAllowedDataTypes['boolean'] === 'full' || loopAllowedDataTypes['option_boolean'] === 'full')
        ) {
          matchingDataTypes = true
        }
      }
      if (matchingDataTypes === true) {
        matchingFormFields[loopFornFieldKey] = loopFormField
      }
    }
    let matchingFormFieldsArray: TsInterface_UnspecifiedObject[] = objectToArray(matchingFormFields).sort(dynamicSort('name', null))
    if (
      analysisProcedure != null &&
      analysisProcedure.field1 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field1] != null &&
      us_associatedFormFields[analysisProcedure.field1]['form_type'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allow_hardcode_comparison'] === true
    ) {
      matchingFormFieldsArray.unshift({ key: 'HARDCODE', name: rLIB('Hardcoded Value') })
    }
    // JSX
    let inputJSX = <></>
    if (
      analysisProcedure != null &&
      analysisProcedure.condition != null &&
      analysisProcedureOptions != null &&
      analysisProcedureOptions[analysisProcedure.condition] != null &&
      analysisProcedureOptions[analysisProcedure.condition]['field2_visible'] === true
    ) {
      let sx: TsInterface_UnspecifiedObject = {}
      if (getProp(analysisProcedure, 'field2', null) == null || getProp(analysisProcedure, 'field2', '') === '') {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: themeVariables.warning_main,
        }
      } else {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
        }
      }
      inputJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={sx}
        >
          <Select
            onChange={(event, value) => {
              updateAnalysisProcedureData(analysisProcedure, {
                // field1: null,
                // field1_option: null,
                // condition: null,
                field2: event.target.value,
                field2_option: null,
                field2_hardcode: null,
                // field3: null,
                // field3_hardcode: null,
              })
            }}
            value={analysisProcedure.field2 || ''}
          >
            {/* <MenuItem value={ "HARDCODE" }>{ rLIB("Hardcoded Value") }</MenuItem> */}
            {matchingFormFieldsArray.map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Field2Option = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let inputJSX = <></>
    if (
      analysisProcedure != null &&
      analysisProcedure.field2 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field2] != null &&
      us_associatedFormFields[analysisProcedure.field2]['form_type'] != null &&
      us_associatedFormFields[analysisProcedure.field2]['options'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field2]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field2]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field2]['form_type']]['analysis_procedure_data']['allowed_data_types'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field2]['form_type']]['analysis_procedure_data']['allowed_data_types']['option_boolean'] ===
        'full'
    ) {
      let sx: TsInterface_UnspecifiedObject = {}
      if (getProp(analysisProcedure, 'field2_option', null) == null || getProp(analysisProcedure, 'field2_option', '') === '') {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: themeVariables.warning_main,
        }
      } else {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
        }
      }
      // JSX
      inputJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={sx}
        >
          <Select
            onChange={(event, value) => {
              updateAnalysisProcedureData(analysisProcedure, {
                // field1: null,
                // field1_option: null,
                // condition: null,
                // field2: null,
                field2_option: event.target.value,
                // field2_hardcode: null,
                // field3: null,
                // field3_hardcode: null,
              })
            }}
            value={analysisProcedure.field2_option || ''}
            disabled={objectToArray(us_associatedFormFields[analysisProcedure.field2]['options']).length === 0}
          >
            {objectToArray(us_associatedFormFields[analysisProcedure.field2]['options'])
              .sort(dynamicSort('order', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['value']}
                >
                  {option['value']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Field2Hardcode = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let inputJSX = <></>
    if (
      analysisProcedure != null &&
      analysisProcedure.condition != null &&
      analysisProcedureOptions != null &&
      analysisProcedureOptions[analysisProcedure.condition] != null &&
      analysisProcedureOptions[analysisProcedure.condition]['field2_visible'] === true &&
      analysisProcedure.field2 === 'HARDCODE'
    ) {
      let sx: TsInterface_UnspecifiedObject = {}
      if (getProp(analysisProcedure, 'field2_hardcode', null) == null || getProp(analysisProcedure, 'field2_hardcode', '') === '') {
        sx = {
          minWidth: '230px',
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: themeVariables.warning_main,
        }
      } else {
        sx = {
          minWidth: '230px',
          marginRight: '8px',
          marginBottom: '8px',
        }
      }
      inputJSX = (
        <FormControl sx={sx}>
          <TextField
            className="bp_thin_text_input"
            sx={{ marginTop: '0px', marginBottom: '0px' }}
            color="primary"
            value={analysisProcedure.field2_hardcode || ''}
            margin="normal"
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateAnalysisProcedureData(analysisProcedure, {
                  // field1: null,
                  // field1_option: null,
                  // condition: null,
                  // field2: null,
                  // field2_option: null,
                  field2_hardcode: event.target.value,
                  // field3: null,
                  // field3_hardcode: null,
                })
              }
            }}
            onBlur={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateAnalysisProcedureData(analysisProcedure, {
                  // field1: null,
                  // field1_option: null,
                  // condition: null,
                  // field2: null,
                  // field2_option: null,
                  field2_hardcode: event.target.value,
                  // field3: null,
                  // field3_hardcode: null,
                })
              }
            }}
            variant="outlined"
          />
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Field3 = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let allowedDataTypes: TsInterface_UnspecifiedObject = {}
    if (
      analysisProcedure != null &&
      analysisProcedure.field1 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field1] != null &&
      us_associatedFormFields[analysisProcedure.field1]['form_type'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types'] != null
    ) {
      allowedDataTypes = formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allowed_data_types']
    }
    // Determine which form fields are compatible with the selected field 1
    let matchingFormFields: TsInterface_UnspecifiedObject = {}
    for (let loopFornFieldKey in us_associatedFormFields) {
      let loopFormField = us_associatedFormFields[loopFornFieldKey]
      let matchingDataTypes = false
      if (
        loopFormField != null &&
        loopFormField['form_type'] != null &&
        formTypeOptions != null &&
        formTypeOptions[loopFormField['form_type']] != null &&
        formTypeOptions[loopFormField['form_type']]['analysis_procedure_data'] != null &&
        formTypeOptions[loopFormField['form_type']]['analysis_procedure_data']['allowed_data_types'] != null
      ) {
        let loopAllowedDataTypes = formTypeOptions[loopFormField['form_type']]['analysis_procedure_data']['allowed_data_types']
        if (allowedDataTypes['string'] === 'full' && loopAllowedDataTypes['string'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['number'] === 'full' && loopAllowedDataTypes['number'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['boolean'] === 'full' && (loopAllowedDataTypes['boolean'] === 'full' || loopAllowedDataTypes['option_boolean'] === 'full')) {
          matchingDataTypes = true
        }
        if (allowedDataTypes['date'] === 'full' && loopAllowedDataTypes['date'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['time'] === 'full' && loopAllowedDataTypes['time'] === 'full') {
          matchingDataTypes = true
        }
        if (allowedDataTypes['datetime'] === 'full' && loopAllowedDataTypes['datetime'] === 'full') {
          matchingDataTypes = true
        }
        if (
          allowedDataTypes['option_boolean'] === 'full' &&
          (loopAllowedDataTypes['boolean'] === 'full' || loopAllowedDataTypes['option_boolean'] === 'full')
        ) {
          matchingDataTypes = true
        }
      }
      if (matchingDataTypes === true) {
        matchingFormFields[loopFornFieldKey] = loopFormField
      }
    }
    let matchingFormFieldsArray: TsInterface_UnspecifiedObject[] = objectToArray(matchingFormFields).sort(dynamicSort('name', null))
    if (
      analysisProcedure != null &&
      analysisProcedure.field1 != null &&
      us_associatedFormFields != null &&
      us_associatedFormFields[analysisProcedure.field1] != null &&
      us_associatedFormFields[analysisProcedure.field1]['form_type'] != null &&
      formTypeOptions != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data'] != null &&
      formTypeOptions[us_associatedFormFields[analysisProcedure.field1]['form_type']]['analysis_procedure_data']['allow_hardcode_comparison'] === true
    ) {
      matchingFormFieldsArray.unshift({ key: 'HARDCODE', name: rLIB('Hardcoded Value') })
    }
    // JSX
    let inputJSX = <></>
    if (
      analysisProcedure != null &&
      analysisProcedure.condition != null &&
      analysisProcedureOptions != null &&
      analysisProcedureOptions[analysisProcedure.condition] != null &&
      analysisProcedureOptions[analysisProcedure.condition]['field3_visible'] === true
    ) {
      let sx: TsInterface_UnspecifiedObject = {}
      if (getProp(analysisProcedure, 'field3', null) == null || getProp(analysisProcedure, 'field3', '') === '') {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: themeVariables.warning_main,
        }
      } else {
        sx = {
          minWidth: '130px',
          marginRight: '8px',
          marginBottom: '8px',
        }
      }
      inputJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={sx}
        >
          <Select
            onChange={(event, value) => {
              updateAnalysisProcedureData(analysisProcedure, {
                // field1: null,
                // field1_option: null,
                // condition: null,
                // field2: null,
                // field2_option: null,
                // field2_hardcode: null,
                field3: event.target.value,
                field3_hardcode: null,
              })
            }}
            value={analysisProcedure.field3 || ''}
            // disabled={ objectToArray( us_associatedFormFields ).length === 0 }
          >
            {/* <MenuItem value={ "HARDCODE" }>{ rLIB("Hardcoded Value") }</MenuItem> */}
            {matchingFormFieldsArray.map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Field3Hardcode = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let inputJSX = <></>
    if (
      analysisProcedure != null &&
      analysisProcedure.condition != null &&
      analysisProcedureOptions != null &&
      analysisProcedureOptions[analysisProcedure.condition] != null &&
      analysisProcedureOptions[analysisProcedure.condition]['field3_visible'] === true &&
      analysisProcedure.field3 === 'HARDCODE'
    ) {
      let sx: TsInterface_UnspecifiedObject = {}
      if (getProp(analysisProcedure, 'field3_hardcode', null) == null || getProp(analysisProcedure, 'field3_hardcode', '') === '') {
        sx = {
          minWidth: '230px',
          marginRight: '8px',
          marginBottom: '8px',
          backgroundColor: themeVariables.warning_main,
        }
      } else {
        sx = {
          minWidth: '230px',
          marginRight: '8px',
          marginBottom: '8px',
        }
      }
      inputJSX = (
        <FormControl sx={sx}>
          <TextField
            className="bp_thin_text_input"
            sx={{ marginTop: '0px', marginBottom: '0px' }}
            color="primary"
            value={analysisProcedure.field3_hardcode || ''}
            margin="normal"
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateAnalysisProcedureData(analysisProcedure, {
                  // field1: null,
                  // field1_option: null,
                  // condition: null,
                  // field2: null,
                  // field2_option: null,
                  // field2_hardcode: null,
                  // field3: null,
                  field3_hardcode: event.target.value,
                })
              }
            }}
            onBlur={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateAnalysisProcedureData(analysisProcedure, {
                  // field1: null,
                  // field1_option: null,
                  // condition: null,
                  // field2: null,
                  // field2_option: null,
                  // field2_hardcode: null,
                  // field3: null,
                  field3_hardcode: event.target.value,
                })
              }
            }}
            variant="outlined"
          />
        </FormControl>
      )
    }
    return inputJSX
  }

  const rJSX_AnalysisProcedure_Result = (analysisProcedure: TsInterface_UnspecifiedObject): JSX.Element => {
    let inputJSX = (
      <Box>
        <Box>
          <Typography
            variant="h6"
            className=""
          >
            {rLIB('Highlight the following cells')}
          </Typography>
        </Box>
        <Box>
          {objectToArray(us_associatedFormFields)
            .sort(dynamicSort('name', null))
            .map((formField: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Checkbox
                  checked={getProp(getProp(analysisProcedure, 'highlighted_cells', {}), formField.key, false)}
                  onChange={(event, value) => {
                    if (value != null) {
                      let updateObject: TsInterface_UnspecifiedObject = {
                        highlighted_cells: {
                          [formField.key]: value,
                        },
                      }
                      updateAnalysisProcedureData(analysisProcedure, updateObject)
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                {formField.name}
              </Box>
            ))}
        </Box>
      </Box>
    )
    return inputJSX
  }

  const rJSX_AnalysisProcedure = (): JSX.Element => {
    let analysisProcedureJSX = <></>
    if (us_selectedAnalysisProcedure !== '') {
      let selectedAnalysisProcedure = getProp(us_analysisTemplateAnalysisProcedures, us_selectedAnalysisProcedure, {})
      // JSX
      analysisProcedureJSX = (
        <Card className="tw-p-2">
          <Box>
            <Box>
              <Typography
                variant="h6"
                className="tw-font-bold tw-inline-block tw-mr-2"
              >
                {rLIB('Procedure Name')}:{' '}
              </Typography>
              <Typography
                variant="h6"
                className="tw-opacity-30 tw-inline-block"
              >
                {getProp(selectedAnalysisProcedure, 'name', rLIB('Analysis Procedure Name'))}{' '}
              </Typography>
              <Icon
                icon="pen-to-square"
                className="tw-ml-2 tw-cursor-pointer tw-inline-block tw-opacity-30 hover:tw-opacity-100"
                sx={{
                  '&:hover': {
                    color: themeVariables.success_main,
                  },
                }}
                tooltip={rLIB('Edit')}
                tooltipPlacement="right"
                onClick={() => {
                  editAnalysisProcedureName(selectedAnalysisProcedure)
                }}
              />
            </Box>
            <Box>
              <Box className="tw-ml-4">
                <Switch
                  color="info"
                  checked={selectedAnalysisProcedure.active === true ? true : false}
                  disabled={false}
                  onChange={(event, value) => {
                    if (value === true) {
                      updateAnalysisProcedureData(selectedAnalysisProcedure, { active: true })
                    } else if (value === false) {
                      updateAnalysisProcedureData(selectedAnalysisProcedure, { active: false })
                    }
                  }}
                />
                {selectedAnalysisProcedure.active === true ? rLIB('Active') : rLIB("Don't Run")}
              </Box>
            </Box>
          </Box>
          <Divider className="tw-mt-2 tw-mb-2" />
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Condition')}
            </Typography>
            <Box className="tw-ml-4">
              {rJSX_AnalysisProcedure_Field1(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Field1Option(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Condition(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Field2(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Field2Option(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Field2Hardcode(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Field3(selectedAnalysisProcedure)}
              {rJSX_AnalysisProcedure_Field3Hardcode(selectedAnalysisProcedure)}
            </Box>
          </Box>
          <Divider className="tw-mt-2 tw-mb-2" />
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold tw-inline-block"
            >
              {rLIB('Result')}
            </Typography>
            <Box className="tw-ml-4">{rJSX_AnalysisProcedure_Result(selectedAnalysisProcedure)}</Box>
          </Box>

          {/* <Json data={ selectedAnalysisProcedure } alphabetized={ true } /> */}
        </Card>
      )
    }
    return analysisProcedureJSX
  }

  const rJSX_SettingTabContent = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Box>
          <Typography
            variant="h5"
            className="tw-mt-3 tw-mb-2 tw-font-bold"
            sx={{ color: themeVariables.error_main }}
          >
            {rLIB('Analysis Template Settings')}
          </Typography>
          <Card className="tw-p-2">
            <Box>
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {rLIB('Analysis Name')}
              </Typography>
              <Box className="tw-ml-4">
                <Typography
                  variant="h6"
                  className="tw-opacity-50"
                >
                  {getProp(us_analysisTemplate, 'name', '')}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {rLIB('Form')}
              </Typography>
              <Box className="tw-ml-4">
                <Typography
                  variant="h6"
                  className="tw-opacity-50"
                >
                  {getProp(us_associatedForm, 'name', '')}
                </Typography>
              </Box>
            </Box>
            {/* <Box>
							<Typography variant="h6" className="tw-font-bold">{ s_REQUIRE_COMPARISON_FILE_IMPORT }</Typography>
							<Box className="tw-ml-4">
								<Switch
									color="info"
									checked={ us_analysisTemplate.require_comparison_file_import === true ? true : false }
									disabled={ false }
									onChange={ (event, value) => {
										if ( value === true ){
											updateAnalysisTemplateData( { require_comparison_file_import: true } )
										} else if( value === false ){
											updateAnalysisTemplateData( { require_comparison_file_import: false } )
										}
									} }
								/>
								{ us_analysisTemplate.require_comparison_file_import === true ? s_COMPARISON_CSV_IMPORT_REQUIRED : s_NO_COMPARISON_CSV_IMPORT }
							</Box>
						</Box> */}
            {/* { rJSX_UniqueIdentifierDropdown() } */}
          </Card>
        </Box>
        <Box>
          <Typography
            variant="h5"
            className="tw-mt-3 tw-mb-2 tw-font-bold"
            sx={{ color: themeVariables.error_main }}
          >
            {rLIB('Analysis Procedures')}
          </Typography>
          <Box className="">
            <FormControl
              className="bp_thin_select_input"
              sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
            >
              <Select
                onChange={(event, value) => {
                  us_setSelectedAnalysisProcedure(event.target.value)
                }}
                value={us_selectedAnalysisProcedure}
                disabled={objectToArray(us_associatedFormFields).length === 0}
              >
                {objectToArray(us_analysisTemplateAnalysisProcedures)
                  .sort(dynamicSort('name', null))
                  .map((option: TsInterface_UnspecifiedObject) => (
                    <MenuItem
                      key={option['key']}
                      value={option['key']}
                    >
                      {option['name']}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {rJSX_NewAnalysisProcedure()}
          </Box>
          {rJSX_AnalysisProcedure()}
        </Box>
      </Box>
    )
    return tabJSX
  }

  // const rJSX_TestTabContent = (): JSX.Element => {
  // let tabJSX =
  // <Box>
  // 	<Json data={ us_analysisTemplate } alphabetized={ true } />
  // 	<Json data={ us_analysisTemplateAnalysisProcedures } alphabetized={ true } />
  // 	<Json data={ us_associatedForm } alphabetized={ true } />
  // 	<Json data={ us_associatedFormFields } alphabetized={ true } />
  // </Box>
  // return tabJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              {rJSX_SettingTabContent()}
              {/* <TabsUrl
							tabs={[
								{
									tabUrlKey: "Settings",
									tabHeader: s_SETTINGS,
									tabOnChange: () => {  },
									tabContent: rJSX_SettingTabContent(),
								},
								{
									tabUrlKey: "Test_Analysis",
									tabHeader: s_TEST_ANALYSIS,
									tabOnChange: () => {  },
									tabContent: rJSX_TestTabContent(),
								},
							]} tabsSettings={ {
								baseUrl: ApplicationPages.DataAnalysisTemplateViewPage.url(itemKey),
								tabQueryParam: "tab",
								overridePageTitle: true,
								basePageTitle: rLIB("Data Analysis Template", false) as string
							} }
						/> */}
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

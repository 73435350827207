///////////////////////////////
// Imports
///////////////////////////////

import { defaultLocalization } from 'app/localization/default_localization'
import { Trans } from 'react-i18next'
import textLibraryEN from '../../app/localization/library_en.json'
import textLibraryES from '../../app/localization/library_es.json'

///////////////////////////////
// Exports
///////////////////////////////

export function rLIB<K extends keyof typeof textLibraryEN>(key: K, translate: boolean = true): string | JSX.Element {
  if (textLibraryEN == null || textLibraryEN[key] === undefined) {
    return key
  } else if (translate) {
    return <Trans>{textLibraryEN[key]}</Trans>
  } else {
    let textLibrary: any = textLibraryEN
    if (defaultLocalization === 'es') {
      textLibrary = textLibraryES
    }
    // TODO: Handle Dynamic Language Settings
    // Handle Manual Translations
    if (textLibrary[key] !== undefined) {
      return textLibrary[key]
    } else {
      return textLibraryEN[key]
    }
    return textLibraryEN[key]
  }
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to define translations for strings throughout the application using the rLIB custom function
			Strings that are not defined for the selected language will default to whatever is stated in the tags

	*/

///////////////////////////////
// Imports
///////////////////////////////

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { defaultLocalization } from './default_localization'
import textLibraryEN from './library_en.json'
import textLibraryES from './library_es.json'

///////////////////////////////
// Variables
///////////////////////////////

const resources = {
  en: {
    translation: textLibraryEN,
  },
  es: {
    translation: textLibraryES,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLocalization,
  // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
  // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  // if you're using a language detector, do not define the lng option
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n

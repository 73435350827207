///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:
			TIMEZONES

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

function sortArrayOfArraysByIndex(arr: any[], index: number) {
  if (!Array.isArray(arr) || !Number.isInteger(index) || arr.some((subArr) => !Array.isArray(subArr))) {
    throw new Error('Invalid input. The first argument must be an array of arrays, and the second argument must be an integer.')
  }
  arr.sort((a, b) => {
    if (a[index] < b[index]) {
      return -1
    }
    if (a[index] > b[index]) {
      return 1
    }
    return 0
  })
}

export const lookupTableTypeOptions: TsInterface_UnspecifiedObject = {
  identical_lookup: {
    key: 'identical_lookup',
    value: rLIB('Identical Lookup'),
  },
  range_lookup: {
    key: 'range_lookup',
    value: rLIB('Range Lookup'),
  },
  matrix_lookup: {
    key: 'matrix_lookup',
    value: rLIB('Matrix Lookup'),
  },
}

export const lookupTableExampleDownloads: TsInterface_UnspecifiedObject = {
  identical_lookup: {
    name: 'Identical Lookup - US States',
    example_data: [
      ['AL', 'Alabama'],
      ['AK', 'Alaska'],
      ['AZ', 'Arizona'],
      ['AR', 'Arkansas'],
      ['CA', 'California'],
      ['CO', 'Colorado'],
      ['CT', 'Connecticut'],
      ['DE', 'Delaware'],
      ['FL', 'Florida'],
      ['GA', 'Georgia'],
      ['HI', 'Hawaii'],
      ['ID', 'Idaho'],
      ['IL', 'Illinois'],
      ['IN', 'Indiana'],
      ['IA', 'Iowa'],
      ['KS', 'Kansas'],
      ['KY', 'Kentucky'],
      ['LA', 'Louisiana'],
      ['ME', 'Maine'],
      ['MD', 'Maryland'],
      ['MA', 'Massachusetts'],
      ['MI', 'Michigan'],
      ['MN', 'Minnesota'],
      ['MS', 'Mississippi'],
      ['MO', 'Missouri'],
      ['MT', 'Montana'],
      ['NE', 'Nebraska'],
      ['NV', 'Nevada'],
      ['NH', 'New Hampshire'],
      ['NJ', 'New Jersey'],
      ['NM', 'New Mexico'],
      ['NY', 'New York'],
      ['NC', 'North Carolina'],
      ['ND', 'North Dakota'],
      ['OH', 'Ohio'],
      ['OK', 'Oklahoma'],
      ['OR', 'Oregon'],
      ['PA', 'Pennsylvania'],
      ['RI', 'Rhode Island'],
      ['SC', 'South Carolina'],
      ['SD', 'South Dakota'],
      ['TN', 'Tennessee'],
      ['TX', 'Texas'],
      ['UT', 'Utah'],
      ['VT', 'Vermont'],
      ['VA', 'Virginia'],
      ['WA', 'Washington'],
      ['WV', 'West Virginia'],
      ['WI', 'Wisconsin'],
      ['WY', 'Wyoming'],
    ],
  },
  range_lookup: {
    name: 'Range Lookup - Credit Scores',
    example_data: [
      [300, 580, 'Poor'],
      [580, 670, 'Fair'],
      [670, 740, 'Good'],
      [740, 800, 'Very Good'],
      [800, 850, 'Exceptional'],
    ],
  },
  matrix_lookup: {
    name: 'Matrix Lookup - Eisenhower Matrix',
    example_data: [
      ['important', 'urgent', 'do it'],
      ['important', 'not urgent', 'schedule it'],
      ['not important', 'urgent', 'delegate it'],
      ['not important', 'not urgent', 'delete it'],
    ],
  },
}

export const returnDataAsDownloadableArray = (lookupTableType: string, databaseDataObject: TsInterface_UnspecifiedObject) => {
  let outputArray: any = []
  switch (lookupTableType) {
    case 'identical_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        outputArray.push([loopDataRowKey, databaseDataObject[loopDataRowKey]])
      }
      sortArrayOfArraysByIndex(outputArray, 0)
      break
    case 'range_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        let splitIndex = loopDataRowKey.indexOf('~')
        let lookupStart = loopDataRowKey.substring(0, splitIndex)
        let lookupEnd = loopDataRowKey.substring(splitIndex + 1)
        outputArray.push([lookupStart, lookupEnd, databaseDataObject[loopDataRowKey]])
      }
      sortArrayOfArraysByIndex(outputArray, 0)
      break
    case 'matrix_lookup':
      for (let loopDataRowKey in databaseDataObject) {
        let splitIndex = loopDataRowKey.indexOf('~')
        let lookupStart = loopDataRowKey.substring(0, splitIndex)
        let lookupEnd = loopDataRowKey.substring(splitIndex + 1)
        outputArray.push([lookupStart, lookupEnd, databaseDataObject[loopDataRowKey]])
      }
      sortArrayOfArraysByIndex(outputArray, 0)
      break
  }
  return outputArray
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Document, Font, Image, Page, StyleSheet, Text } from '@react-pdf/renderer'
import QRCode from 'qrcode'
import { rJSX_DynamicApplicationLogoPngSrc } from 'rfbp_aux/components/dynamic_app_branding'
import { getBaseURL } from 'rfbp_aux/functions/get_base_url'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Register Font
Font.register({ family: 'Roboto', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf' })

// Styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 40,
  },
  header: {
    fontSize: 36,
    marginBottom: 0,
    fontFamily: 'Roboto',
  },
  paragraph: {
    fontSize: 24,
    fontFamily: 'Roboto',
  },
  logo: {
    width: 250,
  },
  qrCode: {
    width: 350,
    height: 350,
  },
})

///////////////////////////////
// Functions
///////////////////////////////

const generateQRCodeDataUrl = (data: string) => {
  const canvas = document.createElement('canvas')
  // @ts-expect-error
  QRCode.toCanvas(canvas, data, { width: 100, height: 100 })
  return canvas.toDataURL('image/png')
}

export const returnPDFDownloadDocument = (qrCodeKey: string, qrCodeName: string) => {
  // Instantiate Variables
  let qrCodeUrl = getBaseURL() + '/form/' + qrCodeKey
  let qrCodeImageDataUrl = generateQRCodeDataUrl(qrCodeUrl)
  // Create PDF
  let qrCodePDFJSX = (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <Image
          src={rJSX_DynamicApplicationLogoPngSrc('black')}
          style={styles.logo}
        />
        <Image
          src={qrCodeImageDataUrl}
          style={styles.qrCode}
        />
        <Text style={styles.header}>{qrCodeName}</Text>
      </Page>
    </Document>
  )
  return qrCodePDFJSX
}

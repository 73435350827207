///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Stack } from '@mui/material'
import { returnLoginLinkColor, rJSX_DynamicApplicationLogoSvg } from 'rfbp_aux/components/dynamic_app_branding'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const rJSX_UnauthenticatedPageHeader = (un_routerNavigation: any): JSX.Element => {
  let headerJSX = (
    <Box>
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box className="tw-ml-2 tw-mt-1.5">{rJSX_DynamicApplicationLogoSvg('40px', {}, {})}</Box>
        <Box>
          <Button
            color={returnLoginLinkColor()}
            variant="outlined"
            className="tw-m-2"
            onClick={() => {
              un_routerNavigation(ApplicationPages.UnauthenticatedLoginPage.url())
            }}
          >
            {rLIB('Log In')}
          </Button>
        </Box>
      </Stack>
      <Divider />
    </Box>
  )

  return headerJSX
}

///////////////////////////////
// Description
///////////////////////////////

/*
		USAGE:
			Data Services contains micro services for data requests that are best hosted on the server

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { returnCorrectedGravity } from './net_bbls_lookup_tables'

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// const calculateBarrelsFromGenericStrapping = (
// topGaugeInches: number,
// bottomGaugeInches: number,
// bblsPerInch: number
// ): null | number => {
// let totalBBLs = null
// if(
// 	topGaugeInches != null &&
// 	bottomGaugeInches != null &&
// 	bblsPerInch != null
// ){
// 	totalBBLs = ( topGaugeInches - bottomGaugeInches ) * bblsPerInch
// } else {
// 	totalBBLs = null
// }
// return totalBBLs
// }

// const calculateBarrelsFromIncrementalStrapping = (
// topGaugeInches: number,
// bottomGaugeInches: number,
// incrementalStrapping: TsInterface_UnspecifiedObject
// ): null | number => {
// let totalBBLs = null
// if(
// 	topGaugeInches != null &&
// 	bottomGaugeInches != null &&
// 	incrementalStrapping != null
// ){
// 	let noFormatErrors = true
// 	let totalTankVolume = 0
// 	for( let rowIndex in incrementalStrapping ){
// 		let row = incrementalStrapping[ rowIndex ]
// 		if(
// 			row.bbls_per_inch == null ||
// 			row.start == null ||
// 			row.end == null ||
// 			isNaN( row.bbls_per_inch ) ||
// 			isNaN( row.start ) ||
// 			isNaN( row.end )
// 		){
// 			noFormatErrors = false
// 		} else {
// 			if(
// 				row.start <= topGaugeInches &&
// 				topGaugeInches <= row.end &&
// 				row.start <= bottomGaugeInches &&
// 				bottomGaugeInches <= row.end
// 			){
// 				// If the top gauge is between the start and end strappings AND the end gauge is between the start and end strappings
// 				totalTankVolume += ( ( topGaugeInches - bottomGaugeInches ) * row.bbls_per_inch )
// 			} else if(
// 				row.start <= topGaugeInches &&
// 				topGaugeInches <= row.end
// 			){
// 				// If just the top gauge is between the start and end strappings
// 				totalTankVolume += ( ( topGaugeInches - row.start ) * row.bbls_per_inch )
// 			} else if(
// 				row.start <= bottomGaugeInches &&
// 				bottomGaugeInches <= row.end
// 			){
// 				// If just the end gauge is between the start and end strappings
// 				totalTankVolume += ( ( row.end - bottomGaugeInches ) * row.bbls_per_inch )
// 			} else if(
// 				bottomGaugeInches <= row.start &&
// 				row.end <= topGaugeInches
// 			){
// 				// If the start and end strappings are between the top and bottom gauges
// 				totalTankVolume += ( ( row.end - row.start ) * row.bbls_per_inch )
// 			}
// 		}
// 	}
// 	// If no errors, set tank hieght and volume if accessible
// 	if( noFormatErrors == true ){
// 		totalBBLs = totalTankVolume
// 	} else {
// 		totalBBLs = null
// 	}
// } else {
// 	totalBBLs = null
// }
// return totalBBLs
// }

const iterateBaseDensity = (
  commodity: 'crude oil' | 'fuel oil' | 'jet fuel' | 'transition zone' | 'gasoline' | 'lube oil',
  observedDensity: number,
  baseDensityGuess: number,
  observedTemperature: number,
  observedPressure: number,
  dAlpha: number,
  iterator: number,
  originalDensityFormat: 'API' | 'SI',
  carryThroughParams: TsInterface_UnspecifiedObject,
  iterativeBool: boolean,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let error: TsInterface_UnspecifiedObject = { message: null, details: null, code: null }
    try {
      //========================================//
      //    calculateVolumeCorrectionFactors    //
      //========================================//
      let baseDensity = baseDensityGuess
      let densityFormat = 'SI'
      let alternateTemperature = observedTemperature
      let alternatePressure = observedPressure
      // Set variables
      let minDensity = 0
      let maxDensity = 0
      let K0 = 0
      let K1 = 0
      let K2 = 0
      let volumeCorrectionFactor = 1
      let alternateDensity
      let convertedDensity
      let tempShiftConstant = 0.01374979547
      // Variables Dependent on Commodity
      if (commodity == 'crude oil') {
        minDensity = 610.6
        maxDensity = 1163.5
        K0 = 341.0957
        K1 = 0
        K2 = 0
      } else if (commodity == 'fuel oil') {
        minDensity = 838.3127
        maxDensity = 1163.5
        K0 = 103.872
        K1 = 0.2701
        K2 = 0
      } else if (commodity == 'jet fuel') {
        minDensity = 787.5195
        maxDensity = 838.3127
        K0 = 330.301
        K1 = 0
        K2 = 0
      } else if (commodity == 'transition zone') {
        minDensity = 770.352
        maxDensity = 787.5195
        K0 = 1489.067
        K1 = 0
        K2 = -0.0018684
      } else if (commodity == 'gasoline') {
        minDensity = 610.6
        maxDensity = 770.352
        K0 = 192.4571
        K1 = 0.2438
        K2 = 0
      } else if (commodity == 'lube oil') {
        minDensity = 800.9
        maxDensity = 1163.5
        K0 = 0
        K1 = 0.34878
        K2 = 0
      } else {
        if (iterativeBool === false) {
          error.message = 'Commodity Not Set'
          error.details = 'Commodity must be set in order to run net bbl calculation'
          error.code = 'ER-S-NB-IBD-01'
          console.error(error)
          reject({ success: false, error: error })
        }
      }
      // Convert Base Density to Correct Units
      if (densityFormat === 'API') {
        convertedDensity = (141.5 / (baseDensity + 131.5)) * 999.016
      } else {
        convertedDensity = baseDensity
      }
      // Step 1 - Check input values to make sure they are in the range of the standard
      if (iterativeBool === false) {
        if (alternateTemperature < -58.0 || alternateTemperature > 302.2) {
          error.message = 'Temperatures out of Standard Range'
          error.details = 'observedTemperature is set to: ' + alternateTemperature
          error.code = 'ER-S-NB-IBD-02'
          console.error(error)
          reject({ success: false, error: error })
        } else if (alternatePressure > 1500) {
          error.message = 'Pressure out of Standard Range'
          error.details = 'observedPressure is set to: ' + alternatePressure
          error.code = 'ER-S-NB-IBD-03'
          console.error(error)
          reject({ success: false, error: error })
        } else if (convertedDensity < minDensity || convertedDensity > maxDensity) {
          error.message = 'Density out of Standard Range'
          error.details = 'convertedDensity is set to: ' + convertedDensity
          error.code = 'ER-S-NB-IBD-04'
          console.error(error)
          reject({ success: false, error: error })
        }
      }
      // Get rid of negative pressures
      if (alternatePressure < 0) {
        alternatePressure = 0
      }
      //========================================//
      //    shiftTemperatureFromITS90toIPTS68   //
      //========================================//
      // Step 2 - Shift the Input Temperature to IPTS-68 basis (11.1.5.3)
      // let temp = alternateTemperature
      let temperatureFormat = 'F'
      // Set variables
      let scaledTemp, deltaTemp, correctedTemp
      let temperature = alternateTemperature
      let alpha1 = -0.148759
      let alpha2 = -0.267408
      let alpha3 = 1.08076
      let alpha4 = 1.269056
      let alpha5 = -4.089591
      let alpha6 = -1.871251
      let alpha7 = 7.438081
      let alpha8 = -3.536296
      // If temperature format is in Fahrenheit, convert to Celsius
      if (temperatureFormat === 'F') {
        temperature = (temperature - 32) / 1.8
      }
      // Calculate the Scaled Temperature Value and temperature correction
      scaledTemp = temperature / 630
      deltaTemp =
        (alpha1 +
          (alpha2 +
            (alpha3 + (alpha4 + (alpha5 + (alpha6 + (alpha7 + alpha8 * scaledTemp) * scaledTemp) * scaledTemp) * scaledTemp) * scaledTemp) * scaledTemp) *
            scaledTemp) *
        scaledTemp
      // Determine the equivalent IPTS-68
      correctedTemp = temperature - deltaTemp
      if (temperatureFormat === 'F') {
        correctedTemp = 1.8 * correctedTemp + 32
      }
      let shiftedTemperature = correctedTemp
      //========================================//
      //      shiftDensityFromITS90toIPTS68     //
      //========================================//
      // Step 3 - Shift the Input Base Density to IPTS-68 basis
      let baseDensityInstance = convertedDensity
      // Run Calculation
      let A = (tempShiftConstant / 2) * ((K0 / baseDensityInstance + K1) * (1 / baseDensityInstance) + K2)
      let B = (2 * K0 + K1 * baseDensityInstance) / (K0 + (K1 + K2 * baseDensityInstance) * baseDensityInstance)
      let shiftedDensity = baseDensityInstance * (1 + (Math.exp(A * (1 + 0.8 * A)) - 1) / (1 + A * (1 + 1.6 * A) * B))
      //========================================//
      //     calculateThermalExpansionFactor    //
      //========================================//
      // Step 4 - Determine the Coefficient of Thermal Expansion if none was provided
      // Run Calculation
      let thermalExpansionFactor = (K0 / shiftedDensity + K1) * (1 / shiftedDensity) + K2
      //========================================//
      //  calculateTemperatureCorrectionFactor  //
      //========================================//
      // Step 5 - Calculate the difference between the base and alternate temperatures and correction factor
      // Run Calculation
      let deltaTempInstance = shiftedTemperature - 60.0068749
      let tempCorrectionFactor = Math.exp(
        -1 * thermalExpansionFactor * deltaTempInstance * (1 + 0.8 * thermalExpansionFactor * (deltaTempInstance + tempShiftConstant)),
      )
      //========================================//
      // scaledCompressibilityCorrectionFactor  //
      //========================================//
      // Step 6 - Calculate the scaled compressibility factor
      // Run Calculation
      let scaledCompressibilityFactor = Math.exp(
        -1.9947 + 0.00013427 * shiftedTemperature + (793920 + 2326 * shiftedTemperature) / (shiftedDensity * shiftedDensity),
      )
      //========================================//
      //    calculatePressureCorrectionFactor   //
      //========================================//
      // Step 7 - Calculate the pressure correction factor
      // Run Calculation
      let pressureCorrectionFactor = 1 / (1 - 0.00001 * scaledCompressibilityFactor * alternatePressure)
      //========================================//
      //       Last Calculations & Return       //
      //========================================//
      // Step 8 - Calculate the Combined Temperature and Pressure Correction
      volumeCorrectionFactor = tempCorrectionFactor * pressureCorrectionFactor
      if (iterativeBool === false) {
        volumeCorrectionFactor = parseInt(volumeCorrectionFactor.toFixed(5))
      }
      // Step 9 - Correct Base Density to Alternate Conditions
      alternateDensity = volumeCorrectionFactor * convertedDensity
      // Step 10 - Return
      let correctionFactors = {
        volumeCorrectionFactor: volumeCorrectionFactor,
        tempCorrectionFactor: tempCorrectionFactor,
        pressureCorrectionFactor: pressureCorrectionFactor,
        thermalExpansionFactor: thermalExpansionFactor,
        alternateDensity: alternateDensity,
        scaledCompressibilityFactor: scaledCompressibilityFactor,
      }
      let deltaRhoComparison = observedDensity - correctionFactors.alternateDensity
      if (Math.abs(deltaRhoComparison) < 0.000001) {
        // Convergence
        // Do Final Calculation
        determineFinalCorrectionFactors(commodity, baseDensityGuess, correctionFactors.pressureCorrectionFactor, carryThroughParams)
          .then((finalCalculationResults) => {
            resolve(finalCalculationResults)
          })
          .catch((finalCalculationResultsReject) => {
            reject(finalCalculationResultsReject)
          })
      } else if (iterator > 15) {
        error.message = 'Convergence did not occur'
        error.details = 'Max Iterations Met'
        error.code = 'ER-S-NB-IBD-05'
        console.error(error)
        reject({ success: false, error: error })
      } else {
        // Step 5 - Revise the estimate for the density at base conditions
        //========================================//
        //       calculateRecursionConstantE      //
        //========================================//
        let recursionConstantE = observedDensity / (correctionFactors.tempCorrectionFactor * correctionFactors.pressureCorrectionFactor) - baseDensityGuess
        //========================================//
        //      calculateRecursionConstantDt      //
        //========================================//
        let deltaTempInstance2 = observedTemperature - 60
        let recursionConstantDt =
          dAlpha * correctionFactors.thermalExpansionFactor * deltaTempInstance2 * (1 + 1.6 * correctionFactors.thermalExpansionFactor * deltaTempInstance2)
        //========================================//
        //      calculateRecursionConstantDp      //
        //========================================//
        let recursionConstantDp =
          (2 *
            correctionFactors.pressureCorrectionFactor *
            observedPressure *
            correctionFactors.scaledCompressibilityFactor *
            (7.9392 + 0.02326 * observedTemperature)) /
          (observedDensity * observedDensity)
        //========================================//
        //           calculateDeltaRho            //
        //========================================//
        let deltaRho = recursionConstantE / (1 + recursionConstantDt + recursionConstantDp)
        let newRho = baseDensityGuess + deltaRho
        // Step 6 - Increment Or Exit if n = 15
        //========================================//
        //      Run through iteration again       //
        //========================================//
        iterateBaseDensity(
          commodity,
          observedDensity,
          newRho,
          observedTemperature,
          observedPressure,
          dAlpha,
          iterator + 1,
          originalDensityFormat,
          carryThroughParams,
          true,
        )
          .then((iterationResults) => {
            resolve(iterationResults)
          })
          .catch((iterationResultsReject) => {
            reject(iterationResultsReject)
          })
      }
    } catch (err) {
      error.message = 'Base Density Iteration Failed'
      error.details = err
      error.code = 'ER-S-NB-IBD-06'
      console.error(error)
      reject({ success: false, error: error })
    }
  })
}

const determineFinalCorrectionFactors = (
  commodity: 'crude oil' | 'fuel oil' | 'jet fuel' | 'transition zone' | 'gasoline' | 'lube oil',
  correctedDensity: number,
  pressureCorrection: number,
  carryThroughParams: TsInterface_UnspecifiedObject,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let error: TsInterface_UnspecifiedObject = { message: null, details: null, code: null }
    try {
      // Set Variables
      let minDensity = 0
      let maxDensity = 0
      let K0 = 0
      let K1 = 0
      let K2 = 0
      let tempShiftConstant = 0.01374979547
      // Variables Dependent on Commodity
      if (commodity == 'crude oil') {
        minDensity = 610.6
        maxDensity = 1163.5
        // dAlpha = 2.0
        K0 = 341.0957
        K1 = 0
        K2 = 0
      } else if (commodity == 'fuel oil') {
        minDensity = 838.3127
        maxDensity = 1163.5
        // dAlpha = 1.3
        K0 = 103.872
        K1 = 0.2701
        K2 = 0
      } else if (commodity == 'jet fuel') {
        minDensity = 787.5195
        maxDensity = 838.3127
        // dAlpha = 2.0
        K0 = 330.301
        K1 = 0
        K2 = 0
      } else if (commodity == 'transition zone') {
        minDensity = 770.352
        maxDensity = 787.5195
        // dAlpha = 8.5
        K0 = 1489.067
        K1 = 0
        K2 = -0.0018684
      } else if (commodity == 'gasoline') {
        minDensity = 610.6
        maxDensity = 770.352
        // dAlpha = 1.5
        K0 = 192.4571
        K1 = 0.2438
        K2 = 0
      } else if (commodity == 'lube oil') {
        minDensity = 800.9
        maxDensity = 1163.5
        // dAlpha = 1.0
        K0 = 0
        K1 = 0.34878
        K2 = 0
      } else {
        error.message = 'Commodity Not Set'
        error.details = 'Commodity must be set in order to run net bbl calculation'
        error.code = 'ER-S-NB-DFCF-01'
        console.error(error)
        reject({ success: false, error: error })
      }
      // Step 7 - Set Base Density and check to make sure it is the range of the standard
      // Check to make sure density is whitin ranged
      if (correctedDensity < minDensity || correctedDensity > maxDensity) {
        error.message = 'Calculated Density out of Standard Range'
        error.details = 'Corrected Density calculated to be: ' + correctedDensity
        error.code = 'ER-S-NB-DFCF-02'
        console.error(error)
        reject({ success: false, error: error })
      } else {
        let averageTemp = (parseFloat(carryThroughParams.topTemp) + parseFloat(carryThroughParams.bottomTemp)) / 2
        averageTemp = parseFloat(averageTemp.toFixed(1))
        // Calculate Temp Correction Factor
        //========================================//
        //    shiftTemperatureFromITS90toIPTS68   //
        //========================================//
        // Step 2 - Shift the Input Temperature to IPTS-68 basis (11.1.5.3)
        let temperatureFormat = 'F'
        // Set variables
        let scaledTemp, deltaTemp, correctedTemp
        let temperature = averageTemp
        let alpha1 = -0.148759
        let alpha2 = -0.267408
        let alpha3 = 1.08076
        let alpha4 = 1.269056
        let alpha5 = -4.089591
        let alpha6 = -1.871251
        let alpha7 = 7.438081
        let alpha8 = -3.536296
        // If temperature format is in Fahrenheit, convert to Celsius
        if (temperatureFormat === 'F') {
          temperature = (temperature - 32) / 1.8
        }
        // Calculate the Scaled Temperature Value and temperature correction
        scaledTemp = temperature / 630
        deltaTemp =
          (alpha1 +
            (alpha2 +
              (alpha3 + (alpha4 + (alpha5 + (alpha6 + (alpha7 + alpha8 * scaledTemp) * scaledTemp) * scaledTemp) * scaledTemp) * scaledTemp) * scaledTemp) *
              scaledTemp) *
          scaledTemp
        // Determine the equivalent IPTS-68
        correctedTemp = temperature - deltaTemp
        if (temperatureFormat === 'F') {
          correctedTemp = 1.8 * correctedTemp + 32
        }
        let shiftedTemperature = correctedTemp
        //========================================//
        //      shiftDensityFromITS90toIPTS68     //
        //========================================//
        // Step 3 - Shift the Input Base Density to IPTS-68 basis
        let baseDensityInstance = correctedDensity
        // Run Calculation
        let A = (tempShiftConstant / 2) * ((K0 / baseDensityInstance + K1) * (1 / baseDensityInstance) + K2)
        let B = (2 * K0 + K1 * baseDensityInstance) / (K0 + (K1 + K2 * baseDensityInstance) * baseDensityInstance)
        let shiftedDensity = baseDensityInstance * (1 + (Math.exp(A * (1 + 0.8 * A)) - 1) / (1 + A * (1 + 1.6 * A) * B))
        //========================================//
        //     calculateThermalExpansionFactor    //
        //========================================//
        // Step 4 - Determine the Coefficient of Thermal Expansion if none was provided
        // Run Calculation
        let newThermalExpansionFactor = (K0 / shiftedDensity + K1) * (1 / shiftedDensity) + K2
        //========================================//
        //  calculateTemperatureCorrectionFactor  //
        //========================================//
        // Step 5 - Calculate the difference between the base and alternate temperatures and correction factor
        // Run Calculation
        let deltaTempInstance = shiftedTemperature - 60.0068749
        let tempCorrectionFactor = Math.exp(
          -1 * newThermalExpansionFactor * deltaTempInstance * (1 + 0.8 * newThermalExpansionFactor * (deltaTempInstance + tempShiftConstant)),
        )
        //========================================//
        //            calculateFFactor            //
        //========================================//
        // Instantiate Variables
        let f_A = -1.9947
        let f_B = 13.427
        let C = 79392
        let D = 232.6
        let rho = parseFloat(correctedDensity.toFixed(1))
        let tempInstance = parseFloat(averageTemp.toFixed(1))
        let sign
        // Run Calculation
        if (tempInstance - Math.floor(tempInstance) >= 0) {
          sign = 1
        } else {
          sign = -1
        }
        let rhoSquare = rho * rho
        let term1 = f_A
        let term2 = Math.floor(f_B * tempInstance + 0.5 * sign) * 0.00001
        let term3 = Math.floor(C / rhoSquare + 0.5) * 0.00001
        let term4 = Math.floor((D * tempInstance) / rhoSquare + 0.5 * sign) * 0.00001
        let fFactor = Math.exp(term1 + term2 + term3 + term4)
        fFactor = parseFloat(fFactor.toFixed(5))
        //========================================//
        // Run Net Volume Calculation
        // Correction Factors
        let final_fFactor = fFactor
        let final_ctlFactor = parseFloat(tempCorrectionFactor.toFixed(4))
        let final_cplFactor = parseFloat(pressureCorrection.toFixed(4))
        // TODO: maybe remove meter factor(mf) if it's already being taken into consideration elsewhere....
        // More accurate correctedApiGravity
        let final_ccf = parseFloat((carryThroughParams.mf * final_ctlFactor * final_cplFactor).toFixed(4))
        // let final_ccf = parseFloat(carryThroughParams.mf * final_ctlFactor * final_cplFactor).toFixed(5)
        let final_csw = parseFloat((1 - parseFloat(carryThroughParams.bsw) / 100).toFixed(5))
        // Volumes
        let final_iv = parseFloat(carryThroughParams.bbl.toFixed(2))
        let final_gsv = parseFloat((final_iv * final_ccf).toFixed(2))
        let final_nsv = parseFloat((final_gsv * final_csw).toFixed(2))
        let final_swv = parseFloat((final_gsv - final_nsv).toFixed(2))
        // Calculate Corrected Gravity
        let observedGravity = null
        let observedTemperature = null
        if (carryThroughParams != null && carryThroughParams['observedDensity'] != null) {
          observedGravity = carryThroughParams['observedDensity']
        }
        if (carryThroughParams != null && carryThroughParams['observedTemperature'] != null) {
          observedTemperature = carryThroughParams['observedTemperature']
        }
        let newCorrectedGravity = returnCorrectedGravity(observedGravity, observedTemperature)
        if (newCorrectedGravity == null) {
          // newCorrectedGravity = correctedApiGravity
          newCorrectedGravity = null
        }
        // Resolve
        resolve({
          success: true,
          netBbls: final_nsv,
          correctedApiGravity: newCorrectedGravity,
          // Volumes
          indicatedVolume: final_iv,
          grossStandardVolume: final_gsv,
          netStandardVolume: final_nsv,
          sedimentWaterVolume: final_swv,
          // Factors
          fFactor: final_fFactor,
          combinedCorrectionFactor: final_ccf,
          correctionForSedimentAndWater: final_csw,
          tempCorrectionFactor: final_ctlFactor,
          pressureCorrectionFactor: final_cplFactor,
          meterFactor: carryThroughParams.mf,
          bsw: carryThroughParams.bsw,
        })
      }
    } catch (err) {
      error.message = 'Unable to determine final correction factors'
      error.details = err
      error.code = 'ER-S-NB-DFCF-03'
      console.error(error)
      reject({ success: false, error: error })
    }
  })
}

///////////////////////////////
// Exports
///////////////////////////////

export const calculateNetBarrels = (
  observedDensity: number,
  observedTemperature: number,
  observedPressure: number,
  bsw: number,
  bbl: number,
  mf: number,
  topTemp: number,
  bottomTemp: number,
  commodity: 'crude oil' | 'fuel oil' | 'jet fuel' | 'transition zone' | 'gasoline' | 'lube oil',
  densityFormat: 'API' | 'SI',
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let error: TsInterface_UnspecifiedObject = { message: null, details: null, code: null }
    try {
      //========================================//
      //       calculateBaseDensity (Rho)       //
      //========================================//
      // Set variables
      let minDensity = 0
      let maxDensity = 0
      let dAlpha = 0
      let volumetricThermalExpansionCoefficient = 0.00045
      if (mf === 0) {
        mf = 1
      }
      let carryThroughParams: TsInterface_UnspecifiedObject = {
        bsw: bsw,
        bbl: bbl,
        mf: mf,
        topTemp: topTemp,
        bottomTemp: bottomTemp,
        observedDensity: observedDensity,
        observedTemperature: observedTemperature,
      }
      // Correct API Gravity - SPE 28944 - "New Method To Read and Correct the API Gravity of Oil From Observed Temperatures to 60 ºF"
      // Replaced
      carryThroughParams['correctedApiGravity'] =
        (observedDensity - 0.059175 * (observedTemperature - 60)) / (1.0 + volumetricThermalExpansionCoefficient * (observedTemperature - 60))
      // Variables Dependent on Commodity
      if (commodity == 'crude oil') {
        minDensity = 610.6
        maxDensity = 1163.5
        dAlpha = 2.0
      } else if (commodity == 'fuel oil') {
        minDensity = 838.3127
        maxDensity = 1163.5
        dAlpha = 1.3
      } else if (commodity == 'jet fuel') {
        minDensity = 787.5195
        maxDensity = 838.3127
        dAlpha = 2.0
      } else if (commodity == 'transition zone') {
        minDensity = 770.352
        maxDensity = 787.5195
        dAlpha = 8.5
      } else if (commodity == 'gasoline') {
        minDensity = 610.6
        maxDensity = 770.352
        dAlpha = 1.5
      } else if (commodity == 'lube oil') {
        minDensity = 800.9
        maxDensity = 1163.5
        dAlpha = 1.0
      } else {
        error.message = 'Commodity Not Set'
        error.details = 'Commodity must be set in order to run net bbl calculation'
        error.code = 'ER-S-NB-CNB-01'
        console.error(error)
        reject({ success: false, error: error })
      }
      // Convert Base Density to Correct Units
      let convertedDensity
      if (densityFormat === 'API') {
        convertedDensity = (141.5 / (observedDensity + 131.5)) * 999.016
      } else {
        convertedDensity = observedDensity
      }
      // Step 1 - Check the Input Values to determine if they are in the range of the Standard
      if (observedTemperature < -58.0 || observedTemperature > 302.2) {
        error.message = 'Temperatures out of Standard Range'
        error.details = 'observedTemperature is set to: ' + observedTemperature
        error.code = 'ER-S-NB-CNB-02'
        console.error(error)
        reject({ success: false, error: error })
      } else if (observedPressure > 1500) {
        error.message = 'Pressure out of Standard Range'
        error.details = 'observedPressure is set to: ' + observedPressure
        error.code = 'ER-S-NB-CNB-03'
        console.error(error)
        reject({ success: false, error: error })
      } else if (convertedDensity < minDensity || convertedDensity > maxDensity) {
        error.message = 'Density out of Standard Range'
        error.details = 'convertedDensity is set to: ' + convertedDensity
        error.code = 'ER-S-NB-CNB-04'
        console.error(error)
        reject({ success: false, error: error })
      }
      // Get rid of negative pressures
      if (observedPressure < 0) {
        observedPressure = 0
      }
      // Step 2 - Use the observed density as the initial guess for the base density
      // Step 3 - Calculate the Correction Factor due to temperature and pressure (11.1.6.1)
      let iterator = 0
      // Iterate until achieve the correct value
      iterateBaseDensity(
        commodity,
        convertedDensity,
        convertedDensity,
        observedTemperature,
        observedPressure,
        dAlpha,
        iterator,
        densityFormat,
        carryThroughParams,
        true,
      )
        .then((iterationResult) => {
          resolve(iterationResult)
        })
        .catch((iterationResultReject) => {
          reject(iterationResultReject)
        })
    } catch (err) {
      error.message = 'Failed to Calculate Net BBLs'
      error.details = err
      error.code = 'ER-S-NB-CNB-05'
      console.error(error)
      reject({ success: false, error: error })
    }
  })
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { returnTableColumnsFromDatabaseFormDataFormat } from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ActiveTransactionalClasses_Query,
  DatabaseRef_ClassFields_Collection,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveDataBucketsWithSpecificClass_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_DataForSpecificClass_DateRange_Query,
  DatabaseRef_DataForSpecificDataBucket_DateRange_Query,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_Snackbar } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AllDataListPage']['key']

// Table Settings
let tableSettings_FilteredData: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  size: 'small',
  sort_direction: 'desc',
  sort_property_default: 'timestamp_primary_query',
  sortable: false,
  collapsible_columns: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 195px)',
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_loadedFormFields, us_setLoadedFormFields ] = 					useState<boolean>(false)
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filterStartDate, us_setFilterStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [us_filterEndDate, us_setFilterEndDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1))
  const [us_formFields, us_setFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingData, us_setLoadingData] = useState<boolean>(false)
  const [us_qRCodes, us_setQRCodes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_queriedData, us_setQueriedData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClass, us_setSelectedClass] = useState<string>('')
  const [us_selectedDataBucket, us_setSelectedDataBucket] = useState<string>('')
  const [us_tableColumns_FilteredData, us_setTableColumns_FilteredData] = useState<TsInterface_TableColumns>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('All Data', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTransactionalClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setQRCodes(newData)
      ur_forceRerender()
    }
    if (us_selectedClass != null && us_selectedClass !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveDataBucketsWithSpecificClass_Query(res_GCK.clientKey, us_selectedClass),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedClass])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadingData(false)
      us_setQueriedData(newData)
      ur_forceRerender()
    }
    let startDate = new Date(us_filterStartDate.getFullYear(), us_filterStartDate.getMonth(), us_filterStartDate.getDate(), 0, 0, 0)
    let endDate = new Date(us_filterEndDate.getFullYear(), us_filterEndDate.getMonth(), us_filterEndDate.getDate(), 0, 0, 0)
    if (us_selectedDataBucket != null && us_selectedDataBucket !== '') {
      us_setLoadingData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_DataForSpecificDataBucket_DateRange_Query(res_GCK.clientKey, us_selectedDataBucket, startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else if (us_selectedClass != null && us_selectedClass !== '') {
      us_setLoadingData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_DataForSpecificClass_DateRange_Query(res_GCK.clientKey, us_selectedClass, startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setQueriedData({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_filterStartDate, us_selectedClass, us_selectedDataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // us_setLoadedFormFields( true )
      us_setFormFields(newData)
      ur_forceRerender()
    }
    if (us_selectedClass != null && us_selectedClass !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_selectedClass), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      // us_setLoadedFormFields( false )
      us_setFormFields({})
      ur_forceRerender()
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedClass])

  useEffect(() => {
    us_setTableColumns_FilteredData(returnTableColumnsFromDatabaseFormDataFormat(us_formFields, {}, {}, { includeDataOriginationSource: true }))
  }, [uc_setUserInterface_SnackbarDisplay, us_formFields])

  // Functions
  const changeCalendarStartDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setFilterStartDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const changeCalendarEndDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setFilterEndDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_SelectedClassDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block">
        <FormControl
          className="bp_thin_select_input"
          sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px', background: themeVariables.white }}
        >
          <Select
            onChange={(event, value) => {
              us_setSelectedClass(event.target.value)
              us_setSelectedDataBucket('')
            }}
            value={us_selectedClass || ''}
            disabled={us_loadingData === true}
          >
            {objectToArray(us_activeClasses)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                >
                  <Box
                    sx={{ color: themeVariables.error_main }}
                    className="tw-inline-block"
                  >
                    {rLIB('Form')}:
                  </Box>
                  &nbsp;
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_StartDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_filterStartDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarStartDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_EndDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_filterEndDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarEndDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_QRCodeSelect = (): JSX.Element => {
    let selectJSX = <></>
    if (us_qRCodes != null && objectToArray(us_qRCodes).length > 0) {
      selectJSX = (
        <Box className="tw-inline-block tw-mr-2">
          <FormControl
            className="bp_thin_select_input tw-mr-2"
            sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px', background: themeVariables.white }}
          >
            <Select
              onChange={(event, value) => {
                us_setSelectedDataBucket(event.target.value)
              }}
              value={us_selectedDataBucket || ''}
              disabled={us_loadingData === true}
              // label={ rLIB("Data Bucket") }
            >
              <MenuItem value={''}>{rLIB("Don't filter by Data Bucket")}</MenuItem>
              {objectToArray(us_qRCodes)
                .sort(dynamicSort('name', null))
                .map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                  >
                    <Box
                      sx={{ color: themeVariables.error_main }}
                      className="tw-inline-block"
                    >
                      {rLIB('Data Bucket')}:
                    </Box>
                    &nbsp;
                    {option['name']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )
    }
    return selectJSX
  }

  // const rJSX_CurrentTableCount = (): JSX.Element => {
  //   let tableCountJSX = (
  //     <Box className="tw-inline-block tw-align-top tw-mt-1.5 tw-opacity-50">
  //       {s_SHOWING} {objectToArray(us_queriedData).length} {objectToArray(us_queriedData).length === 1 ? s_RECORD : s_RECORDS}
  //     </Box>
  //   )
  //   return tableCountJSX
  // }

  const rJSX_DataTable = (): JSX.Element => {
    let tabContentJSX = <></>
    if (
      // us_loadedFormFields === true &&
      us_tableColumns_FilteredData != null &&
      objectToArray(us_tableColumns_FilteredData).length > 0 &&
      us_queriedData != null &&
      objectToArray(us_queriedData).length > 0
    ) {
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableBasic
              tableAdditionalData={{
                editable: true,
              }}
              tableColumns={us_tableColumns_FilteredData}
              tableData={objectToArray(us_queriedData)}
              tableSettings={tableSettings_FilteredData}
            />
          </Card>
          {/* <Box className="tw-text-right">{rJSX_CurrentTableCount()}</Box> */}
        </Box>
      )
    } else if (
      // us_loadedFormFields === true &&
      us_queriedData != null &&
      objectToArray(us_queriedData).length === 0
    ) {
      tabContentJSX = (
        <Box>
          <Card className="tw-text-center tw-py-4">
            <Typography variant="h6">{rLIB('No data found for the selected date and filter options')}</Typography>
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('All Data')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              {rJSX_StartDatePicker()}
              {rJSX_EndDatePicker()}
              {rJSX_SelectedClassDropdown()}
              {rJSX_QRCodeSelect()}
            </Box>
            <Box>{rJSX_DataTable()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Tasks_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'main')
}

export const DatabaseRef_Tasks_Document = (clientKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'main', taskKey)
}

export const DatabaseRef_TasksWithStatus_Query = (clientKey: string, status: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: status }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_TaskIdNumbers_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'id_numbers', 'counter')
}

export const DatabaseRef_TaskLogs_Collection = (clientKey: string, taskKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'main', taskKey, 'logs')
}

export const DatabaseRef_TaskLogs_Query = (clientKey: string, taskKey: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'main', taskKey, 'logs'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

export const DatabaseRef_TaskLogs_Document = (clientKey: string, taskKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'main', taskKey, 'logs', logKey)
}

export const DatabaseRef_TaskSettings_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'transactional_data', 'tasks', 'settings', 'all')
}

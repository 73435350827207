//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Local Storage on form changes
[  ]

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, CircularProgress, Divider, Stack, Typography } from '@mui/material/'
import { cloudFunctionUnauthenticatedRequests } from 'app/services/external_requests/external_requests'
import { returnCleanFormDataReadyForSubmission, returnFormInputsFromDatabaseDataFormat } from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { rJSX_DynamicApplicationLogoSvg } from 'rfbp_aux/components/dynamic_app_branding'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Form, TsInterface_FormInputs, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { dynamicSort, getProp, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getPageLocalStorage, setPageLocalStorage } from 'rfbp_core/services/local_storage'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { taskTemplateStepTypes } from '../data_tasks/task_template_view'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

let workflowCss: string = `
		@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');
	`

const getDeviceType = () => {
  const userAgent = navigator.userAgent
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'apple'
  } else if (/Android/i.test(userAgent)) {
    return 'android'
  } else if (/Macintosh|Mac OS X/i.test(userAgent)) {
    return 'apple'
  } else if (/Windows/i.test(userAgent)) {
    return 'pc'
  } else {
    return 'other'
  }
}

///////////////////////////////
// Functions
///////////////////////////////

function openNavigation(lat: number, lng: number) {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent)

  const locationUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`

  if (isMobile) {
    // On mobile, attempt to open the default maps app based on OS
    const androidScheme = `geo:${lat},${lng}?q=${lat},${lng}`
    const iosScheme = `maps://?q=${lat},${lng}`

    // Check if it's iOS
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = iosScheme
    } else {
      // Assume Android for other mobile devices
      window.location.href = androidScheme
    }
  } else {
    // Open Google Maps in browser for desktop users
    window.open(locationUrl, '_blank')
  }
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const clientKey: string = params.cid as string
  const taskKey: string = params.tid as string
  const userKey: string = params.uid as string

  // Hooks - useContext, useState, useReducer, other
  const [us_deviceType, us_setDeviceType] = useState<string>(getDeviceType())
  const [us_forceReloadTimestamp, us_setForceReloadTimestamp] = useState<number>(0)
  const [us_buttonToShowLoading, us_setButtonToShowLoading] = useState<string | null>(null)
  const [us_pageView, us_setPageView] = useState<string>('checklist')
  const [us_selectedStep, us_setSelectedStep] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskCompletionOrderType, us_setTaskCompletionOrderType] = useState<'sequential' | 'any_order'>('sequential')
  const [us_loadingTaskData, us_setLoadingTaskData] = useState<boolean>(true)
  const [us_errorData, us_setErrorData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_task, us_setTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userTaskData, us_setUserTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userLocalStorageTaskData, us_setUserLocalStorageTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_allTaskMetadata, us_setAllTaskMetadata] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskLoadStatus, us_setTaskLoadStatus] = useState<string>('start') // start, error, require_app, form, submitting, success
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_submissionResults, us_setSubmissionResults] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_submittingTask, us_setSubmittingTask] = useState<boolean>(false)

  // Hooks - useEffect
  useEffect(() => {
    us_setDeviceType(getDeviceType())
    return () => {}
  }, [])

  useEffect(() => {
    // Runs one time after data is loaded from cloud function
    if (taskKey != null && us_loadingTaskData === false) {
      let pageKey = 'tasks/' + clientKey + '/' + taskKey + '/' + userKey
      let pageLocalStorageData = getPageLocalStorage(pageKey)
      // Loop through all steps and add to us_allTaskMetadata if stepKey is not present in us_allTaskMetadata
      let mergedUserTaskDataObject: TsInterface_UnspecifiedObject = {}
      // Add from local storage unless the step already has data
      for (let stepKey in pageLocalStorageData) {
        if (getProp(mergedUserTaskDataObject, stepKey, null) == null) {
          mergedUserTaskDataObject[stepKey] = getProp(pageLocalStorageData, stepKey, {})
        }
      }
      us_setUserLocalStorageTaskData({ steps: mergedUserTaskDataObject })
    }
    return () => {}
  }, [clientKey, userKey, taskKey, us_loadingTaskData])

  useEffect(() => {
    if (taskKey != null && (us_taskLoadStatus === 'start' || us_taskLoadStatus === 'checklist' || us_taskLoadStatus === 'pending_acceptance')) {
      if (us_forceReloadTimestamp > 0) {
        // Just for reload
      }
      cloudFunctionUnauthenticatedRequests({
        function: 'get_task_data',
        client_key: clientKey,
        task_key: taskKey,
        user_key: userKey,
      })
        .then((res_CFUR) => {
          let taskData = getProp(getProp(res_CFUR, 'data', {}), 'data', {})
          let task = getProp(taskData, 'task', {})
          if (task != null && task.task_procedures != null && task.task_procedures.require_app === true) {
            us_setTaskLoadStatus('require_app')
          } else {
            us_setAllTaskMetadata(taskData)
            us_setUserTaskData(getProp(taskData, 'user_task_data', {}))
            us_setTask(task)
            us_setLoadingTaskData(false)
            if (task != null && task['task_procedures'] != null && task['task_procedures']['step_order_of_completion'] != null) {
              us_setTaskCompletionOrderType(task['task_procedures']['step_order_of_completion'])
            }
            if (
              task != null &&
              task['associated_assigned_users_history'] != null &&
              task['associated_assigned_users_history'][userKey] != null &&
              task['associated_assigned_users_history'][userKey]['status'] === 'assigned'
            ) {
              us_setTaskLoadStatus('pending_acceptance')
            } else {
              us_setTaskLoadStatus('checklist')
            }
            ur_forceRerender()
          }
        })
        .catch((rej_CFUR) => {
          if (rej_CFUR != null && rej_CFUR.response != null && rej_CFUR.response.data != null && rej_CFUR.response.data.error != null) {
            us_setErrorData(rej_CFUR.response.data.error)
          }

          console.log(rej_CFUR)

          // Task has been completed
          if (rej_CFUR != null && rej_CFUR.response != null && rej_CFUR.response.data != null && rej_CFUR.response.data.task_completed === true) {
            us_setTaskLoadStatus('success')
            us_setSubmissionResults({ confirmation_number: rej_CFUR.response.data.confirmation_number })
          } else {
            us_setTaskLoadStatus('error')
          }
        })
    }
    return () => {}
  }, [clientKey, taskKey, ur_forceRerender, us_taskLoadStatus, userKey, us_forceReloadTimestamp])

  // Functions
  const returnSortedChecklistItems = (): TsInterface_UnspecifiedObject[] => {
    let sortedChecklistItems: TsInterface_UnspecifiedObject[] = []
    if (us_allTaskMetadata != null && us_allTaskMetadata['task'] != null && us_allTaskMetadata['task']['task_steps'] != null) {
      for (let stepKey in us_allTaskMetadata['task']['task_steps']) {
        let step = us_allTaskMetadata['task']['task_steps'][stepKey]
        if (step != null && step['status'] === 'active' && (step['step_type'] === 'navigation' || step['step_type'] === 'form')) {
          let stepObject: TsInterface_UnspecifiedObject = { ...step }
          if (us_allTaskMetadata['additional_task_step_data'] != null && us_allTaskMetadata['additional_task_step_data'][stepKey] != null) {
            stepObject['additional_task_step_data'] = us_allTaskMetadata['additional_task_step_data'][stepKey]
          }
          if (us_userTaskData != null && us_userTaskData['steps'] != null && us_userTaskData['steps'][stepKey] != null) {
            stepObject['user_task_data'] = us_userTaskData['steps'][stepKey]
          }
          // // Loop through and add user local storage data if it exists
          // if (
          //   us_userLocalStorageTaskData != null &&
          //   us_userLocalStorageTaskData[stepKey] != null &&
          //   (us_userTaskData == null || us_userTaskData['steps'] == null || us_userTaskData['steps'][stepKey] == null)
          // ) {
          //   stepObject['user_task_data'] = us_userLocalStorageTaskData[stepKey]
          // }
          sortedChecklistItems.push(stepObject)
        }
      }
    }
    return sortedChecklistItems.sort(dynamicSort('order', 'asc'))
  }

  const startTask = () => {
    us_setButtonToShowLoading('start_task')
    let taskUserDataUpdateObject = {
      status: 'in_progress',
      associated_assigned_users_history: {
        [userKey]: {
          status: 'in_progress',
        },
      },
    }
    cloudFunctionUnauthenticatedRequests({
      function: 'save_task_root_data',
      client_key: clientKey,
      task_key: taskKey,
      user_key: userKey,
      task_data: taskUserDataUpdateObject,
    })
      .then((res_CFUR) => {
        us_setButtonToShowLoading(null)
        us_setForceReloadTimestamp(new Date().getTime())
        setTimeout(() => {
          us_setForceReloadTimestamp(new Date().getTime())
          ur_forceRerender()
        }, 1000)
      })
      .catch((rej_CFUR) => {
        console.error('rej_CFUR', rej_CFUR)
        us_setButtonToShowLoading(null)
      })
  }

  const submitTask = () => {
    // TODO: Submit Task
    // us_setSubmittingTask(true)
    us_setTaskLoadStatus('submitting')
    cloudFunctionUnauthenticatedRequests({
      function: 'submit_task',
      client_key: clientKey,
      task_key: taskKey,
      user_key: userKey,
    })
      .then((res_CFUR) => {
        us_setTaskLoadStatus('success')
        us_setSubmissionResults(getProp(res_CFUR, 'data', {}))
      })
      .catch((rej_CFUR) => {
        if (rej_CFUR != null && rej_CFUR.response != null && rej_CFUR.response.data != null && rej_CFUR.response.data.error != null) {
          us_setErrorData(rej_CFUR.response.data.error)
        }
        us_setTaskLoadStatus('error')
      })
  }

  const completeStep = (step: TsInterface_UnspecifiedObject, stepData: TsInterface_UnspecifiedObject) => {
    us_setButtonToShowLoading('step_' + step.key)
    let taskUserDataUpdateObject: TsInterface_UnspecifiedObject = {
      steps: {
        [step.key]: {
          status: 'complete',
          timestamp_completed: new Date(),
          data: stepData,
        },
      },
    }
    cloudFunctionUnauthenticatedRequests({
      function: 'save_task_data',
      client_key: clientKey,
      task_key: taskKey,
      user_key: userKey,
      task_data: taskUserDataUpdateObject,
    })
      .then((res_CFUR) => {
        us_setForceReloadTimestamp(new Date().getTime())
        setTimeout(() => {
          us_setForceReloadTimestamp(new Date().getTime())
          ur_forceRerender()
        }, 1000)
        us_setButtonToShowLoading(null)
      })
      .catch((rej_CFUR) => {
        console.error('rej_CFUR', rej_CFUR)
        us_setButtonToShowLoading(null)
      })
  }

  const findCurrentActiveStepIndex = (): number | null => {
    const sortedSteps = returnSortedChecklistItems()
    for (let i = 0; i < sortedSteps.length; i++) {
      const step = sortedSteps[i]
      if (step.user_task_data?.status !== 'complete') {
        return i
      }
    }
    return null // All steps are completed
  }

  const formOnChange: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
    // Loop through and add to us_setAllTaskMetadata
    let pageKey = 'tasks/' + clientKey + '/' + taskKey + '/' + userKey
    setPageLocalStorage(pageKey, us_selectedStep.key, {
      data: returnCleanFormDataReadyForSubmission(formData, formInputs),
    })
  }

  const formSubmission: TsType_FormSubmission = (formSubmittedData, formAdditionalData) => {
    console.log(us_selectedStep)
    us_setButtonToShowLoading('step_' + us_selectedStep.key)
    return new Promise((resolve, reject) => {
      let formInputs: TsInterface_FormInputs = {}
      // Generate Form Inputs
      if (us_selectedStep != null && us_selectedStep.additional_task_step_data != null && us_selectedStep.additional_task_step_data.form != null) {
        formInputs = returnFormInputsFromDatabaseDataFormat(
          us_selectedStep.additional_task_step_data.form,
          clientKey,
          getProp(us_allTaskMetadata, 'directory_verification_options', {}), // TODO: Check if this is correct
        )
      }
      // Generate Update Object
      let taskUserDataUpdateObject: TsInterface_UnspecifiedObject = {
        steps: {
          [us_selectedStep.key]: {
            status: 'complete',
            timestamp_completed: new Date(),
            data: returnCleanFormDataReadyForSubmission(formSubmittedData, formInputs),
          },
        },
      }
      // Save
      cloudFunctionUnauthenticatedRequests({
        function: 'save_task_data',
        client_key: clientKey,
        task_key: taskKey,
        user_key: userKey,
        task_data: taskUserDataUpdateObject,
      })
        .then((res_CFUR) => {
          resolve({ success: true })
          us_setForceReloadTimestamp(new Date().getTime())
          us_setPageView('checklist')
          us_setButtonToShowLoading(null)
        })
        .catch((rej_CFUR) => {
          console.error('rej_CFUR', rej_CFUR)
          reject({ success: false })
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFUR.error })
          us_setButtonToShowLoading(null)
        })
    })
  }

  // JSX Generation
  const rJSX_StartContent = (): JSX.Element => {
    let contentJSX = (
      <Box className="tw-text-center">
        <Box
          className="tw-mb-6"
          sx={{ paddingTop: '30vh' }}
        >
          {rJSX_DynamicApplicationLogoSvg('80px', {}, {})}
        </Box>
        <CircularProgress
          color="error"
          size={50}
        />
        <Typography
          variant="h6"
          className="tw-mt-6"
        >
          {rLIB('Loading Form')}
        </Typography>
      </Box>
    )
    return contentJSX
  }

  const rJSX_ErrorContent = (): JSX.Element => {
    let contentJSX = (
      <Box
        className="tw-text-center"
        sx={{ marginTop: '30vh' }}
      >
        <Box className="tw-mb-6">
          <Typography
            sx={{
              fontSize: '50px',
              color: themeVariables.error_main,
              fontFamily: "'Roboto Slab', serif",
            }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{
                fontSize: '44px',
              }}
            />
            {rLIB('Error')}
          </Typography>
          <style>{workflowCss}</style>
          <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
            <Typography variant="h5">{getProp(us_errorData, 'message', rLIB('An unknown error has occurred'))}</Typography>
            <Typography variant="h6">{getProp(us_errorData, 'details', rLIB('Contact support if this error persists'))}</Typography>
            <Typography className="tw-opacity-30 tw-mt-2">{getProp(us_errorData, 'code', 'ER-D-QECF-UKN-01')}</Typography>
          </Box>
        </Box>
      </Box>
    )
    return contentJSX
  }

  // Checklist
  const rJSX_StepContent = (step: TsInterface_UnspecifiedObject, disableAll: boolean): JSX.Element => {
    let contentJSX = <></>
    if (getProp(step, 'step_type', 'form') === 'navigation') {
      contentJSX = rJSX_NavigationStepContent(step, disableAll)
    } else if (getProp(step, 'step_type', 'form') === 'form') {
      contentJSX = rJSX_FormStepContent(step, disableAll)
    }
    return contentJSX
  }

  const rJSX_NavigationStepContent = (step: TsInterface_UnspecifiedObject, disableAll: boolean): JSX.Element => {
    let lat: number | null = null
    let lng: number | null = null
    if (
      step != null &&
      step.additional_task_step_data != null &&
      step.additional_task_step_data.associated_directory_item != null &&
      step.additional_task_step_data.associated_directory_item.latitude != null
    ) {
      lat = step.additional_task_step_data.associated_directory_item.latitude
    }
    if (
      step != null &&
      step.additional_task_step_data != null &&
      step.additional_task_step_data.associated_directory_item != null &&
      step.additional_task_step_data.associated_directory_item.longitude != null
    ) {
      lng = step.additional_task_step_data.associated_directory_item.longitude
    }
    // Step Completed
    let stepCompleted: boolean = false
    if (step != null && step.user_task_data != null && step.user_task_data.status === 'complete') {
      stepCompleted = true
    }
    // Navigation Location
    let navigationLocationLine1JSX = <></>
    let navigationLocationLine2JSX = <></>
    if (
      step != null &&
      step.key != null &&
      us_task != null &&
      us_task.task_step_associations != null &&
      us_task.task_step_associations[step.key] != null &&
      us_task.task_step_associations[step.key].associated_directory_item_name != null
    ) {
      navigationLocationLine1JSX = (
        <Typography className="tw-opacity-70 tw-ml-4">{us_task.task_step_associations[step.key].associated_directory_item_name}</Typography>
      )
    }
    if (lat != null && lng != null) {
      navigationLocationLine2JSX = (
        <Typography className="tw-opacity-70 tw-ml-4">
          ({lat}, {lng})
        </Typography>
      )
    }
    // Buttons
    let contentJSX = (
      <Box>
        <Typography>{step.name}</Typography>
        <Box>
          {navigationLocationLine1JSX}
          {navigationLocationLine2JSX}
        </Box>
        <Box className="tw-mt-2 tw-text-right">
          <Button
            variant="outlined"
            color="error"
            startIcon={<Icon icon="diamond-turn-right" />}
            className="tw-mr-2"
            disabled={lat == null || lng == null || disableAll || us_buttonToShowLoading !== null}
            onClick={() => {
              if (lat != null && lng != null) {
                openNavigation(lat, lng)
              }
            }}
          >
            {rLIB('Directions')}
          </Button>
          <Button
            variant="outlined"
            color="success"
            startIcon={
              us_buttonToShowLoading === 'step_' + step.key ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                <Icon icon="square-check" />
              )
            }
            disabled={stepCompleted || disableAll || us_buttonToShowLoading !== null}
            onClick={() => {
              completeStep(step, {})
            }}
          >
            {rLIB('Complete Step')}
          </Button>
        </Box>
        {rJSX_CompletionTimestamp(step)}
      </Box>
    )
    return contentJSX
  }

  const rJSX_CompletionTimestamp = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let stepCompletionJSX = <></>
    if (step != null && step.user_task_data != null && step.user_task_data.status === 'complete' && step.user_task_data.timestamp_completed != null) {
      stepCompletionJSX = (
        <Box
          className="tw-text-right tw-mt-1"
          sx={{ color: themeVariables.success_main }}
        >
          <Typography>
            {rLIB('Completed')} {returnFormattedDate(returnDateFromUnknownDateFormat(step.user_task_data.timestamp_completed), 'YYYY-MM-DD h:mm a')}
          </Typography>
        </Box>
      )
    }
    return stepCompletionJSX
  }

  const rJSX_FormStepContent = (step: TsInterface_UnspecifiedObject, disableAll: boolean): JSX.Element => {
    let stepCompleted: boolean = false
    if (step != null && step.user_task_data != null && step.user_task_data.status === 'complete') {
      stepCompleted = true
    }
    let contentJSX = (
      <Box>
        <Typography>{step.name}</Typography>
        <Box className="tw-mt-2 tw-text-right">
          <Button
            variant="outlined"
            color="info"
            startIcon={
              us_buttonToShowLoading === 'step_' + step.key ? (
                <Icon
                  icon="arrows-rotate"
                  className="bp_spin"
                />
              ) : (
                <Icon icon="clipboard-list-check" />
              )
            }
            disabled={stepCompleted || disableAll || us_buttonToShowLoading !== null}
            onClick={() => {
              us_setPageView('form')
              us_setSelectedStep(step)
            }}
          >
            {rLIB('Fill Out Form')}
          </Button>
        </Box>
        {rJSX_CompletionTimestamp(step)}
      </Box>
    )
    return contentJSX
  }

  const rJSX_StepStatus = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let contentJSX = <></>
    if (step != null && step.user_task_data != null && step.user_task_data.status === 'complete') {
      contentJSX = (
        <Icon
          icon="square-check"
          sx={{ color: themeVariables.success_main, fontSize: '28px' }}
        />
      )
    } else {
      contentJSX = (
        <Icon
          icon="square"
          type="light"
          sx={{ color: themeVariables.gray_400, fontSize: '28px' }}
        />
      )
    }
    return contentJSX
  }

  const rJSX_ChecklistItem = (step: TsInterface_UnspecifiedObject, index: number, disableAll: boolean): JSX.Element => {
    let borderColor: string = themeVariables.white
    if (step != null && step.user_task_data != null && step.user_task_data.status === 'complete') {
      borderColor = themeVariables.success_main
    }
    let opacity: number = 1
    if (us_taskCompletionOrderType === 'sequential') {
      let currentActiveStepIndex = findCurrentActiveStepIndex()
      if (currentActiveStepIndex != null && index - 1 > currentActiveStepIndex) {
        opacity = 0.4
        disableAll = true
      }
    }
    let contentJSX = (
      <Card
        className="tw-mb-2"
        sx={{ opacity: opacity }}
      >
        <Box
          sx={{
            border: '2px solid ' + borderColor + ' !important',
            borderRadius: '10px',
          }}
        >
          <Box className="tw-px-2 tw-pt-2">
            <Stack
              direction="row"
              spacing={2}
              className="tw-justify-between"
            >
              <Stack
                direction="row"
                spacing={1}
              >
                <Box>{rJSX_StepStatus(step)}</Box>
                <Typography
                  sx={{ fontWeight: 500 }}
                  variant="h6"
                >
                  {rLIB('Step')} {index}
                </Typography>
              </Stack>
              <Icon
                className="tw-mr-2"
                icon={getProp(taskTemplateStepTypes, getProp(step, 'step_type', 'form'), {}).icon}
                sx={{ color: getProp(taskTemplateStepTypes, getProp(step, 'step_type', 'form'), {}).icon_color, fontSize: '24px' }}
              />
            </Stack>
          </Box>
          <Divider className="tw-mt-2" />
          <Box className="tw-p-2">{rJSX_StepContent(step, disableAll)}</Box>
        </Box>
      </Card>
    )
    return contentJSX
  }

  const rJSX_SubmitTaskButton = (): JSX.Element => {
    let buttonDisabled: boolean = false
    let orderedChecklistItems = returnSortedChecklistItems()
    for (let loopStepIndex in orderedChecklistItems) {
      let loopStep = orderedChecklistItems[loopStepIndex]
      if (loopStep != null && (loopStep.user_task_data == null || loopStep.user_task_data.status != 'complete')) {
        buttonDisabled = true
      }
    }
    // JSX
    let submitTaskButtonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={
          us_taskLoadStatus === 'submitting' ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="square-check" />
          )
        }
        sx={{ fontSize: '18px' }}
        disabled={buttonDisabled || us_taskLoadStatus === 'submitting'}
        onClick={() => {
          submitTask()
        }}
      >
        {rLIB('Submit Task')}
      </Button>
    )
    return submitTaskButtonJSX
  }

  const rJSX_ChecklistContent = (): JSX.Element => {
    let contentJSX = (
      <Box
        className="tw-p-4 tw-m-auto tw-mt-4"
        sx={{ maxWidth: '800px' }}
      >
        <Box className="tw-text-center">
          <Typography
            variant="h5"
            className="tw-mb-2 tw-m-auto"
            sx={{ fontWeight: 'bold' }}
          >
            {rLIB('Task')} {getProp(us_task, 'id_number', '')}
          </Typography>
        </Box>
        <Box>
          {returnSortedChecklistItems().map((step, index) => (
            <Box key={index}>{rJSX_ChecklistItem(step, index + 1, false)}</Box>
          ))}
        </Box>
        <Box className="tw-mt-4 tw-text-center">{rJSX_SubmitTaskButton()}</Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_FormContent = (): JSX.Element => {
    let formInputs: TsInterface_FormInputs = {}
    // Generate Form Inputs
    if (us_selectedStep != null && us_selectedStep.additional_task_step_data != null && us_selectedStep.additional_task_step_data.form != null) {
      formInputs = returnFormInputsFromDatabaseDataFormat(
        us_selectedStep.additional_task_step_data.form,
        clientKey,
        getProp(us_allTaskMetadata, 'directory_verification_options', {}), // TODO: Check if this is correct
      )
    }
    // Form Data
    let formData: TsInterface_UnspecifiedObject = {}
    if (us_selectedStep != null && us_selectedStep.user_task_data != null && us_selectedStep.user_task_data.data != null) {
      formData = us_selectedStep.user_task_data.data
    } else if (
      us_userLocalStorageTaskData != null &&
      us_userLocalStorageTaskData['steps'] != null &&
      us_userLocalStorageTaskData['steps'][us_selectedStep.key] != null &&
      us_userLocalStorageTaskData['steps'][us_selectedStep.key].data != null
    ) {
      formData = us_userLocalStorageTaskData['steps'][us_selectedStep.key].data
    }
    // JSX
    let contentJSX = (
      <Box
        className="tw-p-4 tw-m-auto tw-mt-4"
        sx={{ maxWidth: '800px' }}
      >
        <Box className="tw-text-center">
          <Typography
            variant="h5"
            className="tw-mb-0 tw-m-auto"
            sx={{ fontWeight: 'bold' }}
          >
            {rLIB('Task')} {getProp(us_task, 'id_number', '')}
          </Typography>
          <Typography
            variant="body1"
            className="tw-mb-2 tw-m-auto"
            // sx={{ fontWeight: 'bold' }}
          >
            {getProp(us_selectedStep, 'name', '')}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Icon icon="arrow-left" />}
            className="tw-mb-2"
            onClick={() => {
              us_setPageView('checklist')
              us_setSelectedStep({})
            }}
          >
            {rLIB('Back to Checklist')}
          </Button>
        </Box>
        <Box>
          <Card className="tw-mb-2">
            <Box className="tw-p-2">
              <Typography>{rLIB('Form')}</Typography>
              <Form
                formAdditionalData={{}}
                formData={formData} // TODO: Add form data
                formInputs={formInputs}
                formOnChange={formOnChange}
                formSubmission={formSubmission}
                formSettings={{}}
              />
            </Box>
          </Card>
        </Box>
      </Box>
    )
    return contentJSX
  }

  // TODO: Implement on all other buttons
  const rJSX_ButtonIcon = (spinningStatus: string, icon: string): JSX.Element => {
    if (us_buttonToShowLoading === spinningStatus) {
      return (
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
        />
      )
    } else {
      return <Icon icon={icon} />
    }
  }

  const rJSX_PendingAcceptanceContent = (): JSX.Element => {
    let contentJSX = (
      <Box
        className="tw-p-4 tw-m-auto tw-mt-4"
        sx={{ maxWidth: '800px' }}
      >
        <Box className="tw-text-center">
          <Typography
            variant="h5"
            className="tw-mb-0 tw-m-auto"
            sx={{ fontWeight: 'bold' }}
          >
            {rLIB('Task')} {getProp(us_task, 'id_number', '')}
          </Typography>
        </Box>
        <Box className="tw-mt-2 tw-text-center">
          <Button
            variant="contained"
            color="success"
            startIcon={rJSX_ButtonIcon('start_task', 'play')}
            disabled={us_buttonToShowLoading !== null}
            onClick={() => {
              startTask()
            }}
          >
            <Typography variant="h6">{rLIB('Start Task')}</Typography>
          </Button>
        </Box>
        <Box className="tw-mt-2 tw-opacity-40">
          {returnSortedChecklistItems().map((step, index) => (
            <Box key={index}>{rJSX_ChecklistItem(step, index + 1, true)}</Box>
          ))}
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_RequireAppContent = (): JSX.Element => {
    let appStoreIconJSX = <></>
    let appTextColor = themeVariables.info_main
    let appStoreLinksJSX = <></>
    if (us_deviceType === 'apple') {
      appStoreIconJSX = <i className="fa-brands fa-apple tw-mr-2"></i>
      appStoreLinksJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '24px' }}
            onClick={() => {
              // TODO: Open app store link
            }}
          >
            <i className="fa-brands fa-app-store-ios tw-mr-2"></i>
            {rLIB('App Store')}
          </Button>
        </Box>
      )
    } else if (us_deviceType === 'android') {
      appTextColor = themeVariables.success_main
      appStoreIconJSX = <i className="fa-brands fa-android tw-mr-2"></i>
      appStoreLinksJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '24px' }}
            onClick={() => {
              // TODO: Open app store link
            }}
          >
            <i className="fa-brands fa-google-play tw-mr-2"></i>
            {rLIB('Google Play')}
          </Button>
        </Box>
      )
    } else {
      appStoreIconJSX = (
        <Icon
          icon="mobile"
          className="tw-mr-2"
        />
      )
      appStoreLinksJSX = (
        <Box className="tw-text-center tw-mt-4">
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '18px' }}
            className="tw-mr-2 tw-mb-2"
            onClick={() => {
              // TODO: Open app store link
            }}
            startIcon={<i className="fa-brands fa-app-store-ios"></i>}
          >
            {rLIB('App Store')}
          </Button>
          <Button
            variant="contained"
            sx={{ background: themeVariables.background_json, fontSize: '18px' }}
            className="tw-mb-2"
            onClick={() => {
              // TODO: Open app store link
            }}
            startIcon={<i className="fa-brands fa-google-play"></i>}
          >
            {rLIB('Google Play')}
          </Button>
        </Box>
      )
    }

    // Full JSX
    let contentJSX = (
      <Box
        className="tw-text-center"
        sx={{ marginTop: '30vh' }}
      >
        <Box className="tw-mb-6">
          <Typography
            sx={{
              fontSize: '40px',
              color: appTextColor,
              fontFamily: "'Roboto Slab', serif",
            }}
          >
            {appStoreIconJSX}
            {rLIB('App Required')}
          </Typography>
          <style>{workflowCss}</style>
          <Box
            sx={{ maxWidth: '400px', margin: 'auto' }}
            className="tw-mt-2"
          >
            <Typography variant="h5">{rLIB('Download the orchestrate app to complete this task')}</Typography>
            {appStoreLinksJSX}
          </Box>
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_SubmittingContent = (): JSX.Element => {
    let contentJSX = (
      <Box className="tw-text-center">
        <Box sx={{ paddingTop: '30vh' }}>
          {rJSX_DynamicApplicationLogoSvg('80px', {}, {})}
          <CircularProgress
            color="error"
            size={50}
          />
          <Typography
            variant="h6"
            className="tw-mt-6"
          >
            {rLIB('Submitting Task')}
          </Typography>
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_SuccessContent = (): JSX.Element => {
    let contentJSX = (
      <Box
        className="tw-text-center"
        sx={{ marginTop: '30vh' }}
      >
        <Box className="tw-mb-6">
          <Typography
            sx={{
              fontSize: '40px',
              color: themeVariables.success_main,
              fontFamily: "'Roboto Slab', serif",
            }}
          >
            <Icon
              icon="circle-check"
              className="tw-mr-2"
              sx={{
                fontSize: '34px',
              }}
            />
            {rLIB('Task Complete')}
          </Typography>
          <style>{workflowCss}</style>
          <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
            <Typography variant="h5">
              {rLIB('Confirmation Code')}:
              <Box
                className="tw-inline-block tw-ml-1"
                sx={{ fontWeight: 700, color: themeVariables.info_main }}
              >
                {getProp(us_submissionResults, 'confirmation_number', rLIB('Missing'))}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    )
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <></>
    if (us_taskLoadStatus === 'start') {
      pageJSX = rJSX_StartContent()
    } else if (us_taskLoadStatus === 'error') {
      pageJSX = rJSX_ErrorContent()
    } else if (us_taskLoadStatus === 'require_app') {
      pageJSX = rJSX_RequireAppContent()
    } else if (us_taskLoadStatus === 'pending_acceptance') {
      pageJSX = rJSX_PendingAcceptanceContent()
    } else if (us_taskLoadStatus === 'checklist') {
      if (us_pageView === 'checklist') {
        pageJSX = rJSX_ChecklistContent()
      } else if (us_pageView === 'form') {
        pageJSX = rJSX_FormContent()
      }
    } else if (us_taskLoadStatus === 'submitting') {
      pageJSX = rJSX_SubmittingContent()
    } else if (us_taskLoadStatus === 'success') {
      pageJSX = rJSX_SuccessContent()
    }
    return pageJSX
  }

  return <>{rJSX_Page()}</>
}

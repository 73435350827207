/* eslint-disable react/prop-types */
///////////////////////////////
// Imports
///////////////////////////////

import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_ApiDocumentation, TsInterface_ApiDocumentationUserInfo } from '../components/documentation'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

const apiDeployVersion = '1.0.0'
const exampleAPIKey = '7336da09-76c1-48e2-92c5-8634b87157a9'
const exampleUIDKey = '4b6677b0-0c24-445f-9e96-d85f88367e95'

// Reusable Response Objects
const exampleResponse401 = {
  success: false,
  error: {
    code: 'ER-API-VA-01',
    message: 'No User Found for UID',
  },
}

///////////////////////////////
// Exports
///////////////////////////////

export const returnApiDocumentation = (userInfo: TsInterface_ApiDocumentationUserInfo, clientKey: string): TsInterface_ApiDocumentation => {
  let apiDocumentation: TsInterface_ApiDocumentation = {
    base_data: {
      base_url: 'https://us-central1-data-ore-app.cloudfunctions.net/api',
      version: apiDeployVersion,
    },
    endpoints: {
      'GET /version/': {
        description: 'Returns the version of the API. You can also use this endpoint to check your credentials are active.',
        short_description: 'Returns API Version',
        method: 'GET',
        url: '/version/',
        parameters: {
          api_key: {
            key: 'api_key',
            required: true,
            description: "The API key for the client's account.",
            example_value: getProp(userInfo, 'api_key', exampleAPIKey),
            data_type: 'string',
          },
          uid: {
            key: 'uid',
            required: true,
            description: "The UID for the client's account.",
            example_value: getProp(userInfo, 'uid', exampleUIDKey),
            data_type: 'string',
          },
          workspace_id: {
            key: 'workspace_id',
            required: true,
            description: 'Your Workspace ID',
            example_value: clientKey,
            data_type: 'string',
          },
        },
        example_request: {
          api_key: getProp(userInfo, 'api_key', exampleAPIKey),
          uid: getProp(userInfo, 'uid', exampleUIDKey),
          workspace_id: clientKey,
        },
        example_responses: {
          '200': {
            key: '200',
            response: {
              success: true,
              api_version: apiDeployVersion,
              query: {
                uid: getProp(userInfo, 'uid', exampleUIDKey),
                api_key: getProp(userInfo, 'api_key', exampleAPIKey),
                workspace_id: clientKey,
              },
            },
          },
          '401': {
            key: '401',
            response: exampleResponse401,
          },
        },
      },
      'GET /forms/': {
        description: 'Get a list of all active forms',
        short_description: 'List Forms',
        method: 'GET',
        url: '/forms/',
        parameters: {
          api_key: {
            key: 'api_key',
            required: true,
            description: "The API key for the client's account.",
            example_value: getProp(userInfo, 'api_key', exampleAPIKey),
            data_type: 'string',
          },
          uid: {
            key: 'uid',
            required: true,
            description: "The UID for the client's account.",
            example_value: getProp(userInfo, 'uid', exampleUIDKey),
            data_type: 'string',
          },
          workspace_id: {
            key: 'workspace_id',
            required: true,
            description: 'Your Workspace ID',
            example_value: clientKey,
            data_type: 'string',
          },
        },
        example_request: {
          api_key: getProp(userInfo, 'api_key', exampleAPIKey),
          uid: getProp(userInfo, 'uid', exampleUIDKey),
          workspace_id: clientKey,
        },
        example_responses: {
          '200': {
            key: '200',
            response: {
              success: true,
              count: 'NUMBER',
              forms: {
                $formKey: {
                  class_type: 'transactional_data | persistent_data',
                  fields: {
                    $fieldKey: {
                      form_type:
                        'display | short_answer | number | paragraph | multiple_choice | checkboxes | dropdown | date | time | datetime | toggle | image_upload | feet_inch_gauge | directory_multiple_choice | etc',
                      key: '$fieldKey',
                      name: '$fieldName',
                      order: 'NUMBER',
                      required: 'BOOLEAN',
                      status: 'active | deleted',
                      unique_identifier: 'BOOLEAN',
                    },
                  },
                  key: '$formKey',
                  name: '$formName',
                  status: 'active | deleted',
                  timestamp_created: 'DATE',
                },
              },
            },
          },
          '401': {
            key: '401',
            response: exampleResponse401,
          },
        },
      },
      'GET /data_buckets/': {
        description: 'Get a list of all active data buckets',
        short_description: 'List Data Buckets',
        method: 'GET',
        url: '/transactional_data_buckets/',
        parameters: {
          api_key: {
            key: 'api_key',
            required: true,
            description: "The API key for the client's account.",
            example_value: getProp(userInfo, 'api_key', exampleAPIKey),
            data_type: 'string',
          },
          uid: {
            key: 'uid',
            required: true,
            description: "The UID for the client's account.",
            example_value: getProp(userInfo, 'uid', exampleUIDKey),
            data_type: 'string',
          },
          workspace_id: {
            key: 'workspace_id',
            required: true,
            description: 'Your Workspace ID',
            example_value: clientKey,
            data_type: 'string',
          },
        },
        example_request: {
          api_key: getProp(userInfo, 'api_key', exampleAPIKey),
          uid: getProp(userInfo, 'uid', exampleUIDKey),
          workspace_id: clientKey,
        },
        example_responses: {
          '200': {
            key: '200',
            response: {
              success: true,
              count: 'NUMBER',
              data_buckets: {
                $dataBucketKey: {
                  associated_class_key: '$classKey',
                  associated_class_name: '$className',
                  available_for_use: 'BOOLEAN',
                  directory_associations: {
                    $directoryFormKey: {
                      key: '$directoryFormKey',
                      name: '$directoryFormName',
                    },
                  },
                  key: '$dataBucketKey',
                  name: '$dataBucketName',
                  provide_confirmation_code: 'BOOLEAN',
                  status: 'active | deleted',
                  timestamp_created: 'DATE',
                },
              },
            },
          },
          '401': {
            key: '401',
            response: exampleResponse401,
          },
        },
      },
      'GET /data/': {
        description: 'Get data for a specific date range - limited to 1000 records. Use closer start and end date if you have more than 1000 records.',
        short_description: 'Get Data',
        method: 'GET',
        url: '/data/',
        parameters: {
          api_key: {
            key: 'api_key',
            required: true,
            description: "The API key for the client's account.",
            example_value: getProp(userInfo, 'api_key', exampleAPIKey),
            data_type: 'string',
          },
          uid: {
            key: 'uid',
            required: true,
            description: "The UID for the client's account.",
            example_value: getProp(userInfo, 'uid', exampleUIDKey),
            data_type: 'string',
          },
          workspace_id: {
            key: 'workspace_id',
            required: true,
            description: 'Your Workspace ID',
            example_value: clientKey,
            data_type: 'string',
          },
          start_date: {
            key: 'start_date',
            required: true,
            description: 'The start date for your data query',
            example_value: new Date().getTime() - 86400000,
            data_type: 'string',
          },
          end_date: {
            key: 'end_date',
            required: true,
            description: 'The end date for your data query',
            example_value: new Date().getTime(),
            data_type: 'string',
          },
        },
        example_request: {
          api_key: getProp(userInfo, 'api_key', exampleAPIKey),
          uid: getProp(userInfo, 'uid', exampleUIDKey),
          workspace_id: clientKey,
        },
        example_responses: {
          '200': {
            key: '200',
            response: {
              success: true,
              count: 'NUMBER',
              data: {
                $dataItemKey: {
                  associated_class_key: '$classKey',
                  associated_data_bucket_key: '$dataBucketKey',
                  associated_data_bucket_name: '$dataBucketName',
                  associated_data_origination_type: 'csv_import | email_file_attachment | qr_code | etc',
                  calculated_data: {
                    $key: '$value',
                  },
                  calculated_data_names: {
                    $key: '$value',
                  },
                  confirmation_number: '$confirmationNumber',
                  data: {
                    $key: '$value',
                  },
                  directory_associations: {
                    $key: '$value',
                  },
                  key: ['$dataItemKey'],
                  metadata: {
                    $key: '$value',
                  },
                  original_data: {
                    $key: '$value',
                  },
                  status: 'active | deleted',
                  timestamp_primary_query: 'DATE',
                  timestamp_submitted: 'DATE',
                },
              },
            },
          },
          '401': {
            key: '401',
            response: exampleResponse401,
          },
        },
      },
    },
  }
  return apiDocumentation
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_CompilationTemplates_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'compilation_templates', 'main')
}

export const DatabaseRef_CompilationTemplates_Document = (clientKey: string, templateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'compilation_templates', 'main', templateKey)
}

export const DatabaseRef_ActiveCompilationTemplates_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'data_management', 'compilation_templates', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_CompilationTemplates_Components_Collection = (clientKey: string, templateKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'data_management', 'compilation_templates', 'main', templateKey, 'components')
}

export const DatabaseRef_CompilationTemplates_Components_Document = (
  clientKey: string,
  templateKey: string,
  componentKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'data_management', 'compilation_templates', 'main', templateKey, 'components', componentKey)
}

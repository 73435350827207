///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

		- Lock / Unlock Button (just disabled inputs)
		- Map on geocode
		- Associations and Metadata
		- PDF Download needs some cleanup
		- Edit form inputs (change requirement, display, options on Multiple choice, etc)
			- basically boolean to override form input
			- multiple choice options pulling from list somewhere else
		- Data list page (just pull from database and display)

		- Staging and Prod (probably not)

		MOVE CALCULATED FIELDS FROM DATA BUCKET TO CLASS???

		RECONCILIATION

			FORM FIELDS
				- Email Send Time and Timezone
				- Maybe show reconciliation data and just fade it 50% if the toggle is not true?
			POST DATA PROCESSING PROCESS
				- Post Data Processing Function
					- Should be triggered on edit of data
					- Process
						- Get Data Bucket for data item since it has "associated_reconciliation_class_mapping" object
						- Also it has the "associated_reconciliation_procedure" which determines how to proceed
						- Loop through the associated_reconciliation_class_mapping for all field key options for unique_identifier
						- Query collection for each unique_identifier to look for potential matches
						- ON data item update object, set the following update object

						updateObject = {
							reconciliation_data: {
								associated_linked_data_item_keys: {
									$key: {
										associated_data_bucket_key: $key,
										associated_class_key: $key,
										key: $key,
										start_meter: value
										stop_meter: value
										unique_identifier: value
										volume: value
										timestamp: value (primary???)
									}
								}
							}
						}

						- Update Object for each linked data item in update Array for Batch Update

						- GOAL - All data items should have all the reconciliation data needed so that emails are a simple query, loop through, and send WITHOUT additional data queries

						- NOTE - need to make sure that the data item is not matched with itself - which should always be a match

						- QUESTION - how to handle change to unique identifier or other fields... need to go back and clean up old link data
						- QUESTION - how to handle duplicates - might need to have options like date needing to be within 1 day, etc
							- Might show all but then have time discrepancies listed in email

			CRON JOB
				- Get data items for the bucket with reconciliation on
				- Post data processing should already be done
				- Loop through to generate email data
				- Send Email


	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material/'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { returnPDFDownloadDocument } from 'app/models/qr_codes/qr_code_file_generation'
import { cloudFunctionManageQRCodeRequest } from 'app/services/external_requests/external_requests'
import { returnTableColumnsFromDatabaseFormDataFormat } from 'app/services/forms/form_services'
import { FirebaseError } from 'firebase/app'
import { MuiChipsInput } from 'mui-chips-input'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useCSVReader } from 'react-papaparse'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { getBaseURL } from 'rfbp_aux/functions/get_base_url'
import {
  DatabaseRef_ActiveClassCustomForms_Query,
  DatabaseRef_ActiveClasses_Query,
  DatabaseRef_ClassFields_Collection,
  DatabaseRef_Class_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import {
  DatabaseRef_DataBucketsActiveCalculations_Query,
  DatabaseRef_DataBuckets_Document,
  DatabaseRef_DataBuckets_InboundEmailMapping_Document,
  DatabaseRef_DataBucket_CalculatedField_Document,
  DatabaseRef_DataBucket_ImportSessions_Collection,
  DatabaseRef_DataBucket_ImportSession_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_ActiveDataBucketGroups_Query,
  DatabaseRef_DataBucketGroups_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/data_bucket_groups'
import { DatabaseRef_ActiveCalculatedFields_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/calculated_fields'
import { DatabaseRef_ActiveFormulas_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/formulas'
import { DatabaseRef_ActiveLookupTables_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/lookup_tables'
import { DatabaseRef_ActiveReportTemplates_Query } from 'rfbp_aux/services/database_endpoints/clients/data_management/report_templates'
import { DatabaseRef_ActiveDirectory_Query } from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import {
  DatabaseRef_ActiveData_SingleDataPoint_Query,
  DatabaseRef_Data_Collection,
  DatabaseRef_Data_Document,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, generateRandomString, getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { isValidEmail } from '../export_scheduled_emails/components/recurring_email_form_dialog'
import { libraryFormulas } from '../refine_formulas/services/calculation_functions'
import { tableColumns_CalculatedFieldVariables, tableSettings_CalculatedFieldVariables } from './tables/calculated_fields'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataBucketsViewPage']['key']

// Base Inbound Email Address
const baseInboundEmailAddress: string = '@upload.orchestrateapp.com'
// const baseInboundEmailAddress: string = '@upload.loadcall.com'

// Table
const tableSettings_QRCodeData: TsInterface_TableDatabaseSettings = {
  rows_per_page: 20,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_primary_query',
  use_live_data: true,
  collapsible_columns: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 240px)',
}

const tableColumns_ImportSessions: TsInterface_TableColumns = {
  timestamp: TableCellTimestamp('timestamp', rLIB('Timestamp'), 'timestamp', 'D MMM YYYY h:mm a', true),
  import_session_type: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Data Source')
      },
      header_sort_by: 'import_session_type',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        switch (rowData.import_session_type) {
          case 'csv_import':
            cellJSX = (
              <Chip
                variant="outlined"
                color="success"
                label={
                  <Box>
                    <Icon
                      icon="file-csv"
                      className="tw-mr-2"
                    />
                    {rLIB('Spreadsheet Import')}
                  </Box>
                }
              />
            )
            break
          case 'email_file_attachment':
            cellJSX = (
              <Chip
                variant="outlined"
                color="info"
                label={
                  <Box>
                    <Icon
                      icon="envelope"
                      className="tw-mr-2"
                    />
                    {rLIB('Email Import')}
                  </Box>
                }
              />
            )
            break
          default:
            cellJSX = (
              <Chip
                variant="outlined"
                color="primary"
                label={
                  <Box>
                    <Icon
                      icon="file-import"
                      className="tw-mr-2"
                    />
                    {rowData.import_session_type}
                  </Box>
                }
              />
            )
            break
        }

        return cellJSX
      },
    },
  },
  success_count: TableCellBasic('success_count', rLIB('Successful Imports'), 'success_count'),
  failure_count: TableCellBasic('failure_count', rLIB('Failed Imports'), 'failure_count'),
}

const tableSettings_ImportSessions: TsInterface_TableDatabaseSettings = {
  rows_per_page: 20,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp',
  use_live_data: true,
  collapsible_columns: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 240px)',
}

const formInputs_FormSubmissionTriggeredEvents: TsInterface_FormInputs = {
  email_on_form_submission_to: {
    data_type: 'string',
    key: 'email_on_form_submission_to',
    label: rLIB('To'),
    input_type: 'custom_form_input_jsx',
    required: true,
    renderCustomFormInput: (
      formInput: TsInterface_FormInput,
      formInputs: TsInterface_FormInputs,
      formData: TsInterface_FormData,
      formInputChange: TsType_InputChangeCallback,
      formSettings: TsInterface_FormSettings,
      formAdditionalData: TsInterface_FormAdditionalData,
      formHooks: TsInterface_FormHooksObject,
    ) => {
      let inputJSX = (
        <Box
          className=""
          sx={{ marginBottom: '16px' }}
        >
          <MuiChipsInput
            label={rLIB('Email Recipients')}
            placeholder={rLIB('Add Emails', false) as string}
            fullWidth={true}
            value={getProp(formData, formInput.key, [])}
            onChange={(chipsArray: string[]) => {
              formInputChange(formInput.key, chipsArray, true)
            }}
            disabled={false}
            disableEdition={true}
            disableDeleteOnBackspace={true}
            onKeyDown={(event: any) => {
              // Nothing
            }}
            addOnBlur={true}
            renderChip={(Component, key, props) => {
              // eslint-disable-next-line
              if (isValidEmail(props.label) === true) {
                return (
                  <Component
                    {...props}
                    key={key}
                  />
                )
              } else {
                return (
                  <Component
                    {...props}
                    key={key}
                    color="error"
                  />
                )
              }
            }}
          />
        </Box>
      )
      return inputJSX
    },
  },
  email_on_form_submission_subject: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_on_form_submission_subject',
    label: rLIB('Subject'),
    required: true,
  },
  email_on_form_submission_body: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'email_on_form_submission_body',
    label: rLIB('Body'),
    required: true,
  },
  email_on_form_submission_attachment: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'email_on_form_submission_attachment',
    label: rLIB('Attachment'),
    required: true,
    options: [],
  },
  email_on_form_submission_attachment_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_on_form_submission_attachment_name',
    label: rLIB('Attachment Name'),
    required: true,
  },
}

const formInputs_StatusChangeTriggeredEvents: TsInterface_FormInputs = {
  email_on_status_change_to: {
    data_type: 'string',
    key: 'email_on_status_change_to',
    label: rLIB('To'),
    input_type: 'custom_form_input_jsx',
    required: true,
    renderCustomFormInput: (
      formInput: TsInterface_FormInput,
      formInputs: TsInterface_FormInputs,
      formData: TsInterface_FormData,
      formInputChange: TsType_InputChangeCallback,
      formSettings: TsInterface_FormSettings,
      formAdditionalData: TsInterface_FormAdditionalData,
      formHooks: TsInterface_FormHooksObject,
    ) => {
      let inputJSX = (
        <Box
          className=""
          sx={{ marginBottom: '16px' }}
        >
          <MuiChipsInput
            label={rLIB('Email Recipients')}
            placeholder={rLIB('Add Emails', false) as string}
            fullWidth={true}
            value={getProp(formData, formInput.key, [])}
            onChange={(chipsArray: string[]) => {
              formInputChange(formInput.key, chipsArray, true)
            }}
            disabled={false}
            disableEdition={true}
            disableDeleteOnBackspace={true}
            onKeyDown={(event: any) => {
              // Nothing
            }}
            addOnBlur={true}
            renderChip={(Component, key, props) => {
              // eslint-disable-next-line
              if (isValidEmail(props.label) === true) {
                return (
                  <Component
                    {...props}
                    key={key}
                  />
                )
              } else {
                return (
                  <Component
                    {...props}
                    key={key}
                    color="error"
                  />
                )
              }
            }}
          />
        </Box>
      )
      return inputJSX
    },
  },
  email_on_status_change_subject: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_on_status_change_subject',
    label: rLIB('Subject'),
    required: true,
  },
  email_on_status_change_body: {
    data_type: 'string',
    input_type: 'text_multiline',
    key: 'email_on_status_change_body',
    label: rLIB('Body'),
    required: true,
  },
  email_on_status_change_attachment: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'email_on_status_change_attachment',
    label: rLIB('Attachment'),
    required: true,
    options: [],
  },
  email_on_status_change_attachment_name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_on_status_change_attachment_name',
    label: rLIB('Attachment Name'),
    required: true,
  },
}

const defaultReviewStatuses: TsInterface_UnspecifiedObject = {
  not_reviewed: {
    key: 'not_reviewed',
    name: 'Not Reviewed',
    color: themeVariables.gray_500,
    icon: 'circle-question',
  },
  under_review: {
    key: 'under_review',
    name: 'Under Review',
    color: themeVariables.warning_main,
    icon: 'circle-pause',
  },
  complete: {
    key: 'complete',
    name: 'Review Complete',
    color: themeVariables.success_main,
    icon: 'circle-check',
  },
  failed_review: {
    key: 'failed_review',
    name: 'Failed Review',
    color: themeVariables.error_main,
    icon: 'circle-xmark',
  },
}

const formInputs_ReviewStatuses: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Status Name'),
    required: true,
  },
  icon: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'icon',
    label: rLIB('Icon'),
    required: true,
    options: [
      {
        key: 'circle',
        value: <>{<Icon icon="circle" />}</>,
      },
      {
        key: 'circle-xmark',
        value: <>{<Icon icon="circle-xmark" />}</>,
      },
      {
        key: 'circle-info',
        value: <>{<Icon icon="circle-info" />}</>,
      },
      {
        key: 'circle-user',
        value: <>{<Icon icon="circle-user" />}</>,
      },
      {
        key: 'circle-exclamation',
        value: <>{<Icon icon="circle-exclamation" />}</>,
      },
      {
        key: 'circle-check',
        value: <>{<Icon icon="circle-check" />}</>,
      },
      {
        key: 'circle-trash',
        value: <>{<Icon icon="circle-trash" />}</>,
      },
      {
        key: 'circle-stop',
        value: <>{<Icon icon="circle-stop" />}</>,
      },
      {
        key: 'circle-star',
        value: <>{<Icon icon="circle-star" />}</>,
      },
      {
        key: 'circle-question',
        value: <>{<Icon icon="circle-question" />}</>,
      },
      {
        key: 'circle-plus',
        value: <>{<Icon icon="circle-plus" />}</>,
      },
      {
        key: 'circle-play',
        value: <>{<Icon icon="circle-play" />}</>,
      },
      {
        key: 'circle-pause',
        value: <>{<Icon icon="circle-pause" />}</>,
      },
      {
        key: 'circle-heart',
        value: <>{<Icon icon="circle-heart" />}</>,
      },
      {
        key: 'circle-envelope',
        value: <>{<Icon icon="circle-envelope" />}</>,
      },
      {
        key: 'circle-ellipsis',
        value: <>{<Icon icon="circle-ellipsis" />}</>,
      },
      {
        key: 'circle-dollar',
        value: <>{<Icon icon="circle-dollar" />}</>,
      },
      {
        key: 'circle-calendar',
        value: <>{<Icon icon="circle-calendar" />}</>,
      },
      {
        key: 'circle-bookmark',
        value: <>{<Icon icon="circle-bookmark" />}</>,
      },
      {
        key: 'circle-bolt',
        value: <>{<Icon icon="circle-bolt" />}</>,
      },
    ],
  },
  color: {
    data_type: 'string',
    key: 'color',
    input_type: 'custom_form_input_jsx',
    label: rLIB('Icon Color'),
    required: true,
    renderCustomFormInput: (
      formInput: any,
      formInputs: any,
      formData: any,
      formInputChange: any,
      formSettings: any,
      formAdditionalData: any,
      formHooks: any,
    ) => {
      let inputJSX = <></>
      inputJSX = (
        <Box>
          <Typography>{rLIB('Font Color')}</Typography>
          <SketchPicker
            color={getProp(formData, formInput.key, '#ffffff')}
            onChangeComplete={(event: any) => {
              formInputChange(formInput.key, event.hex, true)
            }}
          />
        </Box>
      )
      return inputJSX
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

function isValidDate(dateString: string) {
  const parsedDate = Date.parse(dateString)
  return !isNaN(parsedDate) && !isNaN(new Date(parsedDate).getDate())
}

function parseFirebaseError(error: any) {
  // Check if error is an instance of FirebaseError
  if (error instanceof FirebaseError) {
    const { code, message } = error
    // Attempt to parse any JSON-like structure in the message
    const jsonMatch = message.match(/{.*}/)
    let details = null
    if (jsonMatch) {
      try {
        details = JSON.parse(jsonMatch[0])
      } catch (parseError) {
        console.error('Failed to parse JSON in message:', parseError)
      }
    }
    return { code, message, details }
  } else {
    console.error('Error is not an instance of FirebaseError')
    return null
  }
}

const addGlobalInboundEmail = (clientKey: string, dataBucketKey: string, email: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && dataBucketKey != null && email != null) {
      cloudFunctionManageQRCodeRequest({
        function: 'addInboundEmailToDataBucket',
        client_key: clientKey,
        data_bucket_key: dataBucketKey,
        email: email,
      })
        .then((res_CFMQRCR: any) => {
          resolve(res_CFMQRCR)
        })
        .catch((rej_CFMQRCR) => {
          let parsedError = parseFirebaseError(rej_CFMQRCR)
          if (parsedError != null) {
            reject({ success: false, error: getProp(parsedError, 'details', {}) })
          } else {
            reject(rej_CFMQRCR)
          }
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to save inbound email address'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-DBV-AGIE-01',
        },
      })
    }
  })
}

const deleteGlobalInboundEmail = (clientKey: string, dataBucketKey: string) => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && dataBucketKey != null) {
      cloudFunctionManageQRCodeRequest({
        function: 'removeInboundEmailFromDataBucket',
        client_key: clientKey,
        data_bucket_key: dataBucketKey,
      })
        .then((res_CFMQRCR: any) => {
          resolve(res_CFMQRCR)
        })
        .catch((rej_CFMQRCR) => {
          reject(rej_CFMQRCR)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to delete inbound email address'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-DBV-DGIE-01',
        },
      })
    }
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_activeCalculatedFields, us_setActiveCalculatedFields] = useState<TsInterface_TableColumns>({})
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeDataBucketGroups, us_setActiveDataBucketGroups] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeFormulas, us_setActiveFormulas] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeLookupTables, us_setActiveLookupTables] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeReportTemplates, us_setActiveReportTemplates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculatedFieldsList, us_setCalculatedFieldsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataBucket, us_setDataBucket] = useState<TsInterface_UnspecifiedObject>({})
  const [us_inboundEmailFieldMapping, us_setInboundEmailFieldMapping] = useState<TsInterface_UnspecifiedObject>({})
  const [us_inboundEmailFieldMappingCount, us_setInboundEmailFieldMappingCount] = useState<TsInterface_UnspecifiedObject>({})
  const [us_parsingData, us_setParsingData] = useState<boolean>(false)
  const [us_selectedClass, us_setSelectedClass] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClassCustomForms, us_setSelectedClassCustomForms] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClassFields, us_setSelectedClassFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_singleQRCodeDataPoint, us_setSingleQRCodeDataPoint] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableColumns_QRCodeData, us_setTableColumns_QRCodeData] = useState<TsInterface_TableColumns>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { CSVReader } = useCSVReader()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)

  // Hooks - useEffect
  useEffect(() => {
    if (us_dataBucket != null && us_dataBucket.name != null) {
      document.title = (rLIB('Data Buckets', false) as string) + ' - ' + us_dataBucket.name
    } else {
      document.title = rLIB('Data Buckets', false) as string
    }
  }, [us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveReportTemplates(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveReportTemplates_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataBucket(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeTransactionalClasses: TsInterface_UnspecifiedObject = {}
      for (let loopClassKey in newData) {
        let loopClass = newData[loopClassKey]
        if (loopClass != null && loopClass.class_type === 'transactional_data') {
          activeTransactionalClasses[loopClassKey] = loopClass
        }
      }
      us_setActiveClasses(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedClass(newData)
      ur_forceRerender()
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Class_Document(res_GCK.clientKey, us_dataBucket.associated_class_key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCalculatedFieldsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveCalculatedFields_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeClassFields: TsInterface_UnspecifiedObject = {}
      for (let loopFieldKey in newData) {
        let loopField = newData[loopFieldKey]
        if (loopField.status != 'inactive') {
          activeClassFields[loopFieldKey] = loopField
        }
      }
      us_setSelectedClassFields(activeClassFields)
      ur_forceRerender()
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_dataBucket.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_dataBucket])

  useEffect(() => {
    us_setTableColumns_QRCodeData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_selectedClassFields,
        {},
        {},
        {
          includeDataOriginationSource: true,
          showReviewColumn: us_dataBucket.review_enabled,
          includeNotesOnReview: us_dataBucket.review_notes_enabled,
          defaultReviewStatus: us_dataBucket.default_review_status,
          reviewStatuses: us_dataBucket.review_statuses,
        },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_selectedClassFields, us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSingleQRCodeDataPoint(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveData_SingleDataPoint_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveCalculatedFields(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_DataBucketsActiveCalculations_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveFormulas(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveFormulas_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveLookupTables(newData)
      // ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveLookupTables_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveDataBucketGroups(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveDataBucketGroups_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedClassCustomForms(newData)
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ActiveClassCustomForms_Query(res_GCK.clientKey, us_dataBucket.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_dataBucket])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setInboundEmailFieldMapping(newData)
    }
    if (us_dataBucket != null && us_dataBucket.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_DataBuckets_InboundEmailMapping_Document(res_GCK.clientKey, itemKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_dataBucket, itemKey])

  useEffect(() => {
    if (us_inboundEmailFieldMapping != null && us_selectedClassFields != null) {
      let fieldMappingCountObject: TsInterface_UnspecifiedObject = {}
      for (let loopFieldKey in us_selectedClassFields) {
        let loopField = us_selectedClassFields[loopFieldKey]
        if (fieldMappingCountObject[loopField.name] == null) {
          fieldMappingCountObject[loopField.name] = 0
        }
        fieldMappingCountObject[loopField.name]++
      }
      for (let loopFieldKey in us_inboundEmailFieldMapping) {
        let loopField = us_inboundEmailFieldMapping[loopFieldKey]
        for (let loopHeaderIndex in loopField) {
          let loopHeader = loopField[loopHeaderIndex]
          if (fieldMappingCountObject[loopHeader] == null) {
            fieldMappingCountObject[loopHeader] = 0
          }
          fieldMappingCountObject[loopHeader]++
        }
      }
      us_setInboundEmailFieldMappingCount(fieldMappingCountObject)
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_dataBucket, us_inboundEmailFieldMapping, us_selectedClassFields])

  // Functions
  const tableDatabaseEndpoint_QRCodeData = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'associated_data_bucket_key', comparator: '==', value: itemKey },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_primary_query', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Data_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_DataBucketImportSessions = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_DataBucket_ImportSessions_Collection(uc_RootData_ClientKey as string, itemKey),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const downloadImportTemplate = () => {
    let sortedFormFields = objectToArray(us_selectedClassFields).sort(dynamicSort('order', null))
    let downloadData = ['Submitted Date']
    for (let loopFieldIndex in sortedFormFields) {
      let loopField = sortedFormFields[loopFieldIndex]
      if (loopField.status === 'active') {
        if (loopField.form_type === 'checkboxes') {
          let sortedOrderArray = objectToArray(loopField.options).sort(dynamicSort('order', null))
          for (let loopOptionIndex in sortedOrderArray) {
            let loopOption = sortedOrderArray[loopOptionIndex]
            downloadData.push(loopField.name + ' - ' + loopOption.value)
          }
        } else if (
          loopField.form_type !== 'display'
          // TODO: photo, signature, etc
        ) {
          downloadData.push(loopField.name)
        }
      }
    }
    downloadCSV(us_dataBucket.name + ' - import', [downloadData])
  }

  const handleFileSelection = (data: any): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Instantiate variables
        let importSessionKey = new Date().getTime() + '_' + generateRandomString(6, null)
        let unformattedData: TsInterface_UnspecifiedObject[] = []
        let uploadedFileColumns: string[] = []
        us_setParsingData(true)
        // let dataMapping: string[] = []
        // Loop through and format data
        for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
          uploadedFileColumns[cellIndex] = data[0][cellIndex]
        }
        for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
          if (data != null && data[rowIndex] != null) {
            let loopRow = data[rowIndex]
            let unformattedDataRow: TsInterface_UnspecifiedObject = {}
            for (let cellIndex = 0; cellIndex < loopRow.length; cellIndex++) {
              if (loopRow[cellIndex] != null && uploadedFileColumns[cellIndex] != null && uploadedFileColumns[cellIndex] !== '') {
                unformattedDataRow[uploadedFileColumns[cellIndex]] = loopRow[cellIndex]
              }
            }
            unformattedData.push(unformattedDataRow)
          }
        }
        // Parse Into actual upload
        let formattedDataArray: TsInterface_UnspecifiedObject[] = []
        let loopCount = 0
        let successCount = 0
        let failureCount = 0
        for (let loopRow in unformattedData) {
          let loopRowData = unformattedData[loopRow]
          if (loopRowData != null && loopRowData['Submitted Date'] != null && isValidDate(loopRowData['Submitted Date']) === true) {
            loopCount++
            successCount++
            let formattedRowData: TsInterface_UnspecifiedObject = {
              associated_import_session_key: importSessionKey,
              associated_data_origination_type: 'csv_import',
              associated_class_key: us_dataBucket.associated_class_key,
              associated_data_bucket_key: itemKey,
              associated_data_bucket_name: getProp(us_dataBucket, 'name', ''),
              confirmation_number: null,
              data: {},
              original_data: {},
              directory_associations: getProp(us_dataBucket, 'directory_associations', {}),
              key: importSessionKey + '_' + loopCount.toString(),
              metadata: {},
              status: 'active',
              timestamp_submitted: new Date(loopRowData['Submitted Date']),
              timestamp_primary_query: new Date(loopRowData['Submitted Date']),
            }
            for (let loopFieldIndex in us_selectedClassFields) {
              let loopField = us_selectedClassFields[loopFieldIndex]
              if (loopField.form_type === 'checkboxes' && loopField.key != null) {
                formattedRowData['data'][loopField.key] = {}
                formattedRowData['original_data'][loopField.key] = {}
                for (let loopOptionKey in loopField['options']) {
                  let loopOption = loopField['options'][loopOptionKey]
                  if (loopRowData[loopField.name + ' - ' + loopOption.value] != null && loopRowData[loopField.name + ' - ' + loopOption.value] !== '') {
                    formattedRowData['data'][loopField.key][loopOption.value] = loopRowData[loopField.name + ' - ' + loopOption.value]
                    formattedRowData['original_data'][loopField.key][loopOption.value] = loopRowData[loopField.name + ' - ' + loopOption.value]
                  }
                }
              } else if (
                loopField.form_type !== 'display'
                // TODO: photo, signature, etc
              ) {
                if (loopField != null && loopField.name != null && loopField.key != null && loopRowData[loopField.name] != null) {
                  formattedRowData['data'][loopField.key] = loopRowData[loopField.name]
                  formattedRowData['original_data'][loopField.key] = loopRowData[loopField.name]
                }
              }
            }
            formattedDataArray.push(formattedRowData)
          } else {
            failureCount++
          }
        }
        // Generate Batch Update
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopIndex in formattedDataArray) {
          let loopRow = formattedDataArray[loopIndex]
          if (loopRow != null && getProp(loopRow, 'key', null) != null) {
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_Data_Document(res_GCK.clientKey, getProp(loopRow, 'key', null)),
              data: loopRow,
            })
          }
        }
        // Add Import Session Data
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_DataBucket_ImportSession_Document(res_GCK.clientKey, itemKey, importSessionKey),
          data: {
            associated_class_key: getProp(us_dataBucket, 'associated_class_key', null),
            associated_data_bucket_key: itemKey,
            associated_data_bucket_name: getProp(us_dataBucket, 'name', ''),
            client_key: res_GCK.clientKey,
            failure_count: failureCount,
            key: importSessionKey,
            success_count: successCount,
            timestamp: new Date(),
            import_session_type: 'csv_import',
          },
        })
        DatabaseStagedBatchUpdate(updateArray)
          .then((res_DSBU) => {
            us_setParsingData(false)
            uc_setUserInterface_AlertDialogDisplay({
              display: true,
              alert: {
                color: 'info',
                header: rLIB('Data Import Complete') as JSX.Element,
                icon: <Icon icon="badge-check" />,
                text: (
                  <>
                    {loopCount} {rLIB('Successful Imports')}
                  </>
                ),
              },
            })
          })
          .catch((rej_DSBU) => {
            us_setParsingData(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const setMergeDataBucketRootData = (newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const addCalculatedField = () => {
    let calculatedFieldOptions = []
    for (let loopCalculatedFieldKey in us_calculatedFieldsList) {
      let loopCalculatedField = us_calculatedFieldsList[loopCalculatedFieldKey]
      if (loopCalculatedField != null && loopCalculatedField.status === 'active') {
        calculatedFieldOptions.push({
          key: loopCalculatedFieldKey,
          value: loopCalculatedField.name,
        })
      }
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            // name: {
            //   data_type: 'string',
            //   input_type: 'text_basic',
            //   key: 'name',
            //   label: rLIB('Calculated Field Name'),
            //   required: true,
            // },
            associated_calculated_field_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_calculated_field_key',
              label: rLIB('Calculated Field'),
              options: calculatedFieldOptions.sort(dynamicSort('value', 'asc')),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = { ...formSubmittedData }
              updateObject['status'] = 'active'
              updateObject['order'] = new Date().getTime()
              updateObject['key'] = formSubmittedData['associated_calculated_field_key']
              updateObject['name'] = getProp(us_calculatedFieldsList, formSubmittedData['associated_calculated_field_key'] + '.name', '')
              updateObject['associated_calculated_field_name'] = getProp(
                us_calculatedFieldsList,
                formSubmittedData['associated_calculated_field_key'] + '.name',
                '',
              )
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, updateObject['key']), updateObject)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editCalculatedFieldName = (calculatedField: TsInterface_UnspecifiedObject, calculatedFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: calculatedField,
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Calculated Field Name'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { ...formSubmittedData }
                  DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const addOrderToCalculatedField = (calculatedFieldKey: string) => {
    let updateObject = { order: new Date().getTime() }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
          .then((res_DSMD) => {
            // Nothing
          })
          .catch((rej_DSMD) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const moveCalculatedFieldUp = (calculatedFieldKey: string) => {
    // Convert calculated fields to array and sort by order
    let calculatedFieldsArray = objectToArray(us_activeCalculatedFields)
      .filter((field) => field.order != null)
      .sort((a, b) => a.order - b.order)
    // Find current field index
    let currentIndex = calculatedFieldsArray.findIndex((field) => field.key === calculatedFieldKey)
    // If not at top and found the field
    if (currentIndex > 0) {
      let currentField = calculatedFieldsArray[currentIndex]
      let previousField = calculatedFieldsArray[currentIndex - 1]
      // Swap orders
      let tempOrder = currentField.order
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, currentField.key),
              data: { order: previousField.order },
            },
            {
              type: 'setMerge',
              ref: DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, previousField.key),
              data: { order: tempOrder },
            },
          ]
          DatabaseBatchUpdate(updateArray).catch((rej_DBU) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
          })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const moveCalculatedFieldDown = (calculatedFieldKey: string) => {
    // Convert calculated fields to array and sort by order
    let calculatedFieldsArray = objectToArray(us_activeCalculatedFields)
      .filter((field) => field.order != null)
      .sort((a, b) => a.order - b.order)
    // Find current field index
    let currentIndex = calculatedFieldsArray.findIndex((field) => field.key === calculatedFieldKey)
    // If not at bottom and found the field
    if (currentIndex !== -1 && currentIndex < calculatedFieldsArray.length - 1) {
      let currentField = calculatedFieldsArray[currentIndex]
      let nextField = calculatedFieldsArray[currentIndex + 1]
      // Swap orders
      let tempOrder = currentField.order
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, currentField.key),
              data: { order: nextField.order },
            },
            {
              type: 'setMerge',
              ref: DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, nextField.key),
              data: { order: tempOrder },
            },
          ]
          DatabaseBatchUpdate(updateArray).catch((rej_DBU) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
          })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const editCalculatedFieldAssociatedFormula = (calculatedField: TsInterface_UnspecifiedObject, calculatedFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: calculatedField,
          formInputs: {
            associated_formula_key: {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_formula_key',
              label: rLIB('Formula'),
              required: true,
              options: objectToArray(us_activeFormulas)
                .sort(dynamicSort('name', 'asc'))
                .map((loopFormula) => {
                  return {
                    key: loopFormula.key,
                    value: loopFormula.name,
                  }
                }),
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject = { ...formSubmittedData }
                  updateObject['associated_formula_name'] = getProp(us_activeFormulas, formSubmittedData['associated_formula_key'] + '.name', '')
                  DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, calculatedFieldKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('New Calculated Field')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteCalculatedField = (calculatedField: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Calculated Field'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this Calculated Field?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = {
                  status: 'deleted',
                }
                DatabaseSetMergeDocument(DatabaseRef_DataBucket_CalculatedField_Document(res_GCK.clientKey, itemKey, calculatedField['key']), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const addImportHeader = (formFieldName: string, formFieldKey: string) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            name: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'name',
              label: rLIB('Header'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = { [formFieldKey]: [...getProp(us_inboundEmailFieldMapping, formFieldKey, [])] }
              updateObject[formFieldKey].push(formSubmittedData['name'])
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DataBuckets_InboundEmailMapping_Document(res_GCK.clientKey, itemKey), updateObject)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('New Import Header for')} {formFieldName}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const deleteImportHeader = (formFieldKey: string, importHeaderKey: string) => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Import Header'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this import header?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = { [formFieldKey]: [...getProp(us_inboundEmailFieldMapping, formFieldKey, [])] }
                const index = updateObject[formFieldKey].indexOf(importHeaderKey)
                if (index > -1) {
                  updateObject[formFieldKey].splice(index, 1)
                }
                DatabaseSetMergeDocument(DatabaseRef_DataBuckets_InboundEmailMapping_Document(res_GCK.clientKey, itemKey), updateObject)
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const addReviewStatus = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_ReviewStatuses,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let reviewStatusKey = keyFromString(formSubmittedData.name) + '_' + new Date().getTime()
              formSubmittedData['key'] = reviewStatusKey
              let updateObject = {
                ['review_statuses']: {
                  [reviewStatusKey]: formSubmittedData,
                },
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Review Status')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editReviewStatus = (reviewStatus: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: reviewStatus,
          formInputs: formInputs_ReviewStatuses,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject = {
                ['review_statuses']: {
                  [reviewStatus['key']]: formSubmittedData,
                },
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                    .then((res_DAD) => {
                      resolve(res_DAD)
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Review Status')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteReviewStatus = (reviewStatus: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Review Status'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this Review Status?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            let updateObject = {
              ['review_statuses']: {
                [reviewStatus['key']]: {
                  status: 'deleted',
                },
              },
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                  .then((res_DAD) => {
                    resolve(res_DAD)
                  })
                  .catch((rej_DAD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                    reject(rej_DAD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const generateGlobalInboundEmailParser = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: {
            email: {
              data_type: 'string',
              key: 'email',
              input_type: 'custom_form_input_jsx',
              label: rLIB('Custom Inbound Email Address'),
              required: true,
              renderCustomFormInput: (
                formInput: any,
                formInputs: any,
                formData: any,
                formInputChange: any,
                formSettings: any,
                formAdditionalData: any,
                formHooks: any,
              ) => {
                let inputJSX = <></>
                inputJSX = (
                  <Box>
                    <Typography sx={{ marginBottom: '8px' }}>{rLIB('Custom Inbound Email Address')}</Typography>
                    <FormControl sx={{ width: '100%', marginBottom: '8px' }}>
                      <TextField
                        color="primary"
                        value={getProp(formData, formInput.key, '')}
                        InputProps={{
                          endAdornment: <Box>{baseInboundEmailAddress}</Box>,
                        }}
                        onChange={(event: any) => {
                          formInputChange(formInput.key, event.target.value, true)
                        }}
                      />
                    </FormControl>
                  </Box>
                )
                return inputJSX
              },
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let inboundEmailAddress = formSubmittedData.email + baseInboundEmailAddress
                  if (isValidEmail(inboundEmailAddress) === true) {
                    addGlobalInboundEmail(res_GCK.clientKey, itemKey, formSubmittedData.email)
                      .then((res_AIE) => {
                        resolve(res_AIE)
                      })
                      .catch((rej_AIE) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_AIE.error })
                        reject(rej_AIE)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: rLIB('Failed to save inbound email address'),
                        details: rLIB('Invalid email address'),
                        code: 'ER-D-DBV-AGIE-02',
                      },
                    })
                  }
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Add Inbound Email')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const disableInboundEmail = () => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Inbound Email Settings'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Disable'),
        text: rLIB('Are you sure that you want to disable inbound emails for this Data Bucket?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                deleteGlobalInboundEmail(res_GCK.clientKey, itemKey)
                  .then((res_DIGE) => {
                    resolve(res_DIGE)
                  })
                  .catch((rej_DIGE) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DIGE.error })
                    reject(rej_DIGE)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  // JSX Generation
  const rJSX_BackButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Data Buckets')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              un_routerNavigation(ApplicationPages.DataBucketsListPage.url())
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button tw-mb-2"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={() => {
            un_routerNavigation(ApplicationPages.DataBucketsListPage.url())
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2 tw-mb-2"
        >
          {rLIB('Back to all Data Buckets')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_NewCalculatedFieldButton = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrinkButton === true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Add Calculated Field')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              addCalculatedField()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button tw-mb-2"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    } else {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            addCalculatedField()
          }}
          disableElevation
          startIcon={<Icon icon="circle-plus" />}
          className="tw-mr-2 tw-mb-2"
        >
          {rLIB('Add Calculated Field')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_DownloadQRCodePDFButtonProper = (disabled: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        disableElevation
        disabled={disabled}
        startIcon={<Icon icon="qrcode" />}
        className="tw-mr-2"
      >
        {rLIB('Download QR Code PDF')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DownloadQRCodePDFButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_dataBucket != null && us_dataBucket.available_for_use === true) {
      buttonJSX = (
        <PDFDownloadLink
          document={returnPDFDownloadDocument(itemKey, us_dataBucket.name)}
          fileName={'Orchestrate QR Code - ' + us_dataBucket.name + '.pdf'}
        >
          {({ blob, url, loading, error }) => (loading ? rJSX_DownloadQRCodePDFButtonProper(true) : rJSX_DownloadQRCodePDFButtonProper(false))}
        </PDFDownloadLink>
      )
    } else {
      buttonJSX = rJSX_DownloadQRCodePDFButtonProper(true)
    }
    return buttonJSX
  }

  const rJSX_GeofenceSettings = (): JSX.Element => {
    let geofenceSettings = <></>
    if (us_dataBucket.require_geofence === true) {
      geofenceSettings = (
        <Box>
          <FormControl className="tw-mr-2">
            <TextField
              color="primary"
              sx={{ paddding: '2px' }}
              // className="bp_thin_text_input tw-m-0"
              value={getProp(us_dataBucket, 'location_latitude', '')}
              margin="normal"
              type="number"
              className="bp_thin_text_input"
              placeholder={'Latitude'}
              // @ts-expect-error
              onWheel={(event) => event.target.blur()}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  setMergeDataBucketRootData({ location_latitude: event.target.value })
                }
              }}
              variant="outlined"
            />
          </FormControl>
          <FormControl className="tw-mr-2">
            <TextField
              color="primary"
              sx={{ paddding: '2px' }}
              // className="bp_thin_text_input tw-m-0"
              value={getProp(us_dataBucket, 'location_longitude', '')}
              margin="normal"
              type="number"
              className="bp_thin_text_input"
              placeholder={'Longitude'}
              // @ts-expect-error
              onWheel={(event) => event.target.blur()}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  setMergeDataBucketRootData({ location_longitude: event.target.value })
                }
              }}
              variant="outlined"
            />
          </FormControl>
        </Box>
      )
    }
    return geofenceSettings
  }

  const rJSX_InactiveBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (us_dataBucket.available_for_use !== true) {
      bannerJSX = (
        <Box
          className="tw-p-1 tw-mb-2 tw-text-center"
          sx={{ width: '100%', background: themeVariables.error_main }}
        >
          <Typography
            variant="h6"
            className="tw-font-bold tw-text-white"
          >
            {rLIB('Data Bucket Not Active')}
          </Typography>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_dataBucket != null && us_dataBucket.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Data Bucket')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_dataBucket.name}</Box>
        </Box>
      )
    } else {
      pageHeader = rLIB('Data Bucket') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_AssociationCategorySelection = (associationClass: TsInterface_UnspecifiedObject): JSX.Element => {
    let associationClassSelectionJSX = <></>
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
        tooltip={
          <>
            {rLIB('Edit')} {getProp(associationClass, 'name', rLIB('Association'))}
          </>
        }
        tooltipPlacement="right"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={() => {
          DatabaseGetCollection(DatabaseRef_ActiveDirectory_Query(uc_RootData_ClientKey as string, associationClass.key))
            .then((res_DGC) => {
              let associationsObject: TsInterface_UnspecifiedObject = res_DGC.data
              let associationsArray: TsInterface_UnspecifiedObject[] = []
              for (let loopAssociationKey in res_DGC.data) {
                let loopAssociation = res_DGC.data[loopAssociationKey]
                associationsArray.push({
                  key: loopAssociationKey,
                  value: loopAssociation.name,
                })
              }
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      association_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_select',
                        key: 'association_key',
                        label: <>{getProp(associationClass, 'name', rLIB('Association'))}</>,
                        options: associationsArray,
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              directory_associations: {
                                [associationClass.key]: {
                                  name: associationsObject[formSubmittedData.association_key]['name'],
                                  key: formSubmittedData.association_key,
                                },
                              },
                            }
                            DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit')} {getProp(associationClass, 'name', rLIB('Association'))}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            })
            .catch((rej_DGC) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
            })
        }}
      />
    )
    let deleteIconJSX = (
      <Icon
        icon="trash"
        className="tw-cursor-pointer tw-ml-2 tw-opacity-30 hover:tw-opacity-100"
        tooltip={
          <>
            {rLIB('Remove')} {getProp(associationClass, 'name', rLIB('Association'))}
          </>
        }
        tooltipPlacement="right"
        sx={{
          '&:hover': {
            color: themeVariables.error_main,
          },
        }}
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let updateObject = {
                directory_associations: {
                  [associationClass.key]: {},
                },
              }
              DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey), updateObject)
                .then((res_DSMD) => {
                  ur_forceRerender()
                })
                .catch((rej_DSMD) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                })
            })
            .catch((rej_GCK) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }}
      />
    )
    if (us_dataBucket != null && us_dataBucket.directory_associations != null && us_dataBucket.directory_associations[associationClass.key] != null) {
      if (us_dataBucket.directory_associations[associationClass.key]['name'] != null) {
        associationClassSelectionJSX = (
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-inline-block"
            >
              {us_dataBucket.directory_associations[associationClass.key]['name']}
            </Typography>
            {editIconJSX}
            {deleteIconJSX}
          </Box>
        )
      } else if (us_dataBucket.directory_associations[associationClass.key]['key'] != null) {
        associationClassSelectionJSX = (
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-inline-block"
            >
              {us_dataBucket.directory_associations[associationClass.key]['key']}
            </Typography>
            {editIconJSX}
            {deleteIconJSX}
          </Box>
        )
      } else {
        associationClassSelectionJSX = (
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-opacity-30 tw-italic tw-inline-block"
            >
              {rLIB('Not Set')}
            </Typography>
            {editIconJSX}
            {/* { deleteIconJSX } */}
          </Box>
        )
      }
    } else {
      associationClassSelectionJSX = (
        <Box>
          <Typography
            variant="subtitle1"
            className="tw-opacity-30 tw-italic tw-inline-block"
          >
            {rLIB('Not Set')}
          </Typography>
          {editIconJSX}
        </Box>
      )
    }
    return associationClassSelectionJSX
  }

  const rJSX_TimezoneSettings = (): JSX.Element => {
    let timezoneSettingsJSX = <></>
    timezoneSettingsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Bucket Timezone')}
        </Typography>
        <Box className="tw-ml-4">{rJSX_TimezoneDropdown()}</Box>
      </Box>
    )
    return timezoneSettingsJSX
  }

  const rJSX_AssociationSelection = (): JSX.Element => {
    let associationSelectionJSX = <></>
    let relatedClassKeys: TsInterface_UnspecifiedObject = {}
    for (let loopClassFieldKey in getProp(us_selectedClass, 'associated_related_class_keys', {})) {
      if (
        us_selectedClass != null &&
        us_selectedClass['associated_related_class_keys'] != null &&
        us_selectedClass['associated_related_class_keys'][loopClassFieldKey] === true &&
        us_activeClasses != null &&
        us_activeClasses[loopClassFieldKey] != null
      ) {
        relatedClassKeys[loopClassFieldKey] = us_activeClasses[loopClassFieldKey]
      }
    }
    // Selection JSX
    associationSelectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Associations')}
        </Typography>
        <Box className="tw-ml-4">
          <TableContainer>
            <Table size="small">
              <TableBody>
                {objectToArray(relatedClassKeys)
                  .sort(dynamicSort('name', null))
                  .map((associationClass: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow
                      key={index}
                      className="tw-cursor-pointer"
                    >
                      <TableCell>
                        <Typography variant="subtitle1">{associationClass.name}</Typography>
                      </TableCell>
                      <TableCell>{rJSX_AssociationCategorySelection(associationClass)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="body1"
            className="tw-mt-1 tw-mb-2 tw-opacity-40 tw-italic"
          >
            *{rLIB('Changes made to associations are not retroactive on previously collected data')}
          </Typography>
        </Box>
      </Box>
    )
    return associationSelectionJSX
  }

  const rJSX_AvailableForUseToggle = (): JSX.Element => {
    let toggleJSX = (
      <Card className="tw-p-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Bucket Active for use')}
        </Typography>
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_dataBucket.available_for_use === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ available_for_use: true })
              } else if (value === false) {
                setMergeDataBucketRootData({ available_for_use: false })
              }
            }}
          />
          {us_dataBucket.available_for_use === true ? rLIB('Data Bucket Active') : rLIB('Data Bucket Not Active')}
        </Box>
      </Card>
    )
    return toggleJSX
  }

  const rJSX_ConfirmationCodeToggle = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        {/* <Typography variant="h6" className="tw-font-bold">{ s_CONFIRMATION_CODE }</Typography> */}
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_dataBucket.provide_confirmation_code === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ provide_confirmation_code: true })
              } else if (value === false) {
                setMergeDataBucketRootData({ provide_confirmation_code: false })
              }
            }}
          />
          {us_dataBucket.provide_confirmation_code === true ? rLIB('Provide confirmation code upon form submission') : rLIB('No confirmation code')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_PreventMultipleUseLinkToggle = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        {/* <Typography variant="h6" className="tw-font-bold">{ s_CONFIRMATION_CODE }</Typography> */}
        <Box className="tw-ml-4">
          <Switch
            color="info"
            checked={us_dataBucket.prevent_multiple_use_link !== true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ prevent_multiple_use_link: false })
              } else if (value === false) {
                setMergeDataBucketRootData({ prevent_multiple_use_link: true })
              }
            }}
          />
          {us_dataBucket.prevent_multiple_use_link === true ? rLIB('Multiple Use Links Disabled') : rLIB('Multiple Use Links Enabled')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_DataTypeDropdown = (): JSX.Element => {
    let filteredActiveClasses: TsInterface_UnspecifiedObject = {}
    for (let loopClassKey in us_activeClasses) {
      if (us_activeClasses[loopClassKey].class_type === 'transactional_data') {
        filteredActiveClasses[loopClassKey] = us_activeClasses[loopClassKey]
      }
    }
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            //
            // let updateObject = { data_type: event.target.value }
            let updateObject: any = { associated_class_key: event.target.value }
            if (us_activeClasses != null && us_activeClasses[event.target.value] != null && us_activeClasses[event.target.value].name != null) {
              updateObject['associated_class_name'] = us_activeClasses[event.target.value].name
            }
            setMergeDataBucketRootData(updateObject)
            // setMergeDataBucketRootData({ associated_class_key: event.target.value })
          }}
          value={getProp(us_dataBucket, 'associated_class_key', '')}
          disabled={objectToArray(us_singleQRCodeDataPoint).length > 0 || us_dataBucket.available_for_use === true}
        >
          {objectToArray(filteredActiveClasses)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_CustomFormBoolean = (): JSX.Element => {
    let toggleJSX = (
      <Box>
        <Box className="tw-ml-0">
          <Switch
            color="info"
            checked={us_dataBucket.use_custom_form === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                setMergeDataBucketRootData({ use_custom_form: true })
              } else if (value === false) {
                setMergeDataBucketRootData({ use_custom_form: false })
              }
            }}
          />
          {us_dataBucket.use_custom_form === true ? rLIB('Use Custom Form') : rLIB('Use Default Form')}
        </Box>
      </Box>
    )
    return toggleJSX
  }

  const rJSX_CustomFormDropdown = (): JSX.Element => {
    let filteredCustomForms: TsInterface_UnspecifiedObject = {}
    for (let loopCustomFormKey in us_selectedClassCustomForms) {
      if (
        us_selectedClassCustomForms[loopCustomFormKey] != null &&
        us_selectedClassCustomForms[loopCustomFormKey].status === 'active' &&
        us_selectedClassCustomForms[loopCustomFormKey].name != null &&
        us_selectedClassCustomForms[loopCustomFormKey].key != null &&
        us_selectedClassCustomForms[loopCustomFormKey].fields != null &&
        objectToArray(us_selectedClassCustomForms[loopCustomFormKey].fields).length > 0
      ) {
        filteredCustomForms[loopCustomFormKey] = us_selectedClassCustomForms[loopCustomFormKey]
      }
    }
    let dropdownJSX = <></>
    if (us_dataBucket.use_custom_form === true) {
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              let updateObject: any = { associated_class_custom_form_key: event.target.value }
              setMergeDataBucketRootData(updateObject)
            }}
            value={getProp(us_dataBucket, 'associated_class_custom_form_key', '')}
          >
            {objectToArray(filteredCustomForms)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_TimezoneDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            setMergeDataBucketRootData({ timezone: event.target.value })
          }}
          value={getProp(us_dataBucket, 'timezone', '')}
        >
          <MenuItem value="America/New_York"> {rLIB('Eastern Time')}</MenuItem>
          <MenuItem value="America/Chicago"> {rLIB('Central Time')}</MenuItem>
          <MenuItem value="America/Denver"> {rLIB('Mountain Time')}</MenuItem>
          <MenuItem value="America/Los_Angeles"> {rLIB('Pacific Time')}</MenuItem>
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DataBucketGroupSelection = (): JSX.Element => {
    let checklistJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Bucket Groups')}
        </Typography>
        {objectToArray(us_activeDataBucketGroups)
          .sort(dynamicSort('name', null))
          .map((dataBucketGroup: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Checkbox
                checked={getProp(getProp(us_dataBucket, 'associated_data_bucket_group_keys', {}), dataBucketGroup.key, false)}
                onChange={(event, value) => {
                  if (value != null) {
                    let dataBucketUpdateObject: TsInterface_UnspecifiedObject = {
                      associated_data_bucket_group_keys: {
                        [dataBucketGroup.key]: value,
                      },
                    }
                    let groupUpdateObject: TsInterface_UnspecifiedObject = {}
                    if (value === true) {
                      groupUpdateObject = {
                        associated_data_bucket_keys: {
                          [us_dataBucket.key]: us_dataBucket.name,
                        },
                      }
                    } else {
                      groupUpdateObject = {
                        associated_data_bucket_keys: {
                          [us_dataBucket.key]: null,
                        },
                      }
                    }
                    // Save to database
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_DataBuckets_Document(res_GCK.clientKey, itemKey),
                          data: dataBucketUpdateObject,
                        })
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_DataBucketGroups_Document(res_GCK.clientKey, dataBucketGroup.key),
                          data: groupUpdateObject,
                        })
                        DatabaseBatchUpdate(updateArray)
                          .then((res_DBU) => {
                            // Nothing
                          })
                          .catch((rej_DBU) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              {dataBucketGroup.name}
            </Box>
          ))}
      </Box>
    )
    return checklistJSX
  }

  const rJSX_ReviewStatusIconDropdown = (reviewStatus: TsInterface_UnspecifiedObject): JSX.Element => {
    let statusJSX = <></>
    if (reviewStatus != null && reviewStatus.status != 'deleted') {
      statusJSX = (
        <Box className="tw-py-1 tw-ml-6">
          <Stack
            direction="row"
            alignItems="center"
          >
            <Icon
              icon={reviewStatus.icon}
              sx={{ color: reviewStatus.color, fontSize: '18px', marginRight: '8px' }}
              className="tw-mr-2"
            />
            {reviewStatus.name}
            <Icon
              icon="pen-to-square"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Edit')}
              onClick={() => {
                editReviewStatus(reviewStatus)
              }}
            />
            <Icon
              icon="trash"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
              tooltip={rLIB('Delete')}
              onClick={() => {
                deleteReviewStatus(reviewStatus)
              }}
            />
          </Stack>
        </Box>
      )
    }
    return statusJSX
  }

  const rJSX_DataReviewStatusOptions = (): JSX.Element => {
    return (
      <Box className="">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Review Statuses')}
        </Typography>
        {objectToArray(us_dataBucket.review_statuses)
          .sort(dynamicSort('name', 'asc'))
          .map((reviewStatus: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_ReviewStatusIconDropdown(reviewStatus)}</Box>
          ))}
        <Box>
          <Button
            color="success"
            variant="contained"
            size="small"
            disableElevation
            startIcon={<Icon icon="circle-plus" />}
            onClick={() => {
              addReviewStatus()
            }}
            className="tw-ml-6"
          >
            {rLIB('Add Review Status')}
          </Button>
        </Box>
      </Box>
    )
  }

  const rJSX_ReviewSettings = (): JSX.Element => {
    return (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Data Review Settings')}
        </Typography>
        <Box>
          <Box className="tw-ml-4">
            <Switch
              color="info"
              checked={us_dataBucket.review_enabled === true ? true : false}
              disabled={false}
              onChange={(event, value) => {
                if (value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = { review_enabled: true }
                  if (us_dataBucket.review_statuses == null) {
                    updateObject['review_statuses'] = defaultReviewStatuses
                    updateObject['default_review_status'] = 'not_reviewed'
                  }
                  setMergeDataBucketRootData(updateObject)
                } else if (value === false) {
                  setMergeDataBucketRootData({ review_enabled: false })
                }
              }}
            />
            {us_dataBucket.review_enabled === true ? rLIB('Data Review Enabled') : rLIB('Data Review Not Enabled')}
          </Box>
          {us_dataBucket.review_enabled === true ? (
            <Box className="tw-ml-4">
              <Switch
                color="info"
                checked={us_dataBucket.review_notes_enabled === true ? true : false}
                disabled={false}
                onChange={(event, value) => {
                  if (value === true) {
                    let updateObject: TsInterface_UnspecifiedObject = { review_notes_enabled: true }
                    setMergeDataBucketRootData(updateObject)
                  } else if (value === false) {
                    setMergeDataBucketRootData({ review_notes_enabled: false })
                  }
                }}
              />
              {us_dataBucket.review_notes_enabled === true ? rLIB('Include notes with review') : rLIB('Do not include notes with review')}
            </Box>
          ) : null}
        </Box>
        {us_dataBucket.review_enabled === true ? rJSX_DataReviewStatusOptions() : null}
      </Box>
    )
  }

  const rJSX_ReportTemplateDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            setMergeDataBucketRootData({ associated_default_report_template: event.target.value })
          }}
          value={getProp(us_dataBucket, 'associated_default_report_template', '')}
        >
          {objectToArray(us_activeReportTemplates).map((reportTemplate: TsInterface_UnspecifiedObject, index: number) => (
            <MenuItem
              key={index}
              value={reportTemplate.key}
            >
              {reportTemplate.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DefaultReportTemplateSelection = (): JSX.Element => {
    let reportTemplateSelectionJSX = <></>
    reportTemplateSelectionJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Default Report Template')}
        </Typography>
        <Box className="tw-ml-4">{rJSX_ReportTemplateDropdown()}</Box>
      </Box>
    )
    return reportTemplateSelectionJSX
  }

  const rJSX_SettingsTabContent = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card className="tw-p-2">
          <Box>{rJSX_InactiveBanner()}</Box>
          <Box className="tw-p-2">
            {rJSX_AvailableForUseToggle()}
            <Card className="tw-p-2 tw-mt-2">
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {rLIB('Form for Data Bucket')}
              </Typography>
              <Box className="tw-ml-4">
                {rJSX_DataTypeDropdown()}
                {rJSX_CustomFormBoolean()}
                {rJSX_CustomFormDropdown()}
              </Box>
            </Card>
            <Card className="tw-p-2 tw-mt-2">{rJSX_AssociationSelection()}</Card>
            <Card className="tw-p-2 tw-mt-2">{rJSX_TimezoneSettings()}</Card>
            <Card className="tw-p-2 tw-mt-2">{rJSX_DefaultReportTemplateSelection()}</Card>
            <Card className="tw-p-2 tw-mt-2">{rJSX_DataBucketGroupSelection()}</Card>
            <Card className="tw-p-2 tw-mt-2">{rJSX_ReviewSettings()}</Card>
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_DirectFormLinkCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Direct Form Link')}
        </Typography>
        {us_dataBucket.prevent_multiple_use_link !== true ? (
          <>
            <Box className="tw-ml-4">
              <a
                className="tw-text-info_main tw-underline"
                href={getBaseURL() + '/form/' + itemKey}
                target="_blank"
                rel="noreferrer"
              >
                {getBaseURL() + '/form/' + itemKey}
              </a>
            </Box>
            {rJSX_ConfirmationCodeToggle()}
          </>
        ) : (
          <></>
        )}
        {rJSX_PreventMultipleUseLinkToggle()}
      </Card>
    )
  }

  const rJSX_QRCodeCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('QR Code')}
        </Typography>
        {us_dataBucket.prevent_multiple_use_link !== true ? (
          <>
            <Box className="tw-ml-4">{rJSX_DownloadQRCodePDFButton()}</Box>
            {rJSX_ConfirmationCodeToggle()}

            <Box>
              {/* <Typography variant="h6" className="tw-font-bold">{ s_ACCESS_TYPE }</Typography> */}
              <Box className="tw-ml-4">
                <Switch
                  color="info"
                  checked={us_dataBucket.require_app === true ? true : false}
                  disabled={false}
                  onChange={(event, value) => {
                    if (value === true) {
                      setMergeDataBucketRootData({ require_app: true })
                    } else if (value === false) {
                      setMergeDataBucketRootData({ require_app: false, require_geofence: false })
                    }
                  }}
                />
                {us_dataBucket.require_app === true ? rLIB('Users must scan in the app') : rLIB('User can scan with the app or from the browser')}
              </Box>
            </Box>
            <Box>
              {/* <Typography variant="h6" className="tw-font-bold">{ s_FORCE_GEOFENCE_VERIFICATION }</Typography> */}
              <Box className="tw-ml-4">
                <Switch
                  color="info"
                  checked={us_dataBucket.require_geofence === true ? true : false}
                  disabled={getProp(us_dataBucket, 'require_app', false) === false}
                  onChange={(event, value) => {
                    if (value === true) {
                      setMergeDataBucketRootData({ require_geofence: true })
                    } else if (value === false) {
                      setMergeDataBucketRootData({ require_geofence: false })
                    }
                  }}
                />
                {us_dataBucket.require_geofence === true ? rLIB('Geofence verification required') : rLIB('Geofence verification not required')}
              </Box>
            </Box>
            <Box className="tw-ml-4">{rJSX_GeofenceSettings()}</Box>
          </>
        ) : (
          <></>
        )}

        {rJSX_PreventMultipleUseLinkToggle()}
      </Card>
    )
  }

  const rJSX_DownloadTemplateButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        disableElevation
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          downloadImportTemplate()
        }}
        startIcon={<Icon icon="cloud-arrow-down" />}
      >
        {rLIB('Download Template')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_UploadCSVButton = (): JSX.Element => {
    let buttonJSX = (
      <CSVReader
        // https://react-papaparse.js.org/docs#config
        // https://github.com/Bunlong/react-papaparse/blob/v4.0.0/examples/CSVReaderBasicUpload.tsx
        onUploadAccepted={(results: any) => {
          handleFileSelection(results.data)
        }}
        noDrag
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
          <Button
            color="info"
            variant="contained"
            disableElevation
            disabled={us_parsingData === true}
            className="tw-mr-2 tw-mb-2"
            {...getRootProps()}
            startIcon={<Icon icon="cloud-arrow-up" />}
          >
            {rLIB('Upload CSV')}
          </Button>
        )}
      </CSVReader>
    )
    return buttonJSX
  }

  const rJSX_ManualImportCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Manual Import')}
        </Typography>
        <Box className="tw-ml-4">
          {rJSX_DownloadTemplateButton()}
          {rJSX_UploadCSVButton()}
        </Box>
      </Card>
    )
  }

  const rJSX_ImportHeaderChip = (fieldKey: string | null, headerName: string, deletable: boolean): JSX.Element => {
    let backgroundColor = themeVariables.gray_200
    let borderColor = themeVariables.gray_300
    let hoverBackgroundColor = themeVariables.gray_300
    let hoverBorderColor = themeVariables.gray_400
    if (
      headerName != null &&
      us_inboundEmailFieldMappingCount != null &&
      us_inboundEmailFieldMappingCount[headerName] != null &&
      us_inboundEmailFieldMappingCount[headerName] > 1
    ) {
      backgroundColor = themeVariables.error_light
      borderColor = themeVariables.error_main
      hoverBackgroundColor = themeVariables.error_main
      hoverBorderColor = themeVariables.error_dark
    }
    return (
      <Box
        className="tw-mr-2 tw-mb-2 tw-cursor-pointer"
        sx={{
          'background': backgroundColor,
          'border': '1px solid ' + borderColor,
          'padding': '2px 4px',
          'borderRadius': '4px',
          '&:hover': {
            background: hoverBackgroundColor,
            border: '1px solid ' + hoverBorderColor,
          },
        }}
        component="span"
        onClick={() => {
          if (deletable === true && fieldKey != null) {
            deleteImportHeader(fieldKey, headerName)
          }
        }}
      >
        {headerName}
      </Box>
    )
  }

  const rJSX_CustomImportMappingFields = (): JSX.Element => {
    let fieldsJSX = (
      <Card className="tw-ml-4 tw-mt-2 tw-mr-4">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold !important' }}
                  >
                    {rLIB('Form Field')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 'bold !important' }}
                  >
                    {rLIB('Valid Import Headers')}
                  </Typography>
                </TableCell>
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">
                    {rLIB('Submitted Date')} ({'MM/DD/YYYY HH:mm'})
                  </Typography>
                </TableCell>
                <TableCell>
                  {rJSX_ImportHeaderChip('Submitted Date', rLIB('Submitted Date', false) as string, false)}
                  {getProp(us_inboundEmailFieldMapping, 'Submitted Date', []).map((importHeader: string, index: number) => (
                    <Box
                      component="span"
                      key={index}
                    >
                      {rJSX_ImportHeaderChip('Submitted Date', importHeader, true)}
                    </Box>
                  ))}
                  <Icon
                    sx={{
                      'fontSize': '18px',
                      '&:hover': {
                        color: themeVariables.success_main,
                      },
                    }}
                    className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-align-middle"
                    icon="circle-plus"
                    tooltip={
                      <>
                        {rLIB('Add Import Header Option')} - {rLIB('Submitted Date', false) as string}
                      </>
                    }
                    tooltipPlacement="top"
                    onClick={() => addImportHeader(rLIB('Submitted Date', false) as string, 'Submitted Date')}
                  />
                </TableCell>
              </TableRow>
              {objectToArray(us_selectedClassFields)
                .sort(dynamicSort('order', 'asc'))
                .map((field: TsInterface_UnspecifiedObject, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body1">{field.name}</Typography>
                    </TableCell>
                    <TableCell>
                      {rJSX_ImportHeaderChip(field.key, field.name, false)}
                      {getProp(us_inboundEmailFieldMapping, field.key, []).map((importHeader: string, index: number) => (
                        <Box
                          component="span"
                          key={index}
                        >
                          {rJSX_ImportHeaderChip(field.key, importHeader, true)}
                        </Box>
                      ))}
                      <Icon
                        sx={{
                          'fontSize': '18px',
                          '&:hover': {
                            color: themeVariables.success_main,
                          },
                        }}
                        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-align-middle"
                        icon="circle-plus"
                        tooltip={
                          <>
                            {rLIB('Add Import Header Option')} - {field.name}
                          </>
                        }
                        tooltipPlacement="top"
                        onClick={() => addImportHeader(field.name, field.key)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
    return fieldsJSX
  }

  const rJSX_AddInboundEmailButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_dataBucket.inbound_email_enabled !== true || us_dataBucket.inbound_email_address == null) {
      buttonJSX = (
        <Box className="tw-ml-4">
          <Button
            color="info"
            variant="contained"
            disableElevation
            startIcon={<Icon icon="circle-plus" />}
            onClick={() => {
              generateGlobalInboundEmailParser()
            }}
          >
            {rLIB('Add Inbound Email')}
          </Button>
        </Box>
      )
    }
    return buttonJSX
  }

  const rJSX_InboundEmailParserContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_dataBucket.inbound_email_enabled === true && us_dataBucket.inbound_email_address != null) {
      contentJSX = (
        <Box>
          <Stack
            direction="row"
            spacing={2}
          >
            <Box
              className="tw-ml-4"
              sx={{ fontSize: '18px' }}
            >
              <a
                className="tw-text-info_main tw-underline"
                href={'mailto:' + us_dataBucket.inbound_email_address + baseInboundEmailAddress}
              >
                {us_dataBucket.inbound_email_address + baseInboundEmailAddress}
              </a>
            </Box>
            <Icon
              icon="circle-xmark"
              tooltip={rLIB('Disable Inbound Email')}
              tooltipPlacement="right"
              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-align-middle tw-mt-1.5 tw-ml-1 hover:tw-text-error_main"
              onClick={() => {
                disableInboundEmail()
              }}
            />
          </Stack>
          {rJSX_CustomImportMappingFields()}
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_InboundCSVEmailsCard = (): JSX.Element => {
    return (
      <Card className="tw-p-2 tw-mb-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Inbound CSV Email')}
        </Typography>
        {rJSX_AddInboundEmailButton()}
        {rJSX_InboundEmailParserContent()}
      </Card>
    )
  }

  const rJSX_DataSourcesTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (us_dataBucket.available_for_use !== true) {
      tabContentJSX = <Card className="tw-p-4">{rJSX_AvailableForUseToggle()}</Card>
    } else {
      tabContentJSX = (
        <Card className="tw-p-4">
          {rJSX_DirectFormLinkCard()}
          {rJSX_QRCodeCard()}
          {rJSX_ManualImportCard()}
          {rJSX_InboundCSVEmailsCard()}
        </Card>
      )
    }
    return tabContentJSX
  }

  const rJSX_DataTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null && us_tableColumns_QRCodeData != null && objectToArray(us_tableColumns_QRCodeData).length > 0) {
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{
                editable: true,
                us_dataBucket: us_dataBucket,
                associated_report_template_key: getProp(us_dataBucket, 'associated_default_report_template', null),
                // has_default_report_template: getProp(us_dataBucket, 'associated_default_report_template', null) != null,
              }}
              tableColumns={us_tableColumns_QRCodeData}
              tableDatabaseEndpoint={tableDatabaseEndpoint_QRCodeData}
              tableSettings={tableSettings_QRCodeData}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  const rJSX_ImportSessionsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_ImportSessions}
              tableDatabaseEndpoint={tableDatabaseEndpoint_DataBucketImportSessions}
              tableSettings={tableSettings_ImportSessions}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  // Calculated Fields // TODO: Edit in classes_view.tsx as well
  // const rJSX_CalculatedFieldIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
  //   let iconJSX = <></>
  //   if (calculatedField != null && calculatedField['associated_formula_key'] == null) {
  //     iconJSX = (
  //       <Icon
  //         icon="triangle-exclamation"
  //         className="tw-ml-2"
  //         tooltip={rLIB('Calculated Field Missing Formula')}
  //         tooltipPlacement="right"
  //         sx={{ color: themeVariables.error_main }}
  //       />
  //     )
  //   } else {
  //     // TODO: Add other bad format cases
  //     iconJSX = (
  //       <Icon
  //         icon="badge-check"
  //         className="tw-ml-2"
  //         tooltip={rLIB('Calculated Field Setup Complete')}
  //         tooltipPlacement="right"
  //         sx={{ color: themeVariables.success_main }}
  //       />
  //     )
  //   }
  //   return iconJSX
  // }

  const rJSX_EditCalculatedFieldButton = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          editCalculatedFieldName(calculatedField, calculatedField.key)
        }}
      >
        <Icon
          icon="pen-to-square"
          tooltip={rLIB('Edit Calculated Field')}
          tooltipPlacement="top"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_EditCalculatedFieldFormulaIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.success_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          editCalculatedFieldAssociatedFormula(calculatedField, calculatedField.key)
        }}
      >
        <Icon
          icon="pen-to-square"
          tooltip={rLIB('Edit Calculated Field Formula')}
          tooltipPlacement="right"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_DeleteCalculatedFieldFormulaIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = (
      <Box
        className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
        sx={{
          '&:hover': {
            color: themeVariables.error_main,
          },
        }}
        onClick={(event: any) => {
          event.stopPropagation()
          deleteCalculatedField(calculatedField)
        }}
      >
        <Icon
          icon="trash"
          tooltip={rLIB('Delete Calculated Field')}
          tooltipPlacement="top"
        />
      </Box>
    )
    return iconJSX
  }

  const rJSX_CalculationFormulaName = (calculatedField: TsInterface_UnspecifiedObject): string => {
    let formulaName = ''
    if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key].name != null
    ) {
      formulaName = us_activeFormulas[calculatedField.associated_formula_key].name
    } else if (calculatedField != null && calculatedField.associated_formula_name != null) {
      formulaName = calculatedField.associated_formula_name
    }
    return formulaName
  }

  const rJSX_EditCalculatedFieldMissingOrderIcon = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (calculatedField.order == null) {
      iconJSX = (
        <Box
          className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
          sx={{
            '&:hover': {
              color: themeVariables.warning_main,
            },
          }}
          onClick={(event: any) => {
            event.stopPropagation()
            addOrderToCalculatedField(calculatedField.key)
          }}
        >
          <Icon
            icon="triangle-exclamation"
            tooltip={rLIB('Add to Sequence')}
            tooltipPlacement="top"
          />
        </Box>
      )
    }
    return iconJSX
  }

  const rJSX_EditCalculatedFieldUpOrderIcons = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (calculatedField.order != null) {
      iconJSX = (
        <Box
          className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
          sx={{
            '&:hover': {
              color: themeVariables.info_main,
            },
          }}
          onClick={(event: any) => {
            event.stopPropagation()
            moveCalculatedFieldUp(calculatedField.key)
          }}
        >
          <Icon
            icon="up"
            tooltip={rLIB('Move Up')}
            tooltipPlacement="top"
          />
        </Box>
      )
    }
    return iconJSX
  }

  const rJSX_EditCalculatedFieldDownOrderIcons = (calculatedField: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (calculatedField.order != null) {
      iconJSX = (
        <Box
          className="tw-inline-block tw-opacity-30 tw-cursor-pointer tw-ml-2 hover:tw-opacity-100"
          sx={{
            '&:hover': {
              color: themeVariables.info_main,
            },
          }}
          onClick={(event: any) => {
            event.stopPropagation()
            moveCalculatedFieldDown(calculatedField.key)
          }}
        >
          <Icon
            icon="down"
            tooltip={rLIB('Move Down')}
            tooltipPlacement="top"
          />
        </Box>
      )
    }
    return iconJSX
  }

  const rJSX_CalculatedFieldOrderNumber = (index: number): JSX.Element => {
    let orderNumberJSX = <></>
    orderNumberJSX = (
      <Box
        className="tw-inline-block tw-mr-2 tw-px-3 tw-rounded-lg tw-mb-1"
        sx={{ backgroundColor: themeVariables.info_light, color: themeVariables.white }}
      >
        {index + 1}
      </Box>
    )
    return orderNumberJSX
  }

  const rJSX_CalculatedFieldCard = (calculatedField: TsInterface_UnspecifiedObject, index: number): JSX.Element => {
    let calculatedFieldJSX = <></>
    let variablesForCalculatedField: TsInterface_UnspecifiedObject[] = []
    if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key]['formula_type'] === 'library' &&
      us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key'] != null &&
      libraryFormulas != null &&
      libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']] != null &&
      libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables != null
    ) {
      for (let loopVariableKey in libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables) {
        let loopVariable =
          libraryFormulas[us_activeFormulas[calculatedField.associated_formula_key]['associated_library_formula_key']].variables[loopVariableKey]
        variablesForCalculatedField.push({
          key: loopVariableKey,
          name: loopVariable.value,
          data_type: loopVariable.data_type,
        })
      }
    } else if (
      calculatedField != null &&
      calculatedField.associated_formula_key != null &&
      us_activeFormulas != null &&
      us_activeFormulas[calculatedField.associated_formula_key] != null &&
      us_activeFormulas[calculatedField.associated_formula_key].variables != null
    ) {
      for (let loopVariableKey in us_activeFormulas[calculatedField.associated_formula_key].variables) {
        let loopVariable = us_activeFormulas[calculatedField.associated_formula_key].variables[loopVariableKey]
        if (loopVariable != null && loopVariable.status === 'active') {
          variablesForCalculatedField.push(loopVariable)
        }
      }
    }
    calculatedFieldJSX = (
      <Card className="tw-p-4 tw-mb-4">
        {/* <Accordion className="tw-mb-2"> */}
        {/* <AccordionSummary expandIcon={<Icon icon="angle-down" />}> */}
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between tw-pr-2"
          sx={{ width: '100%' }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 900 }}
          >
            {rJSX_CalculatedFieldOrderNumber(index)}
            {calculatedField.name}
            {/* {rJSX_CalculatedFieldIcon(calculatedField)} */}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            className="tw-pt-1"
          >
            {rJSX_EditCalculatedFieldMissingOrderIcon(calculatedField)}
            {rJSX_EditCalculatedFieldUpOrderIcons(calculatedField)}
            {rJSX_EditCalculatedFieldDownOrderIcons(calculatedField)}
            {rJSX_EditCalculatedFieldButton(calculatedField)}
            {rJSX_DeleteCalculatedFieldFormulaIcon(calculatedField)}
          </Stack>
        </Stack>
        {/* </AccordionSummary> */}
        {/* <AccordionDetails sx={{ paddingTop: '0px' }}> */}
        <Divider className="tw-my-2" />
        <Typography variant="h6">
          <Box
            className="tw-inline-block tw-mr-1 tw-ml-8"
            sx={{ fontWeight: 700 }}
          >
            {rLIB('Formula')}:
          </Box>
          {calculatedField.associated_formula_key != null ? (
            <Box className="tw-inline-block tw-mr-1 tw-opacity-40">{rJSX_CalculationFormulaName(calculatedField)}</Box>
          ) : (
            <Box className="tw-inline-block tw-mr-1 tw-opacity-40 tw-italic">{rLIB('Formula Missing')}</Box>
          )}
          {rJSX_EditCalculatedFieldFormulaIcon(calculatedField)}
        </Typography>
        {/* <Divider className="tw-my-3" /> */}
        <Typography variant="h6">
          <Box
            className="tw-inline-block tw-mr-1 tw-mb-2 tw-ml-8"
            sx={{ fontWeight: 700 }}
          >
            {rLIB('Variables')}:
          </Box>
        </Typography>
        <Box
          className="tw-rounded-xl tw-ml-8"
          sx={{ border: '1px solid rgba(0,0,0,0.2)' }}
        >
          <TableBasic
            tableAdditionalData={{
              itemKey: itemKey,
              calculatedField: calculatedField,
              calculatedFields: us_activeCalculatedFields,
              us_selectedClassFields: us_selectedClassFields,
              table_data_source: 'data_bucket',
              us_activeLookupTables: us_activeLookupTables,
            }}
            tableColumns={tableColumns_CalculatedFieldVariables}
            tableData={objectToArray(variablesForCalculatedField)}
            tableSettings={tableSettings_CalculatedFieldVariables}
          />
        </Box>
        <Box className="tw-mt-2">
          {/* <Json
              data={calculatedField}
              alphabetized={true}
            /> */}
        </Box>
        {/* </AccordionDetails> */}
        {/* </Accordion> */}
      </Card>
    )
    return calculatedFieldJSX
  }

  const rJSX_CalculatedFieldsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    tabContentJSX = (
      <Card className="tw-p-4">
        {objectToArray(us_activeCalculatedFields)
          .sort(dynamicSort('order', null))
          .map((calculatedField: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_CalculatedFieldCard(calculatedField, index)}</Box>
          ))}
      </Card>
    )
    return tabContentJSX
  }

  const rJSX_TriggeredEventsFormLineItem = (lineItemKey: string, lineItemName: string | JSX.Element): JSX.Element => {
    // TODO: Form Inputs
    let formInputs: TsInterface_FormInputs = {}
    if (formInputs_FormSubmissionTriggeredEvents != null && formInputs_FormSubmissionTriggeredEvents[lineItemKey] != null) {
      formInputs[lineItemKey] = formInputs_FormSubmissionTriggeredEvents[lineItemKey]
    }
    let templateOptions: TsInterface_UnspecifiedObject[] = []
    if (
      lineItemKey === 'email_on_form_submission_attachment' &&
      us_activeReportTemplates != null &&
      formInputs != null &&
      formInputs['email_on_form_submission_attachment'] != null &&
      formInputs['email_on_form_submission_attachment'].options != null
    ) {
      for (let loopTemplateKey in us_activeReportTemplates) {
        let loopTemplate = us_activeReportTemplates[loopTemplateKey]
        if (loopTemplate != null && loopTemplate.file_type === 'pdf') {
          templateOptions.push({
            key: loopTemplate.key,
            value: loopTemplate.name,
          })
        }
      }
      formInputs['email_on_form_submission_attachment'].options = templateOptions
    }

    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-inline-block tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: getProp(us_dataBucket, 'triggered_events', {}),
                formInputs: formInputs,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    setMergeDataBucketRootData({ triggered_events: { [lineItemKey]: formSubmittedData[lineItemKey] } })
                      .then(() => {
                        resolve({ success: true })
                      })
                      .catch(() => {
                        reject({ success: false })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {rLIB('Edit')} {lineItemName}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    let linItemJSX = <></>
    if (us_dataBucket.triggered_events != null && us_dataBucket.triggered_events[lineItemKey] != null) {
      if (lineItemKey === 'email_on_form_submission_to') {
        linItemJSX = <Box className="tw-inline-block">{us_dataBucket.triggered_events[lineItemKey].join(', ')}</Box>
      } else if (lineItemKey === 'email_on_form_submission_attachment') {
        if (
          us_activeReportTemplates != null &&
          us_dataBucket.triggered_events[lineItemKey] != null &&
          us_activeReportTemplates[us_dataBucket.triggered_events[lineItemKey]] != null
        ) {
          linItemJSX = <Box className="tw-inline-block">{us_activeReportTemplates[us_dataBucket.triggered_events[lineItemKey]].name}</Box>
        } else {
          linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
        }
      } else {
        linItemJSX = <Box className="tw-inline-block">{us_dataBucket.triggered_events[lineItemKey]}</Box>
      }
    } else {
      linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
    }
    return (
      <Box className="tw-inline-block">
        {linItemJSX}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_MissingTriggerFieldIcon = (fieldName: string): JSX.Element => {
    let iconJSX = <></>
    if (us_dataBucket.triggered_events == null || us_dataBucket.triggered_events[fieldName] == null) {
      iconJSX = (
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
          sx={{ color: themeVariables.error_main }}
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="circle-check"
          className="tw-mr-2"
          sx={{ color: themeVariables.success_main }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_EmailOnFormSubmissionForm = (): JSX.Element => {
    return (
      <Box className="tw-ml-6">
        <Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_to')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Email Recipients')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_to', rLIB('Email Recipients'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_subject')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Subject')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_subject', rLIB('Subject'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_body')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Body')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_body', rLIB('Body'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_attachment')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Attachment')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_attachment', rLIB('Attachment'))}
          </Box>
          <Box>
            {rJSX_MissingTriggerFieldIcon('email_on_form_submission_attachment_name')}
            <Typography
              variant="body1"
              className="tw-font-bold tw-mr-2 tw-inline-block"
            >
              {rLIB('Attachment Name')}:
            </Typography>
            {rJSX_TriggeredEventsFormLineItem('email_on_form_submission_attachment_name', rLIB('Attachment Name'))}
          </Box>
        </Box>
      </Box>
    )
  }

  const rJSX_TriggeredEventsReviewStatusFormLineItem = (
    reviewStatus: TsInterface_UnspecifiedObject,
    lineItemKey: string,
    lineItemName: string | JSX.Element,
  ): JSX.Element => {
    // TODO: Form Inputs
    let formInputs: TsInterface_FormInputs = {}
    if (formInputs_StatusChangeTriggeredEvents != null && formInputs_StatusChangeTriggeredEvents[lineItemKey] != null) {
      formInputs[lineItemKey] = formInputs_StatusChangeTriggeredEvents[lineItemKey]
    }
    let templateOptions: TsInterface_UnspecifiedObject[] = []
    if (
      lineItemKey === 'email_on_status_change_attachment' &&
      us_activeReportTemplates != null &&
      formInputs != null &&
      formInputs['email_on_status_change_attachment'] != null &&
      formInputs['email_on_status_change_attachment'].options != null
    ) {
      for (let loopTemplateKey in us_activeReportTemplates) {
        let loopTemplate = us_activeReportTemplates[loopTemplateKey]
        if (loopTemplate != null && loopTemplate.file_type === 'pdf') {
          templateOptions.push({
            key: loopTemplate.key,
            value: loopTemplate.name,
          })
        }
      }
      formInputs['email_on_status_change_attachment'].options = templateOptions
    }

    let formData: TsInterface_UnspecifiedObject = {}
    if (us_dataBucket.review_statuses != null && us_dataBucket.review_statuses[reviewStatus.key] != null) {
      formData = us_dataBucket.review_statuses[reviewStatus.key]
    }

    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-inline-block tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: formData,
                formInputs: formInputs,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    setMergeDataBucketRootData({
                      review_statuses: {
                        [reviewStatus.key]: { [lineItemKey]: formSubmittedData[lineItemKey] },
                      },
                    })
                      .then(() => {
                        resolve({ success: true })
                      })
                      .catch(() => {
                        reject({ success: false })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {rLIB('Edit')} {lineItemName}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    let linItemJSX = <></>
    if (
      us_dataBucket.review_statuses != null &&
      us_dataBucket.review_statuses[reviewStatus.key] != null &&
      us_dataBucket.review_statuses[reviewStatus.key][lineItemKey] != null
    ) {
      if (lineItemKey === 'email_on_status_change_to') {
        linItemJSX = <Box className="tw-inline-block">{us_dataBucket.review_statuses[reviewStatus.key][lineItemKey].join(', ')}</Box>
      } else if (lineItemKey === 'email_on_status_change_attachment') {
        if (
          us_activeReportTemplates != null &&
          us_dataBucket.review_statuses[reviewStatus.key][lineItemKey] != null &&
          us_activeReportTemplates[us_dataBucket.review_statuses[reviewStatus.key][lineItemKey]] != null
        ) {
          linItemJSX = <Box className="tw-inline-block">{us_activeReportTemplates[us_dataBucket.review_statuses[reviewStatus.key][lineItemKey]].name}</Box>
        } else {
          linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
        }
      } else {
        linItemJSX = <Box className="tw-inline-block">{us_dataBucket.review_statuses[reviewStatus.key][lineItemKey]}</Box>
      }
    } else {
      linItemJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>
    }
    return (
      <Box className="tw-inline-block">
        {linItemJSX}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_MissingReviewStatusTriggerFieldIcon = (reviewStatus: TsInterface_UnspecifiedObject, fieldName: string): JSX.Element => {
    let iconJSX = <></>
    if (
      us_dataBucket.review_statuses == null ||
      us_dataBucket.review_statuses[reviewStatus.key] == null ||
      us_dataBucket.review_statuses[reviewStatus.key][fieldName] == null
    ) {
      iconJSX = (
        <Icon
          icon="triangle-exclamation"
          className="tw-mr-2"
          sx={{ color: themeVariables.error_main }}
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="circle-check"
          className="tw-mr-2"
          sx={{ color: themeVariables.success_main }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_ReviewStatusEmail = (reviewStatus: TsInterface_UnspecifiedObject): JSX.Element => {
    let statusJSX = <></>
    if (reviewStatus != null && reviewStatus.status != 'deleted') {
      statusJSX = (
        <Card className="tw-p-2 tw-mb-2">
          <Switch
            color="info"
            checked={reviewStatus.trigger_status_update_email === true ? true : false}
            disabled={false}
            onChange={(event, value) => {
              if (value === true) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  review_statuses: {
                    [reviewStatus.key]: {
                      trigger_status_update_email: true,
                    },
                  },
                }
                setMergeDataBucketRootData(updateObject)
              } else if (value === false) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  review_statuses: {
                    [reviewStatus.key]: {
                      trigger_status_update_email: false,
                    },
                  },
                }
                setMergeDataBucketRootData(updateObject)
              }
            }}
          />
          {reviewStatus.name}
          {reviewStatus.trigger_status_update_email === true ? (
            <Box className="tw-ml-6">
              <Box>
                {rJSX_MissingReviewStatusTriggerFieldIcon(reviewStatus, 'email_on_status_change_to')}
                <Typography
                  variant="body1"
                  className="tw-font-bold tw-mr-2 tw-inline-block"
                >
                  {rLIB('Email Recipients')}:
                </Typography>
                {rJSX_TriggeredEventsReviewStatusFormLineItem(reviewStatus, 'email_on_status_change_to', rLIB('Email Recipients'))}
              </Box>
              <Box>
                {rJSX_MissingReviewStatusTriggerFieldIcon(reviewStatus, 'email_on_status_change_subject')}
                <Typography
                  variant="body1"
                  className="tw-font-bold tw-mr-2 tw-inline-block"
                >
                  {rLIB('Subject')}:
                </Typography>
                {rJSX_TriggeredEventsReviewStatusFormLineItem(reviewStatus, 'email_on_status_change_subject', rLIB('Subject'))}
              </Box>
              <Box>
                {rJSX_MissingReviewStatusTriggerFieldIcon(reviewStatus, 'email_on_status_change_body')}
                <Typography
                  variant="body1"
                  className="tw-font-bold tw-mr-2 tw-inline-block"
                >
                  {rLIB('Body')}:
                </Typography>
                {rJSX_TriggeredEventsReviewStatusFormLineItem(reviewStatus, 'email_on_status_change_body', rLIB('Body'))}
              </Box>
              <Box>
                {rJSX_MissingReviewStatusTriggerFieldIcon(reviewStatus, 'email_on_status_change_attachment')}
                <Typography
                  variant="body1"
                  className="tw-font-bold tw-mr-2 tw-inline-block"
                >
                  {rLIB('Attachment')}:
                </Typography>
                {rJSX_TriggeredEventsReviewStatusFormLineItem(reviewStatus, 'email_on_status_change_attachment', rLIB('Attachment'))}
              </Box>
              <Box>
                {rJSX_MissingReviewStatusTriggerFieldIcon(reviewStatus, 'email_on_status_change_attachment_name')}
                <Typography
                  variant="body1"
                  className="tw-font-bold tw-mr-2 tw-inline-block"
                >
                  {rLIB('Attachment Name')}:
                </Typography>
                {rJSX_TriggeredEventsReviewStatusFormLineItem(reviewStatus, 'email_on_status_change_attachment_name', rLIB('Attachment Name'))}
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Card>
      )
    }
    return statusJSX
  }

  const rJSX_ReviewStatusEmails = (): JSX.Element => {
    let reviewJSX = <></>
    if (us_dataBucket.review_statuses != null && us_dataBucket.review_enabled === true) {
      reviewJSX = (
        <Card className="tw-p-4 tw-mt-2">
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Review Status Update Emails')}
          </Typography>
          {objectToArray(us_dataBucket.review_statuses)
            .sort(dynamicSort('name', 'asc'))
            .map((reviewStatus: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_ReviewStatusEmail(reviewStatus)}</Box>
            ))}
        </Card>
      )
    }
    return reviewJSX
  }

  const rJSX_TriggeredEventsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    tabContentJSX = (
      <Card className="tw-p-4">
        <Card className="tw-p-2">
          <Box>
            <Typography
              variant="h6"
              className="tw-font-bold"
            >
              {rLIB('Email when form is submitted')}
            </Typography>
            <Box className="tw-ml-4">
              <Switch
                color="info"
                checked={getProp(getProp(us_dataBucket, 'triggered_events', {}), 'email_on_form_submission', false) === true ? true : false}
                disabled={false}
                onChange={(event, value) => {
                  if (value === true) {
                    setMergeDataBucketRootData({ triggered_events: { email_on_form_submission: true } })
                  } else if (value === false) {
                    setMergeDataBucketRootData({ triggered_events: { email_on_form_submission: false } })
                  }
                }}
              />
              {getProp(getProp(us_dataBucket, 'triggered_events', {}), 'email_on_form_submission', false) === true
                ? rLIB('Send email when form is submitted')
                : rLIB('Do not send email when form is submitted')}
            </Box>
            {getProp(getProp(us_dataBucket, 'triggered_events', {}), 'email_on_form_submission', false) === true ? rJSX_EmailOnFormSubmissionForm() : <></>}
          </Box>
        </Card>
        {rJSX_ReviewStatusEmails()}
      </Card>
    )
    return tabContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Settings',
                  tabHeader: rLIB('Settings'),
                  tabOnChange: () => {},
                  tabContent: rJSX_SettingsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Data_Sources',
                  tabHeader: rLIB('Data Sources'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DataSourcesTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Calculated_Fields',
                  tabHeader: rLIB('Calculated Fields'),
                  tabOnChange: () => {},
                  tabContent: rJSX_CalculatedFieldsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewCalculatedFieldButton(false), minJSX: rJSX_NewCalculatedFieldButton(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Triggers',
                  tabHeader: rLIB('Triggered Events'),
                  tabOnChange: () => {},
                  tabContent: rJSX_TriggeredEventsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Data',
                  tabHeader: rLIB('Data'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DataTabContent(),
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'Imports',
                  tabHeader: rLIB('Import Sessions'),
                  tabOnChange: () => {},
                  tabContent: rJSX_ImportSessionsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_DownloadTemplateButton(), minJSX: rJSX_DownloadTemplateButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_UploadCSVButton(), minJSX: rJSX_UploadCSVButton(), sizeCutoff: 0 },
                  ],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.DataBucketsViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Data Bucket', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			1) Initializes firebase
			2) Initializes localization
			3) Creates the root application jsx

		TODO: (Categories used in other files)
			[ ] Architecture
			[ ] Bug
			[ ] Cleanup
			[ ] Feature
			[ ] Typescript

	*/

///////////////////////////////
// Imports
///////////////////////////////

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { createRoot } from 'react-dom/client'
import FirebaseInit from 'rfbp_aux/config/firebase_init'
import App from './app'
import './app/localization/localization'

// import es from 'javascript-time-ago/locale/es.json'

///////////////////////////////
// Initialization
///////////////////////////////

// Init Firebase
FirebaseInit()

// Init Time
TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(es)

///////////////////////////////
// Render
///////////////////////////////

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			- TODO: move meter gaps and overlaps from using linked data to using JUST the raw station logs broken down by data bucket
				Don't want to have to rely on linked data for this - it should be able to be done without station logs

			- MAYBE add a location name field for carrier tickets so that they can be easily disregarded if all their data is lumped together???



			TAGGING?
				On data upload (post data processing) look for matches and make those updates as needed
					That way the data is always up to date / no need for matching on the fly

			// EMAIL Structure
				// Name of Reconciliation
				// Date Range
				// Data Buckets Included and Classification
					// Number of Data Items for each bucket

				// Totals
					// Volumne by location (maybe in the table above)

				// Discrepancies

				// Link to log in and view

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { cloudFunctionSendEmailRequest } from 'app/services/external_requests/external_requests'
import { MuiChipsInput } from 'mui-chips-input'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveClasses_Query, DatabaseRef_ClassFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveDataBuckets_Query, DatabaseRef_DataBuckets_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { DatabaseRef_AutomaticReconciliation_Document } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/automatic_reconciliations'
import { DatabaseRef_GlobalRecurringEmail_Document } from 'rfbp_aux/services/database_endpoints/global_emails'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { convertToPacificTime, isValidEmail, sendDateOptions, timezoneOptions } from '../export_scheduled_emails/components/recurring_email_form_dialog'
import {
  formatTime,
  getDataItemsForAutoReconciliation,
  listOfReconciliationErrors,
  reconciliationEmailTemplateObject,
  runReconciliationOnDataSet,
} from './calculate_reconciliation'
import { autoReconciliationLibraryOptions } from './reconciliation_list'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ReconciliationAutoViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ items, setItems ] = 																	useState<TsInterface_UnspecifiedObject>({})
  const [us_sendingEmail, us_setSendingEmail] = useState<boolean>(false)
  const [us_dataBuckets, us_setDataBuckets] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeTransactionalClasses, us_setActiveTransactionalClasses] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_associatedDataBucket, us_setAssociatedDataBucket] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_associatedFormFields, us_setAssociatedFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_dataItemsInReconciliationDateRange, us_setDataItemsInReconciliationDateRange] = useState<TsInterface_UnspecifiedObject>({})
  const [us_otherClassUniqueIdentifierField, us_setOtherClassUniqueIdentifierField] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reconciliationOtherClassFields, us_setReconciliationOtherClassFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootReconciliation, us_setRootReconciliation] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(new Date())
  const [us_selectedReconciliationClassKey, us_setSelectedReconciliationClassKey] = useState<string | null>(null)
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(new Date(new Date().getTime() - 7 * 86400000))
  const inputRef = useRef(null)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Reconciliation', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootReconciliation(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_AutomaticReconciliation_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // useEffect(() => {
  // let unsubscribeLiveData: TsType_VoidFunction
  // const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //   us_setAssociatedDataBucket(newData)
  //   ur_forceRerender()
  // }
  // if (us_rootReconciliation != null && us_rootReconciliation.associated_data_bucket_key != null) {
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       unsubscribeLiveData = DatabaseGetLiveDocument(
  //         DatabaseRef_DataBuckets_Document(res_GCK.clientKey, us_rootReconciliation.associated_data_bucket_key),
  //         updateLiveData,
  //       )
  //     })
  //     .catch((rej_GCK) => {
  //       console.error(rej_GCK)
  //     })
  // }
  // return () => {
  //   if (typeof unsubscribeLiveData === 'function') {
  //     unsubscribeLiveData()
  //   }
  // }
  // }, [us_rootReconciliation, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // useEffect(() => {
  // let unsubscribeLiveData: TsType_VoidFunction
  // const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //   us_setAssociatedFormFields(newData)
  //   ur_forceRerender()
  // }
  // if (us_associatedDataBucket != null && us_associatedDataBucket.associated_class_key != null) {
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       unsubscribeLiveData = DatabaseGetLiveCollection(
  //         DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_associatedDataBucket.associated_class_key),
  //         updateLiveData,
  //       )
  //     })
  //     .catch((rej_GCK) => {
  //       console.error(rej_GCK)
  //     })
  // }
  // return () => {
  //   if (typeof unsubscribeLiveData === 'function') {
  //     unsubscribeLiveData()
  //   }
  // }
  // }, [us_associatedDataBucket, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeTransactionalClasses: TsInterface_UnspecifiedObject = {}
      for (let loopClassKey in newData) {
        let loopClass = newData[loopClassKey]
        if (loopClass != null && loopClass.class_type === 'transactional_data') {
          activeTransactionalClasses[loopClassKey] = loopClass
        }
      }
      us_setActiveTransactionalClasses(activeTransactionalClasses)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDataBuckets(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveDataBuckets_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        getDataItemsForAutoReconciliation(res_GCK.clientKey, itemKey, us_rootReconciliation, us_selectedStartDate, us_selectedEndDate, 'full')
          .then((res_GDIFAR) => {
            runReconciliationOnDataSet(
              getProp(res_GDIFAR, 'reconciliation', {}),
              getProp(res_GDIFAR, 'data_buckets', {}),
              getProp(res_GDIFAR, 'data_items', {}),
            )
              .then((res_RRODS) => {
                us_setDataItemsInReconciliationDateRange(res_RRODS as any)
              })
              .catch((rej_RRODS) => {
                console.error(rej_RRODS)
              })
            // // TEMP: TESTING
            // let combinedDataItems: TsInterface_UnspecifiedObject = {}
            // for (let loopDataItemKey in exampleStationLogs) {
            //   let loopDataItem = exampleStationLogs[loopDataItemKey]
            //   combinedDataItems['sl_' + loopDataItemKey] = loopDataItem
            // }
            // for (let loopDataItemKey in exampleCarrierTickets) {
            //   let loopDataItem = exampleCarrierTickets[loopDataItemKey]
            //   combinedDataItems['ct_' + loopDataItemKey] = loopDataItem
            // }
            // runReconciliationOnDataSet(getProp(res_GDIFAR, 'reconciliation', {}), getProp(res_GDIFAR, 'data_buckets', {}), combinedDataItems)
            //   .then((res_RRODS) => {
            //     us_setDataItemsInReconciliationDateRange(res_RRODS as any)
            //   })
            //   .catch((rej_RRODS) => {
            //     console.error(rej_RRODS)
            //   })
          })
          .catch((rej_GDIFAR) => {
            console.error(rej_GDIFAR)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })

    // let unsubscribeLiveData: TsType_VoidFunction
    // const updateLiveData = ( newData: TsInterface_UnspecifiedObject ) => {
    // 	us_setDataItemsInReconciliationDateRange( newData )
    // 	ur_forceRerender()
    // }
    // getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then( ( res_GCK ) => {
    // 	unsubscribeLiveData = DatabaseGetLiveCollection( DatabaseRef_DataForSpecificReconciliation_DateRange_Query( res_GCK.clientKey, itemKey, us_selectedStartDate, us_selectedEndDate ), updateLiveData )
    // }).catch( ( rej_GCK ) => {
    // 	console.error( rej_GCK )
    // })
    // return () => {
    // 	if (typeof unsubscribeLiveData === 'function'){
    // 		unsubscribeLiveData()
    // 	}
    // }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootReconciliation, us_selectedEndDate, us_selectedStartDate])

  useEffect(() => {
    let uniqueIdentifierField: TsInterface_UnspecifiedObject = {}
    for (let loopFieldKey in us_reconciliationOtherClassFields) {
      let loopField = us_reconciliationOtherClassFields[loopFieldKey]
      if (loopField != null && loopField['unique_identifier'] === true) {
        uniqueIdentifierField = loopField
      }
    }
    us_setOtherClassUniqueIdentifierField(uniqueIdentifierField)
  }, [us_reconciliationOtherClassFields])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setReconciliationOtherClassFields(newData)
      ur_forceRerender()
    }
    if (us_selectedReconciliationClassKey != null && us_selectedReconciliationClassKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_selectedReconciliationClassKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootReconciliation, us_selectedReconciliationClassKey])

  // Functions
  const updateDataBucketRootData = (newData: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_AutomaticReconciliation_Document(res_GCK.clientKey, itemKey), newData)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const updateToRecipients = (chipsArray: string[]) => {
    let cleanEmailObject: TsInterface_UnspecifiedObject = {}
    for (let loopIndex = 0; loopIndex < chipsArray.length; loopIndex++) {
      let loopEmail = chipsArray[loopIndex]
      cleanEmailObject[loopEmail] = loopEmail
    }
    // Update Object
    let toArray: string[] = []
    for (let loopKey in cleanEmailObject) {
      toArray.push(loopKey)
    }
    updateDataBucketRootData({ associated_reconciliation_email_recipients: toArray })
  }

  const returnOtherActiveClassMappingDropdownValue = (field: TsInterface_UnspecifiedObject): string => {
    let value = ''
    if (
      us_rootReconciliation != null &&
      us_rootReconciliation.associated_reconciliation_class_mapping != null &&
      us_selectedReconciliationClassKey != null &&
      us_selectedReconciliationClassKey !== '' &&
      us_rootReconciliation.associated_reconciliation_class_mapping[us_selectedReconciliationClassKey] != null &&
      us_rootReconciliation.associated_reconciliation_class_mapping[us_selectedReconciliationClassKey][field.key] != null
    ) {
      value = us_rootReconciliation.associated_reconciliation_class_mapping[us_selectedReconciliationClassKey][field.key]
    }
    return value
  }

  const addEmailToGlobalQueue = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let promiseArray: TsType_UnknownPromise[] = []
          let existingGlobalRecurringQueueEmail: TsInterface_UnspecifiedObject = {}
          promiseArray.push(
            DatabaseGetDocument(DatabaseRef_GlobalRecurringEmail_Document(itemKey))
              .then((res_GDE) => {
                existingGlobalRecurringQueueEmail = res_GDE.data
              })
              .catch((rej_GDE) => {
                console.error(rej_GDE)
              }),
          )
          Promise.all(promiseArray).finally(() => {
            let globalRecurringEmailUpdateObject: TsInterface_UnspecifiedObject = {
              schedule_status: 'in_queue',
              specialty_process: 'station_log_reconciliation',
              key: itemKey,
              client_key: res_GCK.clientKey,
              timestamp_added_to_queue: new Date(),
              timestamp_email_scheduled: getProp(us_rootReconciliation, 'timestamp_email_scheduled', null),
              timestamp_email_scheduled_pacific: getProp(us_rootReconciliation, 'timestamp_email_scheduled_pacific', null),
              query_date_range_type: getProp(us_rootReconciliation, 'query_date_range_type', null),
            }
            if (existingGlobalRecurringQueueEmail == null || existingGlobalRecurringQueueEmail.timestamp_last_email == null) {
              globalRecurringEmailUpdateObject['timestamp_last_email'] = new Date(2020, 0, 1, 0, 0, 0, 0)
            }
            if (existingGlobalRecurringQueueEmail == null || existingGlobalRecurringQueueEmail.timestamp_last_email_success == null) {
              globalRecurringEmailUpdateObject['timestamp_last_email_success'] = new Date(2020, 0, 1, 0, 0, 0, 0)
            }
            if (existingGlobalRecurringQueueEmail == null || existingGlobalRecurringQueueEmail.timestamp_last_email_failure == null) {
              globalRecurringEmailUpdateObject['timestamp_last_email_failure'] = new Date(2020, 0, 1, 0, 0, 0, 0)
            }
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
              {
                type: 'setMerge',
                ref: DatabaseRef_AutomaticReconciliation_Document(res_GCK.clientKey, itemKey),
                data: {
                  schedule_status: 'in_queue',
                },
              },
              {
                type: 'setMerge',
                ref: DatabaseRef_GlobalRecurringEmail_Document(itemKey),
                data: globalRecurringEmailUpdateObject,
              },
            ]
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                resolve(res_DBU)
              })
              .catch((rej_DBU) => {
                reject(rej_DBU)
              })
          })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    })
  }

  const removeEmailFromGlobalQueue = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_AutomaticReconciliation_Document(res_GCK.clientKey, itemKey),
              data: {
                schedule_status: 'not_in_queue',
              },
            },
            {
              type: 'setMerge',
              ref: DatabaseRef_GlobalRecurringEmail_Document(itemKey),
              data: {
                schedule_status: 'not_in_queue',
              },
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    })
  }

  const changeCalendarStartDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedStartDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const changeCalendarEndDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedEndDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const sendEmail = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            to_array: getProp(us_rootReconciliation, 'associated_reconciliation_email_recipients', []),
            subject: getProp(us_rootReconciliation, 'associated_reconciliation_email_subject', ''),
          },
          formInputs: {
            to_array: {
              data_type: 'string',
              input_type: 'custom_form_input_jsx',
              key: 'to_array',
              label: <></>,
              required: true,
              renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                let inputJSX = (
                  <Box sx={{ marginBottom: '12px' }}>
                    <MuiChipsInput
                      ref={inputRef}
                      label={rLIB('Email Recipients')}
                      placeholder={'Add Emails'}
                      fullWidth={true}
                      value={getProp(formData, formInput.key, [])}
                      onChange={(chipsArray: string[]) => {
                        formInputChange(formInput.key, chipsArray, true)
                      }}
                      disableEdition={true}
                      disableDeleteOnBackspace={true}
                      onKeyDown={(event: any) => {
                        if (event.key === 'Tab' && inputRef.current === document.activeElement) {
                          event.preventDefault() // Prevent default tab behavior
                          // Add the current input value as a chip
                          if (
                            inputRef != null &&
                            inputRef.current != null &&
                            // @ts-expect-error
                            inputRef.current.value != null
                          ) {
                            // @ts-expect-error
                            let inputValue = inputRef.current.value.trim()
                            if (inputValue) {
                              formInputChange(
                                formInput.key,
                                [...getProp(us_rootReconciliation, 'associated_reconciliation_email_recipients', []), inputValue],
                                true,
                              )
                              // @ts-expect-error
                              inputRef.current.value = '' // Clear input value
                            }
                          }
                        }
                      }}
                      addOnBlur={true}
                      renderChip={(Component, key, props) => {
                        // eslint-disable-next-line
                        if (isValidEmail(props.label) === true) {
                          return (
                            <Component
                              {...props}
                              key={key}
                            />
                          )
                        } else {
                          return (
                            <Component
                              {...props}
                              key={key}
                              color="error"
                            />
                          )
                        }
                      }}
                    />
                  </Box>
                )
                return inputJSX
              },
            },
            subject: {
              data_type: 'string',
              input_type: 'text_basic',
              key: 'subject',
              label: rLIB('Subject'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {
            submit_button_icon: <Icon icon="envelope" />,
            submit_button_text: <>{rLIB('Send Email')}</>,
            submit_button_theme: 'info',
          },
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              us_setSendingEmail(true)
              let emailHTML = generateHtmlForEmailFromTemplateObject(
                reconciliationEmailTemplateObject,
                getProp(us_dataItemsInReconciliationDateRange, 'emailFormattedData', {}),
              )
              cloudFunctionSendEmailRequest({
                function: 'sendHtmlEmail',
                toArray: formSubmittedData['to_array'],
                ccArray: [],
                bccArray: [],
                subject: formSubmittedData['subject'],
                html: emailHTML,
              })
                .then((res_CFSER) => {
                  us_setSendingEmail(false)
                  resolve(res_CFSER)
                })
                .catch((rej_CFSER) => {
                  us_setSendingEmail(false)
                  reject(rej_CFSER)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFSER })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'info',
          formDialogHeaderText: <>{rLIB('Send Email')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="envelope"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          un_routerNavigation(ApplicationPages.ReconciliationListPage.url())
        }}
        startIcon={<Icon icon="angle-left" />}
      >
        {rLIB('Back to all Reconciliations')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_StartDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_selectedStartDate}
          datePickerDateOnChange={changeCalendarStartDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_EndDatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_selectedEndDate}
          datePickerDateOnChange={changeCalendarEndDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_rootReconciliation != null && us_rootReconciliation.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Reconciliation')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_rootReconciliation.name}</Box>
          {/* <Box className="tw-inline-block tw-font-light tw-opacity-50 tw-mr-1">({ returnFormattedDate( returnDateFromUnknownDateFormat( getProp( us_rootReconciliation, "filter_start_date", new Date() )), "D MMM YYYY h:mm a" ) } - </Box> */}
          {/* <Box className="tw-inline-block tw-font-light tw-opacity-50">{ returnFormattedDate( returnDateFromUnknownDateFormat( getProp( us_rootReconciliation, "filter_end_date", new Date() )), "D MMM YYYY h:mm a" ) })</Box> */}
        </Box>
      )
    } else {
      pageHeader = rLIB('Reconciliation') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_SendStatusButtons = (): JSX.Element => {
    // Inactive Button
    let inactiveButtonJSX = (
      <Button
        variant={getProp(us_rootReconciliation, 'schedule_status', 'not_in_queue') === 'not_in_queue' ? 'contained' : 'outlined'}
        color={getProp(us_rootReconciliation, 'schedule_status', 'not_in_queue') === 'not_in_queue' ? 'warning' : 'inherit'}
        sx={{ marginRight: '8px' }}
        size="small"
        onClick={() => {
          removeEmailFromGlobalQueue()
        }}
        startIcon={<Icon icon="circle-pause" />}
      >
        {rLIB('Not in Queue')}
      </Button>
    )
    // Active Button
    let activeButtonJSX = (
      <Button
        variant={getProp(us_rootReconciliation, 'schedule_status', 'not_in_queue') === 'in_queue' ? 'contained' : 'outlined'}
        color={getProp(us_rootReconciliation, 'schedule_status', 'not_in_queue') === 'in_queue' ? 'success' : 'inherit'}
        sx={{ marginRight: '8px' }}
        size="small"
        disabled={getProp(us_rootReconciliation, 'timestamp_email_scheduled', null) == null}
        onClick={() => {
          addEmailToGlobalQueue()
        }}
        startIcon={<Icon icon="circle-play" />}
      >
        {rLIB('In Queue')}
      </Button>
    )
    let buttonsJSX = (
      <Box className="tw-mt-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Email Queue Status')}
        </Typography>
        <Box className="tw-ml-4">
          {inactiveButtonJSX}
          {activeButtonJSX}
        </Box>
      </Box>
    )
    return buttonsJSX
  }

  const rJSX_SettingsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-4">
        <Box>
          {rJSX_ReconciliationSettings()}
          {rJSX_SelectedReconciliationProcedureData()}
          {rJSX_Tolerances()}
          {rJSX_ErrorsToDisplay()}
          {/* {rJSX_ReconciliationEmail()} */}
          {/* {rJSX_ScheduledDate()} */}
          {/* {rJSX_SendStatusButtons()} */}
        </Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_SendEmailButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="info"
        className="tw-mb-2"
        startIcon={
          us_sendingEmail === true ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="envelope" />
          )
        }
        disabled={us_sendingEmail === true}
        onClick={() => {
          sendEmail()
        }}
      >
        {rLIB('Send Email')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_EmailTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-4">
        <Box>
          {/* {rJSX_ReconciliationSettings()} */}
          {/* {rJSX_SelectedReconciliationProcedureData()} */}
          {/* {rJSX_Tolerances()} */}
          {rJSX_ReconciliationEmail()}
          {rJSX_ScheduledDate()}
          {rJSX_SendStatusButtons()}
        </Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ReconciliationSettings = (): JSX.Element => {
    let settingsJSX = <></>
    settingsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Reconciliation Procedure')}
        </Typography>
        <Box className="tw-ml-4">
          <FormControl
            className="bp_thin_select_input"
            sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                updateDataBucketRootData({ associated_reconciliation_procedure: event.target.value })
              }}
              value={getProp(us_rootReconciliation, 'associated_reconciliation_procedure', '')}
            >
              {objectToArray(autoReconciliationLibraryOptions)
                .sort(dynamicSort('value', null))
                .map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                  >
                    {option['value']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
    return settingsJSX
  }

  const rJSX_VolumeTolerance = (): JSX.Element => {
    let tolerancesJSX = <></>
    let reconciliationProcedure = getProp(us_rootReconciliation, 'associated_reconciliation_procedure', null)
    if (reconciliationProcedure === 'crude_oil_metered' || reconciliationProcedure === 'crude_oil_volume') {
      tolerancesJSX = (
        <Box className="tw-mb-2">
          <Box className="tw-ml-4">
            <TextField
              type="number"
              sx={{ minWidth: '240px' }}
              label={rLIB('Volume Tolerance Warning')}
              value={getProp(us_rootReconciliation, 'volume_tolerance_warning', '')}
              onChange={(event) => {
                updateDataBucketRootData({ volume_tolerance_warning: event.target.value })
              }}
              variant="outlined"
              size="small"
              className="tw-mr-2"
            />
            <TextField
              type="number"
              sx={{ minWidth: '240px' }}
              label={rLIB('Volume Tolerance Error')}
              value={getProp(us_rootReconciliation, 'volume_tolerance_error', '')}
              onChange={(event) => {
                updateDataBucketRootData({ volume_tolerance_error: event.target.value })
              }}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      )
    }
    return tolerancesJSX
  }

  const rJSX_TimestampTolerance = (): JSX.Element => {
    let tolerancesJSX = <></>
    let reconciliationProcedure = getProp(us_rootReconciliation, 'associated_reconciliation_procedure', null)
    if (reconciliationProcedure === 'crude_oil_metered' || reconciliationProcedure === 'crude_oil_volume') {
      tolerancesJSX = (
        <Box className="tw-mb-2">
          <Box className="tw-ml-4">
            <TextField
              type="number"
              sx={{ minWidth: '240px' }}
              label={rLIB('Timestamp Warning (Minutes)')}
              value={getProp(us_rootReconciliation, 'timestamp_tolerance_warning', '')}
              onChange={(event) => {
                updateDataBucketRootData({ timestamp_tolerance_warning: event.target.value })
              }}
              variant="outlined"
              size="small"
              className="tw-mr-2"
            />
            <TextField
              type="number"
              sx={{ minWidth: '240px' }}
              label={rLIB('Timestamp Error (Minutes)')}
              value={getProp(us_rootReconciliation, 'timestamp_tolerance_error', '')}
              onChange={(event) => {
                updateDataBucketRootData({ timestamp_tolerance_error: event.target.value })
              }}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      )
    }
    return tolerancesJSX
  }

  const rJSX_MeterTolerance = (): JSX.Element => {
    let tolerancesJSX = <></>
    let reconciliationProcedure = getProp(us_rootReconciliation, 'associated_reconciliation_procedure', null)
    if (reconciliationProcedure === 'crude_oil_metered') {
      tolerancesJSX = (
        <Box className="tw-mb-2">
          <Box className="tw-ml-4">
            <TextField
              type="number"
              sx={{ minWidth: '240px' }}
              label={rLIB('Meter Tolerance Warning')}
              value={getProp(us_rootReconciliation, 'meter_tolerance_warning', '')}
              onChange={(event) => {
                updateDataBucketRootData({ meter_tolerance_warning: event.target.value })
              }}
              variant="outlined"
              size="small"
              className="tw-mr-2"
            />
            <TextField
              type="number"
              sx={{ minWidth: '240px' }}
              label={rLIB('Meter Tolerance Error')}
              value={getProp(us_rootReconciliation, 'meter_tolerance_error', '')}
              onChange={(event) => {
                updateDataBucketRootData({ meter_tolerance_error: event.target.value })
              }}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      )
    }
    return tolerancesJSX
  }

  const rJSX_Tolerances = (): JSX.Element => {
    let tolerancesJSX = (
      <Box className="tw-mt-2">
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          {rLIB('Tolerance Limits')}
        </Typography>
        <Box className="tw-ml-4">
          {rJSX_VolumeTolerance()}
          {rJSX_MeterTolerance()}
          {rJSX_TimestampTolerance()}
        </Box>
      </Box>
    )
    return tolerancesJSX
  }

  const rJSX_ErrorsToDisplay = (): JSX.Element => {
    let tolerancesJSX = (
      <Box className="tw-mt-2">
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('Errors to Display')}
        </Typography>
        <Box className="tw-ml-4">
          {objectToArray(listOfReconciliationErrors).map((error: TsInterface_UnspecifiedObject) => (
            <Box key={error['key']}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={getProp(us_rootReconciliation, 'associated_reconciliation_errors_to_display', []).includes(error['key'])}
                    onChange={(event) => {
                      updateDataBucketRootData({
                        associated_reconciliation_errors_to_display: event.target.checked
                          ? [...getProp(us_rootReconciliation, 'associated_reconciliation_errors_to_display', []), error['key']]
                          : getProp(us_rootReconciliation, 'associated_reconciliation_errors_to_display', []).filter((key: string) => key !== error['key']),
                      })
                    }}
                  />
                }
                label={error['name']}
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
    return tolerancesJSX
  }

  const rJSX_ReconciliationEmail = (): JSX.Element => {
    let emailJSX = (
      <Box>
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-font-bold tw-mb-2"
          >
            {rLIB('Send Email To')}
          </Typography>
          <Box className="tw-ml-4">
            <MuiChipsInput
              ref={inputRef}
              label={rLIB('Email Recipients')}
              placeholder={'Add Emails'}
              fullWidth={true}
              value={getProp(us_rootReconciliation, 'associated_reconciliation_email_recipients', [])}
              onChange={(chipsArray: string[]) => {
                updateToRecipients(chipsArray)
              }}
              disableEdition={true}
              disableDeleteOnBackspace={true}
              onKeyDown={(event: any) => {
                if (event.key === 'Tab' && inputRef.current === document.activeElement) {
                  event.preventDefault() // Prevent default tab behavior
                  // Add the current input value as a chip
                  if (
                    inputRef != null &&
                    inputRef.current != null &&
                    // @ts-expect-error
                    inputRef.current.value != null
                  ) {
                    // @ts-expect-error
                    let inputValue = inputRef.current.value.trim()
                    if (inputValue) {
                      updateToRecipients([...getProp(us_rootReconciliation, 'associated_reconciliation_email_recipients', []), inputValue])
                      // @ts-expect-error
                      inputRef.current.value = '' // Clear input value
                    }
                  }
                }
              }}
              addOnBlur={true}
              renderChip={(Component, key, props) => {
                // eslint-disable-next-line
                if (isValidEmail(props.label) === true) {
                  return (
                    <Component
                      {...props}
                      key={key}
                    />
                  )
                } else {
                  return (
                    <Component
                      {...props}
                      key={key}
                      color="error"
                    />
                  )
                }
              }}
            />
          </Box>
        </Box>
        <Box className="tw-mt-2">
          <Typography
            variant="h6"
            className="tw-font-bold tw-mb-2"
          >
            {rLIB('Email Subject')}
          </Typography>
          <Box className="tw-ml-4">
            <TextField
              sx={{ width: '100%' }}
              label={rLIB('Email Subject')}
              value={getProp(us_rootReconciliation, 'associated_reconciliation_email_subject', '')}
              onChange={(event) => {
                updateDataBucketRootData({ associated_reconciliation_email_subject: event.target.value })
              }}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      </Box>
    )
    return emailJSX
  }

  const rJSX_OtherActiveClassDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px' }}
      >
        <Select
          onChange={(event, value) => {
            // updateDataBucketRootData( { associated_class_key: event.target.value } )
            us_setReconciliationOtherClassFields({})
            us_setSelectedReconciliationClassKey(event.target.value)
          }}
          value={us_selectedReconciliationClassKey || ''}
        >
          {objectToArray(us_activeTransactionalClasses)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_OtherActiveClassMappingDropdown = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let dropdownJSX = <></>
    if (us_selectedReconciliationClassKey != null && us_selectedReconciliationClassKey !== '' && objectToArray(us_reconciliationOtherClassFields).length > 0) {
      if (field.type === 'unqiue_identifier') {
        dropdownJSX = (
          <Box
            className="tw-font-bold"
            sx={{ color: themeVariables.info_main, fontSize: '16px' }}
          >
            {getProp(us_otherClassUniqueIdentifierField, 'name', <></>)}
          </Box>
        )
      } else if (field.type === 'class_field') {
        let formInputSX: TsInterface_UnspecifiedObject = { minWidth: '130px' }
        let formValue = returnOtherActiveClassMappingDropdownValue(field)
        if (formValue === '' || formValue == null) {
          formInputSX = { minWidth: '130px', backgroundColor: themeVariables.warning_light }
        }
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input"
            sx={formInputSX}
          >
            <Select
              onChange={(event, value) => {
                let updateObject = {
                  associated_reconciliation_class_mapping: {
                    [us_selectedReconciliationClassKey]: {
                      [field.key]: event.target.value,
                    },
                  },
                }
                updateDataBucketRootData(updateObject)
              }}
              value={formValue || ''}
            >
              {objectToArray(us_reconciliationOtherClassFields)
                .sort(dynamicSort('name', null))
                .map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                  >
                    {option['name']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_GroupMappingDropdown = (): JSX.Element => {
    let dropdownJSX = <></>
    if (
      us_selectedReconciliationClassKey != null &&
      us_selectedReconciliationClassKey !== '' &&
      us_rootReconciliation != null &&
      us_rootReconciliation.associated_reconciliation_procedure != null &&
      autoReconciliationLibraryOptions[us_rootReconciliation.associated_reconciliation_procedure] != null
    ) {
      let formInputSX: TsInterface_UnspecifiedObject = { minWidth: '130px' }
      let formValue = ''
      if (
        us_rootReconciliation != null &&
        us_rootReconciliation.associated_reconciliation_class_group_selections != null &&
        us_selectedReconciliationClassKey != null &&
        us_selectedReconciliationClassKey !== '' &&
        us_rootReconciliation.associated_reconciliation_class_group_selections[us_selectedReconciliationClassKey] != null
      ) {
        formValue = us_rootReconciliation.associated_reconciliation_class_group_selections[us_selectedReconciliationClassKey]
      }
      if (formValue === '' || formValue == null) {
        formInputSX = { minWidth: '130px', backgroundColor: themeVariables.warning_light }
      }
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input"
          sx={formInputSX}
        >
          <Select
            onChange={(event, value) => {
              if (us_selectedReconciliationClassKey != null) {
                let updateObject = {
                  associated_reconciliation_class_group_selections: {
                    [us_selectedReconciliationClassKey as string]: event.target.value,
                  },
                }
                updateDataBucketRootData(updateObject)
              }
            }}
            value={formValue || ''}
          >
            {objectToArray(getProp(autoReconciliationLibraryOptions[us_rootReconciliation.associated_reconciliation_procedure], 'group_options', {}))
              .sort(dynamicSort('value', null))
              .map((option: TsInterface_UnspecifiedObject) => (
                <MenuItem
                  key={option['key']}
                  value={option['key']}
                >
                  {option['value']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_ScheduledDate = (): JSX.Element => {
    let scheduleDateJSX = <></>
    let editIcon = <></>
    if (us_rootReconciliation.schedule_status == null || us_rootReconciliation.schedule_status === 'not_in_queue') {
      editIcon = (
        <Icon
          icon="pen-to-square tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
          sx={{
            'marginLeft': '8px',
            'opacity': 0.3,
            'cursor': 'pointer',
            '&:hover': {
              opacity: 1,
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            let formData: TsInterface_UnspecifiedObject = {}
            if (us_rootReconciliation != null && us_rootReconciliation['query_date_range_type'] != null) {
              formData['query_date_range_type'] = us_rootReconciliation['query_date_range_type']
            }
            if (us_rootReconciliation != null && us_rootReconciliation['timestamp_email_scheduled'] != null) {
              formData['timestamp_email_scheduled'] = us_rootReconciliation['timestamp_email_scheduled']
            }
            if (us_rootReconciliation != null && us_rootReconciliation['timestamp_email_scheduled_timezone'] != null) {
              formData['timestamp_email_scheduled_timezone'] = us_rootReconciliation['timestamp_email_scheduled_timezone']
            }
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: formData,
                  formInputs: {
                    query_date_range_type: {
                      data_type: 'string',
                      input_type: 'multiple_choice_radio',
                      key: 'query_date_range_type',
                      label: rLIB('When to send email'),
                      options: objectToArray(sendDateOptions),
                      required: true,
                    },
                    timestamp_email_scheduled: {
                      data_type: 'string',
                      input_type: 'timestamp_time',
                      key: 'timestamp_email_scheduled',
                      label: rLIB('Send Time'),
                      required: true,
                    },
                    timestamp_email_scheduled_timezone: {
                      data_type: 'string',
                      input_type: 'multiple_choice_radio',
                      key: 'timestamp_email_scheduled_timezone',
                      label: rLIB('Timezone'),
                      options: objectToArray(timezoneOptions),
                      required: true,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            query_date_range_type: formSubmittedData.query_date_range_type,
                            timestamp_email_scheduled: formSubmittedData.timestamp_email_scheduled,
                            timestamp_email_scheduled_timezone: formSubmittedData.timestamp_email_scheduled_timezone,
                            timestamp_email_scheduled_pacific: convertToPacificTime(
                              formSubmittedData.timestamp_email_scheduled,
                              formSubmittedData.timestamp_email_scheduled_timezone,
                            ),
                          }
                          DatabaseSetMergeDocument(DatabaseRef_AutomaticReconciliation_Document(res_GCK.clientKey, itemKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          reject(rej_GCK)
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{rLIB('Edit Send Date')}</>,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="circle-plus"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    }
    if (us_rootReconciliation != null && us_rootReconciliation.timestamp_email_scheduled != null) {
      let formattedSendDay = getProp(us_rootReconciliation, 'query_date_range_type', '')
      if (
        sendDateOptions != null &&
        sendDateOptions[us_rootReconciliation.query_date_range_type] != null &&
        sendDateOptions[us_rootReconciliation.query_date_range_type]['value'] != null
      ) {
        formattedSendDay = sendDateOptions[us_rootReconciliation.query_date_range_type]['value']
      }
      let timezoneJSX = <></>
      if (us_rootReconciliation.timestamp_email_scheduled_timezone != null) {
        timezoneJSX = (
          <Box
            component="span"
            sx={{ color: themeVariables.info_main }}
          >
            {us_rootReconciliation.timestamp_email_scheduled_timezone.toUpperCase()}
          </Box>
        )
      }
      scheduleDateJSX = (
        <Box className="tw-mt-2">
          <Typography variant="h6">
            <Box className="tw-font-bold">{rLIB('Send Time')}</Box>
            <Box className="tw-pl-4">
              <Box
                component="span"
                sx={{ color: themeVariables.info_main, marginRight: '6px' }}
              >
                {formattedSendDay} {us_rootReconciliation.timestamp_email_scheduled} {timezoneJSX}
              </Box>
              {editIcon}
            </Box>
          </Typography>
        </Box>
      )
    } else {
      scheduleDateJSX = (
        <Box className="tw-mt-2">
          <Typography variant="h6">
            <Box className="tw-font-bold">{rLIB('Send Time')}</Box>
            <Box className="tw-pl-4">
              <Box
                component="span"
                sx={{ opacity: 0.4, fontStyle: 'italic', marginRight: '6px' }}
              >
                {rLIB('Not Selected')}
              </Box>
              {editIcon}
            </Box>
          </Typography>
        </Box>
      )
    }
    return scheduleDateJSX
  }

  const rJSX_FieldMappingDeletionIcon = (field: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (
      field != null &&
      field.key != null &&
      us_rootReconciliation != null &&
      us_rootReconciliation.associated_reconciliation_class_mapping != null &&
      us_selectedReconciliationClassKey != null &&
      us_selectedReconciliationClassKey !== '' &&
      us_rootReconciliation.associated_reconciliation_class_mapping[us_selectedReconciliationClassKey] != null &&
      us_rootReconciliation.associated_reconciliation_class_mapping[us_selectedReconciliationClassKey][field.key] != null
    ) {
      iconJSX = (
        <Box className="tw-inline-block tw-mt-2 tw-ml-2 tw-curser-pointer tw-opacity-10 hover:tw-opacity-100 hover:tw-text-error_main">
          <Icon
            icon="circle-x"
            sx={{
              fontSize: '18px',
            }}
            onClick={() => {
              if (us_selectedReconciliationClassKey != null) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  associated_reconciliation_class_mapping: {
                    [us_selectedReconciliationClassKey as string]: {
                      [field.key]: null,
                    },
                  },
                }
                updateDataBucketRootData(updateObject)
              }
            }}
          />
        </Box>
      )
    }
    return iconJSX
  }

  const rJSX_SelectedReconciliationProcedureData = (): JSX.Element => {
    let selectedReconciliationProcedureDataJSX = <></>
    if (
      us_rootReconciliation != null &&
      us_rootReconciliation.associated_reconciliation_procedure != null &&
      autoReconciliationLibraryOptions[us_rootReconciliation.associated_reconciliation_procedure] != null
    ) {
      selectedReconciliationProcedureDataJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {rLIB('Required Field Mapping')}
          </Typography>
          <Box className="tw-ml-4">
            <TableContainer className="tw-w-auto">
              <Table
                size="small"
                className="tw-w-auto"
              >
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: '4px !important' }}></TableCell>
                    <TableCell sx={{ padding: '4px !important' }}>{rJSX_OtherActiveClassDropdown()}</TableCell>
                  </TableRow>
                  {objectToArray(autoReconciliationLibraryOptions[us_rootReconciliation.associated_reconciliation_procedure]['fields_to_map']).map(
                    (field: TsInterface_UnspecifiedObject, index: number) => (
                      <TableRow key={index}>
                        <TableCell sx={{ padding: '4px !important' }}>
                          <Typography variant="subtitle1">{field.value}</Typography>
                        </TableCell>
                        <TableCell sx={{ padding: '4px !important' }}>
                          {rJSX_OtherActiveClassMappingDropdown(field)}
                          {rJSX_FieldMappingDeletionIcon(field)}
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                  <TableRow className="tw-bg-gray-100">
                    <TableCell sx={{ padding: '4px !important' }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 800 }}
                      >
                        {rLIB('Group')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: '4px !important' }}>{rJSX_GroupMappingDropdown()}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )
    }
    return selectedReconciliationProcedureDataJSX
  }

  // Errors and Warnings
  const rJSX_ErrorDetails_GapsInStationLogMeterReadingsByDataBucket = (dataBucket: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.station_logs).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('First Station Log ID')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Second Station Log ID')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Meter Gap')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['station_logs'])
                  .sort(dynamicSort('start_meter', 'asc'))
                  .map((stationLogPair: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.stop_meter_station_log.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.first_station_log_stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.start_meter_station_log.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.second_station_log_start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: color, color: themeVariables.white }}>
                        {stationLogPair.gap.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_OverlapsInStationLogMeterReadingsByDataBucket = (dataBucket: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.station_logs).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('First Station Log ID')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Second Station Log ID')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Meter Overlap')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['station_logs'])
                  .sort(dynamicSort('start_meter', 'asc'))
                  .map((stationLogPair: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.stop_meter_station_log.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.first_station_log_stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.start_meter_station_log.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.second_station_log_start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: color, color: themeVariables.white }}>
                        {Math.abs(stationLogPair.gap).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_StartMeterDiscrepancies = (dataBucket: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.combined_station_logs_and_carrier_tickets).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Station Log')} {rLIB('Start Meter')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Carrier Ticket')} {rLIB('Start Meter')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Discrepancy')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['combined_station_logs_and_carrier_tickets'])
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((stationLogPair: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.station_log.start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.carrier_ticket.start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: color, color: themeVariables.white }}>
                        {Math.abs(stationLogPair.start_meter_discrepancy).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_StopMeterDiscrepancies = (dataBucket: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.combined_station_logs_and_carrier_tickets).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Station Log')} {rLIB('Start Meter')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Carrier Ticket')} {rLIB('Start Meter')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Discrepancy')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['combined_station_logs_and_carrier_tickets'])
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((stationLogPair: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.station_log.stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.carrier_ticket.stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: color, color: themeVariables.white }}>
                        {Math.abs(stationLogPair.stop_meter_discrepancy).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_TimestampDiscrepancies = (dataBucket: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.combined_station_logs_and_carrier_tickets).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Station Log')} {rLIB('Timestamp')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Carrier Ticket')} {rLIB('Timestamp')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Discrepancy')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['combined_station_logs_and_carrier_tickets'])
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((stationLogPair: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>
                        {returnFormattedDate(stationLogPair.station_log.timestamp_primary_query, 'D MMM YYYY H:mm')}
                      </TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>
                        {returnFormattedDate(stationLogPair.carrier_ticket.timestamp_primary_query, 'D MMM YYYY H:mm')}
                      </TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: color, color: themeVariables.white }}>
                        {formatTime(Math.abs(stationLogPair.timestamp_discrepancy))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_VolumeDiscrepancies = (dataBucket: TsInterface_UnspecifiedObject, color: string): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.combined_station_logs_and_carrier_tickets).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Station Log')} {rLIB('Volume')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>
                    {rLIB('Carrier Ticket')} {rLIB('Volume')}
                  </TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Discrepancy')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['combined_station_logs_and_carrier_tickets'])
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((stationLogPair: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.unique_identifier}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.station_log.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLogPair.carrier_ticket.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: color, color: themeVariables.white }}>
                        {Math.abs(stationLogPair.volume_discrepancy)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_StationLogsMissingData = (dataBucket: TsInterface_UnspecifiedObject, color: string, cellToHighlight: string): JSX.Element => {
    let defaultCellSX = { padding: '4px !important' }
    let highlightCellSX = { padding: '4px !important', backgroundColor: color, color: themeVariables.white }
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.station_logs).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Volume')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Timestamp')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['station_logs'])
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((stationLog: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={cellToHighlight === 'unique_identifier' ? highlightCellSX : defaultCellSX}>{stationLog.unique_identifier}</TableCell>
                      <TableCell sx={cellToHighlight === 'start_meter' ? highlightCellSX : defaultCellSX}>{stationLog.start_meter}</TableCell>
                      <TableCell sx={cellToHighlight === 'stop_meter' ? highlightCellSX : defaultCellSX}>{stationLog.stop_meter}</TableCell>
                      <TableCell sx={cellToHighlight === 'volume' ? highlightCellSX : defaultCellSX}>{stationLog.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{returnFormattedDate(stationLog.timestamp_primary_query, 'D MMM YYYY H:mm')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_CarrierTicketsMissingData = (dataBucket: TsInterface_UnspecifiedObject, color: string, cellToHighlight: string): JSX.Element => {
    let defaultCellSX = { padding: '4px !important' }
    let highlightCellSX = { padding: '4px !important', backgroundColor: color, color: themeVariables.white }
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: color }}
          >
            ({Object.keys(dataBucket.carrier_tickets).length})
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Volume')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Timestamp')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(dataBucket['carrier_tickets'])
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((carrierTicket: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={cellToHighlight === 'unique_identifier' ? highlightCellSX : defaultCellSX}>{carrierTicket.unique_identifier}</TableCell>
                      <TableCell sx={cellToHighlight === 'start_meter' ? highlightCellSX : defaultCellSX}>{carrierTicket.start_meter}</TableCell>
                      <TableCell sx={cellToHighlight === 'stop_meter' ? highlightCellSX : defaultCellSX}>{carrierTicket.stop_meter}</TableCell>
                      <TableCell sx={cellToHighlight === 'volume' ? highlightCellSX : defaultCellSX}>{carrierTicket.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{returnFormattedDate(carrierTicket.timestamp_primary_query, 'D MMM YYYY H:mm')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_StationLogsWithDuplicateUniqueIdentifiers = (stationLogs: TsInterface_UnspecifiedObject): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mt-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {objectToArray(stationLogs)[0].unique_identifier}
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Volume')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Timestamp')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(stationLogs)
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((stationLog: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: themeVariables.error_main, color: themeVariables.white }}>
                        {stationLog.unique_identifier}
                      </TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLog.start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLog.stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{stationLog.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{returnFormattedDate(stationLog.timestamp_primary_query, 'D MMM YYYY H:mm')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_StationLogsWithMultipleCarrierTickets = (dataBucket: TsInterface_UnspecifiedObject): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: themeVariables.error_main }}
          >
            ({Object.keys(dataBucket.station_logs).length})
          </Box>
        </Typography>
        {objectToArray(dataBucket['station_logs'])
          .sort()
          .map((dataGroup: TsInterface_UnspecifiedObject, index: number) => (
            <Card
              className="tw-mb-2"
              key={index}
            >
              <TableContainer className="tw-w-full">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}></TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Volume')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Timestamp')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Station Log')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: themeVariables.error_main, color: themeVariables.white }}>
                        {dataGroup.station_log.unique_identifier}
                      </TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{dataGroup.station_log.start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{dataGroup.station_log.stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{dataGroup.station_log.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>
                        {returnFormattedDate(dataGroup.station_log.timestamp_primary_query, 'D MMM YYYY H:mm')}
                      </TableCell>
                    </TableRow>
                    {objectToArray(dataGroup['carrier_tickets'])
                      .sort(dynamicSort('unique_identifier', 'asc'))
                      .map((carrierTicket: TsInterface_UnspecifiedObject, index2: number) => (
                        <TableRow key={index2}>
                          <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Carrier Ticket')}</TableCell>
                          <TableCell sx={{ padding: '4px !important', backgroundColor: themeVariables.error_main, color: themeVariables.white }}>
                            {carrierTicket.unique_identifier}
                          </TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{carrierTicket.start_meter}</TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{carrierTicket.stop_meter}</TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{carrierTicket.volume}</TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>
                            {returnFormattedDate(carrierTicket.timestamp_primary_query, 'D MMM YYYY H:mm')}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          ))}
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_CarrierTicketsWithDuplicateUniqueIdentifiers = (carrierTickets: TsInterface_UnspecifiedObject): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mt-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {objectToArray(carrierTickets)[0].unique_identifier}
          </Box>
        </Typography>
        <Card className="tw-mb-2">
          <TableContainer className="tw-w-full">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Volume')}</TableCell>
                  <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Timestamp')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objectToArray(carrierTickets)
                  .sort(dynamicSort('unique_identifier', 'asc'))
                  .map((carrierTicket: TsInterface_UnspecifiedObject, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: themeVariables.error_main, color: themeVariables.white }}>
                        {carrierTicket.unique_identifier}
                      </TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{carrierTicket.start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{carrierTicket.stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{carrierTicket.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{returnFormattedDate(carrierTicket.timestamp_primary_query, 'D MMM YYYY H:mm')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails_CarrierTicketsWithMultipleStationLogs = (dataBucket: TsInterface_UnspecifiedObject): JSX.Element => {
    let errorDetailsJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold tw-mb-2"
        >
          <Box
            className="tw-mr-1"
            component={'span'}
          >
            {dataBucket.name}
          </Box>
          <Box
            className="tw-mr-1"
            component={'span'}
            sx={{ color: themeVariables.error_main }}
          >
            ({Object.keys(dataBucket.carrier_tickets).length})
          </Box>
        </Typography>
        {objectToArray(dataBucket['carrier_tickets'])
          .sort()
          .map((dataGroup: TsInterface_UnspecifiedObject, index: number) => (
            <Card
              className="tw-mb-2"
              key={index}
            >
              <TableContainer className="tw-w-full">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}></TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Unique Identifier')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Start Meter')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Stop Meter')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Volume')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Timestamp')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Carrier Ticket')}</TableCell>
                      <TableCell sx={{ padding: '4px !important', backgroundColor: themeVariables.error_main, color: themeVariables.white }}>
                        {dataGroup.carrier_ticket.unique_identifier}
                      </TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{dataGroup.carrier_ticket.start_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{dataGroup.carrier_ticket.stop_meter}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>{dataGroup.carrier_ticket.volume}</TableCell>
                      <TableCell sx={{ padding: '4px !important' }}>
                        {returnFormattedDate(dataGroup.carrier_ticket.timestamp_primary_query, 'D MMM YYYY H:mm')}
                      </TableCell>
                    </TableRow>
                    {objectToArray(dataGroup['station_logs'])
                      .sort(dynamicSort('unique_identifier', 'asc'))
                      .map((stationLog: TsInterface_UnspecifiedObject, index2: number) => (
                        <TableRow key={index2}>
                          <TableCell sx={{ padding: '4px !important', fontWeight: '700' }}>{rLIB('Station Log')}</TableCell>
                          <TableCell sx={{ padding: '4px !important', backgroundColor: themeVariables.error_main, color: themeVariables.white }}>
                            {stationLog.unique_identifier}
                          </TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{stationLog.start_meter}</TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{stationLog.stop_meter}</TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{stationLog.volume}</TableCell>
                          <TableCell sx={{ padding: '4px !important' }}>{returnFormattedDate(stationLog.timestamp_primary_query, 'D MMM YYYY H:mm')}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          ))}
      </Box>
    )
    return errorDetailsJSX
  }

  const rJSX_ErrorDetails = (errorContent: TsInterface_UnspecifiedObject): JSX.Element => {
    let errorDetailsJSX = <></>
    if (errorContent.count > 0) {
      if (errorContent.key === 'gaps_in_station_log_meter_readings_by_data_bucket') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('GAP IN STATION LOG METER READINGS') : rLIB('GAPS IN STATION LOG METER READINGS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_GapsInStationLogMeterReadingsByDataBucket(dataBucket, themeVariables.error_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'overlaps_in_station_log_meter_readings_by_data_bucket') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1 ? rLIB('OVERLAP IN STATION LOG METER READINGS') : rLIB('OVERLAPS IN STATION LOG METER READINGS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_OverlapsInStationLogMeterReadingsByDataBucket(dataBucket, themeVariables.error_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'start_meter_discrepancies') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('START METER DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('START METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StartMeterDiscrepancies(dataBucket, themeVariables.error_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'stop_meter_discrepancies') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('STOP METER DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('STOP METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StopMeterDiscrepancies(dataBucket, themeVariables.error_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'timestamp_discrepancies') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('TIMESTAMP DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('TIMESTAMP DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_TimestampDiscrepancies(dataBucket, themeVariables.error_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'volume_discrepancies') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('VOLUME DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('VOLUME DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_VolumeDiscrepancies(dataBucket, themeVariables.error_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_missing_start_meter') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('STATION LOG MISSING START METER') : rLIB('STATION LOGS MISSING START METER')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsMissingData(dataBucket, themeVariables.error_main, 'start_meter')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_missing_stop_meter') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('STATION LOG MISSING STOP METER') : rLIB('STATION LOGS MISSING STOP METER')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsMissingData(dataBucket, themeVariables.error_main, 'stop_meter')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_missing_volume') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('STATION LOG MISSING VOLUME') : rLIB('STATION LOGS MISSING VOLUME')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsMissingData(dataBucket, themeVariables.error_main, 'volume')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_missing_unique_identifier') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1 ? rLIB('STATION LOG MISSING UNIQUE IDENTIFIER') : rLIB('STATION LOGS MISSING UNIQUE IDENTIFIER')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsMissingData(dataBucket, themeVariables.error_main, 'unique_identifier')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_with_duplicate_unique_identifiers') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1 ? rLIB('STATION LOG WITH DUPLICATE UNIQUE IDENTIFIERS') : rLIB('STATION LOGS WITH DUPLICATE UNIQUE IDENTIFIERS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.station_logs)
                    .sort()
                    .map((stationLog: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsWithDuplicateUniqueIdentifiers(stationLog)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_with_no_matching_carrier_ticket') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1 ? rLIB('STATION LOG WITH NO MATCHING CARRIER TICKETS') : rLIB('STATION LOGS WITH NO MATCHING CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsMissingData(dataBucket, themeVariables.error_main, 'unique_identifier')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'station_logs_matched_with_multiple_carrier_tickets') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('STATION LOG MATCHED WITH MULTIPLE CARRIER TICKETS')
                      : rLIB('STATION LOGS MATCHED WITH MULTIPLE CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StationLogsWithMultipleCarrierTickets(dataBucket)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_missing_start_meter') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('CARRIER TICKET MISSING START METER') : rLIB('CARRIER TICKETS MISSING START METER')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsMissingData(dataBucket, themeVariables.error_main, 'start_meter')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_missing_stop_meter') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('CARRIER TICKET MISSING STOP METER') : rLIB('CARRIER TICKETS MISSING STOP METER')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsMissingData(dataBucket, themeVariables.error_main, 'stop_meter')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_missing_volume') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count} {errorContent.count === 1 ? rLIB('CARRIER TICKET MISSING VOLUME') : rLIB('CARRIER TICKETS MISSING VOLUME')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsMissingData(dataBucket, themeVariables.error_main, 'volume')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_missing_unique_identifier') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1 ? rLIB('CARRIER TICKET MISSING UNIQUE IDENTIFIER') : rLIB('CARRIER TICKETS MISSING UNIQUE IDENTIFIER')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsMissingData(dataBucket, themeVariables.error_main, 'unique_identifier')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_with_duplicate_unique_identifiers') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('CARRIER TICKET WITH DUPLICATE UNIQUE IDENTIFIERS')
                      : rLIB('CARRIER TICKETS WITH DUPLICATE UNIQUE IDENTIFIERS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.carrier_tickets)
                    .sort()
                    .map((carrierTicket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsWithDuplicateUniqueIdentifiers(carrierTicket)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_with_no_matching_station_log') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1 ? rLIB('CARRIER TICKET WITH NO MATCHING STATION LOGS') : rLIB('CARRIER TICKETS WITH NO MATCHING STATION LOGS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsMissingData(dataBucket, themeVariables.error_main, 'unique_identifier')}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (errorContent.key === 'carrier_tickets_matched_with_multiple_station_logs') {
        errorDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="error"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {errorContent.count}{' '}
                    {errorContent.count === 1
                      ? rLIB('CARRIER TICKET MATCHED WITH MULTIPLE STATION LOGS')
                      : rLIB('CARRIER TICKETS MATCHED WITH MULTIPLE STATION LOGS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(errorContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_CarrierTicketsWithMultipleStationLogs(dataBucket)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else {
        errorDetailsJSX = <Box className="tw-ml-8">{/* <Json data={errorContent} /> */}</Box>
      }
    }
    return errorDetailsJSX
  }

  const rJSX_ErrorSummary = (): JSX.Element => {
    let errorSummaryJSX = <></>
    let totalErrors = 0
    for (let errorKey in us_dataItemsInReconciliationDateRange.errors) {
      totalErrors += us_dataItemsInReconciliationDateRange.errors[errorKey].count
    }
    if (totalErrors === 0) {
      errorSummaryJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: themeVariables.success_main }}
          >
            <Icon
              icon="badge-check"
              className="tw-mr-2"
            />
            {rLIB('No Errors')}
          </Typography>
          <Box className="tw-mb-2"></Box>
        </Box>
      )
    } else {
      errorSummaryJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: themeVariables.error_main }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
            />
            {rLIB('Errors')} ({totalErrors})
          </Typography>
          <Box className="tw-mb-2">
            {objectToArray(getProp(us_dataItemsInReconciliationDateRange, 'errors', {}))
              .sort(dynamicSort('order', 'asc'))
              .map((errorDetails: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_ErrorDetails(errorDetails)}</Box>
              ))}
          </Box>
        </Box>
      )
    }
    return errorSummaryJSX
  }

  const rJSX_WarningDetails = (warningContent: TsInterface_UnspecifiedObject): JSX.Element => {
    let warningDetailsJSX = <></>
    if (warningContent.count > 0) {
      if (warningContent.key === 'gaps_in_station_log_meter_readings_by_data_bucket') {
        warningDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="warning"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {warningContent.count} {warningContent.count === 1 ? rLIB('GAP IN STATION LOG METER READINGS') : rLIB('GAPS IN STATION LOG METER READINGS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(warningContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_GapsInStationLogMeterReadingsByDataBucket(dataBucket, themeVariables.warning_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (warningContent.key === 'overlaps_in_station_log_meter_readings_by_data_bucket') {
        warningDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="warning"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {warningContent.count}{' '}
                    {warningContent.count === 1 ? rLIB('OVERLAP IN STATION LOG METER READINGS') : rLIB('OVERLAPS IN STATION LOG METER READINGS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(warningContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_OverlapsInStationLogMeterReadingsByDataBucket(dataBucket, themeVariables.warning_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (warningContent.key === 'start_meter_discrepancies') {
        warningDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="warning"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {warningContent.count}{' '}
                    {warningContent.count === 1
                      ? rLIB('START METER DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('START METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(warningContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StartMeterDiscrepancies(dataBucket, themeVariables.warning_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (warningContent.key === 'stop_meter_discrepancies') {
        warningDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="warning"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {warningContent.count}{' '}
                    {warningContent.count === 1
                      ? rLIB('STOP METER DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('STOP METER DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(warningContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_StopMeterDiscrepancies(dataBucket, themeVariables.warning_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (warningContent.key === 'timestamp_discrepancies') {
        warningDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="warning"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {warningContent.count}{' '}
                    {warningContent.count === 1
                      ? rLIB('TIMESTAMP DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('TIMESTAMP DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(warningContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_TimestampDiscrepancies(dataBucket, themeVariables.warning_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      } else if (warningContent.key === 'volume_discrepancies') {
        warningDetailsJSX = (
          <Accordion className="tw-mb-2">
            <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
              <Chip
                className=""
                color="warning"
                variant="filled"
                label={
                  <Typography
                    variant="h6"
                    className="tw-font-bold"
                  >
                    {warningContent.count}{' '}
                    {warningContent.count === 1
                      ? rLIB('VOLUME DISCREPANCY BETWEEN STATION LOGS AND CARRIER TICKETS')
                      : rLIB('VOLUME DISCREPANCIES BETWEEN STATION LOGS AND CARRIER TICKETS')}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: '0px' }}>
              <Box className="tw-ml-8 tw-mt-2">
                <Box className="tw-ml-4">
                  {objectToArray(warningContent.data_buckets)
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_ErrorDetails_VolumeDiscrepancies(dataBucket, themeVariables.warning_main)}</Box>
                    ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      }
    }
    return warningDetailsJSX
  }

  const rJSX_WarningSummary = (): JSX.Element => {
    let warningSummaryJSX = <></>
    let totalWarnings = 0
    for (let warningKey in us_dataItemsInReconciliationDateRange.warnings) {
      totalWarnings += us_dataItemsInReconciliationDateRange.warnings[warningKey].count
    }
    if (totalWarnings === 0) {
      warningSummaryJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: themeVariables.success_main }}
          >
            <Icon
              icon="badge-check"
              className="tw-mr-2"
            />
            {rLIB('No Warnings')}
          </Typography>
          <Box className="tw-mb-2"></Box>
        </Box>
      )
    } else {
      warningSummaryJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: themeVariables.warning_main }}
          >
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
            />
            {rLIB('Warnings')} ({totalWarnings})
          </Typography>
          <Box className="tw-mb-2">
            {objectToArray(getProp(us_dataItemsInReconciliationDateRange, 'warnings', {}))
              .sort(dynamicSort('associated_data_bucket_name', 'asc'))
              .map((warningDetails: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_WarningDetails(warningDetails)}</Box>
              ))}
          </Box>
        </Box>
      )
    }
    return warningSummaryJSX
  }

  const rJSX_ReconciliationSummary = (): JSX.Element => {
    let summaryJSX = <></>
    let reconciliationProcedure = getProp(us_rootReconciliation, 'associated_reconciliation_procedure', null)
    if (reconciliationProcedure === 'crude_oil_metered' || reconciliationProcedure === 'crude_oil_volume') {
      summaryJSX = (
        <Box sx={{ maxWidth: '1600px', margin: 'auto' }}>
          {/* <Typography
            variant="h6"
            className="tw-font-bold"
            sx={{ color: themeVariables.info_main }}
          >
            <Icon
              icon="rectangle-history"
              className="tw-mr-2"
            />
            {rLIB('Reconciliation Summary')}
          </Typography> */}
          <Grid2
            container
            spacing={2}
          >
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_station_logs', 0)).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Total Station Logs')}</Typography>
              </Card>
            </Grid2>
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_station_log_volume', 0).toFixed(1)).toLocaleString(
                    'en-US',
                  )}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Total Station Log Volume')}</Typography>
              </Card>
            </Grid2>
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_carrier_tickets', 0)).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Total Carrier Tickets')}</Typography>
              </Card>
            </Grid2>
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(
                    getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_carrier_ticket_volume', 0).toFixed(1),
                  ).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Total Carrier Ticket Volume')}</Typography>
              </Card>
            </Grid2>
          </Grid2>
          <Grid2
            container
            spacing={2}
          >
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(
                    getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_linked_station_log_volume', 0).toFixed(1),
                  ).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Linked Station Log Volume')}</Typography>
              </Card>
            </Grid2>
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(
                    getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_unlinked_station_log_volume', 0).toFixed(1),
                  ).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Unlinked Station Log Volume')}</Typography>
              </Card>
            </Grid2>
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(
                    getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_linked_carrier_ticket_volume', 0).toFixed(1),
                  ).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Linked Carrier Ticket Volume')}</Typography>
              </Card>
            </Grid2>
            <Grid2
              xs={6}
              md={6}
              lg={3}
              xl={3}
            >
              <Card className="tw-text-center tw-p-2">
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: '48px', marginBottom: '-8px' }}
                >
                  {parseFloat(
                    getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'total_unlinked_carrier_ticket_volume', 0).toFixed(1),
                  ).toLocaleString('en-US')}
                </Typography>
                <Typography variant="subtitle1">{rLIB('Unlinked Carrier Ticket Volume')}</Typography>
              </Card>
            </Grid2>
          </Grid2>

          <Card className="tw-mt-4">
            <Box className="tw-p-4">
              <Typography
                variant="h6"
                sx={{ fontWeight: '600' }}
              >
                {rLIB('Station Logs by Data Buckets')}
              </Typography>
            </Box>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '700' }}>{rLIB('Data Bucket')}</TableCell>
                    <TableCell sx={{ fontWeight: '700' }}>{rLIB('Number of Station Logs')}</TableCell>
                    <TableCell sx={{ fontWeight: '700' }}>{rLIB('Total Volume')}</TableCell>
                  </TableRow>
                  {objectToArray(getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'station_log_data_bucket_breakdown', {}))
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{getProp(dataBucket, 'associated_data_bucket_name', dataBucket.associated_data_bucket_key)}</TableCell>
                        <TableCell>{getProp(dataBucket, 'total_station_logs', 0)}</TableCell>
                        <TableCell>{getProp(dataBucket, 'total_volume', 0)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Card className="tw-mt-4 tw-mb-2">
            <Box className="tw-p-4">
              <Typography
                variant="h6"
                sx={{ fontWeight: '600' }}
              >
                {rLIB('Carrier Tickets by Data Buckets')}
              </Typography>
            </Box>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ fontWeight: '700' }}>{rLIB('Data Bucket')}</TableCell>
                    <TableCell sx={{ fontWeight: '700' }}>{rLIB('Number of Carrier Tickets')}</TableCell>
                    <TableCell sx={{ fontWeight: '700' }}>{rLIB('Total Volume')}</TableCell>
                  </TableRow>
                  {objectToArray(getProp(getProp(us_dataItemsInReconciliationDateRange, 'totals', {}), 'carrier_ticket_data_bucket_breakdown', {}))
                    .sort(dynamicSort('associated_data_bucket_name', 'asc'))
                    .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{getProp(dataBucket, 'associated_data_bucket_name', dataBucket.associated_data_bucket_key)}</TableCell>
                        <TableCell>{getProp(dataBucket, 'total_carrier_tickets', 0)}</TableCell>
                        <TableCell>{getProp(dataBucket, 'total_volume', 0)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          {rJSX_ErrorSummary()}
          {rJSX_WarningSummary()}
        </Box>
      )
    }
    return summaryJSX
  }

  // TEMP
  // const returnEmailTemplate_requestWorkspaceAccessFromOwner = (requestorUserAccount: TsInterface_UnspecifiedObject): TsInterface_EmailTemplateObject => {
  //   let templateObject: TsInterface_EmailTemplateObject = {
  //     HEADER_DIV: {
  //       element_type: 'div',
  //       style: {
  //         'background': 'linear-gradient(#1a2430, #233140)',
  //         'font-size': '20px',
  //         'width': '100%',
  //         'min-height': '50px',
  //         'padding-top': '16px',
  //         'padding-bottom': '8px',
  //         'text-align': 'center',
  //         'border-top-left-radius': '8px',
  //         'border-top-right-radius': '8px',
  //       },
  //       contents: {
  //         LOGO_IMG: {
  //           element_type: 'img',
  //           src_type: 'static',
  //           src: 'https://firebasestorage.googleapis.com/v0/b/data-ore-app.appspot.com/o/global%2Flogo_orchestrate.png?alt=media&token=0ee00e7c-cca0-4c98-b3da-080d91d07680',
  //           alt: 'logo.png',
  //           width: '350px',
  //           height: 'auto',
  //         },
  //       },
  //     },
  //     BODY_DIV: {
  //       element_type: 'div',
  //       style: {
  //         'background': '#f1f1f1',
  //         'padding': '8px',
  //         'border-bottom-left-radius': '8px',
  //         'border-bottom-right-radius': '8px',
  //       },
  //       contents: {
  //         TITLE_TEXT: {
  //           element_type: 'p',
  //           text_type: 'static',
  //           text: 'You have been invited to join an Orchestrate workspace. Please create an account at https://www.orechestratedata.io/ to accept the invitation.',
  //           style: {
  //             'font-size': '18px',
  //             'margin': '0px',
  //             'padding': '8px',
  //             'text-align': 'center',
  //           },
  //         },
  //       },
  //     },
  //   }
  //   return templateObject
  // }

  // const rJSX_TEMPSendEmailButton = (): JSX.Element => {
  //   let emailHTML = generateHtmlForEmailFromTemplateObject(
  //     returnEmailTemplate_requestWorkspaceAccessFromOwner({ name: 'TEST USER', email: 'test@test' }),
  //     getProp(us_dataItemsInReconciliationDateRange, 'emailFormattedData', {}),
  //   )
  //   let buttonJSX = (
  //     <Button
  //       // disabled={true}
  //       variant="contained"
  //       color="primary"
  //       className="tw-mb-2"
  //       onClick={() => {
  //         console.log('SEND EMAIL')
  //         cloudFunctionSendEmailRequest({
  //           function: 'sendHtmlEmail',
  //           toArray: ['john.morrell.if@gmail.com'],
  //           ccArray: [],
  //           bccArray: [],
  //           subject: 'TEST EMAIL',
  //           html: emailHTML,
  //         })
  //           .then((res) => {
  //             console.log(res)
  //           })
  //           .catch((rej) => {
  //             console.error(rej)
  //           })
  //       }}
  //     >
  //       <Icon
  //         icon="envelope"
  //         className="tw-mr-2"
  //       />
  //       SEND TEST EMAIL
  //     </Button>
  //   )
  //   return buttonJSX
  // }

  const rJSX_RunTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-4">
        {/* {rJSX_TEMPSendEmailButton()} */}
        {rJSX_ReconciliationSummary()}
        {/* <Json
          data={us_dataItemsInReconciliationDateRange['dataBuckets']}
          alphabetized={true}
        /> */}
        {/* <Json data={us_dataItemsInReconciliationDateRange["errors"]} alphabetized={true} /> */}
        {/* <Json data={us_dataItemsInReconciliationDateRange["info"]} alphabetized={true} /> */}
        {/* <Json
          data={us_dataItemsInReconciliationDateRange['linkedData']}
          alphabetized={true}
        /> */}
        {/* <Json data={us_dataItemsInReconciliationDateRange["totals"]} alphabetized={true} /> */}
        {/* <Json data={us_dataItemsInReconciliationDateRange["warnings"]} alphabetized={true} /> */}
      </Card>
    )
    return tabJSX
  }

  // const rJSX_TempEmailTestTab = (): JSX.Element => {
  // return (
  //   <Box>
  //     {rJSX_TEMPSendEmailButton()}
  //     <Box
  //       className="ql-scroll-html-render"
  //       dangerouslySetInnerHTML={{
  //         __html: generateHtmlForEmailFromTemplateObject(
  //           reconciliationEmailTemplateObject,
  //           getProp(us_dataItemsInReconciliationDateRange, 'emailFormattedData', {}),
  //         ),
  //       }}
  //     />
  //     {/* <Json data={getProp(us_dataItemsInReconciliationDateRange, 'emailFormattedData', {})} /> */}
  //   </Box>
  // )
  // }

  // TODO - if change the associated_reconciliation_class_group_selections then update data buckets

  const rJSX_DataBucketSelection = (dataBucket: TsInterface_UnspecifiedObject): JSX.Element => {
    let disabled = true
    if (
      dataBucket != null &&
      dataBucket.associated_class_key != null &&
      us_rootReconciliation != null &&
      us_rootReconciliation['associated_reconciliation_class_group_selections'] != null &&
      us_rootReconciliation['associated_reconciliation_class_group_selections'][dataBucket.associated_class_key] != null
    ) {
      disabled = false
    }
    let checked = false
    if (dataBucket != null && dataBucket['associated_reconciliation_keys'] != null && dataBucket['associated_reconciliation_keys'][itemKey] === true) {
      checked = true
    }
    return (
      <Stack
        direction="row"
        spacing={0}
      >
        <Switch
          color="success"
          checked={checked}
          disabled={disabled}
          onChange={(event, value) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                if (value === true) {
                  let dataBucketUpdateObject = { associated_reconciliation_keys: getProp(dataBucket, 'associated_reconciliation_keys', {}) }
                  dataBucketUpdateObject['associated_reconciliation_keys'][itemKey] = true
                  DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, dataBucket.key), dataBucketUpdateObject)
                    .then((res_DSMD) => {
                      // Nothing
                    })
                    .catch((rej_DSMD) => {
                      console.error(rej_DSMD)
                    })
                } else if (value === false) {
                  let dataBucketUpdateObject = { associated_reconciliation_keys: getProp(dataBucket, 'associated_reconciliation_keys', {}) }
                  delete dataBucketUpdateObject['associated_reconciliation_keys'][itemKey]
                  DatabaseUpdateDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, dataBucket.key), dataBucketUpdateObject)
                    .then(() => {
                      // Nothing
                    })
                    .catch((rej_DUD) => {
                      console.error(rej_DUD)
                    })
                }
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
              })
          }}
        />
        <Typography
          variant="h6"
          className="tw-mt-0.5"
        >
          {getProp(dataBucket, 'name', dataBucket.key)}
        </Typography>
      </Stack>
    )
  }

  const rJSX_DataBucketsTab = (): JSX.Element => {
    return (
      <Card className="tw-p-4">
        {objectToArray(us_dataBuckets)
          .sort(dynamicSort('name', 'asc'))
          .map((dataBucket: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_DataBucketSelection(dataBucket)}</Box>
          ))}
      </Card>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabHeader: rLIB('Summary'),
                  tabUrlKey: 'Summary',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_StartDatePicker(), minJSX: rJSX_StartDatePicker(), sizeCutoff: 0 },
                    { fullJSX: rJSX_EndDatePicker(), minJSX: rJSX_EndDatePicker(), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_RunTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Settings'),
                  tabUrlKey: 'Settings',
                  tabButtons: [{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_SettingsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Data Buckets'),
                  tabUrlKey: 'DataBuckets',
                  tabButtons: [{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 }],
                  tabContent: <Box>{rJSX_DataBucketsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Email'),
                  tabUrlKey: 'Email',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_SendEmailButton(), minJSX: rJSX_SendEmailButton(), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_EmailTab()}</Box>,
                },
                // {
                // tabHeader: 'Email Test',
                // tabUrlKey: 'Email',
                // tabButtons: [{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 }],
                // tabContent: <Box>{rJSX_TempEmailTestTab()}</Box>,
                // },
                // {
                // tabHeader: <>JSON (TEMP)</>,
                // tabUrlKey: "JSON",
                // tabButtons: [
                // 	{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
                // ],
                // tabContent:
                // <Box>
                // 	<Json data={ us_rootReconciliation } alphabetized={true} />
                // 	<Json data={ us_associatedDataBucket } alphabetized={true} />
                // 	<Json data={ us_associatedFormFields } alphabetized={true} />
                // </Box>
                // },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.ReconciliationAutoViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Reconciliation', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const getPageLocalStorage = (pageKey: string): any => {
  let currentPageLocalStorageDataString = window.localStorage.getItem(pageKey)
  if (currentPageLocalStorageDataString != null && currentPageLocalStorageDataString !== '') {
    return JSON.parse(currentPageLocalStorageDataString)
  } else {
    return {}
  }
}

export const setPageLocalStorage = (pageKey: string, propKey: string, propValue: any): void => {
  let currentPageLocalStorageDataString = window.localStorage.getItem(pageKey)
  let currentPageLocalStorageDataObject: TsInterface_UnspecifiedObject = {}
  if (currentPageLocalStorageDataString != null && currentPageLocalStorageDataString !== '') {
    currentPageLocalStorageDataObject = JSON.parse(currentPageLocalStorageDataString)
  }
  currentPageLocalStorageDataObject[propKey] = propValue
  window.localStorage.setItem(pageKey, JSON.stringify(currentPageLocalStorageDataObject))
}

export const removePageLocalStorage = (pageKey: string): void => {
  window.localStorage.removeItem(pageKey)
}

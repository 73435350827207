///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material/'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { cloudFunctionManageRequest } from 'rfbp_aux/services/cloud_functions'
import {
  DatabaseRef_ActivePersistentClasses_Query,
  DatabaseRef_ClassFields_Collection,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_ActiveDataBucketsWithSpecificClass_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_ActiveTaskTemplates_Query,
  DatabaseRef_TaskTemplates_Collection,
  DatabaseRef_TaskTemplates_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/task_templates'
import { DatabaseRef_Directory_Document } from 'rfbp_aux/services/database_endpoints/clients/directory_data/all'
import {
  DatabaseRef_TaskIdNumbers_Document,
  DatabaseRef_TaskLogs_Document,
  DatabaseRef_TaskSettings_Document,
  DatabaseRef_Tasks_Collection,
  DatabaseRef_Tasks_Document,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseTransactionIncrement,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import {
  dynamicSort,
  generateRandomString,
  getProp,
  keyFromString,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { TaskViewDialog } from './dialogs/task_view'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TasksIndexPage']['key']

const tableColumns_TaskTemplates: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.TaskTemplateViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Task Template'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this Task Template?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Task Template Name'), 'name'),
}

const tableSettings_TaskTemplates: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp',
  use_live_data: true,
}

// Status Cell
const statusCell = {
  header: {
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      let headerCSS = 'tw-w-4'
      return headerCSS
    },
    header_sort_by: null,
  },
  cell: {
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <>{rJSX_TaskStatusIcon(getProp(rowData, 'status', ''))}</>
      return cellJSX
    },
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      let cellCSS = 'tw-w-4'
      return cellCSS
    },
  },
}

// Manage Options
const viewAction: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="magnifying-glass"
    />
  ),
  label: <>{rLIB('View Task')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        tableHooks.uc_setUserInterface_CustomDialogDisplay2({
          display: true,
          dialog: {
            dialog_jsx: (
              <TaskViewDialog
                clientKey={res_GCK.clientKey}
                taskKey={rowData.key as string}
              />
            ),
            settings: {
              max_width: 'lg',
            },
          },
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  },
}

const assignAction: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="user-plus"
    />
  ),
  label: <>{rLIB('Assign Task')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (
      tableAdditionalData != null &&
      tableAdditionalData.clientKey != null &&
      tableAdditionalData.us_taskSettings != null &&
      tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key != null &&
      tableAdditionalData.us_taskSettings.associated_user_directory_key != null &&
      tableAdditionalData.us_taskSettings.associated_user_directory_name_field_key != null
    ) {
      // Open Form
      let formInputs: TsInterface_FormInputs = {
        // display_form_data_json: {
        //   data_type: 'string',
        //   input_type: 'display_form_data_json',
        //   key: '',
        //   label: <></>,
        // },
        associated_assigned_user_key: {
          data_type: 'string',
          key: 'associated_assigned_user_key',
          input_type: 'custom_form_input_jsx',
          label: <></>,
          required: true,
          renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
            // Search Result
            const rJSX_SearchResult = (
              option: TsInterface_UnspecifiedObject,
              searchInputValue: string | null,
              inputHooks: TsInterface_InputHooksObject,
              additionalSearchData: TsInterface_UnspecifiedObject,
            ): JSX.Element => {
              let searchResultJSX = (
                <Box
                  sx={{ marginLeft: '8px', marginRight: '8px', cursor: 'pointer' }}
                  onClick={() => {
                    inputHooks.us_setSearchInputValue(option.name)
                    inputHooks.us_setOpen(false)
                    inputHooks.optionSelectedCallback(option)
                    if (
                      option != null &&
                      option.key != null &&
                      tableAdditionalData != null &&
                      tableAdditionalData.clientKey != null &&
                      tableAdditionalData.us_taskSettings != null &&
                      tableAdditionalData.us_taskSettings.associated_user_directory_key != null &&
                      tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key != null
                    ) {
                      DatabaseGetDocument(
                        DatabaseRef_Directory_Document(
                          tableAdditionalData.clientKey,
                          tableAdditionalData.us_taskSettings.associated_user_directory_key,
                          option.key,
                        ),
                      )
                        .then((res_DGD) => {
                          console.log(res_DGD.data)
                          console.log(tableAdditionalData.us_taskSettings)
                          formInputChange(
                            'associated_assigned_user_email',
                            res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key],
                            true,
                          )
                        })
                        .catch((rej_DGD) => {
                          console.error(rej_DGD)
                        })
                    }
                  }}
                >
                  <Typography>{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
                </Box>
              )
              return searchResultJSX
            }
            // Input JSX
            let inputJSX = (
              <Box>
                <Typography
                  variant="body1"
                  sx={{ marginBottom: '4px', opacity: '.5' }}
                >
                  {rLIB('User to assign this task to')}
                </Typography>
                <SearchInput
                  clientKey={tableAdditionalData.clientKey}
                  searchIndexKey={tableAdditionalData.us_taskSettings.associated_user_directory_key}
                  searchFilters={['status != deleted']} // TODO: Implement status as filterable field
                  searchResultRenderer={rJSX_SearchResult}
                  additionalSearchData={{}}
                  defaultSearchValue={(formData[formInput.key] as string) || ''}
                  optionSelectedCallback={(option: TsInterface_UnspecifiedObject) => {
                    formInputChange('associated_assigned_user_key', option.key, true)
                  }}
                  sx={{
                    width: '100%',
                    marginBottom: '8px',
                  }}
                />
                {formData.associated_assigned_user_email != null && formData.associated_assigned_user_email != '' ? (
                  <Typography
                    variant="body1"
                    sx={{ marginBottom: '4px' }}
                  >
                    <Box
                      component="span"
                      sx={{ opacity: '.5' }}
                    >
                      {rLIB('Send task email to')}
                    </Box>{' '}
                    <Box
                      component="span"
                      sx={{ color: themeVariables.info_main }}
                    >
                      {formData.associated_assigned_user_email as string}
                    </Box>
                  </Typography>
                ) : null}
              </Box>
            )
            return inputJSX
          },
        },
      }
      // Open Dialog
      tableHooks.uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {},
            formData: {},
            formInputs: formInputs,
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {
              submit_button_theme: 'info',
              submit_button_text: <>{rLIB('Assign Task')}</>,
              submit_button_icon: (
                <Icon
                  type="solid"
                  icon="paper-plane"
                />
              ),
            },
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    // Get User From Directory
                    let userKey = formSubmittedData.associated_assigned_user_key
                    if (userKey != null && userKey != '') {
                      DatabaseGetDocument(
                        DatabaseRef_Directory_Document(res_GCK.clientKey, tableAdditionalData.us_taskSettings.associated_user_directory_key, userKey),
                      )
                        .then((res_DGD) => {
                          if (
                            res_DGD != null &&
                            res_DGD.data != null &&
                            res_DGD.data.status != 'deleted' &&
                            res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key] != null &&
                            res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_name_field_key] != null
                          ) {
                            // let truncatedUserKey = getLast36Characters(userKey)
                            let truncatedUserKey = userKey
                            // If all the fields are filled out, update the task
                            let taskUpdateObject = {
                              status: 'assigned',
                              associated_assigned_user_key: userKey,
                              associated_assigned_user_name: res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_name_field_key],
                              associated_assigned_user_email: res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key],
                              associated_assigned_users_history: {
                                [truncatedUserKey]: {
                                  allow_unassigned_complete: null,
                                  email: res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key],
                                  key: userKey,
                                  name: res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_name_field_key],
                                  status: 'assigned',
                                  timestamp_assigned: new Date(),
                                },
                              },
                            }
                            let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                            let logUpdateObject: TsInterface_UnspecifiedObject = {
                              timestamp: new Date(),
                              key: logKey,
                              text:
                                'Task ' +
                                rowData.id_number +
                                ' assigned to ' +
                                formSubmittedData.associated_assigned_user_name +
                                ' by ' +
                                getProp(tableHooks.uc_RootData_ClientUser, 'name', ''),
                              associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                              associated_user_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                            }
                            let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                              {
                                type: 'setMerge',
                                ref: DatabaseRef_Tasks_Document(res_GCK.clientKey, rowData.key as string),
                                data: taskUpdateObject,
                              },
                              {
                                type: 'setMerge',
                                ref: DatabaseRef_TaskLogs_Document(res_GCK.clientKey, rowData.key as string, logKey),
                                data: logUpdateObject,
                              },
                            ]
                            DatabaseBatchUpdate(updateArray)
                              .then((res_DBU) => {
                                sendTaskInviteEmail(
                                  res_DGD.data[tableAdditionalData.us_taskSettings.associated_user_directory_email_field_key],
                                  rowData.id_number as string,
                                  res_GCK.clientKey,
                                  rowData.key as string,
                                  userKey,
                                )
                                  .then((res_STIE) => {
                                    resolve(res_DBU)
                                  })
                                  .catch((rej_STIE) => {
                                    let error = {
                                      message: rLIB('Failed to email task assignment'),
                                      details: rLIB('Task assigned but failed to email user'),
                                      code: 'ER-D-TI-AA-01',
                                    }
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                                  })
                              })
                              .catch((rej_DBU) => {
                                reject(rej_DBU)
                              })
                          } else {
                            let error = {
                              message: rLIB('Failed to assign task'),
                              details: rLIB('Missing Required Directory Data'),
                              code: 'ER-D-TI-AA-02',
                            }
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                            reject({ success: false })
                          }
                        })
                        .catch((rej_DGD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
                          console.error(rej_DGD)
                          reject(rej_DGD)
                        })
                    } else {
                      let error = {
                        message: rLIB('Failed to assign task'),
                        details: rLIB('Missing Required Directory Data'),
                        code: 'ER-D-TI-AA-03',
                      }
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                      reject({ success: false })
                    }
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'info',
            formDialogHeaderText: <>{rLIB('Assign Task to User')}</>,
            formDialogIcon: (
              <Icon
                type="solid"
                icon="circle-user"
              />
            ),
          },
        },
      })
    }
  },
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'status',
    comparator: '==',
    value: 'unassigned',
    conditions: [],
  },
}

const unassignAction: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="user-xmark"
    />
  ),
  label: <>{rLIB('Unassign Task')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      tableHooks.uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {},
            formData: {},
            formInputs: {
              allow_unassigned_complete: {
                data_type: 'string',
                key: 'allow_unassigned_complete',
                input_type: 'multiple_choice_radio',
                label: rLIB('Allow unassigned user to complete this task if they have already started it'),
                required: true,
                options: [
                  {
                    key: 'yes',
                    value: rLIB('Yes - User can complete this task if they already started it'),
                  },
                  {
                    key: 'no',
                    value: rLIB('No - User will be locked out'),
                  },
                ],
              },
              // HEADER: {
              //   data_type: 'string',
              //   key: 'HEADER',
              //   input_type: 'custom_form_input_jsx',
              //   label: <></>,
              //   required: false,
              //   renderCustomFormInput: (
              //     formInput: TsInterface_FormInput,
              //     formInputs: TsInterface_FormInputs,
              //     formData: TsInterface_FormData,
              //     formInputChange: TsType_InputChangeCallback,
              //     formSettings: TsInterface_FormSettings,
              //     formAdditionalData: TsInterface_FormAdditionalData,
              //     formHooks: TsInterface_FormHooksObject,
              //   ) => {
              //     return <Box>{rLIB('Are you sure that you want to remove this task from its current user?')}</Box>
              //   },
              // },
            },
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {
              submit_button_icon: (
                <Icon
                  type="solid"
                  icon="user-xmark"
                />
              ),
              submit_button_text: rLIB('Unassign Task'),
              submit_button_theme: 'error',
            },
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let allowUnassignedComplete = formSubmittedData.allow_unassigned_complete === 'yes'
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    // let truncatedUserKey = getLast36Characters(rowData.associated_assigned_user_key as string)
                    let truncatedUserKey = rowData.associated_assigned_user_key as string
                    let taskUpdateObject = {
                      status: 'unassigned',
                      associated_assigned_user_key: null,
                      associated_assigned_user_name: null,
                      associated_assigned_user_email: null,
                      associated_assigned_users_history: {
                        [truncatedUserKey]: {
                          allow_unassigned_complete: allowUnassignedComplete,
                          status: 'unassigned',
                          timestamp_unassigned: new Date(),
                        },
                      },
                    }
                    let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                    let logUpdateObject: TsInterface_UnspecifiedObject = {
                      timestamp: new Date(),
                      key: logKey,
                      text:
                        'Task ' +
                        rowData.id_number +
                        ' unassigned from ' +
                        getProp(rowData, 'associated_assigned_user_name', "it's current user") +
                        ' by ' +
                        getProp(tableHooks.uc_RootData_ClientUser, 'name', ''),
                      associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                      associated_user_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                    }
                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_Tasks_Document(res_GCK.clientKey, rowData.key as string),
                        data: taskUpdateObject,
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_TaskLogs_Document(res_GCK.clientKey, rowData.key as string, logKey),
                        data: logUpdateObject,
                      },
                    ]
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        resolve(res_DBU)
                      })
                      .catch((rej_DBU) => {
                        reject(rej_DBU)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'error',
            formDialogHeaderText: <>{rLIB('Unassign Task')}</>,
            formDialogIcon: (
              <Icon
                type="solid"
                icon="circle-x"
              />
            ),
          },
        },
      })

      // tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      //   display: true,
      //   confirm: {
      //     color: 'error',
      //     header: rLIB('Unassign Task'),
      //     icon: (
      //       <Icon
      //         icon="user-xmark"
      //         type="solid"
      //       />
      //     ),
      //     submit_text: rLIB('Unassign Task'),
      //     text: rLIB('Are you sure that you want to remove this task from its current user?'),
      //     submit_callback: () => {
      //       return new Promise((resolve, reject) => {
      //         getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      //           .then((res_GCK) => {
      //             let taskUpdateObject = {
      //               status: 'unassigned',
      //               associated_assigned_user_key: null,
      //               associated_assigned_user_name: null,
      //               associated_assigned_user_email: null,
      //               associated_assigned_users_history: {
      //                 [rowData.associated_assigned_user_key as string]: {
      //                   status: 'unassigned',
      //                   allow_unassigned_complete: false,
      //                 },
      //               },
      //             }
      //             let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
      //             let logUpdateObject: TsInterface_UnspecifiedObject = {
      //               timestamp: new Date(),
      //               key: logKey,
      //               text:
      //                 'Task ' +
      //                 rowData.id_number +
      //                 ' unassigned from ' +
      //                 getProp(rowData, 'associated_assigned_user_name', "it's current user") +
      //                 ' by ' +
      //                 getProp(tableHooks.uc_RootData_ClientUser, 'name', ''),
      //               associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
      //               associated_user_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
      //             }
      //             let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
      //               {
      //                 type: 'setMerge',
      //                 ref: DatabaseRef_Tasks_Document(res_GCK.clientKey, rowData.key as string),
      //                 data: taskUpdateObject,
      //               },
      //               {
      //                 type: 'setMerge',
      //                 ref: DatabaseRef_TaskLogs_Document(res_GCK.clientKey, rowData.key as string, logKey),
      //                 data: logUpdateObject,
      //               },
      //             ]
      //             DatabaseBatchUpdate(updateArray)
      //               .then((res_DBU) => {
      //                 resolve(res_DBU)
      //               })
      //               .catch((rej_DBU) => {
      //                 reject(rej_DBU)
      //               })
      //           })
      //           .catch((rej_GCK) => {
      //             tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      //             reject(rej_GCK)
      //           })
      //       })
      //     },
      //   },
      // })
    }
  },
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'status',
    comparator: '==',
    value: 'assigned',
    conditions: [],
  },
}

const cancelAction: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="circle-x"
    />
  ),
  label: <>{rLIB('Cancel Task')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
        display: true,
        confirm: {
          color: 'error',
          header: rLIB('Cancel Task'),
          icon: (
            <Icon
              icon="circle-x"
              type="solid"
            />
          ),
          submit_text: rLIB('Cancel Task'),
          text: rLIB('Are you sure that you want to cancel this task?'),
          submit_callback: () => {
            return new Promise((resolve, reject) => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let taskUpdateObject = {
                    status: 'deleted',
                  }
                  let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                  let logUpdateObject: TsInterface_UnspecifiedObject = {
                    timestamp: new Date(),
                    key: logKey,
                    text: 'Task ' + rowData.id_number + ' cancelled by ' + getProp(tableHooks.uc_RootData_ClientUser, 'name', ''),
                    associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                    associated_user_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                  }
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Tasks_Document(res_GCK.clientKey, rowData.key as string),
                      data: taskUpdateObject,
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_TaskLogs_Document(res_GCK.clientKey, rowData.key as string, logKey),
                      data: logUpdateObject,
                    },
                  ]
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
      })
    }
  },
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'status',
    comparator: '==',
    value: 'unassigned',
    conditions: [],
  },
}

const undeleteAction: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="rotate-left"
    />
  ),
  label: <>{rLIB('Redo Task')}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (rowData.key != null) {
      tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
        display: true,
        confirm: {
          color: 'warning',
          header: rLIB('Redo Task'),
          icon: (
            <Icon
              icon="rotate-left"
              type="solid"
            />
          ),
          submit_text: rLIB('Redo Task'),
          text: rLIB('Are you sure that you want to redo this task?'),
          submit_callback: () => {
            return new Promise((resolve, reject) => {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let taskUpdateObject = {
                    status: 'unassigned',
                  }
                  let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
                  let logUpdateObject: TsInterface_UnspecifiedObject = {
                    timestamp: new Date(),
                    key: logKey,
                    text: 'Task ' + rowData.id_number + ' restored by ' + getProp(tableHooks.uc_RootData_ClientUser, 'name', ''),
                    associated_user_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                    associated_user_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                  }
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_Tasks_Document(res_GCK.clientKey, rowData.key as string),
                      data: taskUpdateObject,
                    },
                    {
                      type: 'setMerge',
                      ref: DatabaseRef_TaskLogs_Document(res_GCK.clientKey, rowData.key as string, logKey),
                      data: logUpdateObject,
                    },
                  ]
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
      })
    }
  },
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'status',
    comparator: '==',
    value: 'deleted',
    conditions: [],
  },
}

const rJSX_TaskProgressBar = (steps: TsInterface_UnspecifiedObject[], progressBarData: TsInterface_UnspecifiedObject, showName: boolean) => {
  let getStepCompleteTimestamp = (step: TsInterface_UnspecifiedObject): string | null => {
    let timestamp: string | null = null
    if (
      step != null &&
      step.key != null &&
      progressBarData != null &&
      progressBarData.progress != null &&
      progressBarData.progress[step.key] != null &&
      progressBarData.progress[step.key]['timestamp_completed'] != null
    ) {
      timestamp = returnFormattedDate(returnDateFromUnknownDateFormat(progressBarData.progress[step.key]['timestamp_completed']), 'D MMM YYYY h:mm a')
    }
    return timestamp
  }
  let progressBarJSX = (
    <Box className="tw-mb-2">
      <Box>{showName ? <Typography variant="body2">{progressBarData.name}</Typography> : null}</Box>
      <Stack
        direction="row"
        spacing={0}
        sx={{ maxWidth: '200px' }}
      >
        {steps.map((step: TsInterface_UnspecifiedObject, index: number) => (
          <Stack
            direction="row"
            spacing={0}
            key={index}
          >
            <Tooltip
              title={
                getStepCompleteTimestamp(step) != null ? (
                  <Box>
                    {' '}
                    <Box>{step.name} </Box>
                    <Box>
                      {rLIB('Completed on')} {getStepCompleteTimestamp(step)}
                    </Box>
                  </Box>
                ) : (
                  <>{step.name}</>
                )
              }
            >
              <Box
                sx={{
                  cursor: 'default',
                  width: '28px',
                  height: '28px',
                  border: getStepCompleteTimestamp(step) != null ? '2px solid ' + themeVariables.success_light : '2px solid ' + themeVariables.gray_300,
                  backgroundColor: getStepCompleteTimestamp(step) != null ? themeVariables.success_light : themeVariables.gray_100,
                  color: getStepCompleteTimestamp(step) != null ? themeVariables.white : themeVariables.black,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 2,
                }}
              >
                {getStepCompleteTimestamp(step) != null ? (
                  <Icon
                    icon="check"
                    type="solid"
                  />
                ) : (
                  <Typography variant="body2">{index + 1}</Typography>
                )}
              </Box>
            </Tooltip>
            {index < steps.length - 1 && (
              <Box>
                <Box
                  sx={{
                    zIndex: 1,
                    marginTop: '13px',
                    marginLeft: '-2px',
                    marginRight: '-2px',
                    width: '32px',
                    height: '2px',
                    backgroundColor: getStepCompleteTimestamp(step) != null ? themeVariables.success_light : themeVariables.gray_300,
                  }}
                ></Box>
              </Box>
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  )
  return progressBarJSX
}

const rJSX_EmptyTaskProgressBar = (steps: TsInterface_UnspecifiedObject[]) => {
  let progressBarJSX = (
    <Box className="tw-mb-2">
      <Stack
        direction="row"
        spacing={0}
        sx={{ maxWidth: '200px' }}
      >
        {steps.map((step: TsInterface_UnspecifiedObject, index: number) => (
          <Stack
            direction="row"
            spacing={0}
            key={index}
          >
            <Tooltip title={step.name}>
              <Box
                sx={{
                  width: '28px',
                  height: '28px',
                  border: '2px solid ' + themeVariables.gray_400,
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 2,
                }}
              >
                <Typography variant="body2">{index + 1}</Typography>
              </Box>
            </Tooltip>
            {index < steps.length - 1 && (
              <Box>
                <Box
                  sx={{
                    zIndex: 1,
                    marginTop: '13px',
                    marginLeft: '-2px',
                    marginRight: '-2px',
                    width: '32px',
                    height: '2px',
                    backgroundColor: themeVariables.gray_400,
                  }}
                ></Box>
              </Box>
            )}
          </Stack>
        ))}
      </Stack>
    </Box>
  )
  return progressBarJSX
}

const tableCellProgressBar = {
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Task Progress')
    },
    header_sort_by: null,
  },
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      // Helper Functions
      const returnSortedChecklistItems = (task: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject[] => {
        let sortedChecklistItems: TsInterface_UnspecifiedObject[] = []
        if (task != null && task['task_steps'] != null) {
          for (let stepKey in task['task_steps']) {
            let step = task['task_steps'][stepKey]
            if (step != null && step['status'] === 'active' && (step['step_type'] === 'navigation' || step['step_type'] === 'form')) {
              let stepObject: TsInterface_UnspecifiedObject = {
                name: step['name'],
                order: step['order'],
                key: stepKey,
              }
              sortedChecklistItems.push(stepObject)
            }
          }
        }
        return sortedChecklistItems.sort(dynamicSort('order', 'asc'))
      }
      // Determine Which Progress Bars to show
      let orderedTaskSteps: TsInterface_UnspecifiedObject[] = []
      let primaryProgressBarData: TsInterface_UnspecifiedObject = {}
      let hasPrimaryProgressBar: boolean = false
      let secondaryProgressBarsData: TsInterface_UnspecifiedObject[] = []
      let progressBarCount: number = 0
      if (rowData != null && rowData.associated_assigned_users_history != null && rowData.task_steps != null) {
        // Determine Ordered Task Steps
        orderedTaskSteps = returnSortedChecklistItems(rowData)
        for (let loopUserKey in getProp(rowData, 'associated_assigned_users_history', {})) {
          let loopUserHistory = getProp(rowData, 'associated_assigned_users_history', {})[loopUserKey]
          if (rowData.associated_assigned_user_key != null && loopUserKey === rowData.associated_assigned_user_key) {
            primaryProgressBarData = {
              name: getProp(loopUserHistory, 'name', ''),
              status: getProp(loopUserHistory, 'status', ''),
              timestamp_assigned: getProp(loopUserHistory, 'timestamp_assigned', null),
              progress: getProp(loopUserHistory, 'progress', null),
            }
            hasPrimaryProgressBar = true
            progressBarCount++
          } else {
            if (getProp(loopUserHistory, 'progress', null) != null) {
              secondaryProgressBarsData.push({
                name: getProp(loopUserHistory, 'name', ''),
                status: getProp(loopUserHistory, 'status', ''),
                timestamp_assigned: getProp(loopUserHistory, 'timestamp_assigned', null),
                progress: getProp(loopUserHistory, 'progress', null),
              })
              progressBarCount++
            }
          }
        }
      }
      // JSX
      if (progressBarCount > 0) {
        cellJSX = (
          <Box>
            <Box>{hasPrimaryProgressBar ? rJSX_TaskProgressBar(orderedTaskSteps, primaryProgressBarData, progressBarCount > 1) : null}</Box>
            <Box>
              {secondaryProgressBarsData.map((progressBarData: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_TaskProgressBar(orderedTaskSteps, progressBarData, progressBarCount > 1)}</Box>
              ))}
            </Box>
          </Box>
        )
      } else {
        // Empty Progress Bar
        cellJSX = <Box>{rJSX_EmptyTaskProgressBar(orderedTaskSteps)}</Box>
      }

      return cellJSX
    },
  },
}

const tableColumns_UnassignedTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewAction,
    assign: assignAction,
    unassign: unassignAction,
    cancel: cancelAction,
    undelete: undeleteAction,
  }),
  status: statusCell,
  id_number: TableCellBasic('id_number', rLIB('Task Number'), 'id_number'),
  associated_task_template_name: TableCellBasic('associated_task_template_name', rLIB('Task Template'), 'associated_task_template_name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YY h:mm a', false),
}

const tableColumns_AssignedTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewAction,
    assign: assignAction,
    unassign: unassignAction,
    cancel: cancelAction,
    undelete: undeleteAction,
  }),
  status: statusCell,
  id_number: TableCellBasic('id_number', rLIB('Task Number'), 'id_number'),
  associated_assigned_user_name: TableCellBasic('associated_assigned_user_name', rLIB('Assigned To'), 'associated_assigned_user_name'),
  associated_task_template_name: TableCellBasic('associated_task_template_name', rLIB('Task Template'), 'associated_task_template_name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YY h:mm a', false),
}

const tableColumns_InProgressTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewAction,
    assign: assignAction,
    unassign: unassignAction,
    cancel: cancelAction,
    undelete: undeleteAction,
  }),
  status: statusCell,
  id_number: TableCellBasic('id_number', rLIB('Task Number'), 'id_number'),
  associated_assigned_user_name: TableCellBasic('associated_assigned_user_name', rLIB('Assigned To'), 'associated_assigned_user_name'),
  progress: tableCellProgressBar,
  associated_task_template_name: TableCellBasic('associated_task_template_name', rLIB('Task Template'), 'associated_task_template_name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YY h:mm a', false),
}

const tableColumns_CompletedTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewAction,
    assign: assignAction,
    unassign: unassignAction,
    cancel: cancelAction,
    undelete: undeleteAction,
  }),
  status: statusCell,
  id_number: TableCellBasic('id_number', rLIB('Task Number'), 'id_number'),
  associated_assigned_user_name: TableCellBasic('associated_assigned_user_name', rLIB('Assigned To'), 'associated_assigned_user_name'),
  associated_task_template_name: TableCellBasic('associated_task_template_name', rLIB('Task Template'), 'associated_task_template_name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YY h:mm a', false),
}

const tableColumns_CancelledTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewAction,
    assign: assignAction,
    unassign: unassignAction,
    cancel: cancelAction,
    undelete: undeleteAction,
  }),
  status: statusCell,
  id_number: TableCellBasic('id_number', rLIB('Task Number'), 'id_number'),
  associated_assigned_user_name: TableCellBasic('associated_assigned_user_name', rLIB('Assigned To'), 'associated_assigned_user_name'),
  associated_task_template_name: TableCellBasic('associated_task_template_name', rLIB('Task Template'), 'associated_task_template_name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YY h:mm a', false),
}

const tableColumns_AllTasks: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: viewAction,
    assign: assignAction,
    unassign: unassignAction,
    cancel: cancelAction,
    undelete: undeleteAction,
  }),
  status: statusCell,
  id_number: TableCellBasic('id_number', rLIB('Task Number'), 'id_number'),
  associated_assigned_user_name: TableCellBasic('associated_assigned_user_name', rLIB('Assigned To'), 'associated_assigned_user_name'),
  associated_task_template_name: TableCellBasic('associated_task_template_name', rLIB('Task Template'), 'associated_task_template_name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  associated_data_bucket_name: TableCellBasic('associated_data_bucket_name', rLIB('Data Bucket'), 'associated_data_bucket_name'),
  created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YY h:mm a', false),
}

const tableSettings_AllTasks: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
}

const sendTaskInviteEmail = (email: string, idNumber: string, clientKey: string, taskKey: string, userKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let templateObject: TsInterface_EmailTemplateObject = {
      BODY: {
        element_type: 'div',
        style: {
          'background': '#f1f1f1',
          'padding': '8px',
          'border-bottom-left-radius': '8px',
          'border-bottom-right-radius': '8px',
        },
        contents: {
          CONTAINER: {
            element_type: 'div',
            style: {
              'width': '100%',
              'display': 'block',
              'text-align': 'center',
              'margin-top': '16px',
            },
            contents: {
              CARD: {
                element_type: 'div',
                style: {
                  'max-width': '500px',
                  'width': '100%',
                  'display': 'inline-block',
                  'padding-right': '4px',
                  'box-sizing': 'border-box',
                  'margin': 'auto',
                },
                contents: {
                  HEADER: {
                    element_type: 'div',
                    style: {
                      'background': 'linear-gradient(#1a2430, #233140)',
                      'font-size': '20px',
                      'width': '100%',
                      'min-height': '50px',
                      'padding-top': '16px',
                      'padding-bottom': '8px',
                      'text-align': 'center',
                      'border-top-left-radius': '8px',
                      'border-top-right-radius': '8px',
                    },
                    contents: {
                      LOGO: {
                        element_type: 'img',
                        src_type: 'static',
                        src: 'https://firebasestorage.googleapis.com/v0/b/data-ore-app.appspot.com/o/global%2Flogo_orchestrate.png?alt=media&token=0ee00e7c-cca0-4c98-b3da-080d91d07680',
                        alt: 'logo.png',
                        width: '350px',
                        height: 'auto',
                      },
                    },
                  },
                  TEXT_CONTAINER: {
                    element_type: 'div',
                    style: {
                      'background-color': '#FFFFFF',
                      'border-radius': '5px',
                      'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                      'display': 'block',
                      'margin-bottom': '16px',
                      'padding': '12px',
                      'text-align': 'center',
                    },
                    contents: {
                      INTRO: {
                        element_type: 'p',
                        text_type: 'static',
                        text: "You've been assigned a new Orchestrate task",
                        style: {
                          'display': 'block',
                          'margin': '0px',
                          'font-size': '18px',
                        },
                      },
                      DIVIDER1: {
                        element_type: 'div',
                        style: {
                          'height': '1px',
                          'background-color': '#DDD',
                          'margin': '8px 0px',
                        },
                      },
                      ID_NUMBER_CONTAINER: {
                        element_type: 'div',
                        style: {
                          'text-align': 'center',
                          'margin-top': '0px',
                        },
                        contents: {
                          INTRO: {
                            element_type: 'p',
                            text_type: 'static',
                            text: 'Task',
                            style: {
                              'font-size': '24px',
                              'font-weight': 'bold',
                              'margin-bottom': '4px',
                              'color': 'rgba(0, 0, 0, 0.87)',
                              'display': 'inline-block',
                              'margin-right': '6px',
                              'margin-top': '0px',
                            },
                          },
                          ID_NUMBER: {
                            element_type: 'p',
                            text_type: 'dynamic',
                            data_object_key: 'data',
                            data_object_prop_key: 'id_number',
                            style: {
                              'font-size': '24px',
                              'font-weight': 'bold',
                              'margin-bottom': '4px',
                              'color': 'rgba(0, 0, 0, 0.87)',
                              'display': 'inline-block',
                              'margin-top': '0px',
                            },
                            // prefix_text: 'Hi ',
                            // suffix_text: ',',
                          },
                        },
                      },
                      // DIVIDER2: {
                      //   element_type: 'div',
                      //   style: {
                      //     'height': '1px',
                      //     'background-color': '#DDD',
                      //     'margin': '8px 0px',
                      //   },
                      // },

                      TEXT_CONTAINER: {
                        element_type: 'div',
                        style: {
                          'text-align': 'center',
                          'margin-top': '4px',
                        },
                        contents: {
                          LINK: {
                            element_type: 'a',
                            href_type: 'dynamic',
                            href_url_data_object_key: 'data',
                            href_url_data_object_prop_key: 'url',
                            text: 'Click here to get started',
                            text_type: 'static',
                            style: {
                              'display': 'inline-block',
                              'padding': '4px 8px',
                              'border-radius': '4px',
                              'text-decoration': 'none',
                              'cursor': 'pointer',
                              'color': '#FFFFFF',
                              'background-color': '#D41B29',
                              'margin-top': '16px',
                              'font-weight': 'bold',
                              'margin': '0px',
                              'font-size': '18px',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
    let url = window.location.origin + ApplicationPages.UnauthenticatedTaskChecklistPage.url(clientKey, taskKey, userKey)
    let dataObjects: TsInterface_UnspecifiedObject = {
      data: {
        id_number: idNumber.toString(),
        url: url,
      },
    }
    // Send Email
    cloudFunctionManageRequest('sendEmail', {
      function: 'sendHtmlEmail',
      toArray: [email], // Email sent only to you for testing
      subject: 'You have a new Orchestrate task - ' + idNumber,
      html: generateHtmlForEmailFromTemplateObject(templateObject, dataObjects),
      ccArray: [],
      bccArray: [],
    })
      .then((res_CFMR) => {
        resolve(res_CFMR)
      })
      .catch((rej_CFMR) => {
        reject(rej_CFMR)
      })
  })
}

///////////////////////////////
// Functions
///////////////////////////////

// function getLast36Characters(inputString: string): string {
//   if (inputString.length < 36) {
//     return inputString
//   }
//   return inputString.slice(-36)
// }

export const rJSX_TaskStatusIcon = (status: string): JSX.Element => {
  let iconJSX = <></>
  switch (status) {
    case 'unassigned':
      iconJSX = (
        <Icon
          icon="circle-pause"
          sx={{ color: themeVariables.gray_500, fontSize: '18px' }}
          tooltip={rLIB('Unassigned')}
          tooltipPlacement="right"
        />
      )
      break
    case 'assigned':
      iconJSX = (
        <Icon
          icon="circle-user"
          sx={{ color: themeVariables.info_main, fontSize: '18px' }}
          tooltip={rLIB('Assigned')}
          tooltipPlacement="right"
        />
      )
      break
    case 'in_progress':
      iconJSX = (
        <Icon
          icon="circle-play"
          sx={{ fontSize: '18px', color: themeVariables.warning_main }}
          tooltip={rLIB('In Progress')}
          tooltipPlacement="right"
        />
      )
      break
    case 'complete':
      iconJSX = (
        <Icon
          icon="circle-check"
          sx={{ fontSize: '18px', color: themeVariables.success_main }}
          tooltip={rLIB('Completed')}
          tooltipPlacement="right"
        />
      )
      break
    case 'deleted':
      iconJSX = (
        <Icon
          icon="circle-x"
          sx={{ fontSize: '18px', color: themeVariables.error_main }}
          tooltip={rLIB('Cancelled')}
          tooltipPlacement="right"
        />
      )
      break
  }
  return iconJSX
}

export const returnTaskStatusColors = (status: string): string => {
  let color = themeVariables.gray_500
  switch (status) {
    case 'unassigned':
      color = themeVariables.gray_500
      break
    case 'assigned':
      color = themeVariables.info_main
      break
    case 'in_progress':
      color = themeVariables.warning_main
      break
    case 'complete':
      color = themeVariables.success_main
      break
    case 'deleted':
      color = themeVariables.error_main
      break
  }
  return color
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_lockedSettings, us_setLockedSettings] = useState<boolean>(true)
  const [us_taskSettings, us_setTaskSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedClassFields, us_setSelectedClassFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activePersistentClasses, us_setActivePersistentClasses] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const un_routerNavigation = useNavigate()
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Tasks', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActivePersistentClasses(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActivePersistentClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeClassFields: TsInterface_UnspecifiedObject = {}
      for (let loopKey in newData) {
        if (newData[loopKey].status === 'active') {
          activeClassFields[loopKey] = newData[loopKey]
        }
      }
      us_setSelectedClassFields(activeClassFields)
    }
    if (us_taskSettings != null && us_taskSettings.associated_user_directory_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_taskSettings.associated_user_directory_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_taskSettings])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskSettings(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskSettings_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions
  const tableDatabaseEndpoint_TaskTemplates = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_TaskTemplates_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_UnassignedTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'unassigned' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Tasks_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_AssignedTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'assigned' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Tasks_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_InProgressTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'in_progress' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Tasks_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_CompletedTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'complete' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Tasks_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_CancelledTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'deleted' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Tasks_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_AllTasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Tasks_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const createTaskProper = async (clientKey: string, taskToCreate: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      // ID Number
      DatabaseTransactionIncrement(DatabaseRef_TaskIdNumbers_Document(clientKey), 'task_id_number', 1000000)
        .then((res_DTI: any) => {
          taskToCreate['timestamp_created'] = new Date()
          taskToCreate['id_number'] = res_DTI.incrementValue
          DatabaseAddDocument(DatabaseRef_Tasks_Collection(clientKey), taskToCreate, true).then((res_DSMD: any) => {
            let taskKey = res_DSMD.key
            let logKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
            let logUpdateObject: TsInterface_UnspecifiedObject = {
              timestamp: new Date(),
              key: logKey,
              text: 'Task ' + taskToCreate.id_number + ' created by ' + getProp(uc_RootData_ClientUser, 'name', ''),
              associated_user_key: getProp(uc_RootData_ClientUser, 'key', null),
              associated_user_name: getProp(uc_RootData_ClientUser, 'name', null),
            }
            DatabaseSetMergeDocument(DatabaseRef_TaskLogs_Document(clientKey, taskKey, logKey), logUpdateObject)
              .then((res_DSMD) => {
                resolve({ close_dialog: true })
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                reject(rej_DSMD.error)
              })
          })
        })
        .catch((rej_DTI) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DTI.error })
          reject(rej_DTI.error)
        })
    })
  }

  const createTaskPart2 = async (clientKey: string, taskTemplateKey: string, taskTemplate: TsInterface_UnspecifiedObject) => {
    try {
      // Generate Form
      let formInputs: TsInterface_FormInputs = {
        // display_form_data_json: {
        //   data_type: 'string',
        //   input_type: 'display_form_data_json',
        //   key: '',
        //   label: <></>,
        // },
        quantity_to_create: {
          data_type: 'string',
          key: 'quantity_to_create',
          input_type: 'custom_form_input_jsx',
          label: '',
          required: true,
          renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
            // Placeholder so step_associations from other inputs comes through
            return (
              <Box sx={{ marginBottom: '12px' }}>
                <Stack
                  direction="row"
                  spacing={2}
                >
                  <Typography sx={{ marginRight: '8px', fontWeight: 'bold' }}>{rLIB('Quantity of tasks to create')}:</Typography>
                  <Icon
                    icon="circle-minus"
                    sx={{
                      'opacity': 0.5,
                      '&:hover': {
                        opacity: 1,
                        color: themeVariables.error_main,
                      },
                      'cursor': 'pointer',
                    }}
                    onClick={() => {
                      if (getProp(formData, 'quantity_to_create', 1) > 1) {
                        formInputChange('quantity_to_create', getProp(formData, 'quantity_to_create', 1) - 1, true)
                      }
                    }}
                  />
                  <Typography>{getProp(formData, 'quantity_to_create', 1)}</Typography>
                  <Icon
                    icon="circle-plus"
                    sx={{
                      'opacity': 0.5,
                      '&:hover': {
                        opacity: 1,
                        color: themeVariables.success_main,
                      },
                      'cursor': 'pointer',
                    }}
                    onClick={() => {
                      if (getProp(formData, 'quantity_to_create', 1) < 10) {
                        formInputChange('quantity_to_create', getProp(formData, 'quantity_to_create', 1) + 1, true)
                      }
                    }}
                  />
                </Stack>
              </Box>
            )
          },
        },
        step_associations: {
          data_type: 'string',
          key: 'step_associations',
          input_type: 'custom_form_input_jsx',
          label: '',
          required: false,
          renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
            // Placeholder so step_associations from other inputs comes through
            return (
              <Box>
                <Typography sx={{ marginBottom: '4px' }}>
                  <Box
                    component="span"
                    sx={{ marginRight: '8px', fontWeight: 'bold' }}
                  >
                    {rLIB('Task Template')}:
                  </Box>
                  <Box
                    component="span"
                    sx={{ opacity: '.5' }}
                  >
                    {taskTemplate.name}
                  </Box>
                </Typography>
              </Box>
            )
          },
        },
      }
      // Get Data Buckets that use same class key
      const { data: dataBuckets } = await DatabaseGetCollection(
        DatabaseRef_ActiveDataBucketsWithSpecificClass_Query(clientKey, taskTemplate.associated_class_key),
      )
      // Task Template Options
      let dataBucketOptions: TsInterface_UnspecifiedObject[] = []
      for (let loopTemplateKey in dataBuckets) {
        let loopTemplate = dataBuckets[loopTemplateKey]
        if (loopTemplate.associated_class_key != null) {
          dataBucketOptions.push({
            key: loopTemplateKey,
            value: loopTemplate.name,
          })
        }
      }
      formInputs['associated_data_bucket_key'] = {
        data_type: 'string',
        input_type: 'multiple_choice_select',
        key: 'associated_data_bucket_key',
        label: <>{rLIB('Data Bucket')}</>,
        required: true,
        options: dataBucketOptions,
      }
      // Get Directory Options for Steps
      let sortedSteps = objectToArray(getProp(taskTemplate, 'steps', {})).sort(dynamicSort('order', 'asc'))
      for (let loopStepKey in sortedSteps) {
        let loopStep = sortedSteps[loopStepKey]
        if (loopStep.step_type === 'navigation' || loopStep.step_type === 'metadata') {
          // associated_class_key
          if (loopStep.associated_class_key != null) {
            let loopStepInputKey = 'STEP_ASSOCIATED_CLASS_KEY_' + loopStep.key
            formInputs[loopStepInputKey] = {
              data_type: 'string',
              key: loopStepInputKey,
              input_type: 'custom_form_input_jsx',
              label: loopStep.name,
              required: true,
              renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
                // Search Result
                const rJSX_SearchResult = (
                  option: TsInterface_UnspecifiedObject,
                  searchInputValue: string | null,
                  inputHooks: TsInterface_InputHooksObject,
                  additionalSearchData: TsInterface_UnspecifiedObject,
                ): JSX.Element => {
                  let searchResultJSX = (
                    <Box
                      sx={{ marginLeft: '8px', marginRight: '8px', cursor: 'pointer' }}
                      onClick={() => {
                        inputHooks.us_setSearchInputValue(option.name)
                        inputHooks.us_setOpen(false)
                        inputHooks.optionSelectedCallback(option)
                      }}
                    >
                      <Typography>{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
                    </Box>
                  )
                  return searchResultJSX
                }
                // Input JSX
                let inputJSX = (
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ marginBottom: '4px', opacity: '.5' }}
                    >
                      {loopStep.name}
                    </Typography>
                    <SearchInput
                      clientKey={clientKey}
                      searchIndexKey={loopStep.associated_class_key}
                      searchFilters={['status != deleted']} // TODO: Implement status as filterable field
                      searchResultRenderer={rJSX_SearchResult}
                      additionalSearchData={{}}
                      defaultSearchValue={(formData[formInput.key] as string) || ''}
                      optionSelectedCallback={(option: TsInterface_UnspecifiedObject) => {
                        let stepAssociationUpdateObject = getProp(formData, 'step_associations', {})
                        let copyOfStepAssociationUpdateObject = { ...stepAssociationUpdateObject }
                        copyOfStepAssociationUpdateObject[loopStep.key] = {
                          associated_directory_item_key: option.key,
                          associated_directory_item_name: option.name,
                        }
                        formInputChange('step_associations', copyOfStepAssociationUpdateObject, true)
                        formInputChange(formInput.key, option.name, true)
                        // formInputChange(formInput.key + '_key', option.key, true)
                      }}
                      sx={{
                        width: '100%',
                        marginBottom: '8px',
                      }}
                    />
                  </Box>
                )
                return inputJSX
              },
            }
          }
        } else if (loopStep.step_type === 'form' || loopStep.step_type === 'email') {
          // No action needed
        }
      }
      uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {},
            formData: {
              quantity_to_create: 1,
            },
            formInputs: formInputs,
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let updateObject: TsInterface_UnspecifiedObject = {}
                let dataBucketName = null
                if (
                  dataBuckets != null &&
                  formSubmittedData != null &&
                  formSubmittedData.associated_data_bucket_key != null &&
                  dataBuckets[formSubmittedData.associated_data_bucket_key] != null &&
                  dataBuckets[formSubmittedData.associated_data_bucket_key].name != null
                ) {
                  dataBucketName = dataBuckets[formSubmittedData.associated_data_bucket_key].name
                }

                console.log(formSubmittedData)

                // Data From Form
                updateObject['associated_data_bucket_key'] = formSubmittedData.associated_data_bucket_key
                updateObject['associated_data_bucket_name'] = dataBucketName
                if (formSubmittedData.step_associations != null) {
                  updateObject['task_step_associations'] = formSubmittedData.step_associations
                }
                // Task Template Data
                updateObject['associated_task_template_key'] = taskTemplateKey
                updateObject['associated_task_template_name'] = taskTemplate.name
                updateObject['associated_class_key'] = taskTemplate.associated_class_key
                updateObject['associated_class_name'] = taskTemplate.associated_class_name
                updateObject['task_procedures'] = {
                  data_item_creation_method: getProp(taskTemplate, 'data_item_creation_method', 'single'),
                  data_item_multiple_determinate_count: getProp(taskTemplate, 'data_item_multiple_determinate_count', null),
                  data_item_multiple_repeated_step: getProp(taskTemplate, 'data_item_multiple_repeated_step', null),
                  step_order_of_completion: getProp(taskTemplate, 'step_order_of_completion', 'sequential'),
                  require_app: getProp(taskTemplate, 'require_app', false),
                }
                updateObject['task_steps'] = getProp(taskTemplate, 'steps', {})
                // Metadata
                updateObject['status'] = 'unassigned'
                updateObject['timestamp_created'] = new Date()
                updateObject['created_by_key'] = formHooks.uc_RootData_ClientUser.key
                updateObject['created_by_name'] = formHooks.uc_RootData_ClientUser.name
                const createTasks = async () => {
                  for (let loop = 0; loop < formSubmittedData.quantity_to_create; loop++) {
                    await createTaskProper(clientKey, updateObject)
                  }
                }
                // Loop through and create tasks
                ;(async () => {
                  await createTasks()
                  resolve({ close_dialog: true })
                })()
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: <>{rLIB('Create New Task')}</>,
            formDialogIcon: (
              <Icon
                type="solid"
                icon="circle-plus"
              />
            ),
          },
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const createTaskPart1 = async () => {
    try {
      const { clientKey: clientKey } = await getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      const { data } = await DatabaseGetCollection(DatabaseRef_ActiveTaskTemplates_Query(clientKey))
      // Task Template Options
      let taskTemplateOptions: TsInterface_UnspecifiedObject[] = []
      for (let loopTemplateKey in data) {
        let loopTemplate = data[loopTemplateKey]
        if (loopTemplate.associated_class_key != null) {
          taskTemplateOptions.push({
            key: loopTemplateKey,
            value: loopTemplate.name,
          })
        }
      }
      // Form Dialog
      uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {},
            formData: {},
            formInputs: {
              associated_task_template: {
                data_type: 'string',
                input_type: 'multiple_choice_select',
                key: 'associated_task_template',
                label: <>{rLIB('Task Template')}</>,
                required: true,
                options: taskTemplateOptions,
                submit_on_change: true,
              },
            },
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {
              submit_button_hide: true,
            },
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                resolve({ close_dialog: true })
                createTaskPart2(clientKey, formSubmittedData.associated_task_template, getProp(data, formSubmittedData.associated_task_template, null))
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: <>{rLIB('Select Task Template')}</>,
            formDialogIcon: (
              <Icon
                type="solid"
                icon="circle-plus"
              />
            ),
          },
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  // JSX Generation
  const rJSX_NewTaskButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="contained"
        color="success"
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          createTaskPart1()
        }}
        disableElevation
      >
        {rLIB('New Task')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewDisabledTaskButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        disabled={true}
        variant="contained"
        color="success"
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {}}
        disableElevation
      >
        {rLIB('New Task')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewTaskTemplateButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        // size="small"
        variant="contained"
        color="success"
        className="tw-mb-2"
        startIcon={<Icon icon="circle-plus" />}
        onClick={() => {
          // TODO: Fill out
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: <>{rLIB('Name')}</>,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let taskTemplateKey = keyFromString(formSubmittedData.name)
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          name: formSubmittedData.name,
                          key: taskTemplateKey,
                          status: 'active',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_TaskTemplates_Document(res_GCK.clientKey, taskTemplateKey), updateObject)
                          .then((res_DSMD) => {
                            resolve({ close_dialog: true })
                            un_routerNavigation(ApplicationPages.TaskTemplateViewPage.url(taskTemplateKey))
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD.error)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK.error)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('Create New Task Template')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        disableElevation
      >
        {rLIB('New Task Template')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_UnassignedTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              us_taskSettings: us_taskSettings,
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_UnassignedTasks}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UnassignedTasks}
            tableSettings={tableSettings_AllTasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_AssignedTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              us_taskSettings: us_taskSettings,
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_AssignedTasks}
            tableDatabaseEndpoint={tableDatabaseEndpoint_AssignedTasks}
            tableSettings={tableSettings_AllTasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_InProgressTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              us_taskSettings: us_taskSettings,
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_InProgressTasks}
            tableDatabaseEndpoint={tableDatabaseEndpoint_InProgressTasks}
            tableSettings={tableSettings_AllTasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_CompletedTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              us_taskSettings: us_taskSettings,
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_CompletedTasks}
            tableDatabaseEndpoint={tableDatabaseEndpoint_CompletedTasks}
            tableSettings={tableSettings_AllTasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_CancelledTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              us_taskSettings: us_taskSettings,
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_CancelledTasks}
            tableDatabaseEndpoint={tableDatabaseEndpoint_CancelledTasks}
            tableSettings={tableSettings_AllTasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_AllTasksTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{
              us_taskSettings: us_taskSettings,
              clientKey: uc_RootData_ClientKey,
            }}
            tableColumns={tableColumns_AllTasks}
            tableDatabaseEndpoint={tableDatabaseEndpoint_AllTasks}
            tableSettings={tableSettings_AllTasks}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_TaskTemplatesTabContent = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_TaskTemplates}
            tableDatabaseEndpoint={tableDatabaseEndpoint_TaskTemplates}
            tableSettings={tableSettings_TaskTemplates}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_UserDirectoryDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          disabled={us_lockedSettings}
          onChange={(event, value) => {
            // Update task settings
            let updateObject = {
              associated_user_directory_key: event.target.value,
              associated_user_directory_name_field_key: null,
              associated_user_directory_email_field_key: null,
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_TaskSettings_Document(res_GCK.clientKey), updateObject)
                  .then((res_DSMD) => {
                    // console.log(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    console.error(rej_DSMD)
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: rej_DSMD.error,
                    })
                  })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: rej_GCK.error,
                })
              })
          }}
          value={getProp(us_taskSettings, 'associated_user_directory_key', '')}
        >
          {objectToArray(us_activePersistentClasses)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_UserDirectoryNameDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          disabled={us_lockedSettings}
          onChange={(event, value) => {
            // Update task settings
            let updateObject = { associated_user_directory_name_field_key: event.target.value }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_TaskSettings_Document(res_GCK.clientKey), updateObject)
                  .then((res_DSMD) => {
                    // console.log(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    console.error(rej_DSMD)
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: rej_DSMD.error,
                    })
                  })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: rej_GCK.error,
                })
              })
          }}
          value={getProp(us_taskSettings, 'associated_user_directory_name_field_key', '')}
        >
          {objectToArray(us_selectedClassFields)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_UserDirectoryEmailDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input"
        sx={{ minWidth: '130px', marginRight: '8px', marginBottom: '8px' }}
      >
        <Select
          disabled={us_lockedSettings}
          onChange={(event, value) => {
            // Update task settings
            let updateObject = { associated_user_directory_email_field_key: event.target.value }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_TaskSettings_Document(res_GCK.clientKey), updateObject)
                  .then((res_DSMD) => {
                    // console.log(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    console.error(rej_DSMD)
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: rej_DSMD.error,
                    })
                  })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: rej_GCK.error,
                })
              })
          }}
          value={getProp(us_taskSettings, 'associated_user_directory_email_field_key', '')}
        >
          {objectToArray(us_selectedClassFields)
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_SettingsTabContent = (): JSX.Element => {
    let tabJSX = (
      <Card className="tw-p-4">
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB('User Directory')}
        </Typography>
        <Box className="tw-ml-4 tw-mb-1">{rJSX_UserDirectoryDropdown()}</Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB("User's Name")}
        </Typography>
        <Box className="tw-ml-4 tw-mb-1">{rJSX_UserDirectoryNameDropdown()}</Box>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold' }}
        >
          {rLIB("User's Email")}
        </Typography>
        <Box className="tw-ml-4 tw-mb-1">{rJSX_UserDirectoryEmailDropdown()}</Box>
      </Card>
    )
    return tabJSX
  }

  const rJSX_UnlockAndLockButtons = () => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="warning"
        className="tw-mb-2"
        startIcon={us_lockedSettings ? <Icon icon="lock" /> : <Icon icon="unlock" />}
        onClick={() => {
          us_setLockedSettings(!us_lockedSettings)
        }}
      >
        {us_lockedSettings ? rLIB('Unlock') : rLIB('Lock')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Tasks')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'unassigned',
                  tabHeader: (
                    <Stack direction="row">
                      <Box sx={{ marginRight: '6px' }}>{rJSX_TaskStatusIcon('unassigned')}</Box>
                      {rLIB('Unassigned')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_UnassignedTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(false), minJSX: rJSX_NewTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'assigned',
                  tabHeader: (
                    <Stack direction="row">
                      <Box sx={{ marginRight: '6px' }}>{rJSX_TaskStatusIcon('assigned')}</Box>
                      {rLIB('Assigned')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_AssignedTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'in_progress',
                  tabHeader: (
                    <Stack direction="row">
                      <Box sx={{ marginRight: '6px' }}>{rJSX_TaskStatusIcon('in_progress')}</Box>
                      {rLIB('In Progress')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_InProgressTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'complete',
                  tabHeader: (
                    <Stack direction="row">
                      <Box sx={{ marginRight: '6px' }}>{rJSX_TaskStatusIcon('complete')}</Box>
                      {rLIB('Complete')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_CompletedTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'cancelled',
                  tabHeader: (
                    <Stack direction="row">
                      <Box sx={{ marginRight: '6px' }}>{rJSX_TaskStatusIcon('deleted')}</Box>
                      {rLIB('Cancelled')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_CancelledTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewDisabledTaskButton(false), minJSX: rJSX_NewDisabledTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'all',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="filter-slash"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px' }}
                      />
                      {rLIB('All')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_AllTasksTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewTaskButton(false), minJSX: rJSX_NewTaskButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'templates',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="compass-drafting"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px' }}
                      />
                      {rLIB('Task Templates')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_TaskTemplatesTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewTaskTemplateButton(false), minJSX: rJSX_NewTaskTemplateButton(true), sizeCutoff: 0 }],
                },
                {
                  tabUrlKey: 'settings',
                  tabHeader: (
                    <Stack direction="row">
                      <Icon
                        icon="cog"
                        className="tw-mr-2 tw-inline-block"
                        sx={{ fontSize: '18px' }}
                      />
                      {rLIB('Settings')}
                    </Stack>
                  ),
                  tabOnChange: () => {},
                  tabContent: rJSX_SettingsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_UnlockAndLockButtons(), minJSX: rJSX_UnlockAndLockButtons(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.TasksIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Tasks', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

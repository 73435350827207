///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { LogoOrchestrate } from 'app/images/logos/logo_orchestrate'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors } from 'rfbp_core/typescript/global_types'
// @ts-ignore
import logoOrchestrateSrc from '../../app/images/logos/logo_orchestrate.png'

///////////////////////////////
// Exports
///////////////////////////////

export const orchestraGradient = [
  '#07C5C7',
  '#06A0D6',
  '#2F91DB',
  '#7F85D9',
  '#9476DF',
  '#A066DF',
  '#BA4EDB',
  '#DF2DD5',
  '#F238C2',
  '#FD49A4',
  '#FE568C',
  '#FE6377',
]

export const rJSX_DynamicApplicationLogoSvg = (
  height: string,
  colorOverride: TsInterface_UnspecifiedObject,
  additionalStyles: TsInterface_UnspecifiedObject,
) => {
  let logoJSX = <></>
  // let url = getBaseURL()
  // // Logo Options
  // let loadCallLogo = (
  //   <LogoLoadCall
  //     height={height}
  //     color={getProp(colorOverride, 'lc', null)}
  //   />
  // )
  // let secureDeliveryLogo = (
  //   <LogoSecureDelivery
  //     height={height}
  //     l_color={getProp(colorOverride, 'sd_l', themeVariables.warning_main)}
  //     s_color={getProp(colorOverride, 'sd_s', themeVariables.navGradientTopColor)}
  //     d_color={getProp(colorOverride, 'sd_d', themeVariables.navGradientTopColor)}
  //   />
  // )
  // let dataOreLogo = (
  //   <LogoDataOre
  //     height={height}
  //     color={getProp(colorOverride, 'do', '#ffffff')}
  //   />
  // )
  let orchestrateLogo = (
    // <Box sx={{ marginLeft: '-15px' }}>
    <Box sx={additionalStyles}>
      <LogoOrchestrate
        height={height}
        color={getProp(colorOverride, 'do', null)}
      />
    </Box>
  )
  // Set Logo based on URL
  // if (url.includes('localhost')) {
  //   logoJSX = <Box className="">{orchestrateLogo}</Box>
  //   // logoJSX = <Box className="">{dataOreLogo}</Box>
  // } else if (url.includes('loadcall')) {
  //   logoJSX = <Box className="">{loadCallLogo}</Box>
  // } else if (url.includes('data_ore')) {
  //   logoJSX = <Box className="">{dataOreLogo}</Box>
  // } else if (url.includes('securedelivery')) {
  //   logoJSX = <Box className="">{secureDeliveryLogo}</Box>
  // } else {
  //   // logoJSX = <Box className="">{dataOreLogo}</Box>
  //   logoJSX = <Box className="">{orchestrateLogo}</Box>
  // }
  logoJSX = <Box className="">{orchestrateLogo}</Box>
  return logoJSX
}

export const returnNavBarBackgroundColorSx = (): string => {
  let navBarSX = ''
  // let url = getBaseURL()
  // Options
  // let loadCallBackground = 'linear-gradient(to bottom, #f9c727 0%, #f26b21 50%, #ec127a 100%)'
  // let secureDeliveryBackground = 'linear-gradient(to bottom, ' + themeVariables.navGradientTopColor + ', ' + themeVariables.navGradientBottomColor + ')'
  let dataOreBackground = 'linear-gradient(to bottom, ' + '#233140' + ', ' + '#1a2430' + ')'
  // // Set Color based on URL
  // if (url.includes('localhost')) {
  //   navBarSX = dataOreBackground
  // } else if (url.includes('loadcall')) {
  //   navBarSX = loadCallBackground
  // } else if (url.includes('securedelivery')) {
  //   navBarSX = secureDeliveryBackground
  // } else {
  //   navBarSX = dataOreBackground
  // }
  navBarSX = dataOreBackground
  return navBarSX
}

export const returnNavBarSelectedLinkColorSx = (index: number): string => {
  let navBarSX = ''
  // let url = getBaseURL()
  // Options
  // let loadCallColor = themeVariables.info_main
  // let secureDeliveryColor = themeVariables.warning_main
  // let dataOreColor = themeVariables.warning_main
  // Set Color based on URL
  // if (url.includes('localhost')) {
  //   navBarSX = orchestraGradient[index % orchestraGradient.length]
  // } else if (url.includes('loadcall')) {
  //   navBarSX = loadCallColor
  // } else if (url.includes('securedelivery')) {
  //   navBarSX = secureDeliveryColor
  // } else {
  //   navBarSX = orchestraGradient[index % orchestraGradient.length]
  // }
  navBarSX = orchestraGradient[index % orchestraGradient.length]
  return navBarSX
}

export const returnNavBarUnselectedLinkColorSx = (): string => {
  return themeVariables.white
}

export const returnNavBarAppBarBackgroundColorSx = (): string => {
  return themeVariables.background_default
}

export const returnNavBarAppBarTextColorSx = (): string => {
  return themeVariables.primary_dark
}

export const returnLoginLinkColor = (): TsType_MuiComponentColors => {
  let navBarSX: TsType_MuiComponentColors = 'primary'
  // let url = getBaseURL()
  // // Options
  // let loadCallColor: TsType_MuiComponentColors = 'primary'
  // let secureDeliveryColor: TsType_MuiComponentColors = 'error'
  // let dataOreColor: TsType_MuiComponentColors = 'info'

  // // Set Color based on URL
  // if (url.includes('localhost')) {
  //   navBarSX = dataOreColor
  // } else if (url.includes('loadcall')) {
  //   navBarSX = loadCallColor
  // } else if (url.includes('securedelivery')) {
  //   navBarSX = secureDeliveryColor
  // } else {
  //   navBarSX = dataOreColor
  // }
  return navBarSX
}

export const returnLoginButtonColor = (): string => {
  let navBarSX: string = themeVariables.primary_main
  // let url = getBaseURL()
  // // Options
  // let loadCallColor: string = themeVariables.black
  // let secureDeliveryColor: string = themeVariables.error_main
  // let dataOreColor: string = themeVariables.info_main

  // // Set Color based on URL
  // if (url.includes('localhost')) {
  //   navBarSX = dataOreColor
  // } else if (url.includes('loadcall')) {
  //   navBarSX = loadCallColor
  // } else if (url.includes('securedelivery')) {
  //   navBarSX = secureDeliveryColor
  // } else {
  //   navBarSX = dataOreColor
  // }
  return navBarSX
}
export const returnLoginHoverButtonColor = (): string => {
  let navBarSX: string = themeVariables.primary_light
  // let url = getBaseURL()
  // // Options
  // let loadCallColor: string = themeVariables.gray_900
  // let secureDeliveryColor: string = themeVariables.error_light
  // let dataOreColor: string = themeVariables.info_light

  // // Set Color based on URL
  // if (url.includes('localhost')) {
  //   navBarSX = dataOreColor
  // } else if (url.includes('loadcall')) {
  //   navBarSX = loadCallColor
  // } else if (url.includes('securedelivery')) {
  //   navBarSX = secureDeliveryColor
  // } else {
  //   navBarSX = dataOreColor
  // }
  return navBarSX
}

export const rJSX_DynamicApplicationLogoPngSrc = (color: string): string => {
  let logoSrc = ''
  // let url = getBaseURL()
  // Set Logo based on URL
  // if (url.includes('localhost')) {
  //   if (color === 'color') {
  //     logoSrc = logoOrchestrateSrc
  //   } else {
  //     logoSrc = logoOrchestrateSrc
  //   }
  // } else if (url.includes('loadcall')) {
  //   if (color === 'color') {
  //     logoSrc = logoLoadCallColorSrc
  //   } else {
  //     logoSrc = logoLoadCallBlackSrc
  //   }
  // } else if (url.includes('securedelivery')) {
  //   if (color === 'color') {
  //     logoSrc = logoSecureDeliveryColorSrc
  //   } else {
  //     logoSrc = logoSecureDeliveryBlackSrc
  //   }
  // } else {
  //   if (color === 'color') {
  //     logoSrc = logoOrchestrateSrc
  //   } else {
  //     logoSrc = logoOrchestrateSrc
  //   }
  // }
  logoSrc = logoOrchestrateSrc
  return logoSrc
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, CircularProgress, Typography } from '@mui/material/'
import { returnTableColumnsFromDatabaseFormDataFormat } from 'app/services/forms/form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useCSVReader } from 'react-papaparse'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClassFields_Collection } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_DataBuckets_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import { DatabaseRef_DataForSpecificDataBucket_DateRange_Query } from 'rfbp_aux/services/database_endpoints/clients/transactional_data/data'
import {
  DatabaseRef_Reconciliation_ComparisonFile_Collection,
  DatabaseRef_Reconciliation_ComparisonFile_Document,
  DatabaseRef_Reconciliation_Document,
} from 'rfbp_aux/services/database_endpoints/clients/transactional_data/reconciliations'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TsInterface_TableColumns, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['ReconciliationImpromptuViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

function isValidDate(dateString: string) {
  const parsedDate = Date.parse(dateString)
  return !isNaN(parsedDate) && !isNaN(new Date(parsedDate).getDate())
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_tableColumns_QRCodeData, us_setTableColumns_QRCodeData ] = 				useState<TsInterface_TableColumns>({})
  const [us_allQRCodeData, us_setAllQRCodeData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedFormFields, us_setAssociatedFormFields] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedQrCode, us_setAssociatedQrCode] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculatedComparisonData, us_setCalculatedComparisonData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_comaprisonFileData, us_setComaprisonFileData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadedComparisonData, us_setLoadedComparisonData] = useState<boolean>(false)
  const [us_parsingData, us_setParsingData] = useState<boolean>(false)
  const [us_rootReconciliation, us_setRootReconciliation] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableColumns_CFDMissingId, us_setTableColumns_CFDMissingId] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_CFDataTable, us_setTableColumns_CFDataTable] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_CFDuplicateData, us_setTableColumns_CFDuplicateData] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_CFMissingData, us_setTableColumns_CFMissingData] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_DiscrepancyData, us_setTableColumns_DiscrepancyData] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_SDDMissingId, us_setTableColumns_SDDMissingId] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_SDDataTable, us_setTableColumns_SDDataTable] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_SDDuplicateData, us_setTableColumns_SDDuplicateData] = useState<TsInterface_TableColumns>({})
  const [us_tableColumns_SDMissingData, us_setTableColumns_SDMissingData] = useState<TsInterface_TableColumns>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { CSVReader } = useCSVReader()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Reconciliation', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootReconciliation(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Reconciliation_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedQrCode(newData)
      ur_forceRerender()
    }
    if (us_rootReconciliation != null && us_rootReconciliation.associated_data_bucket_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_DataBuckets_Document(res_GCK.clientKey, us_rootReconciliation.associated_data_bucket_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_rootReconciliation, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedFormFields(newData)
      ur_forceRerender()
    }
    if (us_associatedQrCode != null && us_associatedQrCode.associated_class_key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ClassFields_Collection(res_GCK.clientKey, us_associatedQrCode.associated_class_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [us_associatedQrCode, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setComaprisonFileData(newData)
      ur_forceRerender()
    }
    if (itemKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Reconciliation_ComparisonFile_Collection(res_GCK.clientKey, itemKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllQRCodeData(newData)
      ur_forceRerender()
    }
    if (
      us_rootReconciliation != null &&
      us_rootReconciliation['associated_data_bucket_key'] != null &&
      us_rootReconciliation['filter_start_date'] != null &&
      us_rootReconciliation['filter_end_date'] != null
    ) {
      let startDate = new Date(us_rootReconciliation['filter_start_date'])
      let endDate = new Date(us_rootReconciliation['filter_end_date'])
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_DataForSpecificDataBucket_DateRange_Query(res_GCK.clientKey, us_rootReconciliation['associated_data_bucket_key'], startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootReconciliation])

  useEffect(() => {
    us_setTableColumns_SDDataTable(returnTableColumnsFromDatabaseFormDataFormat(us_associatedFormFields, {}, {}, { includeDataOriginationSource: false }))
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields])

  useEffect(() => {
    us_setTableColumns_CFDataTable(returnTableColumnsFromDatabaseFormDataFormat(us_associatedFormFields, {}, {}, { includeDataOriginationSource: false }))
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields])

  useEffect(() => {
    us_setTableColumns_SDMissingData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'recordsMissingFrom_SD_cellHighlights', {}),
        {},
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    us_setTableColumns_CFMissingData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'recordsMissingFrom_CF_cellHighlights', {}),
        {},
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    us_setTableColumns_SDDuplicateData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'duplicateUniqueIdentifiers_SD_cellHighlights', {}),
        {},
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    us_setTableColumns_CFDuplicateData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'duplicateUniqueIdentifiers_CF_cellHighlights', {}),
        {},
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    us_setTableColumns_SDDMissingId(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'recordsMissingUniqueIdentifier_SD_cellHighlights', {}),
        {},
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    us_setTableColumns_CFDMissingId(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'recordsMissingUniqueIdentifier_CF_cellHighlights', {}),
        {},
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    us_setTableColumns_DiscrepancyData(
      returnTableColumnsFromDatabaseFormDataFormat(
        us_associatedFormFields,
        getProp(us_calculatedComparisonData, 'recordsWithDifferentData_cellHighlights', {}),
        getProp(us_calculatedComparisonData, 'recordsWithDifferentData_cellAltData', {}),
        { includeDataOriginationSource: false },
      ),
    )
  }, [uc_setUserInterface_SnackbarDisplay, us_associatedFormFields, us_calculatedComparisonData])

  useEffect(() => {
    // Instantiate Variables
    let uniqueIdentifierProperty: string = ''
    let dataByUniqueIdentifier_SD: TsInterface_UnspecifiedObject = {}
    let dataByUniqueIdentifier_CF: TsInterface_UnspecifiedObject = {}
    let duplicateUniqueIdentifiers_SD: TsInterface_UnspecifiedObject = {}
    let duplicateUniqueIdentifiers_SD_cellHighlights: TsInterface_UnspecifiedObject = {}
    let duplicateUniqueIdentifiers_CF: TsInterface_UnspecifiedObject = {}
    let duplicateUniqueIdentifiers_CF_cellHighlights: TsInterface_UnspecifiedObject = {}
    let recordsMissingFrom_SD: TsInterface_UnspecifiedObject = {}
    let recordsMissingFrom_SD_cellHighlights: TsInterface_UnspecifiedObject = {}
    let recordsMissingFrom_CF: TsInterface_UnspecifiedObject = {}
    let recordsMissingFrom_CF_cellHighlights: TsInterface_UnspecifiedObject = {}
    let recordsMissingUniqueIdentifier_SD: TsInterface_UnspecifiedObject = {}
    let recordsMissingUniqueIdentifier_SD_cellHighlights: TsInterface_UnspecifiedObject = {}
    let recordsMissingUniqueIdentifier_CF: TsInterface_UnspecifiedObject = {}
    let recordsMissingUniqueIdentifier_CF_cellHighlights: TsInterface_UnspecifiedObject = {}
    let recordsWithDifferentData: TsInterface_UnspecifiedObject = {}
    let recordsWithDifferentData_simpleTableData: TsInterface_UnspecifiedObject = {}
    let recordsWithDifferentData_cellHighlights: TsInterface_UnspecifiedObject = {}
    let recordsWithDifferentData_cellAltData: TsInterface_UnspecifiedObject = {}
    // Find Unique Identifier Field
    for (let loopFieldKey in us_associatedFormFields) {
      let loopField = us_associatedFormFields[loopFieldKey]
      if (loopField != null && loopField.unique_identifier === true) {
        uniqueIdentifierProperty = loopField.key
      }
    }
    if (
      us_allQRCodeData != null &&
      objectToArray(us_allQRCodeData).length > 0 &&
      us_comaprisonFileData != null &&
      objectToArray(us_comaprisonFileData).length > 0
    ) {
      // Loop through Orchestrate Data
      for (let loopKey in us_allQRCodeData) {
        let loopRecord = us_allQRCodeData[loopKey]
        if (loopRecord != null && loopRecord['data'] != null && loopRecord['data'][uniqueIdentifierProperty] != null) {
          if (dataByUniqueIdentifier_SD[loopRecord['data'][uniqueIdentifierProperty]] == null) {
            dataByUniqueIdentifier_SD[loopRecord['data'][uniqueIdentifierProperty]] = loopRecord
          } else {
            duplicateUniqueIdentifiers_SD[loopRecord['data'][uniqueIdentifierProperty]] = loopRecord
            duplicateUniqueIdentifiers_SD_cellHighlights[loopRecord['key']] = { [uniqueIdentifierProperty]: true }
          }
        } else {
          recordsMissingUniqueIdentifier_SD[loopKey] = loopRecord
          if (loopRecord['key'] != null) {
            recordsMissingUniqueIdentifier_SD_cellHighlights[loopRecord['key']] = { [uniqueIdentifierProperty]: true }
          }
        }
      }
      // Loop through Comparison File Data
      for (let loopKey in us_comaprisonFileData) {
        let loopRecord = us_comaprisonFileData[loopKey]
        if (loopRecord != null && loopRecord['data'] != null && loopRecord['data'][uniqueIdentifierProperty] != null) {
          if (dataByUniqueIdentifier_CF[loopRecord['data'][uniqueIdentifierProperty]] == null) {
            dataByUniqueIdentifier_CF[loopRecord['data'][uniqueIdentifierProperty]] = loopRecord
          } else {
            duplicateUniqueIdentifiers_CF[loopRecord['data'][uniqueIdentifierProperty]] = loopRecord
            duplicateUniqueIdentifiers_CF_cellHighlights[loopRecord['key']] = { [uniqueIdentifierProperty]: true }
          }
        } else {
          recordsMissingUniqueIdentifier_CF[loopKey] = loopRecord
          if (loopRecord['key'] != null) {
            recordsMissingUniqueIdentifier_CF_cellHighlights[loopRecord['key']] = { [uniqueIdentifierProperty]: true }
          }
        }
      }
      // Find Missing Records
      for (let loopKey in dataByUniqueIdentifier_SD) {
        if (dataByUniqueIdentifier_CF[loopKey] == null) {
          recordsMissingFrom_CF[loopKey] = dataByUniqueIdentifier_SD[loopKey]
          if (dataByUniqueIdentifier_SD[loopKey] != null && dataByUniqueIdentifier_SD[loopKey]['key'] != null) {
            recordsMissingFrom_CF_cellHighlights[dataByUniqueIdentifier_SD[loopKey]['key']] = { [uniqueIdentifierProperty]: true }
          }
        } else {
          for (let loopFieldKey in us_associatedFormFields) {
            if (dataByUniqueIdentifier_SD[loopKey]['data'][loopFieldKey] !== dataByUniqueIdentifier_CF[loopKey]['data'][loopFieldKey]) {
              if (recordsWithDifferentData[loopKey] == null) {
                recordsWithDifferentData[loopKey] = {
                  sd: dataByUniqueIdentifier_SD[loopKey],
                  cf: dataByUniqueIdentifier_CF[loopKey],
                }
                recordsWithDifferentData_simpleTableData[loopKey] = dataByUniqueIdentifier_SD[loopKey]
              }
              if (recordsWithDifferentData_cellHighlights[dataByUniqueIdentifier_SD[loopKey]['key']] == null) {
                recordsWithDifferentData_cellHighlights[dataByUniqueIdentifier_SD[loopKey]['key']] = {}
              }
              recordsWithDifferentData_cellHighlights[dataByUniqueIdentifier_SD[loopKey]['key']][loopFieldKey] = true
              if (recordsWithDifferentData_cellAltData[dataByUniqueIdentifier_SD[loopKey]['key']] == null) {
                recordsWithDifferentData_cellAltData[dataByUniqueIdentifier_SD[loopKey]['key']] = {}
              }
              recordsWithDifferentData_cellAltData[dataByUniqueIdentifier_SD[loopKey]['key']][loopFieldKey] =
                dataByUniqueIdentifier_CF[loopKey]['data'][loopFieldKey]
            }
          }
        }
      }
      for (let loopKey in dataByUniqueIdentifier_CF) {
        if (dataByUniqueIdentifier_SD[loopKey] == null) {
          recordsMissingFrom_SD[loopKey] = dataByUniqueIdentifier_CF[loopKey]
          if (dataByUniqueIdentifier_CF[loopKey] != null && dataByUniqueIdentifier_CF[loopKey]['key'] != null) {
            recordsMissingFrom_SD_cellHighlights[dataByUniqueIdentifier_CF[loopKey]['key']] = { [uniqueIdentifierProperty]: true }
          }
        }
      }
    }
    let combinedComparisonData = {
      uniqueIdentifierProperty: uniqueIdentifierProperty,
      duplicateUniqueIdentifiers_SD: duplicateUniqueIdentifiers_SD,
      duplicateUniqueIdentifiers_SD_cellHighlights: duplicateUniqueIdentifiers_SD_cellHighlights,
      duplicateUniqueIdentifiers_CF: duplicateUniqueIdentifiers_CF,
      duplicateUniqueIdentifiers_CF_cellHighlights: duplicateUniqueIdentifiers_CF_cellHighlights,
      recordsMissingUniqueIdentifier_SD: recordsMissingUniqueIdentifier_SD,
      recordsMissingUniqueIdentifier_SD_cellHighlights: recordsMissingUniqueIdentifier_SD_cellHighlights,
      recordsMissingUniqueIdentifier_CF: recordsMissingUniqueIdentifier_CF,
      recordsMissingUniqueIdentifier_CF_cellHighlights: recordsMissingUniqueIdentifier_CF_cellHighlights,
      recordsMissingFrom_SD: recordsMissingFrom_SD,
      recordsMissingFrom_SD_cellHighlights: recordsMissingFrom_SD_cellHighlights,
      recordsMissingFrom_CF: recordsMissingFrom_CF,
      recordsMissingFrom_CF_cellHighlights: recordsMissingFrom_CF_cellHighlights,
      recordsWithDifferentData: recordsWithDifferentData,
      recordsWithDifferentData_simpleTableData: recordsWithDifferentData_simpleTableData,
      recordsWithDifferentData_cellHighlights: recordsWithDifferentData_cellHighlights,
      recordsWithDifferentData_cellAltData: recordsWithDifferentData_cellAltData,
    }
    us_setCalculatedComparisonData(combinedComparisonData)
    us_setLoadedComparisonData(true)
  }, [us_allQRCodeData, us_associatedFormFields, us_comaprisonFileData])

  // Functions
  const downloadImportTemplate = () => {
    let sortedFormFields = objectToArray(us_associatedFormFields).sort(dynamicSort('order', null))
    let downloadData = ['Submitted Date']
    for (let loopFieldIndex in sortedFormFields) {
      let loopField = sortedFormFields[loopFieldIndex]
      if (loopField.status === 'active') {
        if (loopField.form_type === 'checkboxes') {
          let sortedOrderArray = objectToArray(loopField.options).sort(dynamicSort('order', null))
          for (let loopOptionIndex in sortedOrderArray) {
            let loopOption = sortedOrderArray[loopOptionIndex]
            downloadData.push(loopField.name + ' - ' + loopOption.value)
          }
        } else if (
          loopField.form_type !== 'display'
          // TODO: photo, signature, etc
        ) {
          downloadData.push(loopField.name)
        }
      }
    }
    downloadCSV('Example Reconciliation Import', [downloadData])
  }

  const handleFileSelection = (data: any): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Instantiate variables
        let importSessionKey = new Date().getTime() + '_' + generateRandomString(6, null)
        let unformattedData: TsInterface_UnspecifiedObject[] = []
        let uploadedFileColumns: string[] = []
        us_setParsingData(true)
        // let dataMapping: string[] = []
        // Loop through and format data
        for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
          uploadedFileColumns[cellIndex] = data[0][cellIndex]
        }
        for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
          if (data != null && data[rowIndex] != null) {
            let loopRow = data[rowIndex]
            let unformattedDataRow: TsInterface_UnspecifiedObject = {}
            for (let cellIndex = 0; cellIndex < loopRow.length; cellIndex++) {
              if (loopRow[cellIndex] != null && uploadedFileColumns[cellIndex] != null && uploadedFileColumns[cellIndex] !== '') {
                unformattedDataRow[uploadedFileColumns[cellIndex]] = loopRow[cellIndex]
              }
            }
            unformattedData.push(unformattedDataRow)
          }
        }
        // Parse Into actual upload
        let formattedDataArray: TsInterface_UnspecifiedObject[] = []
        let loopCount = 0
        for (let loopRow in unformattedData) {
          let loopRowData = unformattedData[loopRow]
          if (loopRowData != null && loopRowData['Submitted Date'] != null && isValidDate(loopRowData['Submitted Date']) === true) {
            loopCount++
            let formattedRowData: TsInterface_UnspecifiedObject = {
              associated_import_session_key: importSessionKey,
              associated_data_origination_type: 'csv_import',
              associated_class_key: us_associatedQrCode.associated_class_key,
              associated_data_bucket_key: itemKey,
              confirmation_number: null,
              data: {},
              original_data: {},
              directory_associations: getProp(us_associatedQrCode, 'directory_associations', {}),
              key: importSessionKey + '_' + loopCount.toString(),
              metadata: {},
              status: 'active',
              timestamp_submitted: new Date(loopRowData['Submitted Date']),
              timestamp_primary_query: new Date(loopRowData['Submitted Date']),
            }
            for (let loopFieldIndex in us_associatedFormFields) {
              let loopField = us_associatedFormFields[loopFieldIndex]
              if (loopField.form_type === 'checkboxes' && loopField.key != null) {
                formattedRowData['data'][loopField.key] = {}
                formattedRowData['original_data'][loopField.key] = {}
                for (let loopOptionKey in loopField['options']) {
                  let loopOption = loopField['options'][loopOptionKey]
                  if (loopRowData[loopField.name + ' - ' + loopOption.value] != null && loopRowData[loopField.name + ' - ' + loopOption.value] !== '') {
                    formattedRowData['data'][loopField.key][loopOption.value] = loopRowData[loopField.name + ' - ' + loopOption.value]
                    formattedRowData['original_data'][loopField.key][loopOption.value] = loopRowData[loopField.name + ' - ' + loopOption.value]
                  }
                }
              } else if (
                loopField.form_type !== 'display'
                // TODO: photo, signature, etc
              ) {
                if (loopField != null && loopField.name != null && loopField.key != null && loopRowData[loopField.name] != null) {
                  formattedRowData['data'][loopField.key] = loopRowData[loopField.name]
                  formattedRowData['original_data'][loopField.key] = loopRowData[loopField.name]
                }
              }
            }
            formattedDataArray.push(formattedRowData)
          }
        }
        // Generate Batch Update
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          {
            type: 'setMerge',
            ref: DatabaseRef_Reconciliation_Document(res_GCK.clientKey, itemKey),
            data: {
              uploaded_comparison_file: true,
            },
          },
        ]
        for (let loopIndex in formattedDataArray) {
          let loopRow = formattedDataArray[loopIndex]
          if (loopRow != null && getProp(loopRow, 'key', null) != null) {
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_Reconciliation_ComparisonFile_Document(res_GCK.clientKey, itemKey, getProp(loopRow, 'key', null)),
              data: loopRow,
            })
          }
        }
        DatabaseStagedBatchUpdate(updateArray)
          .then((res_DSBU) => {
            us_setParsingData(false)
            uc_setUserInterface_AlertDialogDisplay({
              display: true,
              alert: {
                color: 'info',
                header: rLIB('Import Complete') as JSX.Element,
                icon: <Icon icon="badge-check" />,
                text: (
                  <>
                    {loopCount} {rLIB('Successful Imports')}
                  </>
                ),
              },
            })
          })
          .catch((rej_DSBU) => {
            us_setParsingData(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="outlined"
        className="tw-mr-2 tw-mb-2"
        onClick={() => {
          un_routerNavigation(ApplicationPages.ReconciliationListPage.url())
        }}
        startIcon={<Icon icon="angle-left" />}
      >
        {rLIB('Back to all Reconciliations')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ReconciliationDataTable = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null && us_tableColumns_SDDataTable != null && objectToArray(us_tableColumns_SDDataTable).length > 0) {
      // Table Settings
      let tableSettings: TsInterface_TableSettings = {
        paginated: false,
        size: 'small',
        sort_direction: 'asc',
        sort_property_default: 'timestamp_primary_query',
        sortable: false,
        collapsible_columns: true,
        sticky_header: true,
        sticky_table_height: 'calc(100vh - 140px)',
        alternate_row_color_hex: themeVariables.background_highlight,
        alternate_row_colors: true,
      }
      // JSX
      tabContentJSX = (
        <Box>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_SDDataTable}
              tableData={objectToArray(us_allQRCodeData)}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    }
    return tabContentJSX
  }

  const rJSX_UploadButtons = (): JSX.Element => {
    let buttonsJSX = (
      <Box className="tw-mt-2">
        <Button
          color="success"
          variant="contained"
          disableElevation
          className="tw-mr-2"
          onClick={() => {
            downloadImportTemplate()
          }}
          startIcon={<Icon icon="cloud-arrow-down" />}
        >
          {rLIB('Download Template')}
        </Button>
        <CSVReader
          // https://react-papaparse.js.org/docs#config
          // https://github.com/Bunlong/react-papaparse/blob/v4.0.0/examples/CSVReaderBasicUpload.tsx
          onUploadAccepted={(results: any) => {
            handleFileSelection(results.data)
          }}
          noDrag
        >
          {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
            <Button
              color="info"
              variant="contained"
              disableElevation
              disabled={us_parsingData === true}
              className="tw-mr-2"
              {...getRootProps()}
              startIcon={<Icon icon="cloud-arrow-up" />}
            >
              {rLIB('Upload CSV')}
            </Button>
          )}
        </CSVReader>
      </Box>
    )
    return buttonsJSX
  }

  const rJSX_ComparisonFileDataTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_rootReconciliation != null && us_rootReconciliation['uploaded_comparison_file'] === true) {
      // Table Settings
      let tableSettings: TsInterface_TableSettings = {
        paginated: false,
        size: 'small',
        sort_direction: 'asc',
        sort_property_default: 'timestamp_primary_query',
        sortable: false,
        collapsible_columns: true,
        sticky_header: true,
        sticky_table_height: 'calc(100vh - 140px)',
        alternate_row_color_hex: themeVariables.background_highlight,
        alternate_row_colors: true,
      }
      // JSX
      tableJSX = (
        <Box>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_CFDataTable}
              tableData={objectToArray(us_comaprisonFileData)}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = rJSX_UploadButtons()
    }
    return tableJSX
  }

  const rJSX_RecordsMissingFromReconciliation = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }

    // if(
    // 	copyOfTableColumns != null &&
    // 	us_calculatedComparisonData != null &&
    // 	us_calculatedComparisonData["uniqueIdentifierProperty"] != null &&
    // 	copyOfTableColumns[ us_calculatedComparisonData["uniqueIdentifierProperty"] ] != null &&
    // 	copyOfTableColumns[ us_calculatedComparisonData["uniqueIdentifierProperty"] ]["cell"] != null &&
    // 	copyOfTableColumns[ us_calculatedComparisonData["uniqueIdentifierProperty"] ]["cell"]["cell_css"] != null
    // ){
    // 	copyOfTableColumns[ us_calculatedComparisonData["uniqueIdentifierProperty"] ]["cell"]["cell_css"] = (
    // 		rowData: TsInterface_TableDataRow,
    // 		tableAdditionalData: TsInterface_TableAdditionalData
    // 	) => { return "tw-bg-amber-400" }
    // }

    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['recordsMissingFrom_SD'] != null &&
      objectToArray(us_calculatedComparisonData['recordsMissingFrom_SD']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['recordsMissingFrom_SD']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Records Missing From Orchestrate')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_SDMissingData}
              tableData={objectToArray(us_calculatedComparisonData['recordsMissingFrom_SD'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Records Missing From Orchestrate')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_RecordsMissingFromComparisonFile = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['recordsMissingFrom_CF'] != null &&
      objectToArray(us_calculatedComparisonData['recordsMissingFrom_CF']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['recordsMissingFrom_CF']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Records Missing From Comparison File')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_CFMissingData}
              tableData={objectToArray(us_calculatedComparisonData['recordsMissingFrom_CF'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Records Missing From Comparison File')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_DuplicateRecordsFromReconciliation = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['duplicateUniqueIdentifiers_SD'] != null &&
      objectToArray(us_calculatedComparisonData['duplicateUniqueIdentifiers_SD']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['duplicateUniqueIdentifiers_SD']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Duplicate Records In Orchestrate Data')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_SDDuplicateData}
              tableData={objectToArray(us_calculatedComparisonData['duplicateUniqueIdentifiers_SD'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Duplicate Records In Orchestrate Data')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_DuplicateRecordsFromComparisonFile = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['duplicateUniqueIdentifiers_CF'] != null &&
      objectToArray(us_calculatedComparisonData['duplicateUniqueIdentifiers_CF']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['duplicateUniqueIdentifiers_CF']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Duplicate Records In Comparison File Data')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_CFDuplicateData}
              tableData={objectToArray(us_calculatedComparisonData['duplicateUniqueIdentifiers_CF'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Duplicate Records In Comparison File Data')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_RecordsMissingUniqueIdentifierFromReconciliation = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['recordsMissingUniqueIdentifier_SD'] != null &&
      objectToArray(us_calculatedComparisonData['recordsMissingUniqueIdentifier_SD']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['recordsMissingUniqueIdentifier_SD']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Orchestrate Records Missing Unique Identifiers')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_SDDMissingId}
              tableData={objectToArray(us_calculatedComparisonData['recordsMissingUniqueIdentifier_SD'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Orchestrate Records Missing Unique Identifiers')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_RecordsMissingUniqueIdentifierFromComparisonFile = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['recordsMissingUniqueIdentifier_CF'] != null &&
      objectToArray(us_calculatedComparisonData['recordsMissingUniqueIdentifier_CF']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['recordsMissingUniqueIdentifier_CF']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Comparison File Records Missing Unique Identifiers')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_CFDMissingId}
              tableData={objectToArray(us_calculatedComparisonData['recordsMissingUniqueIdentifier_CF'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Comparison File Records Missing Unique Identifiers')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_RecordsWithDifferentData = (): JSX.Element => {
    let summaryJSX = <></>
    let tableSettings: TsInterface_TableSettings = {
      paginated: false,
      size: 'small',
      sort_direction: 'asc',
      sort_property_default: 'timestamp_primary_query',
      sortable: false,
      collapsible_columns: true,
      alternate_row_color_hex: themeVariables.background_highlight,
      alternate_row_colors: true,
    }
    if (
      us_calculatedComparisonData != null &&
      us_calculatedComparisonData['recordsWithDifferentData'] != null &&
      objectToArray(us_calculatedComparisonData['recordsWithDifferentData']).length > 0
    ) {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.error_main }}
            >
              {objectToArray(us_calculatedComparisonData['recordsWithDifferentData']).length}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Records with Conflicting Data')}
            </Box>
          </Typography>
          <Card className="">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={us_tableColumns_DiscrepancyData}
              tableData={objectToArray(us_calculatedComparisonData['recordsWithDifferentData_simpleTableData'])}
              tableSettings={tableSettings}
            />
          </Card>
        </Box>
      )
    } else {
      summaryJSX = (
        <Box className="tw-my-2">
          <Typography variant="h6">
            <Icon
              icon="badge-check"
              className="tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            />
            <Box
              component="span"
              className="tw-font-bold tw-mr-1"
              sx={{ color: themeVariables.success_main }}
            >
              {0}
            </Box>
            <Box
              component="span"
              className="tw-mr-1"
            >
              {rLIB('Records with Conflicting Data')}
            </Box>
          </Typography>
        </Box>
      )
    }
    return summaryJSX
  }

  const rJSX_FileReconciliationSummary = (): JSX.Element => {
    let summaryJSX = <></>
    if (us_rootReconciliation != null && us_rootReconciliation['uploaded_comparison_file'] === true) {
      if (us_loadedComparisonData === false) {
        summaryJSX = (
          <Box className="tw-text-center tw-py-4">
            <CircularProgress />
          </Box>
        )
      } else {
        summaryJSX = (
          <Box>
            {rJSX_RecordsMissingFromReconciliation()}
            {rJSX_RecordsMissingFromComparisonFile()}
            {rJSX_DuplicateRecordsFromReconciliation()}
            {rJSX_DuplicateRecordsFromComparisonFile()}
            {rJSX_RecordsMissingUniqueIdentifierFromReconciliation()}
            {rJSX_RecordsMissingUniqueIdentifierFromComparisonFile()}
            {rJSX_RecordsWithDifferentData()}
          </Box>
        )
      }
    } else {
      summaryJSX = rJSX_UploadButtons()
    }
    return summaryJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_rootReconciliation != null && us_rootReconciliation.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{rLIB('Reconciliation')}:</Box>
          <Box className="tw-inline-block tw-opacity-50">{us_rootReconciliation.name}</Box>
          {/* <Box className="tw-inline-block tw-font-light tw-opacity-50 tw-mr-1">({ returnFormattedDate( returnDateFromUnknownDateFormat( getProp( us_rootReconciliation, "filter_start_date", new Date() )), "D MMM YYYY h:mm a" ) } - </Box> */}
          {/* <Box className="tw-inline-block tw-font-light tw-opacity-50">{ returnFormattedDate( returnDateFromUnknownDateFormat( getProp( us_rootReconciliation, "filter_end_date", new Date() )), "D MMM YYYY h:mm a" ) })</Box> */}
        </Box>
      )
    } else {
      pageHeader = rLIB('Reconciliation') as JSX.Element
    }
    return pageHeader
  }

  const rJSX_ReconciliationView = (): JSX.Element => {
    let viewJSX = <></>
    viewJSX = (
      <Box>
        <TabsUrl
          tabs={[
            {
              tabHeader: rLIB('File Reconciliation'),
              tabUrlKey: 'File_Reconciliation',
              tabContent: <Box>{rJSX_FileReconciliationSummary()}</Box>,
            },
            {
              tabHeader: rLIB('Orchestrate Data'),
              tabUrlKey: 'Orchestrate_Data',
              tabContent: <Box>{rJSX_ReconciliationDataTable()}</Box>,
            },
            {
              tabHeader: rLIB('Uploaded Comparison File'),
              tabUrlKey: 'Uploaded_Comparison_File',
              tabContent: <Box>{rJSX_ComparisonFileDataTable()}</Box>,
            },
          ]}
          tabsSettings={{
            baseUrl: ApplicationPages.ReconciliationImpromptuViewPage.url(itemKey),
            tabQueryParam: 'tab',
            overridePageTitle: true,
            basePageTitle: rLIB('Reconciliation', false) as string,
          }}
        />
      </Box>
    )
    return viewJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            {rJSX_ReconciliationView()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*
		Unique Identifier Field (set on form?)

		NEW TAB - Reconciliation

			- highlight if missing uploaded comparison row
			- highlight if missing orchestrate data row
			- highlight if value is different in a cell

			Need a new function to generate table columns that allows for highlights, icons, etc

			Edit Fields by double clicking on cells - update in function that generates table columns

			IF there is a difference between data and original_data - display both values in table somehow (hover for "original" value)

	*/

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			QR Code creation
				- create global qr code with server function
				- return key
				- create local qr code with key

			QR Code management
				- associations are like tags, can have multiple of any directory category
					- associated_location_keys: { locationKey: true }
					- associated_location_names: { locationKey: locationName }
					- mostly used for filtering

			Maybe change directory locations to be associations and you can create an association "type" (location, purchaser, producer etc)
			Then on QR code you choose the association type and then the association key from the list?
			Noit sure if there is a need to have different types of associations, maybe just one type of association is fine

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material/'
import { cloudFunctionManageQRCodeRequest } from 'app/services/external_requests/external_requests'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveClasses_Query } from 'rfbp_aux/services/database_endpoints/clients/architecture/classes'
import { DatabaseRef_DataBuckets_Collection, DatabaseRef_DataBuckets_Document } from 'rfbp_aux/services/database_endpoints/clients/architecture/data_buckets'
import {
  DatabaseRef_DataBucketGroups_Collection,
  DatabaseRef_DataBucketGroups_Document,
} from 'rfbp_aux/services/database_endpoints/clients/architecture/data_bucket_groups'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_PromptDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DataBucketsListPage']['key']

// Table
const tableSettings_DataBucket: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_DataBucket: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.un_routerNavigation(ApplicationPages.DataBucketsViewPage.url(rowData.key as string))
        }
      },
    },
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Rename')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'success',
              confirm_text: rLIB('Save'),
              default_value: null,
              header: rLIB('Rename Data Bucket'),
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: rLIB('Data Bucket Name'),
              input_type: 'text',
              text: <></>,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        name: promptValue,
                      }
                      DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Data Bucket'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this Data Bucket?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_DataBuckets_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Data Bucket Name'), 'name'),
  associated_class_name: TableCellBasic('associated_class_name', rLIB('Form'), 'associated_class_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Date Created'), 'timestamp_created', ' D MMM YYYY - h:mm a', true),
}

const tableSettings_DataBucketGroups: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  alternate_row_color_hex: themeVariables.background_highlight,
  alternate_row_colors: true,
}

const tableColumns_DataBucketGroups: TsInterface_TableColumns = {
  manage: TableCellManage({
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Rename')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'success',
              confirm_text: rLIB('Save'),
              default_value: rowData.name,
              header: rLIB('Rename Data Bucket Group'),
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: rLIB('Data Bucket Group Name'),
              input_type: 'text',
              text: <></>,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        name: promptValue,
                      }
                      DatabaseSetMergeDocument(DatabaseRef_DataBucketGroups_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Data Bucket Group'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this Data Bucket Group?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_DataBucketGroups_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Group Name'), 'name'),
  associated_data_bucket_keys: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Data Buckets')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let dataBucketNamesArray = []
        for (let loopKey in getProp(rowData, 'associated_data_bucket_keys', {})) {
          if (getProp(rowData, 'associated_data_bucket_keys', {})[loopKey] != null) {
            dataBucketNamesArray.push(getProp(rowData, 'associated_data_bucket_keys', {})[loopKey])
          }
        }
        cellJSX = <Box>{dataBucketNamesArray.sort(dynamicSort('name', 'asc')).join(', ')}</Box>

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const [us_activeClasses, us_setActiveClasses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedFilterClass, us_setSelectedFilterClass] = useState<string>('ALL')
  const [us_applyingFilter, us_setApplyingFilter] = useState<boolean>(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void

  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Data Buckets', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let activeTransactionalClasses: TsInterface_UnspecifiedObject = {}
      for (let loopClassKey in newData) {
        let loopClass = newData[loopClassKey]
        if (loopClass != null && loopClass.class_type === 'transactional_data') {
          activeTransactionalClasses[loopClassKey] = loopClass
        }
      }
      us_setActiveClasses(activeTransactionalClasses)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveClasses_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const createDataBucket = (): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: rLIB('Create New Data Bucket') as JSX.Element,
        default_value: null,
        header: rLIB('New Data Bucket'),
        icon: (
          <Icon
            icon="pen-to-square"
            type="solid"
          />
        ),
        input_label: rLIB('Data Bucket Name') as JSX.Element,
        input_type: 'text',
        text: <></>,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                cloudFunctionManageQRCodeRequest({
                  function: 'createGlobalQRCode',
                  client_key: res_GCK.clientKey,
                  name: promptValue,
                })
                  .then((res_CFMQRCR: any) => {
                    un_routerNavigation(ApplicationPages.DataBucketsViewPage.url(res_CFMQRCR.key))
                    resolve(res_CFMQRCR)
                  })
                  .catch((rej_CFMQRCR) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMQRCR.error })
                    reject(rej_CFMQRCR)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const createDataBucketGroup = (): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: rLIB('Create New Data Bucket Group') as JSX.Element,
        default_value: null,
        header: rLIB('New Data Bucket Group'),
        icon: (
          <Icon
            icon="pen-to-square"
            type="solid"
          />
        ),
        input_label: rLIB('Data Bucket Group Name') as JSX.Element,
        input_type: 'text',
        text: <></>,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = {
                  name: promptValue,
                  status: 'active',
                }
                DatabaseAddDocument(DatabaseRef_DataBucketGroups_Collection(res_GCK.clientKey), updateObject, true)
                  .then((res_DAD) => {
                    resolve(res_DAD)
                  })
                  .catch((rej_DAD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                    reject(rej_DAD)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  }

  const tableDatabaseEndpoint_DataBucket = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    if (us_selectedFilterClass != null && us_selectedFilterClass !== 'ALL') {
      queryOperatorsArray.push({ prop: 'associated_class_key', comparator: '==', value: us_selectedFilterClass })
    }
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_DataBuckets_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_DataBucketGroups = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_DataBucketGroups_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_NewDataBucketButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        className="tw-mb-2"
        onClick={() => {
          createDataBucket()
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Data Bucket')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_NewDataBucketGroupButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        className="tw-mb-2"
        onClick={() => {
          createDataBucketGroup()
        }}
        startIcon={<Icon icon="circle-plus" />}
      >
        {rLIB('New Data Bucket Group')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DataBucketsTab = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_applyingFilter === true) {
        tableJSX = <></>
      } else {
        tableJSX = (
          <Box>
            <Card className="">
              <TableDatabase
                tableAdditionalData={{}}
                tableColumns={tableColumns_DataBucket}
                tableDatabaseEndpoint={tableDatabaseEndpoint_DataBucket}
                tableSettings={tableSettings_DataBucket}
              />
            </Card>
          </Box>
        )
      }
    } else {
      tableJSX = <Box></Box>
    }
    return tableJSX
  }

  const rJSX_DataBucketGroupsTab = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_DataBucketGroups}
              tableDatabaseEndpoint={tableDatabaseEndpoint_DataBucketGroups}
              tableSettings={tableSettings_DataBucketGroups}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_FilterClassDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input tw-mb-2"
        sx={{ minWidth: '130px' }}
      >
        <InputLabel>{rLIB('Filter')}</InputLabel>
        <Select
          autoWidth={true}
          label={rLIB('Filter')}
          onChange={(event, value) => {
            us_setSelectedFilterClass(event.target.value)
            us_setApplyingFilter(true)
            setTimeout(() => {
              ur_forceRerender()
              us_setApplyingFilter(false)
            }, 1)
          }}
          value={us_selectedFilterClass}
        >
          <MenuItem value="ALL">{rLIB('All')}</MenuItem>
          {objectToArray(us_activeClasses).map((option: TsInterface_UnspecifiedObject) => (
            <MenuItem
              key={option['key']}
              value={option['key']}
              disabled={option['disabled']}
            >
              {option['name']}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Data Buckets')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Data_Buckets',
                  tabHeader: rLIB('Data Buckets'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DataBucketsTab(),
                  tabButtons: [
                    { fullJSX: rJSX_NewDataBucketButton(), minJSX: rJSX_NewDataBucketButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterClassDropdown(), minJSX: rJSX_FilterClassDropdown(), sizeCutoff: 1 },
                  ],
                },
                {
                  tabUrlKey: 'Groups',
                  tabHeader: rLIB('Groups'),
                  tabOnChange: () => {},
                  tabContent: rJSX_DataBucketGroupsTab(),
                  tabButtons: [{ fullJSX: rJSX_NewDataBucketGroupButton(), minJSX: rJSX_NewDataBucketGroupButton(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.DataBucketsListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Data Buckets', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Wrapper that makes it simple to use custom icons

			<Icon icon="IconLogo" fontSize="large" />

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Tooltip } from '@mui/material'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import './font_awesome/font_awesome.css'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  className?: string
  icon: string
  onClick?: () => void
  size?: '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x'
  sx?: TsInterface_UnspecifiedObject
  tooltip?: string | JSX.Element
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  type?: 'solid' | 'sharp' | 'regular' | 'light' | 'thin' | 'duotone'
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// fa-solid
// fa-regular
// fa-light
// fa-thin
// fa-duotone

// fa-sharp (fa-solid)

// fa-2xs
// fa-xs
// fa-sm
// fa-lg
// fa-xl
// fa-2xl

// fa-1x
// fa-2x
// ..
// fa-10x

///////////////////////////////
// Component
///////////////////////////////

export const Icon = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_className: TsInterface_ComponentProps['className'] = getProp(props, 'className', null)
  let pr_icon: TsInterface_ComponentProps['icon'] = getProp(props, 'icon', null)
  let pr_size: TsInterface_ComponentProps['size'] = getProp(props, 'size', null)
  let pr_sx: TsInterface_ComponentProps['sx'] = getProp(props, 'sx', {})
  let pr_tooltip: TsInterface_ComponentProps['tooltip'] = getProp(props, 'tooltip', '')
  let pr_tooltipPlacement: TsInterface_ComponentProps['tooltipPlacement'] = getProp(props, 'tooltipPlacement', 'bottom')
  let pr_oncClick: TsInterface_ComponentProps['onClick'] = getProp(props, 'onClick', () => {})
  let pr_type: TsInterface_ComponentProps['type'] = getProp(props, 'type', 'solid')

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const generateIconClassName = (): string => {
    let iconClassName = ''
    if (pr_icon != null && pr_icon !== '') {
      iconClassName += 'fa-' + pr_icon
    }
    if (iconClassName.length > 0) {
      iconClassName += ' '
    }
    if (pr_size != null) {
      iconClassName += 'fa-' + pr_size
    }
    if (iconClassName.length > 0) {
      iconClassName += ' '
    }
    if (pr_type === 'sharp') {
      iconClassName += 'fa-solid fa-sharp'
    } else {
      iconClassName += 'fa-' + pr_type
    }
    if (iconClassName.length > 0) {
      iconClassName += ' '
    }
    if (pr_className != null) {
      iconClassName += pr_className
    }
    return iconClassName
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX: JSX.Element = <></>
    if (pr_tooltip != null && pr_tooltip !== '') {
      componentJSX = (
        <Box
          className="tw-contents"
          sx={pr_sx}
          component={'span'}
          onClick={() => {
            if (pr_oncClick != null) {
              pr_oncClick()
            }
          }}
        >
          <Tooltip
            title={pr_tooltip}
            placement={pr_tooltipPlacement}
          >
            <i className={generateIconClassName()}></i>
          </Tooltip>
        </Box>
      )
    } else {
      componentJSX = (
        <Box
          className="tw-contents"
          sx={pr_sx}
          component={'span'}
          onClick={() => {
            if (pr_oncClick != null) {
              pr_oncClick()
            }
          }}
        >
          <i className={generateIconClassName()}></i>
        </Box>
      )
    }
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import React, { useEffect } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { cloudFunctionManageRequest } from 'rfbp_aux/services/cloud_functions'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { generateHtmlForEmailFromTemplateObject, TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperTestLabPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // let items2 = 						getProp(props, "items2", {})

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 			useState< TsInterface_UnspecifiedObject >( {} )
  // const ur_forceRerender = 				useReducer( () => ( {} ), {} )[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {}, [])

  // Functions
  const processFileContent = (content: string) => {
    const lines = content.split('\n')
    let isTable = false
    let table: any = []
    let cutoffs: any = []
    let headersSet = false
    let currentState = ''
    lines.forEach((line: any, index: any) => {
      if (line.trim().startsWith('------------')) {
        if (isTable) {
          table = []
        }
        isTable = true
        const headerLineIndex = index - 1
        const headerLine = lines[headerLineIndex]
        cutoffs = getHeaderCutoffs(line)
        if (!headersSet) {
          const headers = extractHeaders(headerLine, cutoffs)
          headers.unshift('State')
          table.push(headers)
          headersSet = true
        }
      } else if (isTable && line.startsWith('Price: NET')) {
        isTable = false
      } else if (isTable) {
        if (isStateLine(line)) {
          currentState = line.trim().slice(0, -1) // Remove the colon
        } else if (isValidLine(line)) {
          const values = extractValues(line, cutoffs)
          values.unshift(currentState || '') // Add the current state to the beginning
          table.push(values)
        }
      }
    })
    if (table.length > 0) {
      console.log('FINAL Table:')
      console.log(table)
    }
  }

  const getHeaderCutoffs = (line: any) => {
    const cutoffs = []
    let isStart = true
    for (let i = 0; i < line.length; i++) {
      if (line[i] === '-' && (isStart || line[i - 1] === ' ')) {
        cutoffs.push(i)
        isStart = false
      }
    }
    return cutoffs
  }

  const extractHeaders = (headerLine: any, cutoffs: any) => {
    const headers = []
    for (let i = 0; i < cutoffs.length; i++) {
      const start = cutoffs[i]
      const end = cutoffs[i + 1] || headerLine.length
      const header = headerLine.slice(start, end).trim()
      headers.push(header)
    }
    return headers
  }

  const isStateLine = (line: any) => {
    const trimmedLine = line.trim()
    return trimmedLine.length < 30 && trimmedLine.endsWith(':')
  }

  const isValidLine = (line: any) => {
    if (line.trim().length <= 1) return false
    return !isStateLine(line)
  }

  const extractValues = (line: any, cutoffs: any) => {
    const values = []
    for (let i = 0; i < cutoffs.length; i++) {
      const start = cutoffs[i]
      const end = cutoffs[i + 1] || line.length
      let value = line.slice(start, end).trim()
      if (/^\d+\.\d+\*$/.test(value)) {
        value = value.slice(0, -1)
      }
      if (value.endsWith(' E') || value.endsWith(' A')) {
        value = value.slice(0, -3).trim()
      }
      values.push(value)
    }
    return values
  }

  const uploadFiles = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        const file = event.target.files[0]
        if (file && file.type === 'text/plain') {
          const reader = new FileReader()
          reader.onload = (e) => {
            let content: string = e.target?.result as string
            processFileContent(content)
            // Process the file content and turn it into CSV
            resolve({ success: true, data: content })
          }
          reader.readAsText(file)
        } else {
          console.error('Please upload a .txt file.')
          reject({
            success: false,
            error: {
              message: 'Failed to upload file',
              details: 'Please upload a .txt file.',
              code: 'ER-D-TFS-FOS-01',
            },
          })
        }
      } else {
        reject({
          success: false,
          error: {
            message: 'Failed to upload file',
            details: 'Invalid file',
            code: 'ER-D-TFS-FOS-02',
          },
        })
      }
    })
  }

  // JSX Generation
  const rJSX_UploadButton = (): JSX.Element => {
    let uploadButtonJSX = (
      <FileUploadButton
        multiple={false}
        button={{
          text: <>{'Upload File'}</>,
          icon: (
            <Icon
              icon="cloud-arrow-up"
              className="tw-mr-2"
              sx={{ fontSize: '20px' }}
            ></Icon>
          ),
          color: 'success',
          className: 'tw-mr-2',
          variant: 'contained',
          disabled: false,
        }}
        accept=".txt"
        onChange={uploadFiles}
        additionalFileUploadParams={{}}
      />
    )
    return uploadButtonJSX
  }

  const rJSX_EmailPreview = (): JSX.Element => {
    let templateObject: TsInterface_EmailTemplateObject = {
      BODY: {
        element_type: 'div',
        style: {
          'background': '#f1f1f1',
          'padding': '8px',
          'border-bottom-left-radius': '8px',
          'border-bottom-right-radius': '8px',
        },
        contents: {
          CONTAINER: {
            element_type: 'div',
            style: {
              'width': '100%',
              'display': 'block',
              'text-align': 'center',
              'margin-top': '16px',
            },
            contents: {
              CARD: {
                element_type: 'div',
                style: {
                  'max-width': '500px',
                  'width': '100%',
                  'display': 'inline-block',
                  'padding-right': '4px',
                  'box-sizing': 'border-box',
                  'margin': 'auto',
                },
                contents: {
                  HEADER: {
                    element_type: 'div',
                    style: {
                      'background': 'linear-gradient(#1a2430, #233140)',
                      'font-size': '20px',
                      'width': '100%',
                      'min-height': '50px',
                      'padding-top': '16px',
                      'padding-bottom': '8px',
                      'text-align': 'center',
                      'border-top-left-radius': '8px',
                      'border-top-right-radius': '8px',
                    },
                    contents: {
                      LOGO: {
                        element_type: 'img',
                        src_type: 'static',
                        src: 'https://firebasestorage.googleapis.com/v0/b/data-ore-app.appspot.com/o/global%2Flogo_orchestrate.png?alt=media&token=0ee00e7c-cca0-4c98-b3da-080d91d07680',
                        alt: 'logo.png',
                        width: '350px',
                        height: 'auto',
                      },
                    },
                  },
                  TEXT_CONTAINER: {
                    element_type: 'div',
                    style: {
                      'background-color': '#FFFFFF',
                      'border-radius': '5px',
                      'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                      'color': 'rgba(0, 0, 0, 0.87)',
                      'display': 'block',
                      'margin-bottom': '16px',
                      'padding': '12px',
                      'text-align': 'center',
                    },
                    contents: {
                      INTRO: {
                        element_type: 'p',
                        text_type: 'static',
                        text: "You've been assigned a new Orchestrate task",
                        style: {
                          'display': 'block',
                          'margin': '0px',
                          'font-size': '18px',
                        },
                      },
                      DIVIDER1: {
                        element_type: 'div',
                        style: {
                          'height': '1px',
                          'background-color': '#DDD',
                          'margin': '8px 0px',
                        },
                      },
                      ID_NUMBER_CONTAINER: {
                        element_type: 'div',
                        style: {
                          'text-align': 'center',
                          'margin-top': '0px',
                        },
                        contents: {
                          INTRO: {
                            element_type: 'p',
                            text_type: 'static',
                            text: 'Task',
                            style: {
                              'font-size': '24px',
                              'font-weight': 'bold',
                              'margin-bottom': '4px',
                              'color': 'rgba(0, 0, 0, 0.87)',
                              'display': 'inline-block',
                              'margin-right': '6px',
                              'margin-top': '0px',
                            },
                          },
                          ID_NUMBER: {
                            element_type: 'p',
                            text_type: 'dynamic',
                            data_object_key: 'data',
                            data_object_prop_key: 'id_number',
                            style: {
                              'font-size': '24px',
                              'font-weight': 'bold',
                              'margin-bottom': '4px',
                              'color': 'rgba(0, 0, 0, 0.87)',
                              'display': 'inline-block',
                              'margin-top': '0px',
                            },
                            // prefix_text: 'Hi ',
                            // suffix_text: ',',
                          },
                        },
                      },
                      // DIVIDER2: {
                      //   element_type: 'div',
                      //   style: {
                      //     'height': '1px',
                      //     'background-color': '#DDD',
                      //     'margin': '8px 0px',
                      //   },
                      // },

                      TEXT_CONTAINER: {
                        element_type: 'div',
                        style: {
                          'text-align': 'center',
                          'margin-top': '4px',
                        },
                        contents: {
                          LINK: {
                            element_type: 'a',
                            href_type: 'dynamic',
                            href_url_data_object_key: 'data',
                            href_url_data_object_prop_key: 'url',
                            text: 'Click here to get started',
                            text_type: 'static',
                            style: {
                              'display': 'inline-block',
                              'padding': '4px 8px',
                              'border-radius': '4px',
                              'text-decoration': 'none',
                              'cursor': 'pointer',
                              'color': '#FFFFFF',
                              'background-color': '#D41B29',
                              'margin-top': '16px',
                              'font-weight': 'bold',
                              'margin': '0px',
                              'font-size': '18px',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
    let dataObjects: TsInterface_UnspecifiedObject = {
      data: {
        id_number: '1000010',
        url: 'http://localhost:3000/tasks/jmorrell1/d4jlgvQNSTKf7D4x3E8s/566b84a9-142e-41ce-b1e8-2c2c5f049bbf',
      },
    }

    return (
      <Box>
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{ __html: generateHtmlForEmailFromTemplateObject(templateObject, dataObjects) }}
        />

        <Button
          onClick={() => {
            const htmlContent = generateHtmlForEmailFromTemplateObject(templateObject, dataObjects)
            cloudFunctionManageRequest('sendEmail', {
              function: 'sendHtmlEmail',
              toArray: ['john.morrell.if@gmail.com'], // Email sent only to you for testing
              subject: 'Test Email',
              html: htmlContent,
              ccArray: [],
              bccArray: [],
            })
              .then((res) => {
                console.log('Email sent successfully:', res)
              })
              .catch((rej) => {
                console.error('Error sending email:', rej)
              })
          }}
        >
          SEND EMAIL
        </Button>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Test Lab')}
        pageKey={pageKey}
        content={<Box>{rJSX_EmailPreview()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

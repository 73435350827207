///////////////////////////////
// Imports
///////////////////////////////

import { doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ClientUser_Document = (clientKey: string, apiLogKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'settings', 'users', 'main', apiLogKey)
}

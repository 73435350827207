///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Stack } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const rJSX_UnauthenticatedPageFooter = (un_routerNavigation: any): JSX.Element => {
  let footerJSX = <></>
  footerJSX = (
    <Box>
      <Box sx={{ height: '32px' }}></Box>
      <Box sx={{ marginTop: 'auto', position: 'fixed', bottom: 0, width: '100%', background: themeVariables.background_default }}>
        <Divider />
        <Stack
          direction="row"
          className="tw-justify-center"
          spacing={2}
        >
          <Box className="tw-text-center">
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-cursor-pointer tw-m-auto"
              onClick={() => {
                un_routerNavigation(ApplicationPages.UnauthenticatedPrivacyPolicyPage.url())
              }}
            >
              {rLIB('Privacy Policy')}
            </Button>
          </Box>
          <Box className="tw-text-center">
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-cursor-pointer tw-m-auto"
              onClick={() => {
                un_routerNavigation(ApplicationPages.UnauthenticatedTermsOfServicePage.url())
              }}
            >
              {rLIB('Terms of Service')}
            </Button>
          </Box>
        </Stack>
        <Stack
          direction="row"
          className="tw-justify-center"
        >
          <Box
            className="tw-mt-1 tw-mb-2  tw-opacity-30 tw-text-center"
            sx={{ fontSize: '13px' }}
          >
            {rLIB('Copyright')} © {new Date().getFullYear()} {rLIB('All Rights Reserved')}.
          </Box>
        </Stack>
      </Box>
    </Box>
  )
  return footerJSX
}

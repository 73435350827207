///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Classes_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main')
}

export const DatabaseRef_Class_Document = (clientKey: string, formKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey)
}

export const DatabaseRef_ClassFields_Collection = (clientKey: string, formKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'fields')
}

export const DatabaseRef_ClassFields_Document = (clientKey: string, formKey: string, fieldKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'fields', fieldKey)
}

export const DatabaseRef_ClassCalculatedFields_Collection = (clientKey: string, formKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'calculated_fields')
}

export const DatabaseRef_ClassCalculatedFields_Document = (clientKey: string, formKey: string, fieldKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'calculated_fields', fieldKey)
}

export const DatabaseRef_ActiveClasses_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ActivePersistentClasses_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'class_type', comparator: '==', value: 'persistent_data' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ActiveTransactionalClasses_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'class_type', comparator: '==', value: 'transactional_data' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ClassCustomForms_Collection = (clientKey: string, formKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'custom_forms')
}

export const DatabaseRef_ClassCustomForms_Document = (clientKey: string, formKey: string, fieldKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'custom_forms', fieldKey)
}

export const DatabaseRef_ActiveClassCustomForms_Query = (clientKey: string, formKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'classes', 'main', formKey, 'custom_forms'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

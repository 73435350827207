import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_DataBucketGroups_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_bucket_groups', 'main')
}

export const DatabaseRef_DataBucketGroups_Document = (clientKey: string, groupKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'architecture', 'data_bucket_groups', 'main', groupKey)
}

export const DatabaseRef_ActiveDataBucketGroups_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'architecture', 'data_bucket_groups', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

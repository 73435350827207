//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to generate Prompt Dialogs

		TODO:
			[ ] Typescript - 2 instances of any - function copied from MUI Docs
			[ ] Typescript - 1 instance of unknown
	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material/'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  TsInterface_PromptDialogObject,
  TsInterface_UserInterface_PromptDialogInternalState,
  TsType_UserInterface_AllDialogsDismissCallback,
} from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsType_MuiAppBarColors } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  )
})

///////////////////////////////
// Component
///////////////////////////////

export const PromptDialog = (
  prompt: TsInterface_PromptDialogObject,
  dismissDialog: TsType_UserInterface_AllDialogsDismissCallback,
  PromptDialogState: TsInterface_UserInterface_PromptDialogInternalState,
  setPromptDialogState: React.Dispatch<React.SetStateAction<TsInterface_UserInterface_PromptDialogInternalState>>,
): JSX.Element => {
  // Props
  let pr_confirmText: TsInterface_PromptDialogObject['confirm_text'] = getProp(prompt, 'confirm_text', rLIB('Confirm'))
  let pr_inputLabel: TsInterface_PromptDialogObject['input_label'] = getProp(prompt, 'input_label', rLIB('Text'))
  let pr_inputType: TsInterface_PromptDialogObject['input_type'] = getProp(prompt, 'input_type', 'text')
  let pr_promptColor: TsInterface_PromptDialogObject['color'] = getProp(prompt, 'color', 'success')
  let pr_promptHeader: TsInterface_PromptDialogObject['header'] = getProp(prompt, 'header', rLIB('Prompt'))
  let pr_promptIcon: TsInterface_PromptDialogObject['icon'] = getProp(prompt, 'icon', <Icon icon="input-text" />)
  let pr_promptInputValue: TsInterface_PromptDialogObject['default_value'] = getProp(prompt, 'default_value', null)
  let pr_promptText: TsInterface_PromptDialogObject['text'] = getProp(prompt, 'text', rLIB('Please Respond'))
  let pr_submitCallback: TsInterface_PromptDialogObject['submit_callback'] = getProp(
    prompt,
    'submit_callback',
    (promptInputValue: TsInterface_PromptDialogObject['default_value']) => {
      return new Promise((resolve, reject) => {
        resolve({ success: true })
      })
    },
  )

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const handleClose = (): void => {
    dismissDialog()
  }

  const handleSubmit = (): void => {
    setPromptDialogState({ submitting: true })
    pr_submitCallback!(pr_promptInputValue)
      .then((res_SC: any) => {
        setPromptDialogState({ submitting: false })
        if (res_SC == null || res_SC.close_dialog !== false) {
          dismissDialog()
        }
      })
      .catch((rej_SC: any) => {
        setPromptDialogState({ submitting: false })
        if (rej_SC == null || rej_SC.close_dialog !== false) {
          dismissDialog()
        }
      })
  }

  const formInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    pr_promptInputValue = event.target.value
  }

  // JSX Generation
  const rJSX_DialogActions = (): JSX.Element => {
    let dialogActionsJSX
    if (PromptDialogState != null && PromptDialogState['submitting'] === true) {
      dialogActionsJSX = (
        <Stack
          spacing={1}
          direction="row"
        >
          <Button
            color={pr_promptColor}
            disabled
            onClick={handleClose}
            variant="outlined"
          >
            {rLIB('Dismiss')}
          </Button>
          <Button
            color={pr_promptColor}
            disabled
            onClick={handleSubmit}
            variant="contained"
            startIcon={
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            }
          >
            {pr_confirmText}
          </Button>
        </Stack>
      )
    } else {
      dialogActionsJSX = (
        <Stack
          spacing={1}
          direction="row"
        >
          <Button
            color={pr_promptColor}
            onClick={handleClose}
            variant="outlined"
          >
            {rLIB('Dismiss')}
          </Button>
          <Button
            color={pr_promptColor}
            onClick={handleSubmit}
            variant="contained"
          >
            {pr_confirmText}
          </Button>
        </Stack>
      )
    }
    return dialogActionsJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Dialog
          // container={() => document.getElementById('root')}
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          className="bp_dialog_md_width"
          keepMounted
          onClose={handleClose}
          open={true}
        >
          <AppBar
            position="static"
            color={pr_promptColor as TsType_MuiAppBarColors}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                {pr_promptIcon}
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {pr_promptHeader}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box>
              <Typography
                variant="subtitle1"
                display="block"
              >
                {pr_promptText}
              </Typography>
            </Box>
            <Box>
              <FormControl fullWidth>
                <TextField
                  color={pr_promptColor}
                  defaultValue={pr_promptInputValue}
                  id="prompt_dialog_input"
                  label={pr_inputLabel}
                  margin="normal"
                  onChange={(event) => {
                    formInputChange(event)
                  }}
                  type={pr_inputType}
                  variant="outlined"
                />
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>{rJSX_DialogActions()}</DialogActions>
        </Dialog>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
